import React from 'react'
import RadioButton from '../RadioButton'
import Tooltip from 'react-tooltip-lite'
import Lightbulb from '../Lightbulb'
import FormFieldListItemLeukerBeterMaken from '../FormFieldListItemLeukerBeterMaken'
import SubtitleQuestionair from '../Atoms/Subtitle-Questionair'
import QuestionAddition from '../Atoms/Question-Addition'
import { X, CheckCircle, XCircle } from 'react-feather'

const CurrentWorklife = (props) => {
  console.log(props.item)
  return (
    <>
      <SubtitleQuestionair>{props.title}</SubtitleQuestionair>
      <QuestionAddition>{props.question}</QuestionAddition>
      <form class="flex flex-col md:flex-row" onChange={(e) => props.handleChange(e, props.index)}>
        {props.reversedPositive ? (
          <>
            <RadioButton
              labelValue={<span className="icon-cancelMarkedRound" />}
              checked={props.itemType === '1'}
              value="1"
              name={props.name}
              labelText="ja, helaas wel"
            />
            <RadioButton
              labelValue={<span className="icon-checkedMarkedRound" />}
              checked={props.itemType === '2'}
              value="2"
              name={props.name}
              labelText="Nee, gelukkig niet"
            />
          </>
        ) : (
          <>
            <RadioButton
              labelValue={<span className="icon-checkedMarkedRound" />}
              checked={props.itemType === '1'}
              value="1"
              name={props.name}
              labelText="Ja, gelukkig wel"
            />
            <RadioButton
              labelValue={<span className="icon-cancelMarkedRound" />}
              checked={props.itemType === '2'}
              value="2"
              name={props.name}
              labelText="Nee, helaas niet"
            />
          </>
        )}
      </form>
      <h3 className="font-bold text-gray-700 mb-3 mt-10">
        {props.typeOfGoal}
        <Tooltip
          content={
            <div>
              <span>Kan je bijvoorbeeld:</span>
              <ul className="list-disc">
                <li>er anders mee om gaan?</li>
                <li>je mindset veranderen?</li>
                <li>het met iemand bespreken?</li>
                <li>een expert inschakelen? </li>
                <li>je planning aanpassen?</li>
                <li>beter organiseren?</li>
                <li>delegeren, overdragen, uitbesteden?</li>
              </ul>
              <p>Kies je voor nee, dan kan dit betekenen dat je accepteert dat dit een minder leuk punt is.</p>
            </div>
          }
          className="inline-block z-20"
        >
          <Lightbulb />
        </Tooltip>
      </h3>

      <FormFieldListItemLeukerBeterMaken
        leukerMakenBool={props.item.leukerMaken}
        checked={props.item.leukerMaken}
        openPuntLeukerMaken={props.toggleActieToevoegen}
        itemIndex={props.index}
        arrayProp={props.typeOfImportant}
        setLeukerMakenBool={props.setLeukerMakenBool}
      />
    </>
  )
}

export default CurrentWorklife
