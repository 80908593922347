import React, { useEffect, useState } from 'react'
import moment from 'moment'
import TimelineSelect from './TimelineSelectTime'
import TimelineGoalNode from './TimelineGoalNode'

import { Plus, HelpCircle, Calendar } from 'react-feather'
import { makeDateToday } from '../../functions/utils'

import { makeDate } from '../../functions/utils'

import { checkGoalsOnMonth } from '../../functions/utils'
import TimelineExplanation from './TimelineExplanation'
import ContainerMain from '../../components/ContainerMain'
import ModuleNavigation from '../../components/ModuleNavigation'
import TimelineContainer from './TimelineContainer'
import TimelineQuestionMark from '../../components/Molecules/Timeline-QuestionMark'
import Tooltip from 'react-tooltip-lite'
import Lightbulb from '../../components/Lightbulb'
import PageExplanation from '../../components/Template/Page-Explanation'
import InfoUitlegModule from '../../components/InfoUitlegModule'
import ButtonMain from '../../components/ButtonMain'

const monthsOfTheYear = ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec']
const monthStyle = {
  gridColumn: '2',
  display: 'flex',
  backgroundColor: '#FFF',
  width: '60px',
  height: '23px',
  margin: '.2rem auto',
  flexDirection: 'column',
  justifyContent: 'center',
  fontWeight: 'bold',
  color: '#029d8b',
  fontSize: '14px',
  cursor: 'pointer',
}
const monthStyleSelected = {
  gridColumn: '2',
  display: 'flex',
  color: '#029d8b',
  width: '40px',
  height: '40px',
  margin: 'auto',
  flexDirection: 'column',
  justifyContent: 'center',
  fontSize: '12px',
  fontWeight: 'bold',
  cursor: 'pointer',
  position: 'absolute',
  top: '10px',
}

const SelectableMonth = (props) => {
  let gridRow = props.index

  if (props.index % 12 === 0 && props.index !== 0) {
    gridRow = props.index + 1
  } else {
    gridRow = gridRow + 1
  }

  if (props.selectedMonthName) {
    return <div style={{ ...monthStyleSelected, gridRow: 1 }}>{props.selectedMonthName}</div>
  }

  return (
    <>
      <div
        style={{ gridRow, gridColumn: '1/4' }}
        // className={'flex flex-col items-center relative z-10 h-6' + (props.index % 12 === 0 ? ' mb-8 mt-2' : '')}
        className={'flex flex-col items-center relative z-10'}
      >
        {props.node.year !== 0 && (
          <div
            className="border-t-2 wa-border-line absolute w-full"
            style={{
              top: '25%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
            }}
          />
        )}
        {props.node.year != 0 && (
          <span className="bg-green-1300 text-white text-xl px-6 flex items-center z-10">{props.node.year}</span>
        )}
        <div
          style={{ ...monthStyle, gridRow: props.index + 1 }}
          className="flex flex-col z-10"
          onClick={() => {
            props.selectOneMonth(props.node.monthNumber, props.node.monthName)
          }}
        >
          <span className="bg-red-1000 text-white px-2 font-normal rounded-full shadow-md">{props.node.monthName}</span>
        </div>
      </div>
    </>
  )
}

const gridContainer = {
  overflow: 'hidden',
}

const TimelineGrid = (props) => {
  const [amountOfMonthsArr, setAmountOfMonthsArr] = useState([])
  const [years, setYears] = useState([])
  const [monthSet, setMonthSet] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const haveYearGoals = []

  props.goals.forEach((goal) => {
    let year = moment(goal.deadline).format('YYYY')
    if (!haveYearGoals.find((y) => y.year === year)) {
      haveYearGoals.push({ year, hasGoal: true })
    }
  })

  function generateRange(min, max, step) {
    let arr = []
    let m = 0
    let year = 2021
    let updatedYear = 2022

    for (let i = min; i <= max; i += step) {
      updatedYear = Math.floor(year + i / 12)
      const currentYear = monthsOfTheYear[m] === 'jan' ? year + 1 : 0

      const node = {
        monthNumber: i,
        monthName: monthsOfTheYear[m],
        year: currentYear,
        currentYear: (year += monthsOfTheYear[m] === 'jan' ? 1 : 0),
      }
      m++
      if (m === 12) m = 0
      arr.push(node)
    }
    return arr
  }

  function generateYearRange(start, end) {
    var years = []
    for (var year = start; year <= end; year++) {
      years.push(year)
    }
    return years
  }

  const updateGoalTitle = (goal) => {
    alert('Update Title!')
  }

  useEffect(() => {
    const monthValues = [
      { name: 'jan', value: '01' },
      { name: 'feb', value: '02' },
      { name: 'mrt', value: '03' },
      { name: 'apr', value: '04' },
      { name: 'mei', value: '05' },
      { name: 'jun', value: '06' },
      { name: 'jul', value: '07' },
      { name: 'aug', value: '08' },
      { name: 'sep', value: '09' },
      { name: 'okt', value: '10' },
      { name: 'nov', value: '11' },
      { name: 'dec', value: '12' },
    ]

    //create array to generary nodes;
    let newArr = generateRange(1, props.amountOfMonths, 1)

    const years = generateYearRange(2021, 2030)
    newArr = newArr.filter((date) => {
      const month = monthValues.find((monthObj) => date.monthName === monthObj.name)
      const m = month.value
      if (
        moment()
          .subtract(1, 'months')
          .isAfter(moment(`${date.currentYear}-${m}-01`))
      ) {
        return false
      }
      return true
    })

    setAmountOfMonthsArr(newArr)
    setYears(years)
  }, [props.amountOfMonths, isLoading])

  return (
    <React.Fragment>
      <div className="relative w-full pt-8 position:relative;">
        <div className="flex flex-col w-full">
          <div className="flex flex-col md:flex-row w-full justify-center">
            <div className="flex flex-row mb-3 md:absolute md:left-0 z-10 w-1/2 md:w-1/3 "></div>
            <div className="flex flex-row w-full justify-center mt-1">
              <div
                className="flex h-6 md:h-12 w-full md:w-1/6 bg-wa-button-green rounded-full justify-center items-center cursor-pointer hover:bg-green-800"
                onClick={(e) => {
                  props.addGoalByType(e, 'werk')
                }}
              >
                <div className="flex justify-center items-center">
                  <Plus className="text-white w-8 h-4 md:w-4 stroke-4 mt-1 md:mr-1" />
                  <span className="text-white text-xs mr-1 md:text-xl md:mr-0">Werk</span>
                </div>
              </div>
              <div className="flex flex-col text-center mx-8 font-bold">
                <div className="wa-green-button-mb md:text-sm font-light text-black">Vandaag</div>
                <div className="wa-green-button-mb md:text-sm font-light text-black">{makeDateToday()}</div>
              </div>
              <div
                className="flex h-6 md:h-12 w-full md:w-1/6 bg-wa-button-turquoise rounded-full justify-center items-center cursor-pointer hover:bg-green-800"
                onClick={(e) => {
                  props.addGoalByType(e, 'prive')
                }}
              >
                <div className="flex justify-center items-center">
                  <Plus className="text-white w-8 h-4 md:w-4 stroke-4 mt-1 md:mr-1" />
                  <span className="text-white text-xs mr-1 md:text-xl md:mr-0">Prive</span>
                </div>
              </div>
            </div>
          </div>
          <div style={gridContainer} className="bg-white w-full overflow-hidden text-center">
            <div className="w-full mt-4 wa-line"></div>
            {props.zoomedIn && (
              <div
                className=" bg-red-1000 text-white text-sm px-2 font-normal rounded-full shadow-md z-50"
                style={{
                  position: 'absolute',
                  width: '60px',
                  zIndex: 50,
                  marginTop: '.2rem',
                  left: '47.5%',
                }}
              >
                {props.selectedMonthName}
              </div>
            )}
            <div
              style={{
                position: 'relative',
                backgroundColor: 'gray',
                ...props.divStyle,
              }}
            >
              <div
                style={{
                  // gridArea: '1 / 1 / auto / 4',
                  // gridColumn: 2,
                  height: '100%',
                  position: 'absolute',
                  // width: '34px',
                  borderRight: '7px #B64568 dashed',
                  zIndex: 2,
                  marginTop: '.5rem',
                  left: '49.5%',
                }}
              />
              {amountOfMonthsArr.map((node, index) => {
                return (
                  <SelectableMonth
                    key={node.monthName + index}
                    index={index}
                    node={node}
                    selectOneMonth={props.selectOneMonth}
                    selectedMonthName={props.selectedMonthName}
                    year={'2022'}
                    zoomedIn={props.zoomedIn}
                    setZoomedIn={props.setZoomedIn}
                    haveYearGoals={haveYearGoals}
                  />
                )
              })}

              {props.goalWork.length === 0 && props.loading === false && <TimelineQuestionMark column={1} />}

              {props.goalWork.length > 0 &&
                //maak setje per maand gebruik
                //max drie doelen als business logica
                props.goalWork.map((setOfMonths, index) => {
                  return (
                    <TimelineGoalNode
                      key={'work_goal_' + index}
                      index={index}
                      setOfMonths={setOfMonths}
                      setLoading={props.setLoading}
                      goals={props.goals}
                      column={1}
                      zoomedIn={props.zoomedIn}
                      isDashboard={props.isDashboard}
                      amountOfMonthsArr={amountOfMonthsArr}
                    />
                  )
                })}
              {props.goalPrivate.length === 0 && props.loading === false && <TimelineQuestionMark column={3} />}
              {props.goalPrivate.length > 0 &&
                //maak setje per maand gebruik
                //max drie doelen als business logica
                props.goalPrivate.map((setOfMonths, index) => {
                  return (
                    <TimelineGoalNode
                      key={'private_goal_' + index}
                      index={index}
                      setOfMonths={setOfMonths}
                      setLoading={props.setLoading}
                      goals={props.goals}
                      column={3}
                      zoomedIn={props.zoomedIn}
                      isDashboard={props.isDashboard}
                      amountOfMonthsArr={amountOfMonthsArr}
                    />
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TimelineGrid
