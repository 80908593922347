// Algemene Change Handler
function handleChange(event, itemIndex) {
  console.log('TRIGGERED')
  let arrayItems = this.state.arrayItems
  arrayItems[itemIndex][event.target.name] = event.target.value
  this.setState(
    {
      arrayItems: arrayItems,
    },
    () => {
      console.log(this.state)
    },
  )
}

export default handleChange
