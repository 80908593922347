import React, { useState, useEffect } from 'react'

const styleToggleDot = {
  top: '-.25rem',
  left: '-.25rem',
  transition: 'all 0.3s ease-in-out',
}

const styleInputToggle = {
  transform: 'translateX(100%)',
  backgroundColor: '#48bb78',
}

const ToggleButton = (props) => {
  return (
    <label htmlFor="toogleA" className="flex items-center cursor-pointer">
      <div className="relative">
        <input
          id="toogleA"
          type="checkbox"
          className="hidden"
          checked={props.value}
          value={props.value}
          onClick={() => {
            props.onChange()
          }}
        />
        <div
          className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"
          style={{
            backgroundColor: props.value ? '#48bb78' : '#8E8E8E',
          }}
        ></div>

        <div
          className="absolute w-6 h-6 bg-gray-500 rounded-full shadow inset-y-0 left-0"
          style={{
            ...styleToggleDot,
            transform: props.value ? 'translateX(100%)' : 'translateX(0%)',
          }}
        ></div>
      </div>
      <div className="ml-3 text-gray-700 font-medium">
        {props.title} ({props.value ? props.off : props.on})
      </div>
    </label>
  )
}

export default ToggleButton
