import React from 'react'

const ChartButton = (props) => {
  return (
    <button
      onClick={props.onClick}
      className={`bg-wa-button-light-green hover:bg-green-800 max-h-4 cursor-pointer rounded-full px-2 md:py-2 md:px-8 ml-1 mr-1`}
    >
      <span className={`${props.large ? 'text-sm md:text-5xl leading-none align-top' : 'text-sm md:text-base'}`}>
        {props.title}
      </span>
    </button>
  )
}

export default ChartButton
