export const config = {
  apiKey: 'AIzaSyASbtYbjXYeJESWvcjk3FjZt_8oDle73CM',
  authDomain: 'work-life-differently.firebaseapp.com',
  databaseURL: 'https://work-life-differently.firebaseio.com',
  projectId: 'work-life-differently',
  storageBucket: 'work-life-differently.appspot.com',
  messagingSenderId: '499269545522',
  appId: '1:499269545522:web:6e7dfd8c8e8e9d2f',
}

export const config_dev = {
  apiKey: 'AIzaSyDoyOS5M6__gIhioYfU2Y8iiw3a_LxcKBw',
  authDomain: 'dev-workappic-26340.firebaseapp.com',
  databaseURL: 'https://dev-workappic-26340.firebaseio.com',
  projectId: 'dev-workappic-26340',
  storageBucket: 'dev-workappic-26340.appspot.com',
  messagingSenderId: '653595215170',
  appId: '1:653595215170:web:18940d56b606d685ac74db',
}
