import React from 'react'

const How = (props) => {
  return (
    <div className="flex flex-col items-start mb-4">
      <h3 className="font-bold text-gray-700 mb-1 mt-4">Hoe ga ik dat doen?</h3>
      <input
        required
        name="hoeActie"
        className="border-b w-full px-2 py-1"
        placeholder="typ hier je antwoord"
        type="text"
        autoComplete="off"
        value={props.actionHowAction}
        onChange={(e) => {
          props.setActionHowAction(e.target.value)
          props.setActionHowSaved(true)
        }}
        data-index={1}
        data-formname="hoeForm"
      />
      <h3 className="font-bold text-gray-700 mb-1 mt-4">Wat heb ik hiervoor nodig?</h3>
      <input
        required
        placeholder="typ hier je antwoord"
        name="hoeNodig"
        className="border-b w-full px-2 py-1"
        type="text"
        autoComplete="off"
        value={props.actionHowNeed}
        onChange={(e) => {
          props.setActionHowNeed(e.target.value)
          props.setActionHowSaved(true)
        }}
        data-index={2}
        data-formname="hoeForm"
      />
      <h3 className="font-bold text-gray-700 mb-1 mt-4">Heb ik nog iets van anderen nodig?</h3>
      <input
        required
        placeholder="typ hier je antwoord"
        name="hoeAndere"
        className="border-b w-full px-2 py-1"
        type="text"
        autoComplete="off"
        value={props.actionHowOthers}
        onChange={(e) => {
          props.setActionHowOthers(e.target.value)
          props.setActionHowSaved(true)
        }}
        data-index={3}
        data-formname="hoeForm"
      />
      {/* onSubmit={(e) => saveToDatabase(e, true)} */}
      <button
        type="submit"
        className="mt-4 inline-block cursor-pointer bg-wa-green hover:bg-green-800 text-white rounded-full px-5 py-1 font-bold"
        onClick={props.updateAction}
      >
        {props.actionHowSaved ? 'Wijzigen' : 'Toevoegen'}
      </button>
    </div>
  )
}

export default How
