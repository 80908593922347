import React, { useState, useEffect } from 'react'
import CreatableSelect from 'react-select/creatable'

const Selectable = (props) => {
  const [selectableLabels, setSelectableLabels] = useState([])
  const [currentGoal, setCurrentGoal] = useState('Kies een doel...')

  const handleChange = (selectedGoal) => {
    setCurrentGoal(selectedGoal.label)
  }

  useEffect(() => {
    let namesOfLabels = []
    if (props.goal) {
      setCurrentGoal(props.goal.labelNaam)
    }
    if (props.goals.length > 0) {
      props.goals.map((goal) => {
        let labelObject = {
          value: goal.labelNaam,
          label: goal.labelNaam,
          UUID: goal.UUID,
        }
        namesOfLabels.push(labelObject)
      })
    }
    setSelectableLabels(namesOfLabels)
  }, [])

  return (
    <CreatableSelect
      name="labels"
      isMulti
      className=""
      placeholder={currentGoal}
      onChange={handleChange}
      options={selectableLabels}
      value={currentGoal}
    />
  )
}

export default Selectable
