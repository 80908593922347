import React from 'react'

import ModuleContent from './components/ModuleContent'

import MainHeader from './../../components/MainHeader'
import ContainerPage from './../../components/ContainerPage'
import ContainerMain from './../../components/ContainerMain'

class Werkleven extends React.Component {
  render() {
    const volgendeOnderdeel = parseInt(this.props.match.params.onderdeel) + 1
    const vorigeOnderdeel = parseInt(this.props.match.params.onderdeel) - 1
    const huidigeOnderdeel = this.props.match.params.onderdeel
    const huidigeSectie = this.props.match.params.sectie

    let headerTitle = ''
    switch (huidigeSectie) {
      case '1':
        headerTitle = '1. Doel'
        break
      case '2':
        headerTitle = '2. Cijfers'
        break
      case '3':
        headerTitle = '3. Huidig werkleven'
        break
      case '4':
        headerTitle = '4. Gewenst werkleven'
        break
      case '5':
        headerTitle = '5. Mogelijkheden'
        break
      default:
        headerTitle = 'Mijn werkleven'
    }

    return (
      <ContainerPage>
        <MainHeader title={headerTitle} />
        <ContainerMain>
          <ModuleContent
            volgendeOnderdeel={volgendeOnderdeel}
            vorigeOnderdeel={vorigeOnderdeel}
            huidigeOnderdeel={huidigeOnderdeel}
            huidigeSectie={huidigeSectie}
          />
        </ContainerMain>
      </ContainerPage>
    )
  }
}

export default Werkleven
