export const flowObjects = [
  { andersNamelijk: '', doelDatum: '', redenStart: [] },
  {
    GWCijfer: '',
    GWGevoel: '',
    GWSmiley: '',
    GWToelichting: '',
    HWCijfer: '',
    HWGevoel: '',
    HWSmiley: '',
    HWToelichting: '',
  },
  {
    LeukePunten: [],
    minderLeukePunten: [],
  },
  {
    belangrijkePunten: [],
    watWilIkPunten: [],
  },
  {
    LeukePunten: [],
    mijnWerkMogelijkheden: [],
    omschrijving: '',
    werklevenOpties: [],
  },
]
