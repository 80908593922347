import React, { Component } from 'react'
import PropTypes from 'prop-types'

class FormFieldItemContainer extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <div className="mb-1 mt-4">{this.props.children}</div>
  }
}

FormFieldItemContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default FormFieldItemContainer
