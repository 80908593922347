import React from 'react'

const Title = (props) => {
  return (
    <div className={`w-full h-8 text-base mt-2 mb-2 mr-4 text-white rounded-full px-5 py-1 text-center ${props.color}`}>
      {props.title} ({props.amount})
    </div>
  )
}

export default Title
