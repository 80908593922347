import React from 'react'

const IconCheckedUnChecked = (props) => {
  return props.checked ? (
    <span className="icon-checkedMarkedRound mr-2" />
  ) : (
    <span className="icon-cancelMarkedRound  mr-2" />
  )
}

export default IconCheckedUnChecked
