import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { goalStyling } from '../../functions/utils'
import { withFirebase } from '../../components/Firebase'
import { withAuthentication } from '../../components/Session'
import TimelineNode from './TimelineNode'

import { Flag } from 'react-feather'

const TimelineGoalNode = (props) => {
  const [goalIndex, setGoalIndex] = useState(null)

  //console.log('props.setOfMonths', props.setOfMonths)

  useEffect(() => {
    const monthValues = [
      { name: 'jan', value: '01' },
      { name: 'feb', value: '02' },
      { name: 'mrt', value: '03' },
      { name: 'apr', value: '04' },
      { name: 'mei', value: '05' },
      { name: 'jun', value: '06' },
      { name: 'jul', value: '07' },
      { name: 'aug', value: '08' },
      { name: 'sep', value: '09' },
      { name: 'okt', value: '10' },
      { name: 'nov', value: '11' },
      { name: 'dec', value: '12' },
    ]
    if (!props.amountOfMonthsArr && goalIndex) {
      return
    }

    const deadline = moment(props.setOfMonths[0]?.deadline)
    const selectedMonth = monthValues.find((mv, index) => index === deadline.month())

    const selectedIndex = props.amountOfMonthsArr.findIndex((m) => {
      const isSameYear = +deadline.format('YYYY') === m.currentYear
      //console.log('isSameYear.name', isSameYear,  m.currentYear)

      const isSameMonth = selectedMonth.name === m.monthName
      //console.log('isSameMonth.name', isSameMonth, selectedMonth.name)

      if (isSameYear && isSameMonth) {
        return true
      }
      return false
    })

    setGoalIndex(selectedIndex + 1)
  }, [props.amountOfMonthsArr, goalIndex])

  console.log('props.setOfMonths', props.setOfMonths)
  console.log('goalIndex', goalIndex)

  return (
    <>
      {props.setOfMonths[0] && (
        <div
          key={props.setOfMonths[0].labelNaam + '_' + props.index}
          style={{
            gridColumn: props.column,
            gridRowStart: props.zoomedIn ? 1 : goalIndex,
            zIndex: 99,
          }}
        >
          {props.setOfMonths.map((goal, index) => {
            return (
              <TimelineNode
                key={'timelineNode_' + index}
                goal={goal}
                goals={props.goals}
                index={index}
                setLoading={props.setLoading}
                isDashboard={props.isDashboard}
              />
            )
          })}
        </div>
      )}
    </>
  )
}

export default withAuthentication(withFirebase(TimelineGoalNode))
