import React from 'react'
import ReactDOM from 'react-dom'

import 'draft-js/dist/Draft.css'
import './css/tailwind.css'
import './css/styles.scss'
import * as serviceWorker from './serviceWorker'

import App from './components/App'
import Firebase, { FirebaseContext } from './components/Firebase'

import { DataLayer } from './DataLayer'
import reducer, { initialState } from './reducer'

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <DataLayer initialState={initialState} reducer={reducer}>
      <App />
    </DataLayer>
  </FirebaseContext.Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register()
