import React from 'react'
import { Link } from 'react-router-dom'

import smiley1 from '../../../assets/images/icons/smiley-1.png'
import smiley2 from '../../../assets/images/icons/smiley-2.png'
import smiley3 from '../../../assets/images/icons/smiley-3.png'
import smiley4 from '../../../assets/images/icons/smiley-4.png'
import smiley5 from '../../../assets/images/icons/smiley-5.png'

const SMILEYS = [smiley1, smiley2, smiley3, smiley4, smiley5]

const Ratings = (props) => {
  console.log('cijfers', props)
  return (
    <div className={`w-fullmd: mb-6 md:mb-0 md:w-1/4 ${props.marginRight ? 'mr-6' : ''}`}>
      <div className="bg-white h-full overflow-hidden md:p-5">
        <div className="flex justify-between h-full items-center flex-col flex-wrap md:py-6">
          <span className="font-bold text-center text-gray-800 text-base md:text-2xl">{props.title}</span>

          <span className="md:text-6xl text-base text-gray-800 font-bold leading-none">
            {props?.rating ? props.rating : '-'}
          </span>

          <span className="md:text-6xl text-base mt-5 text-gray-800 font-bold leading-none">
            {props?.smiley >= 0 ? <img src={SMILEYS[props.smiley]} height={52} width={52} /> : '-'}
          </span>

          <Link
            to="/mijn-werkleven/2/3"
            className="text-xs py-2 px-5 mt-6 w-40 text-center bg-wa-button-bordeaux hover:bg-green-800 text-white md:text-sm rounded-full cursor-pointer shadow-md"
          >
            {props.content}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Ratings
