import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { ChevronDown, ChevronUp, Plus, X } from 'react-feather'

class FormFieldListItemChevronToggle extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return this.props.openState === false ? (
      <ChevronDown
        onClick={this.props.toggleOpen}
        className="cursor-pointer inline-block absolute left-0 ml-3 mt-1 wa-chevron-color"
      />
    ) : (
      <ChevronUp
        onClick={this.props.toggleOpen}
        className="cursor-pointer inline-block absolute left-0 ml-3 mt-1 wa-chevron-color"
      />
    )
  }
}

FormFieldListItemChevronToggle.propTypes = {
  toggleOpen: PropTypes.func,
  open: PropTypes.bool,
}

export default FormFieldListItemChevronToggle
