import React from 'react'
import { Tag } from 'react-feather'
import Tooltip from 'react-tooltip-lite'
import Lightbulb from '../Lightbulb'
import SelectLabel from '../SelectLabel'
import Selectable from '../Molecules/Selectable'
import DeadLinePicker from './DeadLine-Picker'
import AddNotes from './Add-Notes'
import TitleGreen from '../Atoms/Titles-Green'

const ActionSelectGoals = (props) => {
  return (
    <div className="flex flex-col items-start">
      <div className="flex flex-col items-start">
        <span className="flex text-gray-700 font-bold md:font-lg">
          <TitleGreen title="Doelen" />
          <Tooltip
            content={
              <div className="max-w-sm">
                <p>Kies of maak een doel waar deze actie onder valt.</p>
              </div>
            }
            className="inline-block z-20"
          >
            <Lightbulb />
          </Tooltip>
        </span>
        <span className="text-xs md:text-base mb-3 md:mt-2 inline-block w-48 md:w-64">
          <Selectable
            goals={props.goals}
            goal={props.goal}
            fromActielijst={true}
            standardLabel={props.item.labels}
            index={1}
            handleLabelChange={props.handleLabelChange}
          />
        </span>
      </div>
      <DeadLinePicker
        setActionDeadline={props.setActionDeadline}
        actionDeadline={props.actionDeadline}
        edit={props.update}
      />
      <AddNotes notes={props.actionNotes} addNotes={props.setActionNotes} />
    </div>
  )
}
export default ActionSelectGoals
