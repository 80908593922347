import React from 'react'
import Editor from './../Editor'
import SelectLabel from './../SelectLabel'
import { withFirebase } from '../Firebase'
import RadioButton from './../RadioButton'
import Tooltip from 'react-tooltip-lite'
import { toast } from 'react-toastify'
import Lightbulb from './../Lightbulb'

import PopUpVoegActieToe from './../PopUpVoegActieToe'
import PopUpVoegMogelijkheidToe from './../PopUpVoegMogelijkheidToe'

import FormFieldListItemIngevuldCounter from './../FormFieldListItemIngevuldCounter'
import FormFieldListItemTitle from './../FormFieldListItemTitle'
import FormFieldListItemChevronToggle from './../FormFieldListItemChevronToggle'
import FormFieldListItemLeukerMakenLabel from './../FormFieldListItemLeukerMakenLabel'
import FormFieldListItemContainer from './../FormFieldListItemContainer'
import FormFieldListItemButtonVolgendePunt from './../FormFieldListItemButtonVolgendePunt'
import HorizontalLineDivider from './../HorizontalLineDivider'
import FormFieldListItemLeukerBeterMaken from './../FormFieldListItemLeukerBeterMaken'

import FormFieldLabel from './../FormFieldLabel'
import FormFieldItemContainer from './../FormFieldItemContainer'
import FormFieldTextArea from './../FormFieldTextArea'

import { ChevronDown, ChevronUp, Plus, X } from 'react-feather'
import SubtitleQuestionair from '../Atoms/Subtitle-Questionair'
import QuestionAddition from '../Atoms/Question-Addition'
import ProcessNextPoint from '../Organisms/Process-Next-Point'
import NewPossibilities from '../Template/New-Possibilities'
import CurrentWorklife from '../Template/Current-Worklife'

class ListItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      puntLeukerMaken: false,
      mogelijkheidHuidigPopUp: false,
      mogelijkheidGewenstPopUp: false,
      PuntToevoegenAlsActie: false,
    }
    this.openPuntLeukerMaken = this.openPuntLeukerMaken.bind(this)
    this.closeModule = this.closeModule.bind(this)
    this.togglePopUp = this.togglePopUp.bind(this)
    this.toggleActieToevoegen = this.toggleActieToevoegen.bind(this)
  }

  toggleActieToevoegen() {
    this.setState({
      PuntToevoegenAlsActie: !this.state.PuntToevoegenAlsActie,
    })
  }

  openPuntLeukerMaken(index) {
    this.setState({
      puntLeukerMaken: true,
    })
  }

  togglePopUp(popUpName) {
    this.setState(
      {
        [popUpName]: !this.state[popUpName],
      },
      () => {
        console.log(this.state)
      },
    )
  }

  closeModule() {
    this.setState({
      puntLeukerMaken: false,
    })
  }

  render() {
    const item = this.props.item

    let totaalIngevuld = 0

    console.log('item ingevuld', item)

    if (item.leukerMaken === true || item.leukerMaken === false) {
      totaalIngevuld += 1
    }
    if (item.nieuweMogelijkheid === true || item.nieuweMogelijkheid === false) {
      totaalIngevuld += 1
    }
    if (item.beschrijving && item.beschrijving.length > 0 && item.beschrijving !== '<p><br></p>') {
      totaalIngevuld += 1
    }
    if (item.oorsprong && item.oorsprong.length > 0 && item.oorsprong !== '<p><br></p>') {
      totaalIngevuld += 1
    }
    if (item.toelichting && item.toelichting.length > 0 && item.toelichting !== '<p><br></p>') {
      totaalIngevuld += 1
    }
    if (item.voldoetHuidigWerkleven && item.voldoetHuidigWerkleven.length > 0) {
      totaalIngevuld += 1
    }

    let itemChecked = false
    if (item.leukerMaken === true) {
      itemChecked = true
    }

    return (
      <FormFieldListItemContainer index={this.props.index} isOpen={item.open}>
        <FormFieldListItemChevronToggle
          toggleOpen={() => this.props.toggleOpen(this.props.index)}
          openState={item.open}
        />

        <FormFieldListItemLeukerMakenLabel leukerMakenBool={item.leukerMaken} />

        <FormFieldListItemIngevuldCounter aantalIngevuld={totaalIngevuld} aantalInTeVullen={5} />

        <FormFieldListItemTitle
          toggleOpen={() => this.props.toggleOpen(this.props.index)}
          title={item.title}
          index={this.props.index}
        />

        {item.open === true ? (
          <div className="mt-2">
            <FormFieldItemContainer>
              <FormFieldLabel>Waarom wil je dit?</FormFieldLabel>
              <Editor
                index={this.props.index}
                fieldName="beschrijving"
                setEditorState={this.props.setEditorState}
                initialEditorState={item.beschrijving}
              />
            </FormFieldItemContainer>

            <FormFieldItemContainer>
              <FormFieldLabel>Voldoet jouw huidige werkleven hieraan?</FormFieldLabel>
              <Editor
                index={this.props.index}
                fieldName="toelichting"
                setEditorState={this.props.setEditorState}
                initialEditorState={item.toelichting}
              />
            </FormFieldItemContainer>

            <CurrentWorklife
              item={item}
              itemType={item.voldoetHuidigWerkleven}
              index={this.props.index}
              toggleActieToevoegen={this.toggleActieToevoegen}
              handleChange={this.props.handleChange}
              setLeukerMakenBool={this.props.setLeukerMakenBool}
              title="Huidige Werkleven"
              name="voldoetHuidigWerkleven"
              question="Is dit van toepassing in jouw huidige werkleven?"
              typeOfGoal="Kan je actie ondernemen zodat je dit leuker/better kan maken in jouw huidige werkleven?"
              typeOfImportant="arrayItemsWatWilIk"
            />

            <NewPossibilities
              item={item}
              togglePopUp={this.togglePopUp}
              setNieuweMogelijkheidBool={this.props.setNieuweMogelijkheidBool}
              index={this.props.index}
              typeOfImportant="arrayItemsWatWilIk"
            />

            {this.props.index + 1 !== this.props.length ? (
              <ProcessNextPoint volgendeItem={this.props.volgendeItem} index={this.props.index} />
            ) : null}
          </div>
        ) : null}

        {this.state.MogelijkheidGewenstPopUp ? (
          <PopUpVoegMogelijkheidToe
            userID={this.props.userID}
            firebase={this.props.firebase}
            title={item.title}
            closeModule={() => this.togglePopUp('MogelijkheidGewenstPopUp')}
          />
        ) : null}
        {this.state.PuntToevoegenAlsActie ? (
          <PopUpVoegActieToe
            userID={this.props.userID}
            firebase={this.props.firebase}
            title={item.title}
            closeModule={this.toggleActieToevoegen}
          />
        ) : null}
      </FormFieldListItemContainer>
    )
  }
}

class ListWerkleven4ToggleableWensen extends React.Component {
  render() {
    const setLeukerMakenBool = this.props.setLeukerMakenBool
    const setNieuweMogelijkheidBool = this.props.setNieuweMogelijkheidBool
    const arrayItems = this.props.arrayItems
    const removeItem = this.props.removeItem
    const toggleOpen = this.props.toggleOpen
    const volgendeItem = this.props.volgendeItem
    const handleChange = this.props.handleChange
    const setEditorState = this.props.setEditorState
    const userID = this.props.userID
    const firebase = this.props.firebase

    return (
      <div id="main">
        <ol>
          {arrayItems.map((item, index) => (
            <ListItem
              setLeukerMakenBool={setLeukerMakenBool}
              setNieuweMogelijkheidBool={setNieuweMogelijkheidBool}
              key={index}
              item={item}
              index={index}
              toggleOpen={toggleOpen}
              length={arrayItems.length}
              volgendeItem={volgendeItem}
              handleChange={handleChange}
              setEditorState={setEditorState}
              userID={userID}
              firebase={firebase}
            />
          ))}
        </ol>
      </div>
    )
  }
}

export default withFirebase(ListWerkleven4ToggleableWensen)
