import React from 'react'
import MinderLeukePuntenLijst from './../../../../components/MinderLeukePuntenLijst'
import Lightbulb from './../../../../components/Lightbulb'
import Tooltip from 'react-tooltip-lite'
import ListWerkleven3ToggleableLeukePunten from './../../../../components/ListWerkleven3ToggleableLeukePunten'
import TitleQuestionair from '../../../../components/Atoms/Title-Questionair'

const ANSWERS_NOT_NICE = ['blijvendStructureel', 'leukerMaken', 'toelichting']
const ANSWERS_NICE = ['beschrijving', 'open']

class MijnWerkleven3Pagina4en5 extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.huidigeOnderdeel === '4' ? (
          <TitleQuestionair>D. Vind ik leuk - lijstje</TitleQuestionair>
        ) : (
          <TitleQuestionair>E. Vind ik leuk - analyseren</TitleQuestionair>
        )}
        {this.props.huidigeOnderdeel === '4' ? (
          <div className="mt-4">
            <h3 className="font-bold text-gray-700  mb-4">
              Wat vind jij leuk aan jouw huidige werkleven? Maak hieronder een lijstje.
              <Tooltip
                content={
                  <div>
                    <p>Stel jezelf de volgende vragen:</p>
                    <ul className="list-disc">
                      <li>Waar krijg ik energie van?</li>
                      <li>Wat geeft mij plezier?</li>
                      <li>Wat geeft mij voldoening? </li>
                      <li>Waar hoor ik mezelf positief over praten? </li>
                      <li>Waar ben ik over aan het dagdromen? </li>
                      <li>Waar ga ik van shinen? </li>
                    </ul>
                  </div>
                }
                className="inline-block z-20"
              >
                <Lightbulb />
              </Tooltip>
            </h3>
          </div>
        ) : (
          <div>
            <h3 className="font-bold text-gray-700 mb-4">Let's dive deeper. Beantwoordt per punt de vragen.</h3>
          </div>
        )}
        <div id="omschrijving">
          {this.props.huidigeOnderdeel === '4' ? (
            <React.Fragment>
              <MinderLeukePuntenLijst
                toggleEditItem={this.props.toggleEditItemLeuk}
                editTitleItem={this.props.editTitleItemLeuk}
                updateTitleItem={this.props.updateTitleItemLeuk}
                onSortEnd={this.props.onSortEndLeuk}
                arrayItems={this.props.arrayItems}
                addItem={this.props.addItemLeuk}
                removeItem={this.props.removeItemLeuk}
                changeTitle={this.props.changeTitle}
                loseFocus={this.props.loseFocus}
                amountQuestions={2}
                answers={ANSWERS_NICE}
                type={this.props.type}
              />
            </React.Fragment>
          ) : (
            <ListWerkleven3ToggleableLeukePunten
              setLeukerMakenBool={this.props.setLeukerMakenBool}
              arrayItems={this.props.arrayItems}
              addItem={this.props.addItemLeuk}
              removeItem={this.props.removeItemLeuk}
              toggleOpen={this.props.toggleOpenLeuk}
              volgendeItem={this.props.volgendeItemLeuk}
              handleChange={this.props.handleChange}
              setEditorState={this.props.setEditorState}
              userID={this.props.userID}
            />
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default MijnWerkleven3Pagina4en5
