import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

class ButtonMain extends Component {
  constructor(props) {
    super(props)
  }
  null
  render() {
    return (
      <Link
        to={this.props.link}
        className={`py-2 px-5 bg-${this.props.color}-600 hover: font-bold text-white md:text-lg rounded-lg shadow-md inline-block mt-5`}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </Link>
    )
  }
}

ButtonMain.propTypes = {
  color: PropTypes.string,
  link: PropTypes.string,
}

ButtonMain.defaultProps = {
  color: 'green',
}

export default ButtonMain
