// De volgende pagina's hebben een boolean waarde voor de First Visit van de pagina:
// - Dashboard
// - ToDoLijst (Actielijst)
// - Mijn Werkleven 1
// - Mijn Werkleven 2.1 (/mijn-werkleven/2/1)
// - Mijn Werkleven 2.3 (/mijn-werkleven/2/3)
// - Mijn Werkleven 3
// - Mijn Werkleven 4
// - Mijn Werkleven 5
// Deze state is terug te vinden in de 'users' collection in Firebug onder de property 'firstVisit'

// Functie om een specifieke FirstVisit boolean voor een gebruiker te wijzigen naar 'false'

// Functie om het FirstVisit object in de database te initializen
function initializeFirstVisitObject(uid, firebase, page, dataObject, setFirstVisitStateUitleg) {
  let docRef = firebase.db.collection('users').doc(uid).collection('modulen').doc('firstVisit')

  Object.keys(dataObject).forEach((key) => {
    dataObject[key] = false
  })

  let newObject = {}

  newObject = { ...dataObject, [page]: true }

  docRef
    .set(newObject)
    .then(function () {
      console.log('Document successfully written!')
      setFirstVisitStateUitleg(true)
    })
    .catch(function (error) {
      console.error('Error writing document: ', error)
    })
}

// Functie om de FirstVisit waarde van een specifieke pagina te checken
function checkFirstVisitPage(uid, firebase, page, setFirstVisitStateUitleg) {
  const docRef = firebase.db.collection('users').doc(uid).collection('modulen').doc('firstVisit')
  // API Call
  docRef
    .get()
    .then(function (doc) {
      if (doc.exists) {
        // Als de gebruiker nog geen firstVisit object heeft initializen we deze
        if (doc.data()[page] === undefined) {
          initializeFirstVisitObject(uid, firebase, page, doc.data(), setFirstVisitStateUitleg)
          // Save firstVisit state naar het component van waaruit deze functie wordt aangeroepen
          // Op basis van deze state wordt de uitleg popup bij het eerste bezoek getoond
          // Aangezien in deze if(){} de gebruiker nog geen object heeft is de firstVisit waarde altijd true
        } else {
          setFirstVisitStateUitleg(false)
        }
      }
    })
    .catch(function (error) {
      console.log('Error getting document:', error)
    })
}

export default checkFirstVisitPage
