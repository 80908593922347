import React from 'react'
import GewenstePuntenLijst from './../../../../components/GewenstePuntenLijst'
import ListWerkleven4ToggleableWensen from './../../../../components/ListWerkleven4ToggleableWensen'
import Lightbulb from './../../../../components/Lightbulb'
import Tooltip from 'react-tooltip-lite'
import TitleQuestionair from '../../../../components/Atoms/Title-Questionair'

const ANSWERS_IMPORTANT = ['beschrijving', 'beschrijving_uitgebreid', 'toelichting', 'open']
const ANSWERS_WHAT_I_WANT = ['beschrijving', 'leukerMaken', 'toelichting', 'voldoetHuidigWerkleven', 'open']

class MijnWerkleven4Pagina3en4 extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.huidigeOnderdeel === '3' ? (
          <TitleQuestionair>C. Wil ik wel - lijstje</TitleQuestionair>
        ) : (
          <TitleQuestionair>D. Wil ik wel - analyseren</TitleQuestionair>
        )}

        {this.props.huidigeOnderdeel === '3' ? (
          <div className="mt-4">
            <h3 className="font-bold text-gray-700 mb-4">
              Wat wil jij wel in jouw gewenste werkleven? Maak hieronder een lijstje.
              <Tooltip
                content={
                  <div>
                    <p>Stel jezelf de volgende vragen:</p>
                    <ul className="list-disc">
                      <li>Stel dat alles mogelijk is binnen jouw organisatie. Wat zou je dan het liefst willen?</li>
                      <li>Stel dat alles mogelijk is in jouw werkleven. Wat zou je dan het liefst willen?</li>
                      <li>
                        Stel dat je op je leven terugkijkt. Van welke doelen, dromen, wensen, verlangens, ideeën zou je
                        spijt kunnen krijgen als je ze niet nastreeft?
                      </li>
                      <li>Tip: Check jouw punten bij ‘Vind ik leuk’, welke punten wil je ook hier neerzetten?</li>
                      <li>Tip: Check jouw punten bij ‘Wil ik niet’, hoe zou je dat omzetten naar wat je wel wilt?</li>
                    </ul>
                  </div>
                }
                className="inline-block z-20"
              >
                <Lightbulb />
              </Tooltip>
            </h3>
          </div>
        ) : (
          <div className="mt-4">
            <h3 className="font-bold text-gray-700 mb-4">Let’s dive deeper. Beantwoordt per punt de vragen.</h3>
          </div>
        )}
        <div id="omschrijving">
          {this.props.huidigeOnderdeel === '3' ? (
            <React.Fragment>
              <GewenstePuntenLijst
                toggleEditItem={this.props.toggleEditItem}
                editTitleItem={this.props.editTitleItem}
                onSortEnd={this.props.onSortEnd}
                arrayItems={this.props.arrayItems}
                addItem={this.props.addItem}
                removeItem={this.props.removeItem}
                amountQuestions={5}
                changeTitle={this.props.changeTitle}
                loseFocus={this.props.loseFocus}
                type={'arrayItemsWatWilIk'}
                updateTitleItem={this.props.updateTitleItem}
                answers={ANSWERS_WHAT_I_WANT}
              />
            </React.Fragment>
          ) : (
            <ListWerkleven4ToggleableWensen
              setLeukerMakenBool={this.props.setLeukerMakenBool}
              setNieuweMogelijkheidBool={this.props.setNieuweMogelijkheidBool}
              arrayItems={this.props.arrayItems}
              addItem={this.props.addItem}
              removeItem={this.props.removeItem}
              toggleOpen={this.props.toggleOpen}
              volgendeItem={this.props.volgendeItem}
              handleChange={this.props.handleChange}
              setEditorState={this.props.setEditorState}
              userID={this.props.userID}
            />
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default MijnWerkleven4Pagina3en4
