import React from 'react'
import Tooltip from '../Atoms/Tooltip'
import SelectLabel from '../SelectLabel'
import TitleGreen from '../Atoms/Titles-Green'

const GoalsDropdown = (props) => {
  console.log('selectedLabel', props.standardLabel)
  return (
    <div className="flex">
      <div>
        <TitleGreen title="Doelen" />
        <Tooltip>Kies of maak een doel waar deze actie onder valt.</Tooltip>

        <span className="mt-2 inline-block w-64">
          <SelectLabel
            goals={props.goals || []}
            fromActielijst={true}
            selectedLabels={props.standardLabel}
            item={props.item}
            handleChange={props.handleGoalSelection}
          />
        </span>
      </div>
    </div>
  )
}

export default GoalsDropdown
