import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MainHeader from './../../components/MainHeader'
import { Package } from 'react-feather'

function Divider() {
  return <div className="h-1 block w-full mb-2 mt-2 border-b-2 border-gray-300" />
}

// Simple component containing a shitload of text
// This component contains the Usage policy
class VoorwaardenAlgemeen extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className="min-h-full pb-16 page-container">
        <MainHeader title="Algemene Voorwaarden WorkAppic" />
        <div className="flex">
          <div className="mx-auto container p-6 pt-12 text-gray-700">
            <h2 className="text-lg font-bold mb-2 mt-5">Inleiding</h2>
            <p className="mb-2">
              Mens & Werkplezier verleent je graag toegang tot de webapplicatie WorkAppic en nodigt je van harte uit
              kennis te nemen van ons aanbod. Wij behouden ons het recht voor op elk moment de inhoud van deze algemene
              voorwaarden aan te passen of onderdelen te verwijderen zonder daarover vooraf een mededeling te hoeven
              doen.
            </p>
            <p className="mb-2">
              Mens & Werkplezier biedt een platform WorkAppic waarop bezoekers (gebruikers) vrijblijvend gebruik kunnen
              maken van de informatie en functionaliteiten op het platform.
            </p>
            <p className="mb-2">
              Hieronder vind je de algemene voorwaarden die gelden voor het gebruik van de webapplicatie. Deze zijn
              steeds van toepassing als je gebruik maakt van de webapplicatie. Wij adviseren je dan ook deze voorwaarden
              goed door te nemen. Het is bovendien aan te raden deze gebruiksvoorwaarden op te slaan of af te drukken,
              zodat je deze op een later moment nog eens kunt teruglezen.
            </p>

            <h2 className="text-lg font-bold mb-2 mt-5">Artikel 1. Definities</h2>
            <ul className="pl-5 mb-2">
              <li className="py-1">1.1. WorkAppic: dienst van Mens & Werkplezier zoals beschreven in artikel 3.</li>
              <li className="py-1">1.2. Gebruiker: bezoeker en gebruiker van de Dienst.</li>
              <li className="py-1">1.3. Gebruiksvoorwaarden: onderhavige gebruiksvoorwaarden.</li>
              <li className="py-1">
                1.4. Overeenkomst: overeenkomst tussen WorkAppic en de Gebruiker ten behoeve van de Dienst.
              </li>
              <li className="py-1">
                1.5. Mens & Werkplezier: Mens & Werkplezier gevestigd in 1095 CN Amsterdam en ingeschreven bij de Kamer
                van Koophandel onder registratienummer 63411814.{' '}
              </li>
              <li className="py-1">
                1.6. Website: website van Mens & Werkplezier, WorkLifeDifferently, te raadplegen via
                www.worklifedifferently.com, www.mensenwerkplezier.nl en www.workappic.com en alle bijbehorende sub
                domeinen.
              </li>
            </ul>

            <h2 className="text-lg font-bold mb-2 mt-5">Artikel 2. Algemeen</h2>
            <ul className="pl-5 mb-2">
              <li className="py-1">
                2.1 Deze Gebruiksvoorwaarden zijn van toepassing op ieder gebruik van WorkAppic, tenzij schriftelijk
                anders overeen is gekomen. De Gebruiksvoorwaarden zijn op de Website ter hand gesteld aan de Gebruiker
                en kunnen op verzoek worden toegezonden.
              </li>
              <li className="py-1">2.2 Andere voorwaarden dan onderhavige, zijn niet van toepassing.</li>
              <li className="py-1">2.3 Mens & Werkplezier is gerechtigd de inhoud van deze voorwaarden te wijzigen.</li>
            </ul>

            <h2 className="text-lg font-bold mb-2 mt-5">Artikel 3. Dienstverlening</h2>
            <ul className="pl-5 mb-2">
              <li className="py-1">
                3.1 Voor het gebruik van WorkAppic vragen wij geen vergoeding aan de Gebruiker. Daar staat tegenover dat
                Mens & Werkplezier haar Dienst op ieder moment kan aanpassen. Mens & Werkplezier kan niet garanderen dat
                de Webapplicatie altijd beschikbaar is.
              </li>
              <li className="py-1">
                3.2 Mens & Werkplezier kan niet instaan voor de medische correctheid, volledigheid en actualiteit van de
                informatie op WorkAppic. De inhoud is niet bedoeld als vervanging van professioneel medisch advies. Het
                gebruik van informatie op WorkAppic is voor verantwoordelijkheid van de Gebruiker. Voor medisch advies
                voor de individuele situatie van de Gebruiker dient de Gebruiker zich direct te wenden tot een
                (behandelend) arts of ander soort (medisch)specialist. Mens & Werkplezier is weliswaar niet
                verantwoordelijk voor de inhoud van de (medische) informatie, maar streeft er wel naar de kwaliteit
                hiervan zo goed mogelijk te waarborgen.
              </li>
              <li className="py-1">
                3.3 Overeenkomsten die tussen de Gebruiker en de professional en overige zakelijke partners van Mens &
                Werkplezier tot stand komen, komen uitsluitend tot stand tussen de Gebruiker en een zakelijk partner van
                Mens & Werkplezier.
              </li>
              <li className="py-1">
                3.4 De inhoud van de content op WorkAppic is met de grootste zorgvuldigheid samengesteld. Mens &
                Werkplezier kan echter niet garanderen dat door haar zelf geplaatste informatie op WorkAppic te allen
                tijde juist en volledig is. Alle informatie op WorkAppic en in andere van Mens & Werkplezier afkomstige
                materialen zijn dan ook onder voorbehoud van kennelijke programmeer- en typefouten.
              </li>
              <li className="py-1">
                3.5 WorkAppic bevat eventueel links naar websites van derden. Mens & Werkplezier staat niet in voor de
                juiste werking van deze links. Mens & Werkplezier heeft geen zeggenschap over deze websites van derden
                en aanvaardt geen enkele aansprakelijkheid voor gebruik en/of bezoek aan deze websites.
              </li>
              <li className="py-1">
                3.6 Beperkingen of uitsluitingen van aansprakelijkheid zoals opgenomen in de Gebruiksvoorwaarden zijn
                niet van toepassing in geval van opzet of bewuste roekeloosheid aan de zijde van Mens & Werkplezier.
              </li>
            </ul>

            <h2 className="text-lg font-bold mb-2 mt-5">Artikel 4. Gebruik</h2>
            <ul className="pl-5 mb-2">
              <li className="py-1">
                4.1 De Gebruiker verklaart boven de zestien (16) jaar oud te zijn en de rechtsbevoegdheid te hebben om
                gebruik te maken van WorkAppic.
              </li>
              <li className="py-1">
                4.2 Indien de Gebruiker een afspraak inplant met een Mens & Werkplezier, of zich opgeeft voor een door
                een Mens & Werkplezier georganiseerde activiteit, is de Gebruiker zelf verantwoordelijk voor het feit of
                de afspraak (indien tegen betaling) ook gedekt is door zijn of haar zorgverzekering. Mens & Werkplezier
                raadt de Gebruiker aan eerst zijn of haar polisvoorwaarden te controleren voordat Gebruiker een
                overeenkomst met Mens & Werkplezier aangaat. De Gebruiker begrijpt dat een afspraak inplannen of
                inschrijving voor een activiteit een onherroepelijke overeenkomst kan zijn en dat hier kosten aan
                verbonden kunnen zijn.
              </li>
              <li className="py-1">
                4.3 De Gebruiker begrijpt dat een succesvolle afspraak en het ontvangen van het antwoord op een vraag,
                afhangt van de door hem opgegeven informatie en juistheid van zijn gegevens. Mens & Werkplezier is niet
                verantwoordelijk voor het eventueel onjuist en/of onvolledig verstrekken van informatie en
                (contact-)gegevens van en door de Gebruiker.
              </li>
              <li className="py-1">
                4.4 De Gebruiker verklaart informatie en gegevens naar waarheid te verstrekken en verstrekt gegevens die
                volledig, juist en actueel zijn.
              </li>
              <li className="py-1">
                4.5 De Gebruiker zal het gebruik van de WorkAppic app enkel voor persoonlijk gebruik gebruiken.
              </li>
              <li className="py-1">
                4.6 De door de Gebruiker en professional verstrekte informatie moet vrij zijn van technische fouten en
                malware.
              </li>
              <li className="py-1">
                4.7 De Gebruiker mag WorkAppic enkel gebruiken voor het doel waarvoor het bestemd is.
              </li>
              <li className="py-1">
                4.8 Indien een gebruiker gedrag vertoont dat strijdig is met enige bepaling in deze voorwaarden of
                andere voorwaarden die zijn afgesproken met Mens & Werkplezier, dan is Mens & Werkplezier gerechtigd om
                het gebruik van de app en/of de bijbehorende overeenkomst per direct te beëindigen”.
              </li>
            </ul>

            <h2 className="text-lg font-bold mb-2 mt-5">Artikel 5. Onderhoud</h2>
            <ul className="pl-5 mb-2">
              <li className="py-1">
                5.1 Mens & Werkplezier mag WorkAppic (tijdelijk) buiten gebruik stellen of het gebruik beperken, als dit
                bijvoorbeeld nodig is voor onderhoud of het aanpassen van de Website. Hierdoor ontstaat geen recht op
                schadevergoeding.
              </li>
            </ul>

            <h2 className="text-lg font-bold mb-2 mt-5">Artikel 6. Privacy</h2>
            <ul className="pl-5 mb-2">
              <li className="py-1">
                6.1 Mens & Werkplezier verwerkt persoonsgegevens van de Gebruiker conform het op de Website
                gepubliceerde privacy reglement.
              </li>
              <li className="py-1">
                6.2 Op de Website bevinden zich links naar webpagina’s van derden. Deze derden gaan op hun eigen manier
                om met persoonsgegevens. Mens & Werkplezier is niet aansprakelijk voor het privacy beleid van deze
                derden.
              </li>
              <li className="py-1">
                6.3 Gebruiker zal geen persoonsgegevens van een ander dan de Gebruiker, zoals een behandelend arts of
                een familielid, vermelden wanneer hij gegevens verstrekt via WorkAppic.
              </li>
            </ul>

            <h2 className="text-lg font-bold mb-2 mt-5">Artikel 7. Rechten van intellectuele eigendom</h2>
            <ul className="pl-5 mb-2">
              <li className="py-1">
                7.1 Alle rechten van intellectuele eigendom betreffende de materialen die door Mens & Werkplezier zelf
                gepubliceerd zijn op WorkAppic, liggen bij Mens & Werkplezier of bij de samenwerkende derden en/of haar
                licentiegevers. Kopiëren, verspreiden en elk ander gebruik van deze materialen buiten de mogelijkheden
                binnen WorkAppic, is niet toegestaan zonder schriftelijke toestemming van Mens & Werkplezier, behoudens
                en slechts voor zover anders bepaald in regelingen van dwingend recht (zoals citaatrecht), tenzij bij
                specifieke materialen anders aangegeven is.
              </li>
              <li className="py-1">
                7.2 Het is niet toegestaan webpagina’s of individuele elementen (zoals afbeeldingen, video’s of
                interactieve applicaties) van de Website op te nemen in bijvoorbeeld een frameset of via een inline link
                in een andere webpagina te verwerken, indien daarbij verwarring kan ontstaan over de afkomst van het
                materiaal.
              </li>
              <li className="py-1">
                7.3 Het in 7.1 gestelde houdt ook in dat het de Gebruiker en overige zakelijke partners niet is
                toegestaan, zonder voorafgaande schriftelijke toestemming van Mens & Werkplezier, een substantieel
                gedeelte van de inhoud van (een) databank(en) op te vragen en te hergebruiken en/of niet-substantiële
                gedeelten van de inhoud van een databank(en) herhaald en systematisch op te vragen en te hergebruiken in
                de zin van de Databankenwet.
              </li>
              <li className="py-1">
                7.4 De overige zakelijke partners zorgen ervoor dat de door hen verstrekte informatie, geen inbreuk
                maakt op (intellectuele eigendoms-) rechten van derden.
              </li>
              <li className="py-1">
                7.5 De overige zakelijke partners geven Mens & Werkplezier het recht de door hen aangeboden content aan
                te passen voordat publicatie plaatsvindt. Aanpassing vindt plaats met betrekking tot de leesbaarheid,
                leestekengebruik, grammatica en spelling. Mens & Werkplezier is niet verantwoordelijk voor eventuele
                fouten of wijzigingen die het gevolg zijn van aangebrachte wijzigingen door de redactie van Mens &
                Werkplezier.
              </li>
              <li className="py-1">
                7.6 De overige zakelijke partners leveren alleen teksten en gegevens aan die authentiek zijn en geen
                inbreuk maken op (intellectuele eigendoms-) rechten van derden en altijd onder bronvermelding als het
                niet eigen materiaal is.
              </li>
            </ul>

            <h2 className="text-lg font-bold mb-2 mt-5">Artikel 8. Contact, klachten en melding bij misbruik</h2>
            <ul className="pl-5 mb-2">
              <li className="py-1">
                8.1 Mens & Werkplezier neemt klachten en meldingen van misbruik van WorkAppic of anderszins uiterst
                serieus. In geval van vragen, opmerkingen, klachten, meldingen van misbruik of wanneer de Gebruiker meer
                informatie wenst over Mens & Werkplezier, dan kan contact worden opgenomen door middel van de daartoe
                bestemde contactmogelijkheden via Mens & Werkplezier of via de contactgegevens die onderaan de
                Gebruiksvoorwaarden staan vermeld.
              </li>
              <li className="py-1">
                8.2 De Gebruiker zal Mens & Werkplezier een redelijke termijn geven een klacht te behandelen.
              </li>
              <li className="py-1">
                8.3 Op klachten over WorkAppic zal Mens & Werkplezier zo spoedig mogelijk, maar uiterlijk binnen tien
                (10) dagen na ontvangst reageren. Indien de klacht niet leidt tot een oplossing, staat het de Gebruiker
                vrij een geschil voor te leggen aan de geschillencommissie via het Europese Online Dispute Resolution
                (ODR) platform via http://ec.europa.eu/consumers/odr/.
              </li>
            </ul>

            <h2 className="text-lg font-bold mb-2 mt-5">Artikel 9. Wijzigingen</h2>
            <ul className="pl-5 mb-2">
              <li className="py-1">
                9.1 Mens & Werkplezier is gerechtigd de Gebruiksvoorwaarden op ieder moment aan te passen.
              </li>
              <li className="py-1">
                9.2 Mens & Werkplezier zal de wijzigingen of aanvullingen ten minste zeven (7) dagen voor
                inwerkingtreding aankondigen via de Website zodat de Gebruiker daar kennis van kan nemen. Wijzigingen
                van ondergeschikt belang zullen zonder aankondiging in werking treden.
              </li>
              <li className="py-1">
                9.3 Indien de Gebruiker de wijzigingen niet wenst te accepteren, kan de Gebruiker de Overeenkomst onder
                de Gebruikersvoorwaarden opzeggen tot de datum van inwerkingtreding. Gebruik van WorkAppic na deze datum
                geldt als acceptatie van de gewijzigde Gebruiksvoorwaarden.
              </li>
            </ul>

            <h2 className="text-lg font-bold mb-2 mt-5">Artikel 10. Slotbepalingen</h2>
            <ul className="pl-5 mb-2">
              <li className="py-1">
                10.1 Op de Gebruiksvoorwaarden en de Overeenkomst tussen de Gebruiker en Mens & Werkplezier is
                Nederlands recht van toepassing.
              </li>
              <li className="py-1">
                10.2 Voor zover door regels van dwingend recht niet anders wordt voorgeschreven, zullen alle geschillen
                die mochten ontstaan tussen de Gebruiker en Mens & Werkplezier worden voorgelegd aan de bevoegde rechter
                bij de Rechtbank te Den Haag.
              </li>
              <li className="py-1">
                10.3 Indien een bepaling in de Gebruiksvoorwaarden nietig blijkt te zijn, tast dit niet de geldigheid
                van de gehele Gebruiksvoorwaarden aan, tenzij dit in strijd is met dwingend recht. Mens & Werkplezier
                zal in dat geval ter vervanging (een) nieuwe bepaling(en) vaststellen, waarmee zoveel als rechtens
                mogelijk is aan de bedoeling van de oorspronkelijke bepaling gestalte wordt gegeven.
              </li>
            </ul>

            <p className="mb-2">
              Mocht je na het lezen van deze Gebruiksvoorwaarden vragen, klachten of opmerkingen hebben, neem dan gerust
              contact met ons op.
            </p>
            <p className="mb-2">Mens & Werkplezier</p>
            <p className="mb-2">
              <a className="text-blue-500 underline pointer" href="mailto:hi@workappic.com">
                hi@workappic.com
              </a>{' '}
              of{' '}
              <a className="text-blue-500 underline pointer" href="mailto:glenn@mensenwerkplezier.nl">
                glenn@mensenwerkplezier.nl
              </a>{' '}
              of{' '}
              <a className="text-blue-500 underline pointer" href="mailto:nienke@mensenwerkplezier.nl">
                nienke@mensenwerkplezier.nl
              </a>{' '}
            </p>
            <p className="mb-2">KVK: 63411814</p>
            <p className="mb-2">BTW: 855224538B01</p>
            <p className="mb-2">IBAN: NL55 RABO 0304 1916 47</p>
          </div>
        </div>
      </div>
    )
  }
}

VoorwaardenAlgemeen.propTypes = {}

VoorwaardenAlgemeen.defaultProps = {}

export default VoorwaardenAlgemeen
