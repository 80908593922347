import React, { Component } from 'react'
import PropTypes from 'prop-types'

class FormFieldLabel extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <h3 className="font-bold text-gray-700 mb-1 mt-4">{this.props.children}</h3>
  }
}

FormFieldLabel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default FormFieldLabel
