import React from 'react'
import BelangrijkePuntenLijst from './../../../../components/BelangrijkePuntenLijst'
import ListWerkleven4ToggleableVoorwaarden from './../../../../components/ListWerkleven4ToggleableVoorwaarden'
import Lightbulb from './../../../../components/Lightbulb'
import Tooltip from 'react-tooltip-lite'
import TitleQuestionair from '../../../../components/Atoms/Title-Questionair'

const ANSWERS_IMPORTANT = ['leukerMaken', 'beschrijving_uitgebreid', 'toelichting', 'open']

class MijnWerkleven4Pagina1en2 extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.huidigeOnderdeel === '1' ? (
          <TitleQuestionair>A. Wil ik niet - lijstje</TitleQuestionair>
        ) : (
          <TitleQuestionair>B. Wil ik niet - analyseren</TitleQuestionair>
        )}
        {this.props.huidigeOnderdeel === '1' ? (
          <div className="mt-4">
            <h3 className="font-bold text-gray-700 mb-4">
              Wat wil jij niet in jouw gewenste werkleven? Maak hieronder een lijstje.{' '}
              <Tooltip
                content={
                  <div>
                    <p>Stel jezelf de volgende vragen:</p>
                    <ul className="list-disc pl-2 pt-2 p-4">
                      <li>Wat heb je nu wat je niet meer wilt?</li>
                      <li>Wat ben je liever kwijt dan rijk?</li>
                      <li>Waar wordt jij niet blij van en wil je dus echt niet in jouw werkleven?</li>
                    </ul>
                    <p>Tip: check jouw punten bij ‘Vind ik niet leuk’, welke punten wil je hier neerzetten?</p>
                  </div>
                }
                className="inline-block z-20"
              >
                <Lightbulb />
              </Tooltip>
            </h3>
          </div>
        ) : (
          <div className="mt-4">
            <h3 className="font-bold text-gray-700 mb-4">Let’s dive deeper. Beantwoordt per punt de vragen.</h3>
          </div>
        )}
        <div id="omschrijving">
          {this.props.huidigeOnderdeel === '1' ? (
            <React.Fragment>
              <BelangrijkePuntenLijst
                saveCurrentState={this.props.saveCurrentState}
                toggleEditItem={this.props.toggleEditItemBelangrijkePunten}
                editTitleItem={this.props.editTitleItemBelangrijkePunten}
                onSortEnd={this.props.onSortEndBelangrijkePunten}
                arrayItems={this.props.arrayItems}
                addItem={this.props.addItemBelangrijkePunten}
                removeItem={this.props.removeItemBelangrijkePunten}
                amountQuestions={4}
                changeTitle={this.props.changeTitle}
                loseFocus={this.props.loseFocus}
                type={'arrayItemsBelangrijk'}
                updateTitleItem={this.props.updateTitleItem}
                answers={ANSWERS_IMPORTANT}
              />
            </React.Fragment>
          ) : (
            <ListWerkleven4ToggleableVoorwaarden
              setLeukerMakenBool={this.props.setLeukerMakenBool}
              setNieuweMogelijkheidBool={this.props.setNieuweMogelijkheidBool}
              arrayItems={this.props.arrayItems}
              addItem={this.props.addItemBelangrijkePunten}
              removeItem={this.props.removeItemBelangrijkePunten}
              toggleOpen={this.props.toggleOpenBelangrijkePunten}
              volgendeItem={this.props.volgendeItemBelangrijkePunten}
              handleChange={this.props.handleChange}
              setEditorState={this.props.setEditorState}
              userID={this.props.userID}
            />
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default MijnWerkleven4Pagina1en2
