import React from 'react'
import ContentLoader from 'react-content-loader'

const LoaderEditor = () => (
  <ContentLoader height={120} width={400}>
    <rect x="0" y="0" rx="4" ry="4" width="400" height="10" />
    <rect x="0" y="15" rx="5" ry="5" width="400" height="100" />
  </ContentLoader>
)

export default LoaderEditor
