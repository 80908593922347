import React, { Component } from 'react'
import PropTypes from 'prop-types'

function SmallGreenLabel(props) {
  return (
    <li className="text-xs px-2 md:w-30 md:px-6 md:py-4 wg-dark-green-bg ms:text-sm rounded-full inline md:mr-6 flex justify-start items-center text-center">
      <span className="align-top block">{props.children}</span>
    </li>
  )
}

function DataPlaceholder(props) {
  return <span className="italic font-sm text-gray-700 w-full text-center">{props.children}</span>
}

class DashboardDoelenSlider extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <ul className="flex flex-grow mr-4 overflow-x-auto w-full">
        {this.props.goals ? (
          this.props.goals?.map((item, index) => {
            return <SmallGreenLabel key={item.labelNaam + index}>{item.labelNaam}</SmallGreenLabel>
          })
        ) : (
          <DataPlaceholder>Hier komen je doelen</DataPlaceholder>
        )}
      </ul>
    )
  }
}

DashboardDoelenSlider.propTypes = {}

DashboardDoelenSlider.defaultProps = {}

export default DashboardDoelenSlider
