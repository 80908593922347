import React from 'react'
import { Plus } from 'react-feather'

const AddButton = (props) => {
  return (
    <button
      type="submit"
      onClick={props.onClick}
      className="py-2 px-5 mt-3 text-center bg-wa-button-green border-2	border-white hover:bg-green-800 text-white md:text-base rounded-full cursor-pointer"
    >
      {props.title}
    </button>
  )
}

export default AddButton
