import React from 'react'
import ContentLoader from 'react-content-loader'

const LoaderEditor = () => (
  <ContentLoader height={120}>
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="25" />
    <rect x="0" y="30" rx="5" ry="5" width="100%" height="25" />
    <rect x="0" y="60" rx="5" ry="5" width="100%" height="25" />
    <rect x="0" y="90" rx="5" ry="5" width="100%" height="25" />
  </ContentLoader>
)

export default LoaderEditor
