import React from 'react'
import Lightbulb from '../Lightbulb'
import TooltipLite from 'react-tooltip-lite'

const Tooltip = (props) => {
  return (
    <TooltipLite
      content={
        <div className="max-w-sm">
          <p>{props.children}</p>
        </div>
      }
      className="inline-block z-20"
    ></TooltipLite>
  )
}

export default Tooltip
