import React from 'react'
import ButtonSmallGreen from './Button-Small-Green'

const TitleInput = ({ name, value, handleOnChange, placeholder }) => (
  <div className="flex flex-col md:flex-row md:w-full md:items-center">
    <input
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        handleOnChange(e)
      }}
      className="flex-1 text-base md:text-base py-2 px-4 md:py-3 md:px-2 text-black border md:h-14 mr-4"
    />
    <ButtonSmallGreen name={name} value={value} onClick={handleOnChange} title="Wijzig" isNotCompleted />
  </div>
)

export default TitleInput
