import React, { useState, useEffect } from 'react'
import { withFirebase } from './../Firebase'
import Select from 'react-select'
import { getAllGoals } from '../../functions/goalsHelper'
import { withAuthentication } from '../Session'

const SelectLabel = (props) => {
  const [allGoals, setAllGoals] = useState([])
  const [selectedLabel, setSelectedLabel] = useState('')

  const selection = (e) => {
    setSelectedLabel(e.value)
    props.handleChange(e)
  }

  useEffect(() => {
    if (props.item) {
      const findGoal = allGoals?.find((g) => g.UUID === props.item.UUID) || ''

      setSelectedLabel(findGoal.labelNaam)
    }
  }, [allGoals, props.item])

  useEffect(() => {
    getAllGoals(props.firebase, props.authUser).then((g) => {
      setAllGoals(g)
    })
  }, [])

  return (
    <Select
      options={allGoals?.map((g) => ({ value: g.labelSlug, label: g.labelNaam, UUID: g.UUID, name: 'labels' }))}
      isSearchable={false}
      onChange={(e) => selection(e)}
      value={selectedLabel}
      placeholder={selectedLabel || 'Koppel...'}
      name="labels"
    />
  )
}

export default withAuthentication(withFirebase(SelectLabel))
