import React, { useState } from 'react'

import ActionTools from '../Molecules/ActionsTools'
import Deadline from '../Atoms/Deadline'
import ActionToolTitle from '../Atoms/ActionTool-Title'
import { deleteActionFromFB, updateAction } from '../../functions/actionsHelper'
import { withAuthentication } from '../Session'
import { withFirebase } from '../Firebase'
import { makeDate } from '../../functions/utils'
import { CheckCircle, X } from 'react-feather'
import DatePicker from 'react-date-picker'

const ActionToolsBar = (props) => {
  // get action deadline or get from goal
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const [editAction, setEditAction] = useState(false)
  const [actionTitle, setActionTitle] = useState(props.action.actieNaam || '')
  const actionDeadline = props.action.deadline

  const deleteClickedAction = () => {
    deleteActionFromFB(props.firebase, props.authUser, props.action, props.goal).then((result) => {
      //Reload the page when closing the popup
      //this is a hack untill redux is implemented
      props.deleteAction(result)
    })
  }

  const newActionDate = (e) => {
    if (!e) {
      setDatePickerOpen((prev) => !prev)
      return null
    }

    let newDate = ''

    const month = String(e.getMonth() + 1).padStart(2, '0')
    const day = String(e.getDate()).padStart(2, '0')
    const year = e.getFullYear()
    newDate = year + '-' + month + '-' + day

    let action = props.action
    action.deadline = newDate

    updateAction(props.firebase, props.authUser, action, props.goal).then(() => {
      setDatePickerOpen((prev) => !prev)
    })
  }

  const toggleDoneState = (e, action) => {
    e.preventDefault()

    if (!props.showSettings) {
      const updatedAction = { ...action, voltooid: !action.voltooid }

      if (action.UUID) {
        console.log('action from a goal')
        props.updateCurrentAction(updatedAction)
      } else {
        updateAction(props.firebase, props.authUser, updatedAction, props.goal).then((result) => {
          console.log('result', result)
          props.setSortedActions([...result])
          setEditAction(false)
        })
      }
    }
  }

  const editActionTitle = (e, action) => {
    setActionTitle(e.target.value)
    if (e.key === 'Enter') {
      const updatedAction = { ...action, actieNaam: e.target.value }
      updateAction(props.firebase, props.authUser, updatedAction, props.goal).then((result) => {
        props.setSortedActions([...result])
        setEditAction(false)
      })
    }
  }

  return (
    <>
      {props.completedActions.length >= 1 && props.index === 0 && (
        <div className="flex">
          <h2 className="font-bold text-xl md:text-2xl wa-green-color mb-4">Voltooide acties</h2>
        </div>
      )}
      {props.action.voltooid === props.isDone && (
        <div
          key={props.index}
          className={`flex items-center wa-green-color z-20 text-base border-b relative px-4 py-3 mb-2 relative rounded-full wa-list-container-bg ${
            props.action.voltooid === true && 'line-through'
          }`}
        >
          <ActionToolTitle
            action={props.action}
            title={actionTitle}
            state={props.action.voltooid}
            toggleState={toggleDoneState}
            index={props.index}
            changeTitle={editActionTitle}
            setEditAction={setEditAction}
            editAction={editAction}
          />
          {!datePickerOpen && (
            <Deadline
              date={actionDeadline}
              state={props.action.voltooid}
              onClick={() => {
                setDatePickerOpen((prev) => !prev)
              }}
            />
          )}
          {datePickerOpen && (
            <div className="flex flex-row px-2 text-center  items-center justify-center ml-auto">
              <DatePicker
                onChange={(e) => {
                  newActionDate(e)
                }}
                name={'deadline'}
                value={actionDeadline ? new Date(actionDeadline) : ''}
                format={'dd-MM-y'}
                locale={'nl-NL'}
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                required
              />
            </div>
          )}
          <ActionTools
            action={props.action}
            deleteAction={deleteClickedAction}
            index={props.index}
            goal={props.goal}
            goals={props.goals}
            firebase={props.firebase}
            user={props.authUser}
            setLoading={props.setLoading}
            toggleDoubtFlow={props.toggleDoubtFlow}
            doubtFlow={props.doubtFlow}
            updatedAction={props.updatedAction}
            setSelectedGoal={props.setSelectedGoal}
            selectedAction={props.selectedAction}
          />
        </div>
      )}
    </>
  )
}

export default withAuthentication(withFirebase(ActionToolsBar))
