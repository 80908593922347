import React, { useState, useEffect } from 'react'
import Tooltip from 'react-tooltip-lite'
import { pages } from '../../text.json'
import Lightbulb from '../../components/Lightbulb'
import AddButton from '../../components/Atoms/AddButton'
import Modal from '../../components/Template/Modal'
import ActielijstItemPopup from '../../components/ActielijstItemPopup'
import PopUpVoegDoelToe from '../../components/PopUpVoegDoelToe/PopUpVoegDoelToe'
import { CheckCircle, Circle, Settings } from 'react-feather'
import PopUpGoalSettings from '../../components/Template/PopUpGoalSettings'
import InfoUitlegModule from '../../components/InfoUitlegModule'
import { Link } from 'react-router-dom'
import ButtonSmallGreen from '../../components/Atoms/Button-Small-Green'
import { flowObjects } from '../../utils/flowObjects'
import { useHistory } from 'react-router-dom'

const GoallistSideBar = (props) => {
  const [modalType, setModalType] = useState(false)
  const [selectedGoals, setSelectedGoal] = useState(undefined)
  const [goalSettings, setGoalSettings] = useState(false)
  const history = useHistory()

  const openSettings = (goal) => {
    setSelectedGoal(goal)
  }

  const readInfo = (button) => {
    let docRef = props.firebase.collection('users').doc(props.user.uid).collection('modulen').doc('firstVisit')

    docRef.get().then((doc) => {
      if (doc.exists) {
        if (doc.data()['actionlist'] === undefined) {
          docRef
            .set({ ...doc.data(), actionlist: false })
            .then(function () {
              if (button) {
                history.push('/tijdlijn')
              }
              console.log('Document successfully written!')
            })
            .catch(function (error) {
              console.error('Error writing document: ', error)
            })
        } else {
          if (button) {
            history.push('/tijdlijn')
          }
        }
      }
    })
  }

  useEffect(() => {
    props.reload((prev) => !prev)
  }, [modalType])

  return (
    <>
      {selectedGoals && (
        <Modal
          onClose={() => {
            setSelectedGoal(undefined)
          }}
        >
          <PopUpGoalSettings
            goal={selectedGoals}
            goals={props.currentGoals}
            setLoading={props.reload}
            toggleGoalSettingPopUp={setSelectedGoal}
          />
        </Modal>
      )}
      {modalType && (
        <Modal
          onClose={() => {
            setModalType(false)
          }}
        >
          <PopUpVoegDoelToe
            goals={props.currentGoals}
            goalWork={[]}
            goalPrivate={[]}
            setLoading={props.reload}
            closeModule={() => {
              setModalType(false)
            }}
            selectedType={'werk'}
            displayNotes={true}
            buttonTitle={'Maak doel aan'}
            choiceType={true}
          />
        </Modal>
      )}
      <div className="p-5">
        <span className="text-white font-lg block w-40">
          <span className="text-2xl">Doelen</span>
          <Tooltip
            content={
              <div className="max-w-sm">
                <p>
                  <b>{pages.actionlist.explanationTitleGoals1}</b>
                </p>
                <p>
                  <b>{pages.actionlist.explanationTitleGoals2}</b>
                </p>
                <br />
                <b>{pages.actionlist.explanationTitleGoals3}</b>
                <ul className="list-disc">
                  {pages.actionlist.explanationPointsGoals3?.map((t) => (
                    <li>{t}</li>
                  ))}
                </ul>
                <br />
                <b>{pages.actionlist.explanationTitleGoals4}</b>
                <ul className="list-disc">
                  {pages.actionlist.explanationPointsGoals4?.map((t) => (
                    <li>{t}</li>
                  ))}
                </ul>
                <br />
                <b>{pages.actionlist.explanationTitleGoals5}</b>
                <ul className="list-disc">
                  {pages.actionlist.explanationPointsGoals5?.map((t) => (
                    <li>{t}</li>
                  ))}
                </ul>
                <p>{pages.actionlist.explanationTitleGoals6}</p>
              </div>
            }
            className="inline-block z-20"
          >
            <Lightbulb />
          </Tooltip>
        </span>
        <div>
          <AddButton
            title="+ Doel"
            onClick={() => {
              setModalType(true)
            }}
          />
          <ul className="mt-2">
            {props.currentGoals?.map((g, index) => {
              return (
                <li
                  key={index}
                  className="flex text-white mb-4 font-xl cursor-pointer"
                  onClick={() => {
                    openSettings(g)
                  }}
                >
                  <span className="flex w-full pr-4">
                    {g.labelNaam} ({g.actions.length})
                  </span>
                  <span>
                    <Settings
                      size={window.innerWidth >= 500 ? 24 : 15}
                      className="-ml-4 text-xs md:mr-2 relative cursor-pointer -mt-1 relative"
                    />
                  </span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div>
        <InfoUitlegModule firstVisit={props.firstVisit} user={props.user} firebase={props.firebase} readInfo={readInfo}>
          <div className="text-gray-800">
            <h4 className="font-bold ">{pages.actionlist.pageExplanationTitle}</h4>
            <React.Fragment>
              <p className=" mt-2">{pages.actionlist.pageExplanation}</p>
              <p className=" mt-2">{pages.actionlist.pageExplanationActionsTitle}</p>
              <ul className="list-disc">
                {pages.actionlist.pageExplanationPoints?.map((t) => (
                  <li>{t}</li>
                ))}
              </ul>
              <p className=" mt-2">{pages.actionlist.pageExplanationGoalsTitle}</p>
              <ul className="list-disc">
                {pages.actionlist.pageExplanationGoalsPoints?.map((t) => (
                  <li>{t}</li>
                ))}
              </ul>
              <p className=" mt-2">{pages.actionlist.pageExplanationBear}</p>
              <ul className="mb-6 list-disc">
                {pages.actionlist.pageExplanationBearPoints?.map((t) => (
                  <li>{t}</li>
                ))}
              </ul>
              <p className="mt-1 mb-4">{pages.actionlist.pageExplanationActionsTip}</p>
              <p>
                <Link
                  onClick={() => {
                    readInfo(true)
                  }}
                >
                  <ButtonSmallGreen title="Ga naar Tijdlijn" isNotCompleted />
                </Link>
              </p>
            </React.Fragment>
          </div>
        </InfoUitlegModule>
      </div>
    </>
  )
}

export default GoallistSideBar
