import React from 'react'
import { Link } from 'react-router-dom'
import Tooltip from 'react-tooltip-lite'
import { toast } from 'react-toastify'

import Editor from './../Editor'
import Slider from './../Slider'
import SelectLabel from './../SelectLabel'
import { withFirebase } from '../Firebase'
import Lightbulb from './../Lightbulb'

import { ChevronDown, ChevronUp, Plus, X, Trash2, PlusCircle, MinusCircle, CheckCircle } from 'react-feather'

import PopUpVoegActieToe from './../PopUpVoegActieToe'

import FormFieldListItemIngevuldCounter from './../FormFieldListItemIngevuldCounter'
import FormFieldListItemTitle from './../FormFieldListItemTitle'
import FormFieldListItemChevronToggle from './../FormFieldListItemChevronToggle'
import FormFieldListItemLeukerMakenLabel from './../FormFieldListItemLeukerMakenLabel'
import FormFieldListItemContainer from './../FormFieldListItemContainer'
import Modal from '../Template/Modal'
import FormFieldListItemButtonVolgendePunt from './../FormFieldListItemButtonVolgendePunt'
import HorizontalLineDivider from './../HorizontalLineDivider'
import FormFieldListItemLeukerBeterMaken from './../FormFieldListItemLeukerBeterMaken'

import FormFieldLabel from './../FormFieldLabel'
import FormFieldItemContainer from './../FormFieldItemContainer'
import FormFieldTextArea from './../FormFieldTextArea'
import PopUpVoegDoelToe from '../PopUpVoegDoelToe/PopUpVoegDoelToe'
import { getAllGoals } from '../../functions/goalsHelper'

class ListItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      puntLeukerMaken: false,
      openAddGoalPopUp: false,
      goals: [],
    }
    this.openPuntLeukerMaken = this.openPuntLeukerMaken.bind(this)
    this.closeModule = this.closeModule.bind(this)
    this.toggleOpenAddGoalPopUp = this.toggleOpenAddGoalPopUp.bind(this)
  }

  openPuntLeukerMaken() {
    this.setState({
      puntLeukerMaken: true,
    })
  }

  closeModule() {
    this.setState({
      puntLeukerMaken: false,
    })
  }

  toggleOpenAddGoalPopUp() {
    this.setState({
      openAddGoalPopUp: !this.state.openAddGoalPopUp,
    })
  }

  componentDidMount() {
    let firebase = this.props.firebase
    let that = this

    firebase.auth.onAuthStateChanged(function (user) {
      getAllGoals(firebase, user).then((result) => {
        console.log('result mogelijkheden', result)
        that.setState({ goals: result })
      })
    })
  }

  render() {
    const item = this.props.item

    let totaalIngevuld = 0

    if (item.beschrijving && item.beschrijving.length > 0 && item.beschrijving !== '<p><br></p>') {
      totaalIngevuld += 1
    }
    if (item.blijvendStructureel && item.blijvendStructureel.length > 0 && item.blijvendStructureel !== '<p><br></p>') {
      totaalIngevuld += 1
    }
    if (item.minPunten.length > 0 || item.plusPunten.length > 0) {
      totaalIngevuld += 1
    }
    if (item.toelichting && item.toelichting.length > 0 && item.toelichting !== '<p><br></p>') {
      totaalIngevuld += 1
    }
    if (item.cijfer) {
      totaalIngevuld += 1
    }

    return (
      <FormFieldListItemContainer index={this.props.index} isOpen={item.open}>
        <FormFieldListItemChevronToggle
          toggleOpen={() => this.props.toggleOpen(this.props.index)}
          openState={item.open}
        />
        <div className="mr-20 absolute right-0 top-2  md:top-0">
          <div className="flex md:px-4 md:py-1 md:mt-3 text-sm  rounded-full text-green-500 font-bold">
            <PlusCircle
              size={25}
              className="wa-green-color mr-1 md:-mt-1 md:mr-3 relative select-none pointer-events-none actie-icon-voltooid "
            />
            <span className="wa-green-color mr-4">{item.plusPunten.length}</span>
            <MinusCircle
              size={25}
              className="wa-bordeaux-color mr-1 md:-mt-1 md:mr-3 relative select-none pointer-events-none actie-icon-voltooid  "
            />
            <span className="wa-red-color ml-2">{item.minPunten.length}</span>
          </div>
        </div>

        <FormFieldListItemLeukerMakenLabel leukerMakenBool={item.leukerMaken} />

        <FormFieldListItemIngevuldCounter aantalIngevuld={totaalIngevuld} aantalInTeVullen={5} />

        <FormFieldListItemTitle
          toggleOpen={() => this.props.toggleOpen(this.props.index)}
          title={item.title}
          index={this.props.index}
        />

        {item.open === true ? (
          <div className="mt-2">
            <h3 className="font-bold text-gray-700 mb-1 mt-4">Beschrijf deze mogelijkheid.</h3>
            <Editor
              index={this.props.index}
              fieldName="beschrijving"
              setEditorState={this.props.setEditorState}
              initialEditorState={item.beschrijving}
            />
            <div className="mt-4 mb-6 w-full">
              <div className="md:flex block">
                <div className="mr-10 w-full md:w-1/2">
                  <h3 className="font-bold text-gray-700 mb-1 mt-4">Pluspunten</h3>
                  <form
                    className="flex mt-2 mb-4"
                    onSubmit={(e) => {
                      e.preventDefault()
                      this.props.voegPlusOfMinpuntToe(e, 'plusPunten', this.props.index)
                      this.forceUpdate()
                    }}
                  >
                    <input
                      required
                      name="plusPunten"
                      className="border w-full px-2 py-1"
                      type="text"
                      autoComplete="off"
                    />
                    <button
                      type="submit"
                      className="wg-dark-green-bg hover:bg-green-800 px-5 py-1 inline-block font-bold text-white rounded whitespace-no-wrap shadow w-1/2 text-center"
                    >
                      voeg toe
                    </button>
                  </form>
                  <ul className="mb-4 mt-2">
                    {item.plusPunten
                      ? item.plusPunten.map((item, index) => {
                          return (
                            <li key={index} className="flex w-full relative mt-2 pr-6">
                              <PlusCircle
                                size={25}
                                className="wa-green-color -mt-1 mr-3 relative select-none pointer-events-none actie-icon-voltooid border-2 border-white"
                              />
                              {item}
                              <Trash2
                                className="text-red-200 cursor-pointer absolute top-0 right-0 hover:text-red-700"
                                onClick={() => {
                                  this.props.verwijderPlusOfMinpunt('plusPunten', this.props.index, index)
                                }}
                                size={20}
                              />
                            </li>
                          )
                        })
                      : null}
                  </ul>
                </div>
                <div className="mr-10 w-full md:w-1/2">
                  <h3 className="font-bold text-gray-700 mb-1 mt-4">Minpunten</h3>
                  <form
                    className="flex mt-2 mb-4"
                    onSubmit={(e) => {
                      e.preventDefault()
                      this.props.voegPlusOfMinpuntToe(e, 'minPunten', this.props.index)
                      this.forceUpdate()
                    }}
                  >
                    <input
                      required
                      name="minPunten"
                      className="border w-full px-2 py-1"
                      type="text"
                      autoComplete="off"
                    />
                    <button
                      type="submit"
                      className="wg-dark-green-bg hover:bg-green-800 px-5 py-1 inline-block font-bold text-white rounded whitespace-no-wrap shadow w-1/2 text-center"
                    >
                      voeg toe
                    </button>
                  </form>
                  <ul className="mb-4 mt-2">
                    {item.minPunten
                      ? item.minPunten.map((item, index) => {
                          return (
                            <li key={index} className="w-full flex relative mt-2 pr-6">
                              <MinusCircle
                                size={25}
                                className="wa-bordeaux-color -mt-1 mr-3 relative select-none pointer-events-none actie-icon-voltooid border-2 border-white"
                              />
                              {item}
                              <Trash2
                                className="text-red-200 cursor-pointer absolute top-0 right-0 hover:text-red-700"
                                onClick={() => {
                                  this.props.verwijderPlusOfMinpunt('minPunten', this.props.index, index)
                                }}
                                size={20}
                              />
                            </li>
                          )
                        })
                      : null}
                  </ul>
                </div>
              </div>
            </div>
            <h3 className="font-bold text-gray-700 mb-1 mt-4">
              Check of deze mogelijkheid voldoet aan wat jij niet wilt en wel wilt in jouw gewenste werkleven.
            </h3>
            <div className="md:flex block my-4">
              <div className="w-full md:w-1/2">
                <h4 className="font-bold">Wil ik niet</h4>
                <ul className="pl-4">
                  {this.props.punten &&
                  this.props.punten.belangrijkePunten &&
                  this.props.punten.belangrijkePunten.length > 0 ? (
                    this.props.punten.belangrijkePunten.map((punt) => (
                      <li key={punt.index} className="list-disc">
                        {punt.title}
                      </li>
                    ))
                  ) : (
                    <li className="list-disc">Je hebt nog geen eisen</li>
                  )}
                </ul>
              </div>
              <div className="w-full mt-2 md:w-1/2 md:mt-0">
                <h4 className="font-bold">Wil ik wel</h4>
                <ul className="pl-4">
                  {this.props.punten &&
                  this.props.punten.watWilIkPunten &&
                  this.props.punten.watWilIkPunten.length > 0 ? (
                    this.props.punten.watWilIkPunten.map((punt) => (
                      <li key={punt.index} className="list-disc">
                        {punt.title}
                      </li>
                    ))
                  ) : (
                    <li className="list-disc">Je hebt nog geen wensen</li>
                  )}
                </ul>
              </div>
            </div>
            <Editor
              index={this.props.index}
              fieldName="toelichting"
              setEditorState={this.props.setEditorState}
              initialEditorState={item.toelichting}
            />
            <h3 className="font-bold text-gray-700 mb-1 mt-4">
              Wat zou deze mogelijkheid jou op kunnen leveren?
              <Tooltip
                content={
                  <div className="max-w-sm">
                    <p>Bijvoorbeeld:</p>
                    <ul className="list-disc">
                      <li>lekker shinen</li>
                      <li>een ideaal werkleven</li>
                      <li>meer energie en vrolijker</li>
                      <li>de wereld veroveren</li>
                      <li>gewoon lekkere werkdagen </li>
                      <li>werkplezier verhogen</li>
                      <li>een hoger cijfer voor mijn gewenste werkleven </li>
                      <li>voldoening</li>
                      <li>ontspanning</li>
                    </ul>
                  </div>
                }
                className="inline-block z-20"
              >
                <Lightbulb />
              </Tooltip>
            </h3>
            <textarea
              name="blijvendStructureel"
              value={item.blijvendStructureel}
              onChange={(e) => {
                this.props.handleChange(e, this.props.index)
              }}
              className="border w-full p-4"
            />
            <h3 className="font-bold text-gray-700 mb-1 mt-4">
              Als je deze mogelijkheid gaat doen, welk cijfer geef je dan jouw werkleven?
            </h3>
            <Slider
              nestedArray={true}
              sliderValue={item.cijfer}
              onChange={this.props.handleSliderChange}
              index={this.props.index}
              keyName="cijfer"
            />
            <h3 className="font-bold text-gray-700 mb-1 mt-10">Wat ga je doen met deze mogelijkheid?</h3>
            {this.props.index + 1 !== this.props.length ? (
              <div className="md:flex block mb-6">
                <button
                  onClick={this.openPuntLeukerMaken}
                  className="wg-dark-green-bg hover:bg-blue-800 px-5 py-1 inline-block font-bold text-white rounded whitespace-no-wrap shadow md:w-1/2 w-full md:mt-0 mt-2 text-center mr-2"
                >
                  Actie ondernemen
                </button>
                <button
                  className="wg-dark-green-bg hover:bg-green-800 px-5 py-1 inline-block font-bold text-white rounded whitespace-no-wrap shadow md:w-1/2 w-full md:mt-0 mt-2 text-center ml-0 md:ml-2"
                  onClick={() => this.toggleOpenAddGoalPopUp()}
                >
                  Op tijdlijn zetten
                </button>
                <button
                  className="wg-dark-green-bg hover:bg-green-800 px-5 py-1 inline-block font-bold text-white rounded whitespace-no-wrap shadow md:w-1/2 w-full md:mt-0 mt-2 text-center ml-0 md:ml-2"
                  onClick={() => this.props.volgendeItem(this.props.index)}
                >
                  Volgende mogelijkheid verwerken
                </button>

                {this.state.openAddGoalPopUp ? (
                  <Modal onClose={this.toggleOpenAddGoalPopUp}>
                    <PopUpVoegDoelToe
                      setLoading={() => {}}
                      closeModule={this.toggleOpenAddGoalPopUp}
                      goals={this.state.goals}
                      displayNotes={true}
                      title={item.title}
                      buttonTitle={'Maak doel aan'}
                      choiceType={true}
                    />
                  </Modal>
                ) : null}
              </div>
            ) : (
              <div className="flex mb-6">
                <button
                  onClick={this.openPuntLeukerMaken}
                  className="wg-dark-green-bg hover:bg-blue-800 px-5 py-1 inline-block font-bold text-white rounded whitespace-no-wrap shadow md:w-1/2 w-full md:mt-0 mt-2 text-center mr-2"
                >
                  Direct uitvoeren
                </button>
                <button
                  onClick={() => {
                    alert(
                      'Om jouw mogelijkheid verder te onderzoeken zijn er diverse modules (zie dashboard rechtsonderaan) . Deze worden door Nienke en Glenn gemaakt. Zodra deze klaar zijn, worden ze aan WorkAppic toegevoegd.',
                    )
                  }}
                  className="wg-dark-green-bg hover:bg-blue-800 px-5 py-1 inline-block font-bold text-white rounded whitespace-no-wrap shadow md:w-1/2 w-full md:mt-0 mt-2 text-center mr-2"
                >
                  Verder onderzoeken
                </button>
              </div>
            )}
          </div>
        ) : null}
        {this.state.puntLeukerMaken ? (
          <PopUpVoegActieToe
            userID={this.props.userID}
            firebase={this.props.firebase}
            title={item.title}
            closeModule={this.closeModule}
          />
        ) : null}
      </FormFieldListItemContainer>
    )
  }
}

class ListWerkleven5ToggleableMogelijkheden extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      punten: null,
    }
  }

  componentDidMount() {
    let firebase = this.props.firebase
    let that = this

    firebase.auth.onAuthStateChanged(function (user) {
      if (user) {
        var docRef = firebase.db
          .collection('users')
          .doc(user.uid)
          .collection('modulen')
          .doc('mijnWerkleven')
          .collection('subModulen')
          .doc('4')

        // Haal data op uit de databse
        docRef
          .get()
          .then(function (doc) {
            if (doc.exists) {
              that.setState(
                {
                  punten: doc.data(),
                },
                () => console.log(that.state),
              )
            } else {
              that.setState({
                loading: false,
                authUser: user.uid,
              })
            }
          })
          .catch(function (error) {
            console.log('Error getting document:', error)
          })
      }
    })
  }

  render() {
    const arrayItems = this.props.arrayItems
    const removeItem = this.props.removeItem
    const toggleOpen = this.props.toggleOpen
    const volgendeItem = this.props.volgendeItem
    const handleChange = this.props.handleChange
    const setEditorState = this.props.setEditorState
    const userID = this.props.userID
    const firebase = this.props.firebase
    const voegPlusOfMinpuntToe = this.props.voegPlusOfMinpuntToe

    return (
      <div id="main">
        <ol>
          {arrayItems.map((item, index) => (
            <ListItem
              firebase={firebase}
              userID={userID}
              item={item}
              index={index}
              removeItem={removeItem}
              toggleOpen={toggleOpen}
              volgendeItem={volgendeItem}
              handleChange={handleChange}
              setEditorState={setEditorState}
              voegPlusOfMinpuntToe={voegPlusOfMinpuntToe}
              verwijderPlusOfMinpunt={this.props.verwijderPlusOfMinpunt}
              handleSliderChange={this.props.handleSliderChange}
              punten={this.state.punten}
            />
          ))}
        </ol>
      </div>
    )
  }
}

export default withFirebase(ListWerkleven5ToggleableMogelijkheden)
