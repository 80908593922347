import React, { Component } from 'react'
import PropTypes from 'prop-types'

class FormFieldListItemLeukerMakenLabel extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return this.props.leukerMakenBool ? (
      <div className="absolute px-4 text-base py-1 wa-bg-orange text-yellow-800 top-0 right-0 rounded-full mt-2 mr-20 mb-3">
        Better
      </div>
    ) : null
  }
}

FormFieldListItemLeukerMakenLabel.propTypes = {
  leukerMakenBool: PropTypes.bool,
}

FormFieldListItemLeukerMakenLabel.defaultProps = {
  leukerMakenBool: false,
}

export default FormFieldListItemLeukerMakenLabel
