import React, { useState } from 'react'
import { ChevronDown, ChevronUp, Plus } from 'react-feather'
import ActielijstHoeForm from '../ActielijstHoeForm'
import How from '../Organisms/How'
import PopUpActionsEdit from '../Template/PopUp-Actions-Edit'

const ActionDoubtsTools = (props) => {
  const [howOpen, setHowOpen] = useState(false)
  return (
    <>
      <div className="md:mb-6 border-b-2 border-gray-300 border-dashed w-full flex justify-start items-start md:items-center">
        <div className="inline-block flex justify-center items-middle">
          <div
            className="my-1 text-xs md:text-base md:my-4 cursor-pointer hover:bg-blue-500 hover:text-white rounded px-5 py-1 border-blue-500 border font-bold text-blue-500"
            data-index={1}
            data-name="hoeForm"
            onClick={() => {
              setHowOpen((prev) => !prev)
            }}
          >
            {howOpen ? (
              <ChevronUp className="pointer-events-none inline-block relative -mt-1 mr-1" />
            ) : (
              <ChevronDown className="pointer-events-none inline-block relative -mt-1 mr-1" />
            )}
            Hoe
          </div>
        </div>
        <div className="inline-block flex justify-center items-middle">
          <span
            className="my-1 text-xs ml-16 md:text-base md:my-4 cursor-pointer hover:bg-blue-500 hover:text-white rounded px-5 py-1 border-blue-500 border font-bold text-blue-500"
            data-index={1}
            data-name="twijfelForm"
            onClick={() => {
              props.setToggleDoubts(true)
            }}
          >
            <Plus className="inline-block relative -mt-1 mr-1" />
            Ik Twijfel
          </span>
        </div>
      </div>
      {howOpen && (
        <How
          action={props.action}
          index={1}
          handleHoeOfTwijfelChange={() => {}}
          updateAction={props.updateAction}
          handleChange={props.handleChange}
          actionHowAction={props.actionHowAction}
          setActionHowAction={props.setActionHowAction}
          actionHowOthers={props.actionHowOthers}
          setActionHowOthers={props.setActionHowOthers}
          actionHowNeed={props.actionHowNeed}
          setActionHowNeed={props.setActionHowNeed}
          actionHowSaved={props.actionHowSaved}
          setActionHowSaved={props.setActionHowSaved}
          setHow={props.setHow}
          actionHow={props.actionHow}
        />
      )}
    </>
  )
}

export default ActionDoubtsTools
