// Import Packages
import React from 'react'
import arrayMove from 'array-move'

import { withFirebase } from './../../../../components/Firebase'
import { withRouter } from 'react-router-dom'

import checkFirstVisitPage from './../../../../functions/checkFirstVisitPage'

// Import Module Components
import MijnWerkleven4PaginaIntro from './../../components/MijnWerkleven4PaginaIntro'
import MijnWerkleven4Pagina1en2 from './../../components/MijnWerkleven4Pagina1en2'
import MijnWerkleven4Pagina3en4 from './../../components/MijnWerkleven4Pagina3en4'

// Import Functions
import savingStateToAPI from './../../../../functions/savingStateToAPI'
import savedStateToAPI from './../../../../functions/savedStateToAPI'
import resetSavingState from './../../../../functions/resetSavingState'

// Import Components
import ModuleNavigation from './../../../../components/ModuleNavigation'

// Main Container voor Module 4 van Mijn Werkleven
class MijnWerkleven4 extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      arrayItemsWatWilIk: [],
      arrayItemsBelangrijk: [],
      loading: true,
      authUser: '',
      saving: false,
      saved: false,
    }

    // Algemene Form State functies
    this.handleChange = this.handleChange.bind(this)
    this.setEditorStateWatWilIk = this.setEditorStateWatWilIk.bind(this)

    // Specifieke Pagina 2 Functies
    this.onSortEnd = this.onSortEnd.bind(this)
    this.toggleEditItem = this.toggleEditItem.bind(this)
    this.editTitleItem = this.editTitleItem.bind(this)
    this.addItem = this.addItem.bind(this)
    this.removeItem = this.removeItem.bind(this)

    // Specifieke Pagina 3 Functies
    this.toggleOpen = this.toggleOpen.bind(this)
    this.volgendeItem = this.volgendeItem.bind(this)

    // Save functies
    this.savingStateToAPI = savingStateToAPI.bind(this)
    this.savedStateToAPI = savedStateToAPI.bind(this)
    this.resetSavingState = resetSavingState.bind(this)
    this.saveCurrentState = this.saveCurrentState.bind(this)
    this.saveCurrentStateAndExit = this.saveCurrentStateAndExit.bind(this)

    this.handleChangeBelangrijkePunten = this.handleChangeBelangrijkePunten.bind(this)
    this.setEditorStateBelangrijkePunten = this.setEditorStateBelangrijkePunten.bind(this)

    // Minder Leuke functies
    this.toggleEditItemBelangrijkePunten = this.toggleEditItemBelangrijkePunten.bind(this)
    this.editTitleItemBelangrijkePunten = this.editTitleItemBelangrijkePunten.bind(this)
    this.onSortEndBelangrijkePunten = this.onSortEndBelangrijkePunten.bind(this)
    this.addItemBelangrijkePunten = this.addItemBelangrijkePunten.bind(this)
    this.removeItemBelangrijkePunten = this.removeItemBelangrijkePunten.bind(this)
    this.volgendeItemBelangrijkePunten = this.volgendeItemBelangrijkePunten.bind(this)
    this.toggleOpenBelangrijkePunten = this.toggleOpenBelangrijkePunten.bind(this)

    // Andere functies
    this.setLeukerMakenBool = this.setLeukerMakenBool.bind(this)
    this.setNieuweMogelijkheidBool = this.setNieuweMogelijkheidBool.bind(this)
    this.setOpenStateToFirstItem = this.setOpenStateToFirstItem.bind(this)
    this.changeTitle = this.changeTitle.bind(this)
    this.updateTitleItem = this.updateTitleItem.bind(this)
  }

  changeTitle = (item, type) => {
    if (type === 'arrayItemsWatWilIk') {
      const arrayItemsWatWilIk = this.state.arrayItemsWatWilIk.map((a, i) => {
        if (a.title === item.title) {
          return { ...a, editing: !a.editing }
        }
        return { ...a, editing: false }
      })

      this.setState({ arrayItemsWatWilIk })
    } else {
      const arrayItemsBelangrijk = this.state.arrayItemsBelangrijk.map((a, i) => {
        if (a.title === item.title) {
          return { ...a, editing: !a.editing }
        }
        return a
      })
      this.setState({ arrayItemsBelangrijk })
    }
  }

  loseFocus = (subModule, type) => {
    console.log('subModule, type', subModule, type)
  }

  updateTitleItem = (e, item, type) => {
    if (e.key === 'Enter') {
      if (type === 'arrayItemsWatWilIk') {
        const arrayItemsWatWilIk = this.state.arrayItemsWatWilIk.map((a, i) => {
          if (a.index === item.index) {
            return { ...a, editing: false, title: e.target.value }
          }
          return { ...a, editing: false }
        })

        this.setState({ arrayItemsWatWilIk }, () => {
          this.saveCurrentState()
        })

        //this.setState({ arrayItems })
      } else {
        const arrayItemsBelangrijk = this.state.arrayItemsBelangrijk.map((a, i) => {
          if (a.index === item.index) {
            return { ...a, editing: false, title: e.target.value }
          }
          return a
        })

        this.setState({ arrayItemsBelangrijk }, () => {
          this.saveCurrentState()
        })

        //this.setState({ arrayItemsLeuk })
      }
      //const arrayItems = this.state.arrayItems.map((a, i) => ({ ...a,  editing: false }))
      //const arrayItemsLeuk = this.state.arrayItemsLeuk.map((a, i) => ({ ...a, editing: false }))
    }
    //const arrayItemsWatWilIk = this.state.arrayItemsWatWilIk.map((a, i) => ({ ...a, editing: false }))
    //const arrayItemsBelangrijk = this.state.arrayItemsBelangrijk.map((a, i) => ({ ...a, editing: false }))
    //this.setState({ arrayItemsWatWilIk, arrayItemsBelangrijk }, () => {
    //  this.saveCurrentState()
    //})
  }

  // Functie om de open state te resetten naar het eerste item van een rij
  // Wordt gebruikt na verwijderen en herordenen van items
  setOpenStateToFirstItem = (items) => {
    items.map((item, index) => {
      if (index === 0) {
        item.open = true
      } else {
        item.open = false
      }
      return item
    })
    return items
  }

  setLeukerMakenBool(boolState, index, arrayName) {
    console.log('LEUKER MAKEN', boolState, index, arrayName)
    let newArray = this.state[arrayName]
    newArray[index].leukerMaken = boolState

    this.setState(
      {
        [arrayName]: newArray,
      },
      () => console.log(this.state),
    )
  }

  setNieuweMogelijkheidBool(index, arrayName, init) {
    let newArray = this.state[arrayName]

    if (init) {
      newArray[index].nieuweMogelijkheid = false
    } else if (!newArray[index].nieuweMogelijkheid) {
      newArray[index].nieuweMogelijkheid = true
    } else {
      newArray[index].nieuweMogelijkheid = false
    }

    // if (!newArray[index].leukerMaken) {
    //   newArray[index].leukerMaken = true;
    // } else {
    //   newArray[index].leukerMaken = false;
    // }
    this.setState({
      [arrayName]: newArray,
    })
  }

  /////////////////////////////////////////
  //** Functies voor 'Wat Wil Ik' Items //
  ///////////////////////////////////////

  handleChange(event, itemIndex) {
    let arrayItemsWatWilIk = this.state.arrayItemsWatWilIk
    arrayItemsWatWilIk[itemIndex][event.target.name] = event.target.value
    this.setState(
      {
        arrayItemsWatWilIk: arrayItemsWatWilIk,
      },
      () => {
        console.log(this.state)
      },
    )
  }

  setEditorStateWatWilIk(stateValue, fieldName, itemIndex) {
    if (itemIndex !== undefined) {
      let arrayItemsWatWilIk = this.state.arrayItemsWatWilIk
      arrayItemsWatWilIk[itemIndex][fieldName] = stateValue
      this.setState({
        arrayItemsWatWilIk: arrayItemsWatWilIk,
      })
    } else {
      this.setState({
        [fieldName]: stateValue,
      })
    }
  }

  // Wijzigt de Array volgorde na Drag 'n Drop
  onSortEnd = ({ e, oldIndex, newIndex }) => {
    let reorderedItems = arrayMove(this.state.arrayItemsWatWilIk, oldIndex, newIndex)
    reorderedItems = reorderedItems.map((item, index) => {
      item.index = index
      return item
    })
    reorderedItems = this.setOpenStateToFirstItem(reorderedItems)
    this.setState({
      arrayItemsWatWilIk: reorderedItems,
    })
  }

  // Voegt een nieuw item toe aan arrayItems
  addItem(arrayItem) {
    let newValues = this.state.arrayItemsWatWilIk
    let openState = false
    if (newValues.length === 0) {
      openState = true
    }
    newValues.push({
      index: newValues.length + 1,
      title: arrayItem.newItemValue,
      beschrijving: '',
      toelichting: '',
      oorsprong: '',
      voldoetHuidigWerkleven: '',
      open: openState,
      editing: false,
    })
    this.setState({ arrayItemsWatWilIk: newValues }, () => {
      this.saveCurrentState()
    })
  }

  // Verwijdert een item uit arrayItems

  removeItem(event, item, listPosition, flowIndex, type) {
    const confirmDelete = window.confirm(`Weet je zeker dat je dit item wilt verwijderen?`)

    if (confirmDelete) {
      let newValues = this.state.arrayItemsWatWilIk
      newValues.splice(listPosition, 1)
      this.setOpenStateToFirstItem(newValues)
      this.setState({ arrayItemsWatWilIk: newValues }, () => this.saveCurrentState())
    }
  }

  // Toggle de editing state van een arrayItem
  toggleEditItem(itemIndex) {
    let newValues = this.state.arrayItemsWatWilIk
    newValues[itemIndex].editing = !newValues[itemIndex].editing
    this.setState({ arrayItemsWatWilIk: newValues })
  }

  // Wijzig de title van een arrayItem en reset de editing state
  editTitleItem(event, itemIndex) {
    let newValues = this.state.arrayItemsWatWilIk
    newValues[itemIndex].title = event.target.value
    this.setState({ arrayItemsWatWilIk: newValues })
  }

  // Open het volgende item
  volgendeItem(itemIndex) {
    let arrayItems = this.state.arrayItemsWatWilIk
    arrayItems.forEach((item) => {
      item.open = false
    })
    const nextItem = itemIndex + 1

    const element = document.getElementById(`list-item-${itemIndex}`)
    const y = element.getBoundingClientRect().top + window.scrollY - 10

    arrayItems[nextItem].open = true
    this.setState(
      {
        arrayItemsWatWilIk: arrayItems,
      },
      () => {
        this.saveCurrentState()
        console.log('JOE? ')
        window.scroll({
          top: y,
          behavior: 'smooth',
        })
      },
    )
  }

  // Toggle Open een specifiek item op pagina 3
  toggleOpen(itemIndex) {
    let arrayItems = this.state.arrayItemsWatWilIk
    let openState = arrayItems[itemIndex].open
    arrayItems.forEach((item) => {
      item.open = false
    })
    arrayItems[itemIndex].open = !openState
    this.setState({
      arrayItemsWatWilIk: arrayItems,
    })
  }

  //////////////////////////////////////////////////////
  //** Functies voor 'Wat vind ik belangrijk?' items //
  ////////////////////////////////////////////////////

  // Wijzigt de Array volgorde na Drag 'n Drop
  onSortEndBelangrijkePunten = ({ oldIndex, newIndex }) => {
    let reorderedItems = arrayMove(this.state.arrayItemsBelangrijk, oldIndex, newIndex)
    reorderedItems = reorderedItems.map((item, index) => {
      item.index = index
      return item
    })
    reorderedItems = this.setOpenStateToFirstItem(reorderedItems)
    this.setState({
      arrayItemsBelangrijk: reorderedItems,
    })
  }

  // Voegt een nieuw item toe aan arrayItemsBelangrijk
  addItemBelangrijkePunten(arrayItem) {
    let newValues = this.state.arrayItemsBelangrijk
    let openState = false
    if (newValues.length === 0) {
      openState = true
    }
    newValues.push({
      index: newValues.length + 1,
      title: arrayItem.newItemValue,
      beschrijving: '',
      toelichting: '',
      open: openState,
      editing: false,
    })
    this.setState({ arrayItemsBelangrijk: newValues }, () => this.saveCurrentState())
    console.log(this.state)
  }

  // Verwijdert een item uit arrayItemsBelangrijk
  //
  removeItemBelangrijkePunten(event, item, listPosition, flowIndex, type) {
    let newValues = this.state.arrayItemsBelangrijk
    newValues.splice(listPosition, 1)
    this.setOpenStateToFirstItem(newValues)
    this.setState({ arrayItemsBelangrijk: newValues }, () => this.saveCurrentState())
  }

  // Toggle de editing state van een arrayItem
  toggleEditItemBelangrijkePunten(itemIndex) {
    let newValues = this.state.arrayItemsBelangrijk
    newValues[itemIndex].editing = !newValues[itemIndex].editing
    this.setState({ arrayItemsBelangrijk: newValues })
  }

  // Wijzig de title van een arrayItem en reset de editing state
  editTitleItemBelangrijkePunten(event, itemIndex) {
    let newValues = this.state.arrayItemsBelangrijk
    newValues[itemIndex].title = event.target.value
    this.setState({ arrayItemsBelangrijk: newValues })
  }

  // Open het volgende item op pagina 3
  volgendeItemBelangrijkePunten(itemIndex) {
    let arrayItemsBelangrijk = this.state.arrayItemsBelangrijk
    arrayItemsBelangrijk.forEach((item) => {
      item.open = false
    })

    const element = document.getElementById(`list-item-${itemIndex}`)
    const y = element.getBoundingClientRect().top + window.scrollY - 10

    const nextItem = itemIndex + 1
    arrayItemsBelangrijk[nextItem].open = true
    this.setState(
      {
        arrayItemsBelangrijk: arrayItemsBelangrijk,
      },
      () => {
        this.saveCurrentState()
        window.scroll({
          top: y,
          behavior: 'smooth',
        })
      },
    )
  }

  // Toggle Open een specifiek item op pagina 3
  toggleOpenBelangrijkePunten(itemIndex) {
    let arrayItemsBelangrijk = this.state.arrayItemsBelangrijk
    let openState = arrayItemsBelangrijk[itemIndex].open
    arrayItemsBelangrijk.forEach((item) => {
      item.open = false
    })
    arrayItemsBelangrijk[itemIndex].open = !openState
    this.setState({
      arrayItemsBelangrijk: arrayItemsBelangrijk,
    })
  }

  ////////////////////////////////////
  ///////////////////////////////////
  //** Algemene Database functies //
  /////////////////////////////////
  ////////////////////////////////

  // Save de huidige state naar de Database
  saveCurrentState() {
    let firebase = this.props.firebase

    if (!this.state.authUser) {
      return
    }

    var docRef = firebase.db
      .collection('users')
      .doc(this.state.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('4')

    this.savingStateToAPI()

    const that = this

    docRef
      .set({
        belangrijkePunten: this.state.arrayItemsBelangrijk,
        watWilIkPunten: this.state.arrayItemsWatWilIk,
      })
      .then(function () {
        that.savedStateToAPI()
        console.log('Document successfully written!')
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
  }

  setEditorStateBelangrijkePunten(stateValue, fieldName, itemIndex) {
    if (itemIndex !== undefined) {
      let arrayItemsBelangrijk = this.state.arrayItemsBelangrijk
      arrayItemsBelangrijk[itemIndex][fieldName] = stateValue
      this.setState({
        arrayItemsBelangrijk: arrayItemsBelangrijk,
      })
    } else {
      this.setState({
        [fieldName]: stateValue,
      })
    }
  }

  handleChangeBelangrijkePunten(event, itemIndex) {
    let arrayItemsBelangrijk = this.state.arrayItemsBelangrijk
    arrayItemsBelangrijk[itemIndex][event.target.name] = event.target.value
    this.setState({
      arrayItemsBelangrijk: arrayItemsBelangrijk,
    })
  }

  // Save de huidige state naar de Database en verlaat de pagina
  saveCurrentStateAndExit() {
    let firebase = this.props.firebase

    var docRef = firebase.db
      .collection('users')
      .doc(this.state.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('4')

    this.savingStateToAPI()
    const savedStateToAPI = this.savedStateToAPI

    docRef
      .set({
        belangrijkePunten: this.state.arrayItemsBelangrijk,
        watWilIkPunten: this.state.arrayItemsWatWilIk,
      })
      .then(function () {
        console.log('Document successfully written!')
        const exitStatus = true
        savedStateToAPI(exitStatus)
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
  }

  // Haal de data op vanuit Firebase als het component gemount is
  componentDidMount() {
    let firebase = this.props.firebase
    let that = this

    firebase.auth.onAuthStateChanged(function (user) {
      if (user) {
        checkFirstVisitPage(user.uid, firebase, 'MW4', that.props.setFirstVisitStateUitleg)

        var docRef = firebase.db
          .collection('users')
          .doc(user.uid)
          .collection('modulen')
          .doc('mijnWerkleven')
          .collection('subModulen')
          .doc('4')

        // Haal data op uit de databse
        docRef
          .get()
          .then(function (doc) {
            if (doc.exists) {
              //   Make array for minder leuke items
              let belangrijkePuntenArray = []

              if (doc.data().belangrijkePunten !== undefined) {
                doc.data().belangrijkePunten.forEach((item, index) => {
                  if (index === 0) {
                    item.open = true
                    belangrijkePuntenArray.push(item)
                  } else {
                    item.open = false
                    belangrijkePuntenArray.push(item)
                  }
                })
              }

              //   Make array for Wat Wil Ik Punten
              let watWilIkArray = []
              if (doc.data().watWilIkPunten !== undefined) {
                doc.data().watWilIkPunten.forEach((item, index) => {
                  if (index === 0) {
                    item.open = true
                    watWilIkArray.push(item)
                  } else {
                    item.open = false
                    watWilIkArray.push(item)
                  }
                })
              }

              that.setState(
                {
                  arrayItemsBelangrijk: belangrijkePuntenArray,
                  arrayItemsWatWilIk: watWilIkArray,
                  omschrijving: doc.data().omschrijving || '',
                  werklevenOpties: doc.data().werklevenOpties || [],
                  loading: false,
                  authUser: user.uid,
                },
                () => {
                  console.log(that.state)
                },
              )
            } else {
              that.setState(
                {
                  loading: false,
                  authUser: user.uid,
                },
                () => {
                  console.log(that.state)
                },
              )
            }
          })
          .catch(function (error) {
            console.log('Error getting document:', error)
          })
      }
    })
  }

  render() {
    // Rendered de juiste pagina en wordt meegegeven aan de bottom-navigatie
    const huidigeOnderdeel = this.props.huidigeOnderdeel
    console.log(this.state?.arrayItemsWatWilIk)

    if (huidigeOnderdeel === '1' || huidigeOnderdeel === '2') {
      return (
        <React.Fragment>
          <MijnWerkleven4Pagina1en2
            setLeukerMakenBool={this.setLeukerMakenBool}
            setNieuweMogelijkheidBool={this.setNieuweMogelijkheidBool}
            saveCurrentState={this.saveCurrentState}
            toggleEditItemBelangrijkePunten={this.toggleEditItemBelangrijkePunten}
            editTitleItemBelangrijkePunten={this.editTitleItemBelangrijkePunten}
            onSortEndBelangrijkePunten={this.onSortEndBelangrijkePunten}
            huidigeOnderdeel={huidigeOnderdeel}
            arrayItems={this.state.arrayItemsBelangrijk}
            addItemBelangrijkePunten={this.addItemBelangrijkePunten}
            removeItemBelangrijkePunten={this.removeItemBelangrijkePunten}
            volgendeItemBelangrijkePunten={this.volgendeItemBelangrijkePunten}
            toggleOpenBelangrijkePunten={this.toggleOpenBelangrijkePunten}
            handleChange={this.handleChangeBelangrijkePunten}
            setEditorState={this.setEditorStateBelangrijkePunten}
            userID={this.state.authUser}
            changeTitle={this.changeTitle}
            loseFocus={this.loseFocus}
            updateTitleItem={this.updateTitleItem}
          />
          <ModuleNavigation
            currentState={this.state}
            volgendeOnderdeel={this.props.volgendeOnderdeel}
            vorigeOnderdeel={this.props.vorigeOnderdeel}
            huidigeOnderdeel={this.props.huidigeOnderdeel}
            huidigeSectie={this.props.huidigeSectie}
            saveCurrentState={this.saveCurrentState}
            saveCurrentStateAndExit={this.saveCurrentStateAndExit}
            saving={this.state.saving}
            saved={this.state.saved}
            isCompleted={
              huidigeOnderdeel === '1' ||
              !this.state.arrayItemsBelangrijk?.some(
                (aIL) =>
                  aIL.leukerMaken === undefined ||
                  aIL.nieuweMogelijkheid === undefined ||
                  aIL.toelichting === '' ||
                  aIL.beschrijving_uitgebreid === '<p><br></p>' ||
                  aIL.beschrijving_uitgebreid === '',
              )
            }
            goToPrev={'/mijn-werkleven/3/5'}
          />
        </React.Fragment>
      )
    } else if (huidigeOnderdeel === '3' || huidigeOnderdeel === '4') {
      return (
        <React.Fragment>
          <MijnWerkleven4Pagina3en4
            setLeukerMakenBool={this.setLeukerMakenBool}
            setNieuweMogelijkheidBool={this.setNieuweMogelijkheidBool}
            toggleEditItem={this.toggleEditItem}
            editTitleItem={this.editTitleItem}
            onSortEnd={this.onSortEnd}
            huidigeOnderdeel={huidigeOnderdeel}
            arrayItems={this.state.arrayItemsWatWilIk}
            addItem={this.addItem}
            removeItem={this.removeItem}
            volgendeItem={this.volgendeItem}
            toggleOpen={this.toggleOpen}
            handleChange={this.handleChange}
            setEditorState={this.setEditorStateWatWilIk}
            userID={this.state.authUser}
            changeTitle={this.changeTitle}
            loseFocus={this.loseFocus}
            updateTitleItem={this.updateTitleItem}
          />
          <ModuleNavigation
            currentState={this.state}
            volgendeOnderdeel={this.props.volgendeOnderdeel}
            vorigeOnderdeel={this.props.vorigeOnderdeel}
            huidigeOnderdeel={this.props.huidigeOnderdeel}
            huidigeSectie={this.props.huidigeSectie}
            saveCurrentState={this.saveCurrentState}
            saveCurrentStateAndExit={this.saveCurrentStateAndExit}
            saving={this.state.saving}
            saved={this.state.saved}
            isCompleted={
              huidigeOnderdeel === '3' ||
              !this.state.arrayItemsWatWilIk?.some(
                (aIL) =>
                  aIL.nieuweMogelijkheid === undefined ||
                  aIL.voldoetHuidigWerkleven === '' ||
                  aIL.toelichting === '' ||
                  aIL.toelichting === '<p><br></p>' ||
                  aIL.beschrijving === '<p><br></p>' ||
                  aIL.beschrijving === '',
              )
            }
            goTo={'/mijn-werkleven/5/1'}
          />
        </React.Fragment>
      )
    }
  }
}

export default withRouter(withFirebase(MijnWerkleven4))
