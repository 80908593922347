import React from 'react'
import { User } from 'react-feather'

class Index extends React.Component {
  render() {
    const profilePicture = {
      backgroundImage: `url('${this.props.url}')`,
    }
    if (this.props.inline) {
      return (
        <div className="rounded-lg lg:hidden overflow-hidden">
          <div className="bg-cover bg-top h-40" />
          <div className="flex pt-2 p-5 justify-center">
            {this.props.url ? (
              <div
                className="bg-cover bg-center w-32 h-32 -mt-16 rounded-full border-4 border-white"
                id="cardProfileAvatar"
                style={profilePicture}
              />
            ) : (
              <div
                className="bg-cover bg-center w-32 h-32 -mt-16 rounded-full border-4 border-white"
                id="cardProfileAvatar"
              />
            )}
          </div>
          <div className="border-t-2 border-gray-200 p-5">
            <div className="flex justify-center">
              <div className="flex">
                <div className="relative">
                  <span className="inline font-bold text-gray-700">Account</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="w-1/4 hidden lg:block mr-6">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="bg-cover bg-top h-40" />
          <div className="flex pt-2 p-5 justify-center">
            {this.props.url ? (
              <div
                className="bg-cover bg-center w-32 h-32 -mt-16 rounded-full border-4 border-white"
                id="cardProfileAvatar"
                style={profilePicture}
              />
            ) : (
              <div
                className="bg-cover bg-center w-32 h-32 -mt-16 rounded-full border-4 border-white"
                id="cardProfileAvatar"
              />
            )}
          </div>
          <div className="border-t-2 border-gray-200 p-5">
            <div className="flex justify-center">
              <div className="flex">
                <div className="relative">
                  <span className="inline font-bold text-gray-700">Account</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Index
