import React from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'

const MiniButton = (props) => {
  const renderChevron = () => {
    if (props.chevron.status) {
      return <ChevronDown className="ml-2" />
    }
    return <ChevronUp className="ml-2" />
  }

  return (
    <button
      onClick={props.onClick}
      className={`${props.green ? 'bg-wa-button-light-green hover:bg-green-800' : 'bg-wa-button-bordeaux'}
        max-h-4 cursor-pointer rounded-full py-1 md:py-2 px-8 md:px-16 flex`}
    >
      <span className={`${props.large ? 'text-3xl leading-none align-top' : 'text-base'}`}>{props.title}</span>
      {props.chevron && renderChevron()}
    </button>
  )
}

export default MiniButton
