import React from 'react'
import { createPortal } from 'react-dom'
import { X } from 'react-feather'

const Modal = (props) => {
  const modalRoot = document.getElementById('modal')

  return createPortal(
    <>
      <div className="w-full h-full z-20 left-0 top-0 bg-black opacity-25 fixed" onClick={props.onClose} />
      <div className="w-full h-full z-30 left-0 top-0 fixed grid place-items-center">
        <div className="flex flex-col overflow-y-scroll justify-center w-2/3 bg-white p-4 pt-4 md:p-6 md:pt-12 relative min-width-modal max-height-modal-mob max-height-modal max-w-3xl">
          <X className="z-40 absolute right-0 top-0 mr-4 mt-4 cursor-pointer" onClick={props.onClose} />
          {props.children}
        </div>
      </div>
    </>,
    modalRoot,
  )
}

export default Modal
