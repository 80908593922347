// Import Packages
import React from 'react'
import Tooltip from 'react-tooltip-lite'

import Checkbox from './../../../../components/Checkbox'
import Editor from './../../../../components/Editor'
import Lightbulb from './../../../../components/Lightbulb'

// Import Loaders
import LoaderList from './../../../../components/Loaders/LoaderList'
import LoaderEditor from './../../../../components/Loaders/LoaderEditor'
import TitleQuestionair from '../../../../components/Atoms/Title-Questionair'

const MijnWerkleven3Pagina1 = (props) => {
  const checkBoxArray = [['werknemer'], ['ondernemer'], ['werknemer en ondernemer'], ['werkzoekend']]
  const initialWerklevenOpties = props.initialWerklevenOpties

  return (
    <>
      <TitleQuestionair>A. Wat doe ik?</TitleQuestionair>
      <div id="omschrijving">
        <h3 className="font-bold text-black mb-2">Ik werk als:</h3>
        {props.loading === false ? (
          <form>
            <div className="flex flex-col">
              {checkBoxArray.map((value, index) => {
                return (
                  <Checkbox
                    handleChecked={props.handleChecked}
                    key={index}
                    index={index}
                    initialCheckboxValuesArray={initialWerklevenOpties}
                    checkBoxValue={initialWerklevenOpties[index]}
                    name={value[0]}
                  />
                )
              })}
            </div>

            <div className="mt-6">
              <h3 className="font-bold text-black mb-2">
                Omschrijf jouw huidige werkleven
                <Tooltip
                  content={
                    <div className="max-w-sm">
                      <p>
                        Voorbeelden: jouw functie, de werkzaamheden, het bedrijf, de branche, aantal uren dat je werkt,
                        vrijwilligerswerk, andere werkzaamheden in jouw werkleven. Als je (even) geen werk hebt,
                        beschrijf dan ook wat je doet.
                      </p>
                    </div>
                  }
                  className="inline-block z-20"
                >
                  <Lightbulb />
                </Tooltip>
              </h3>
              <Editor
                setEditorState={props.setEditorState}
                fieldName="omschrijving"
                initialEditorState={props.initialEditorState}
              />
            </div>
          </form>
        ) : (
          <React.Fragment>
            <LoaderList />
            <LoaderEditor />
          </React.Fragment>
        )}
      </div>
    </>
  )
}

export default MijnWerkleven3Pagina1
