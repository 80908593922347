import React from 'react'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

class Index extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      value: 0,
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChangeStart = () => {
    // console.log('Change event started')
  }

  handleChange = (value) => {
    const parsedValue = parseFloat(value).toFixed(1)

    this.setState({
      value: parsedValue,
    })

    if (this.props.nestedArray === true && this.props.huidigOfGewenst === undefined) {
      this.props.onChange(this.props.index, parsedValue)
    } else if (this.props.huidigOfGewenst !== undefined) {
      this.props.onChange(parsedValue, this.props.keyName, this.props.itemIndex, this.props.huidigOfGewenst)
    } else {
      this.props.onChange(parsedValue, this.props.keyName)
    }
  }

  componentDidMount() {
    this.setState({
      value: parseFloat(this.props.sliderValue).toFixed(1),
    })
  }

  render() {
    let sliderClassNames = ''
    let h2ClassNames = ''
    const currentValue = this.state.value
    if (currentValue >= 6) {
      sliderClassNames = 'w-full relative algemene-slider green'
      h2ClassNames = 'font-bold text-center text-green-700 text-2xl shadow rounded-full px-4 py-2 inline'
    }
    if (currentValue < 6 && currentValue > 4) {
      sliderClassNames = 'w-full relative algemene-slider orange'
      h2ClassNames = 'font-bold text-center text-orange-500 text-2xl  shadow rounded-full px-4 py-2 inline'
    }
    if (currentValue <= 4) {
      sliderClassNames = 'w-full relative algemene-slider red'
      h2ClassNames = 'font-bold text-center text-red-300 text-2xl shadow rounded-full px-4 py-2 inline'
    }
    if (isNaN(currentValue)) {
      sliderClassNames = 'w-full relative algemene-slider text-gray-700'
      h2ClassNames = 'font-bold text-center text-gray-700 text-2xl shadow rounded-full px-4 py-2 inline'
    }

    let sliderValue = parseFloat(this.state.value)
    if (isNaN(sliderValue)) {
      sliderValue = 0
    }

    return (
      <div className={sliderClassNames}>
        <div className="flex justify-center">
          <span className="absolute left-0 bottom-0 mb-6 text-gray-700">0</span>
          <h2 className={h2ClassNames}>{isNaN(this.state.value) ? 0 : this.state.value}</h2>
          <span className="absolute right-0 bottom-0 mb-6 text-gray-700">10</span>
        </div>
        <Slider min={0} max={10} step={0.5} tooltip={false} value={sliderValue} onChange={this.handleChange} />
      </div>
    )
  }
}

export default Index
