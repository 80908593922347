import React from 'react'

const Checkbox = (props) => {
  return (
    <span className=" cursor-pointer  py-1 ">
      <label className="text-gray-700 si si-checkbox ">
        <input
          name={props.name || ''}
          checked={props.checkBoxValue}
          className="mr-2 leading-tight "
          type="checkbox"
          value={props.checkBoxValue}
          onClick={(e) => {
            props.handleChecked(e, props.checkBoxValue, props.index)
          }}
        />
        <span className="text-xs md:text-base select-none si-label">{props.name}</span>
      </label>
    </span>
  )
}

export default Checkbox
