import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import Checkbox from '../Checkbox'

const FormFieldListItemLeukerBeterMaken = (props) => {
  console.log(props.checked)
  const [chosen, setChosen] = useState(props.checked)

  const toggle = (checked) => {
    setChosen(checked)
  }

  return (
    <div className="flex mb-4 bg-red">
      <label className="bg-white block mt-2 shadow rounded px-4 py-4 mr-2 cursor-pointer hover:shadow-lg transition-short">
        <span>
          <Checkbox
            handleChecked={() => {
              toggle(true)
              props.openPuntLeukerMaken()
              props.setLeukerMakenBool(true, props.itemIndex, props.arrayProp)
            }}
            onClick={(e) => {}}
            key={1}
            index={1}
            initialCheckboxValuesArray={props.arrayProp}
            checkBoxValue={chosen}
          />
        </span>
        <span>Ja, kan better</span>
      </label>
      <label className="bg-white block mt-2 shadow rounded px-4 py-4 mr-2 cursor-pointer hover:shadow-lg transition-short">
        <Checkbox
          handleChecked={(e) => {
            toggle(false)
            props.setLeukerMakenBool(false, props.itemIndex, props.arrayProp)
          }}
          key={1}
          index={1}
          initialCheckboxValuesArray={props.arrayProp}
          checkBoxValue={!chosen && chosen !== undefined}
        />
        <span>Nee</span>
      </label>
    </div>
  )
}

FormFieldListItemLeukerBeterMaken.propTypes = {
  leukerMakenBool: PropTypes.bool,
  checked: PropTypes.bool,
  openPuntLeukerMaken: PropTypes.func,
}

export default FormFieldListItemLeukerBeterMaken
