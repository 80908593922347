import React, { Component } from 'react'
import PropTypes from 'prop-types'

class DashboardVerticalColumnContainer extends Component {
  constructor(props) {
    super(props)
  }
  null
  render() {
    return <div className="w-full flex-row justify-between">{this.props.children}</div>
  }
}

DashboardVerticalColumnContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default DashboardVerticalColumnContainer
