import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import Tooltip from 'react-tooltip-lite'
import { ChevronDown, ChevronUp, Plus, X } from 'react-feather'

import RadioButton from './../RadioButton'
import Lightbulb from './../Lightbulb'

class PopUpVoegMogelijkheidToe extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mogelijkheidNaam: '',
      keuzeOptie: null,
      saving: false,
      saved: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.saveToDatabase = this.saveToDatabase.bind(this)
    this.saveDocument = this.saveDocument.bind(this)
  }

  handleChange(event) {
    const name = event.target.name
    const value = event.target.value
    this.setState(
      (prevState) => ({
        [name]: value,
      }),
      () => {
        console.log(this.state)
      },
    )
  }

  saveDocument(name, index, exitStatus) {
    const firebase = this.props.firebase
    const closeModule = this.props.closeModule
    const prefixKeuze = this.state.keuzeOptie
    const that = this

    let nieuweMogelijkheid = {
      title: prefixKeuze + ' - ' + name,
      plusPunten: [],
      minPunten: [],
      beschrijving: '',
      blijvendStructureel: '',
      toelichting: '',
      cijfer: 0,
      editing: false,
      index: index,
    }

    var docRef = firebase.db
      .collection('users')
      .doc(this.props.userID)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('5')

    docRef
      .update({
        mijnWerkMogelijkheden: firebase.fieldValue.arrayUnion(nieuweMogelijkheid),
      })
      .then(function () {
        console.log('set mogelijkheid')
        toast('Toegevoegd aan mogelijkheden! 👍')
        that.setState({
          saved: true,
          saving: false,
        })
        setTimeout(function () {
          that.setState({
            saving: false,
            saved: false,
          })
          if (exitStatus === true) {
            closeModule()
          } else {
            that.setState({
              mogelijkheidNaam: '',
            })
          }
        }, 1000)
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
  }

  saveToDatabase(e, exitStatus) {
    e.preventDefault()

    const mogelijkheidNaam = this.state.mogelijkheidNaam
    const firebase = this.props.firebase
    const that = this

    var docRef = firebase.db
      .collection('users')
      .doc(this.props.userID)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('5')

    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          const number = doc.data().mijnWerkMogelijkheden ? doc.data().mijnWerkMogelijkheden.length + 1 : 1
          that.saveDocument(mogelijkheidNaam, number, exitStatus)
        } else {
          docRef.set({
            mijnWerkMogelijkheden: [
              {
                index: 1,
                title: 'Huidige werkleven blijven doen',
                beschrijving: '',
                plusPunten: [],
                minPunten: [],
                toelichting: '',
                blijvendStructureel: '',
                open: true,
                editing: false,
              },
            ],
          })
          const number = doc.data().mijnWerkMogelijkheden ? doc.data().mijnWerkMogelijkheden.length + 1 : 1
          that.saveDocument(mogelijkheidNaam, number, exitStatus)
        }
      })
      .catch(function (error) {
        console.log('Error getting document:', error)
      })
  }

  render() {
    return (
      <React.Fragment>
        <div id="saved" className="w-screen h-screen bg-black z-10 left-0 top-0 opacity-25 fixed" />
        <div className="flex z-20 justify-center items-center w-screen h-screen top-0 left-0 fixed">
          <div
            className="overflow-y-scroll px-8 py-10 shadow-lg bg-white z-20 rounded max-w-lg relative"
            id="add-action-module"
          >
            <X
              className="absolute right-0 top-0 mr-5 mt-5 cursor-pointer"
              onClick={() => {
                this.props.closeModule('mogelijkheidGewenstPopUp')
              }}
            />
            <h3 className="font-bold text-xl text-gray-700">
              Voeg een mogelijkheid toe voor
              <span className="italic ml-1">"{this.props.title}"</span>
            </h3>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                let element = document.getElementById('add-action-module')
                element.scrollTop = 0
                this.saveToDatabase(false)
              }}
              onChange={this.handleChange}
            >
              <p>Mogelijkheden kunnen zijn:</p>
              <h3 className="font-bold text-gray-700 mb-1 mt-6">Binnen huidige werkleven:</h3>
              <RadioButton
                required={true}
                className="inline-block mt-10"
                labelValue="Ontwikkeling/opleiding/cursus"
                value="ontwikkeling/opleiding/cursus"
                name="keuzeOptie"
                checked={this.state.keuzeOptie === 'ontwikkeling/opleiding/cursus'}
              />
              <RadioButton
                required={true}
                className="inline-block mt-10"
                labelValue="Andere taken in huidige rol"
                value="andere taken in huidige rol"
                name="keuzeOptie"
                checked={this.state.keuzeOptie === 'andere taken in huidige rol'}
              />
              <RadioButton
                required={true}
                className="inline-block mt-10"
                labelValue="Andere rol binnen huidige organisatie"
                value="andere rol binnen huidige organisatie"
                name="keuzeOptie"
                checked={this.state.keuzeOptie === 'andere rol binnen huidige organisatie'}
              />
              <h3 className="font-bold text-gray-700 mb-1 mt-6">Buiten huidige werkleven:</h3>
              <RadioButton
                required={true}
                className="inline-block mt-10"
                labelValue="Hobby/vrijwilligerswerk"
                value="Hobby/vrijwilligerswerk"
                name="keuzeOptie"
                checked={this.state.keuzeOptie === 'Hobby/vrijwilligerswerk'}
              />
              <RadioButton
                required={true}
                className="inline-block mt-10"
                labelValue="Onderneming starten"
                value="onderneming starten"
                name="keuzeOptie"
                checked={this.state.keuzeOptie === 'onderneming starten'}
              />
              <RadioButton
                required={true}
                className="inline-block mt-10"
                labelValue="Oriënteren carrièreswitch"
                value="Oriënteren carrièreswitch"
                name="keuzeOptie"
                checked={this.state.keuzeOptie === 'Oriënteren carrièreswitch'}
              />
              <h3 className="font-bold text-gray-700 mb-1 mt-6">
                Beschrijf kort de mogelijkheid
                <Tooltip
                  content={
                    <div className="max-w-sm">
                      <p>Voorbeelden:</p>
                      <ul>
                        <li>- Als je een cursus gaat doen, vul dan in wat voor cursus.</li>
                        <li>
                          - Als je een onderneming zou willen starten, vul dan in wat voor onderneming en hoe je dat
                          wilt doen
                        </li>
                      </ul>
                    </div>
                  }
                  className="inline-block z-20"
                >
                  <Lightbulb />
                </Tooltip>
              </h3>
              <input
                required
                name="mogelijkheidNaam"
                className="border w-full px-2 py-1"
                type="text"
                autoComplete="off"
                value={this.state.mogelijkheidNaam}
                onChange={this.handleChange}
              />
              <div className="flex flex-col flex-wrap">
                <button
                  type="submit"
                  className="py-2 px-5 mt-3 text-center bg-wa-button-green hover:bg-wa-button-bordeaux font-bold text-white md:text-base rounded-lg cursor-pointer shadow-md"
                  onClick={(e) => {
                    this.saveToDatabase(e, true)
                  }}
                >
                  Voeg toe aan Mogelijkheden
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default PopUpVoegMogelijkheidToe
