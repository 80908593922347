import React from 'react'
import { PlusSquare } from 'react-feather'
import arrow from '../../assets/images/iphone-arrow.jpg'

const HOME_PAGE_PROD = 'https://work-life-differently.web.app/'
const HOME_PAGE_DEV = 'https://dev-workappic-26340.web.app/'

const Index = () => {
  if (window.location.href !== HOME_PAGE_PROD || window.location.href !== HOME_PAGE_DEV) {
    return null
  }

  return (
    <div
      style={{ zIndex: 99999, background: '#f7f7f7' }}
      className="z-50 fixed bottom-0 left-0 w-full h-10 mb-8 flex justify-center items-center p-2"
    >
      <div className="text-xs pt-2 flex">
        <PlusSquare className="mr-1" />
        <span>Install on your iPhone tap</span>
        <img className="mx-1 h-5 w-4" src={arrow} />
        <span className="block">and then Add to homescreen</span>
      </div>
      <div
        style={{
          width: 0,
          height: 0,
          borderLeft: '20px solid transparent',
          borderRight: '20px solid transparent',
          borderTop: '20px solid #f7f7f7',
          position: 'absolute',
          top: '2.5rem',
        }}
      />
    </div>
  )
}

export default Index
