import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { X } from 'react-feather'

class PopUpContainer extends Component {
  constructor(props) {
    super(props)
  }
  null

  render() {
    return (
      <React.Fragment>
        <div id="saved" className="w-screen h-screen bg-black z-10 left-0 top-0 opacity-25 fixed" />
        <div className="flex z-20 justify-center items-center w-screen h-screen top-0 left-0 fixed">
          <div
            className="overflow-y-scroll px-8 py-10 shadow-lg bg-white z-20 rounded max-w-lg relative"
            id="add-action-module"
          >
            <X
              className="absolute right-0 top-0 mr-5 mt-5 cursor-pointer"
              onClick={(e) => {
                console.log('props pop up container', this.props.closeModule)
                this.props.closeModule(e)
              }}
            />
            {this.props.children}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

PopUpContainer.propTypes = {}

PopUpContainer.defaultProps = {}

export default PopUpContainer
