import React from 'react'
import { Link } from 'react-router-dom'
import TextHeadingTwo from '../../components/TextHeadingTwo'
import DashboardVerticalColumnContainer from '../../components/DashboardVerticalColumnContainer'
import LikeNoLike from './WorkLife/like-no-like'
import SortableList from '../../components/Organisms/SortableContainer'

const ANSWERS_IMPORTANT = ['leukerMaken', 'beschrijving_uitgebreid', 'toelichting', 'open']
const ANSWERS_WHAT_I_WANT = ['beschrijving', 'leukerMaken', 'toelichting', 'voldoetHuidigWerkleven', 'open']

const   DashboardSectionWantedWorkLife = (props) => {
  const { watWilIkPunten, belangrijkePunten } = props.desiredWorkLife || []

  const onSortEndOne = ({ oldIndex, newIndex }) => {
    props.sortListItems(oldIndex, newIndex, 'gewenstWerkleven', 'belangrijkePunten')
  }

  const onSortEndTwo = ({ oldIndex, newIndex }) => {
    props.sortListItems(oldIndex, newIndex, 'gewenstWerkleven', 'watWilIkPunten')
  }

  return (
    <div className="mb-10">
      <Link to={'/mijn-werkleven/4/1'}>
        <TextHeadingTwo textCenter={true}>4 | Gewenst Werkleven</TextHeadingTwo>
      </Link>
      <DashboardVerticalColumnContainer>
        <div className="flex flex-col md:flex-row w-full">
          <LikeNoLike
            title="Wat wil ik niet"
            amount={props.total.belangrijkePunten}
            showAll={() => props.showAll('gewenstWerkleven', 'belangrijkePunten')}
            displayAll={belangrijkePunten?.length >= props.total.belangrijkePunten}
            add={() => {}}
            goTo={'/mijn-werkleven/4/1'}
          >
            <SortableList
              distance={2}
              items={belangrijkePunten}
              removeItem={props.deleteItem}
              editTitleItem={props.editTitleItem}
              changeTitle={props.changeTitle}
              updateTitleItem={props.updateTitleItem}
              toggleEditItem={() => {}}
              answers={ANSWERS_IMPORTANT}
              onSortEnd={onSortEndOne}
              amountQuestions={5}
              subModulen={'gewenstWerkleven'}
              type={'belangrijkePunten'}
              fIndex={3}
            />
          </LikeNoLike>
          <LikeNoLike
            title="Wat wil ik wel"
            amount={props.total.watWilIkPunten}
            showAll={() => props.showAll('gewenstWerkleven', 'watWilIkPunten')}
            displayAll={watWilIkPunten?.length >= props.total.watWilIkPunten}
            add={() => {}}
            goTo={'/mijn-werkleven/4/3'}
            like
          >
            <SortableList
              distance={2}
              items={watWilIkPunten}
              removeItem={props.deleteItem}
              toggleEditItem={() => {}}
              answers={ANSWERS_WHAT_I_WANT}
              onSortEnd={onSortEndTwo}
              amountQuestions={5}
              editTitleItem={props.editTitleItem}
              changeTitle={props.changeTitle}
              updateTitleItem={props.updateTitleItem}
              type={'watWilIkPunten'}
              subModulen={'gewenstWerkleven'}
              fIndex={3}
            />
          </LikeNoLike>
        </div>
      </DashboardVerticalColumnContainer>
    </div>
  )
}

export default DashboardSectionWantedWorkLife
