import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withFirebase } from '../Firebase'
import { withAuthentication } from '../Session'
import { X } from 'react-feather'
import { toast } from 'react-toastify'
import Lightbulb from './../Lightbulb'
import Tooltip from 'react-tooltip-lite'
import * as ROUTES from '../../css/constants/routes'

class UploadProfilePicture extends React.Component {
  constructor(props) {
    super(props)
    this.submitData = this.submitData.bind(this)
    this.state = {
      uploadedPF: false,
      uploading: false,
    }
  }

  submitData(e) {
    const file = document.getElementById('fileupload').files[0]

    if (file === undefined) {
      toast.error('selecteer eerst een afbeelding')
      return
    }

    if (file.size > 1000000) {
      toast.error('Zorg ervoor dat je afbeelding kleiner is dan 1MB')
      return
    }
    const storage = this.props.storage
    const imagesRef = storage.ref().child(`profile_pictures/${this.props.authUser.uid}`)

    const that = this

    imagesRef.put(document.getElementById('fileupload').files[0]).then(function (snapshot) {
      console.log('Uploaded a blob or file!')
      that.setState({
        uploadedPF: true,
      })
      toast('Succesvol geupload! 👍')
    })

    this.props.userUploadsProfilePicture(this.props.authUser.uid)
  }

  render() {
    return (
      <form
        className="flex border border-4 border-dashed border-gray-300 my-10"
        id="uploadProfilePicture"
        encType="multipart/form-data"
      >
        <input id="fileupload" name="myfile" type="file" accept="image/*" className="px-4 py-4 rounded-lg w-full" />
        <input
          type="submit"
          value={this.state.uploadedPF ? 'Geupload! :)' : 'Uploaden'}
          id="submit"
          className="py-2 px-5 bg-blue-600 hover:bg-blue-800 font-bold text-white md:text-lg rounded-lg shadow-md cursor-pointer"
          onClick={(e) => {
            e.preventDefault()
            this.submitData(e)
          }}
        />
      </form>
    )
  }
}

class PopupStepper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
    }
    this.volgendeStep = this.volgendeStep.bind(this)
    this.vorigeStep = this.vorigeStep.bind(this)
  }

  volgendeStep(e) {
    if (this.state.activeStep === 3) {
      this.props.toggleDashboardPopUp()
      //to={this.state.activeStep !== 3 ? `#` : `/mijn-werkleven/1/1`}
      //this.props.history.push(`/mijn-werkleven/1/1`)
      this.props.nextPage()
    }
    const nextStep = this.state.activeStep + 1
    this.setState({
      activeStep: nextStep,
    })
  }

  vorigeStep() {
    console.log(this.state.activeStep)
    if (this.state.activeStep === 0) {
      return
    }
    const vorigeStep = this.state.activeStep - 1
    this.setState({
      activeStep: vorigeStep,
    })
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <div className="w-screen h-screen z-40 left-0 top-0 bg-black opacity-25 fixed" />
          <div className="flex z-50 justify-center items-center w-screen h-screen top-0 left-0 fixed">
            <div
              className="max-w-xl bg-white rounded shadow-lg p-8 relative info-uitleg-popup overflow-y-scroll transition-short"
              id="stepper"
            >
              <X
                className="absolute right-0 top-0 mr-2 mt-2 cursor-pointer"
                onClick={(e) => {
                  this.props.toggleDashboardPopUp((prev) => !prev)
                }}
              />

              <h3 className="font-bold text-xl text-gray-700 mb-4">Uitleg WorkAppic - {this.state.activeStep + 1}/4</h3>

              {this.state.activeStep === 0 ? (
                <React.Fragment>
                  <p>
                    Welkom
                    {this.props.username ? ` ${this.props.username.trim()}` : ''}!
                  </p>
                  <br />
                  <p>WorkAppic is de easy way to go naar jouw ideale werkleven.</p>
                  <br />
                  <p>Let op: Gebruik WorkAppic alleen met Google Chrome.</p>
                  <br />
                  <p className="mb-4 mt-2">
                    Je kunt WorkAppic een persoonlijk tintje geven door een profielfoto te uploaden.
                  </p>
                  <UploadProfilePicture
                    storage={this.props.firebase.storage}
                    authUser={this.props.authUser}
                    userUploadsProfilePicture={this.props.userUploadsProfilePicture}
                  />
                </React.Fragment>
              ) : null}
              {this.state.activeStep === 1 ? (
                <React.Fragment>
                  <p className="mb-2">
                    Als je met WorkAppic aan de slag gaat vul je 7 onderdelen in. Volg gewoon de flow. Als je gaandeweg
                    nieuwe inzichten krijgt kun je de verschillende onderdelen altijd easy aanpassen.
                  </p>
                </React.Fragment>
              ) : null}
              {this.state.activeStep === 2 ? (
                <React.Fragment>
                  <p className="mb-2">
                    Wil je je gegevens tussentijds opslaan, klik dan op ‘opslaan’. WorkAppic slaat jouw gegevens ook op
                    als je klikt op ‘volgende’ of ‘voltooien’.
                  </p>
                </React.Fragment>
              ) : null}
              {this.state.activeStep === 3 ? (
                <React.Fragment>
                  <div className="mb-4">
                    Loop je even vast of heb je meer inspiratie nodig? Klik dan op
                    <Tooltip
                      content={
                        <div className="max-w-sm">
                          <p>Hier vind je dan meer uitleg of tips.</p>
                        </div>
                      }
                      className="inline-block z-20"
                    >
                      <Lightbulb />
                    </Tooltip>
                    voor suggesties.
                    <br />
                    Veel plezier!
                  </div>
                </React.Fragment>
              ) : null}
              <div className="mt-6">
                <div
                  className={`bg-wa-button-green py-2 px-5 font-bold text-white md:text-base rounded-lg shadow-md inline-block mr-4 ${
                    this.state.activeStep !== 0 ? `hover:bg-green-800 cursor-pointer` : `cursor-not-allowed`
                  } `}
                  onClick={this.vorigeStep}
                >
                  <span className="select-none">Vorige</span>
                </div>
                <Link
                  //to={this.state.activeStep !== 3 ? `#` : `/mijn-werkleven/1/1`}
                  className="py-2 px-5 bg-wa-button-green hover:bg-green-800 font-bold text-white inline-block md:text-base rounded-lg shadow-md cursor-pointer"
                  onClick={this.volgendeStep}
                >
                  <span className="select-none">{this.state.activeStep !== 3 ? `Volgende` : `AAN DE SLAG!`}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withAuthentication(withFirebase(PopupStepper))
