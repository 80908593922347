import React from 'react'

import { Info } from 'react-feather'

const Lightbulb = () => {
  return (
    <div className="flex align-middle justify-center">
      <span className="absolute mx-auto z-10 my-2 text-white font-bold text-sm">i</span>
      <Info color="#B64567" className="bg-wa-button-bordeaux text-white rounded-full knipperlamp cursor-pointer mx-2" />
    </div>
  )
}

export default Lightbulb
