import React from 'react'
import TextHeadingTwo from '../../components/TextHeadingTwo'
import { Link } from 'react-router-dom'
import Footer from './WorkLife/footer'
import DashboardVerticalColumnContainer from '../../components/DashboardVerticalColumnContainer'
import SortablePossibility from './Possibilities/SortablePossibility'

const DashboardSectionPossibilities = (props) => {
  const { mijnWerkMogelijkheden } = props.possibilities || []

  const onSortEndOne = ({ oldIndex, newIndex }) => {
    props.sortListItems(oldIndex, newIndex, 'mogelijkheden', 'mijnWerkMogelijkheden')
  }

  return (
    <div className="md:w-1/2 inline-block align-top pr-2">
      <TextHeadingTwo textCenter={true}>
        <Link to="/mijn-werkleven/5/2">5 | Mogelijkheden</Link>
      </TextHeadingTwo>
      <DashboardVerticalColumnContainer>
        <SortablePossibility
          subModulen={'mogelijkheden'}
          distance={2}
          possibilities={mijnWerkMogelijkheden}
          onSortEnd={onSortEndOne}
          changeTitle={props.changeTitle}
          updateTitleItem={props.updateTitleItem}
          editTitleItem={props.editTitleItem}
          deleteItem={props.deleteItem}
          fIndex={4}
        />
        <div className="mr-5">
          <Footer
            showAll={props.showAll}
            add={props.add}
            amount={props.total.mijnWerkMogelijkheden}
            showAll={() => props.showAll('mogelijkheden', 'mijnWerkMogelijkheden')}
            displayAll={mijnWerkMogelijkheden?.length >= props.total.mijnWerkMogelijkheden}
            showAllBtnVisible={props.total.mijnWerkMogelijkheden > 5}
            goTo={'/mijn-werkleven/5/1'}
          />
        </div>
      </DashboardVerticalColumnContainer>
    </div>
  )
}

export default DashboardSectionPossibilities
