import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import SortableTodoItem from './Sortable-Todo-Item'

const SortableList = ({
  items,
  removeItem,
  editTitleItem,
  toggleEditItem,
  answers,
  amountQuestions,
  updateTitleItem,
  type,
  subModulen,
  changeTitle,
  sortListItems,
  fIndex,
  loseFocus,
}) => {
  return (
    <ol>
      {items &&
        items?.map((item, index) => (
          <SortableTodoItem
            listPosition={index}
            index={index}
            key={`${item.title} - ${index}`}
            fIndex={fIndex}
            item={item}
            removeItem={removeItem}
            editTitleItem={editTitleItem}
            updateTitleItem={updateTitleItem}
            toggleEditItem={toggleEditItem}
            changeTitle={changeTitle}
            amountQuestions={amountQuestions}
            sortListItems={sortListItems}
            type={type}
            answers={answers}
            subModulen={subModulen}
            loseFocus={loseFocus}
          />
        ))}
    </ol>
  )
}

export default SortableContainer(SortableList)
