import React from 'react'

import smiley1 from '../../assets/images/icons/smiley-1.png'
import smiley2 from '../../assets/images/icons/smiley-2.png'
import smiley3 from '../../assets/images/icons/smiley-3.png'
import smiley4 from '../../assets/images/icons/smiley-4.png'
import smiley5 from '../../assets/images/icons/smiley-5.png'

const SMILEYS = [smiley1, smiley2, smiley3, smiley4, smiley5]

const CustomTooltip = ({ active, payload, label }) => {
  if (!active && !payload.length) return null

  return (
    <div className="px-4 text-3xl py-2 text-gray-700 font-bold rounded shadow bg-white">
      <p>
        {`${payload[0].value} - `}
        <span role="img" aria-label="emoji" className="no-underline">
          {payload[0].payload.smiley ? (
            <img src={SMILEYS[payload[0].payload.smiley - 1]} height={52} width={52} />
          ) : (
            '-'
          )}
        </span>
      </p>
    </div>
  )
}

export default CustomTooltip
