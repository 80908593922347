import React from 'react'
import uuidv1 from 'uuid/v1'

import { noFormatDateToday } from './utils'
import checkFirstVisitPage from './checkFirstVisitPage'
import { day } from './utils'

const howFormObj = {
  openState: false,
  savedState: false,
  hoeActie: '',
  hoeAndere: '',
  hoeNodig: '',
}

export const updateActionObj = (action, UUID, name, deadline, notes, howForm, state) => {
  console.log('inside updateActionObj', deadline, action)
  const newAction = {
    actieNaam: name || action.actieNaam,
    voltooid: state || action.voltooid,
    UUID: UUID || action.UUID,
    aUUID: action.aUUID,
    deadline: deadline || action.deadline,
    toggleVoltooid: state || action.toggleVoltooid,
    deadlineSavedState: deadline ? true : false,
    hoeForm: howForm || action.hoeForm,
    notes: notes || action.notes,
    originalIndex: 0,
  }
  return newAction
}

export const updateThought = (action) => {
  const newAction = {
    actieNaam: action.actieNaam,
    aUUID: action.aUUID,
    toggleVoltooid: false,
    voltooid: action.voltooid || false,
    hoeForm: action.hoeForm || howFormObj,
    notes: action.notes || [],
    originalIndex: 0,
  }
  return newAction
}

export const updateThoughtToAction = (thought) => {
  console.log('thought', thought)
  const newAction = {
    actieNaam: thought.actieNaam,
    aUUID: thought.aUUID,
    toggleVoltooid: false,
    voltooid: thought.voltooid || false,
    deadline: thought.deadline || '',
    hoeForm: thought.hoeForm || howFormObj,
    notes: thought.notes || [],
    originalIndex: 0,
  }
  return newAction
}

export const createNewThought = (name, originalIndex) => {
  const newAction = {
    actieNaam: name,
    voltooid: false,
    aUUID: uuidv1(),
    toggleVoltooid: false,
    hoeForm: {
      openState: false,
      savedState: false,
      hoeActie: '',
      hoeAndere: '',
      hoeNodig: '',
    },
    notes: [],
    originalIndex: originalIndex,
  }
  return newAction
}

export const createNewAction = (action) => {
  const newAction = {
    actieNaam: action.actieNaam || '',
    voltooid: action.voltooid || false,
    aUUID: uuidv1(),
    toggleVoltooid: false,
    deadline: action.deadline || '',
    hoeForm: action.hoeForm || {
      openState: false,
      savedState: false,
      hoeActie: '',
      hoeAndere: '',
      hoeNodig: '',
    },
    notes: action.notes || [],
    originalIndex: 0,
  }
  return newAction
}

export const createActionObj = (name, uuid, deadline, notes) => {
  const newAction = {
    actieNaam: name,
    voltooid: false,
    UUID: uuid,
    aUUID: uuidv1(),
    deadline: deadline,
    toggleVoltooid: false,
    deadlineSavedState: deadline ? true : false,
    hoeForm: {
      openState: false,
      savedState: false,
      hoeActie: '',
      hoeAndere: '',
      hoeNodig: '',
    },
    notes: notes || [],
    originalIndex: 0,
  }
  return newAction
}

const getDocRefAction = (firebase, authUser) => {
  return firebase.db.collection('users').doc(authUser.uid).collection('actielijst').doc('acties')
}

const getDocRefGoals = (firebase, authUser) => {
  return firebase.db.collection('users').doc(authUser.uid).collection('actielijst').doc('labels')
}

const collectActions = (doc, target) => {
  let actionList = []
  if (doc.exists) {
    if (doc.data().acties) {
      doc.data().acties.forEach((action) => {
        if (action.actieNaam !== target) {
          actionList.push(action)
        }
      })
    }
  }
  return actionList
}

export const removeThought = (firebase, authUser, thought) => {
  let remainingThoughts = []

  const getDocRefActions = firebase.db.collection('users').doc(authUser.uid).collection('actielijst').doc('acties')

  return getDocRefActions
    .get()
    .then(function (doc) {
      if (doc.exists) {
        if (doc.data().acties && doc.data().acties.length > 0) {
          doc.data().acties.forEach((item) => {
            if (thought.UUID !== undefined) {
              if (thought.aUUID !== item.aUUID) {
                remainingThoughts.push(item)
              }
            }
          })
        }
        return remainingThoughts
      } else {
        return []
      }
    })
    .then(() => {
      getDocRefActions
        .set({
          acties: remainingThoughts,
        })
        .then(function () {
          return remainingThoughts
        })
        .catch(function (error) {
          console.error('Error writing document: ', error)
        })
    })
}

export const getAllThoughts = (firebase, authUser) => {
  let thoughts = []
  const getDocRefActions = firebase.db.collection('users').doc(authUser.uid).collection('actielijst').doc('acties')

  return getDocRefActions.get().then(function (doc) {
    if (doc.exists) {
      if (doc.data().acties && doc.data().acties.length > 0) {
        doc.data().acties.forEach((action) => {
          if (!action.UUID) {
            thoughts.push(action)
          }
        })
      }
      return thoughts
    } else {
      return []
    }
  })
}

function removeDuplicates(originalArray, prop) {
  var newArray = []
  var lookupObject = {}
  let reversedArray = originalArray.reverse()

  if (reversedArray.length > 1) {
    for (var i in reversedArray) {
      lookupObject[reversedArray[i][prop]] = reversedArray[i]
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i])
    }
    return newArray
  } else {
    return originalArray
  }
}

export const getAllActions = (firebase, authUser) => {
  let thoughts = []
  let goalActions = []
  let joinedThoughtsAndActions = []
  let tmpThought = []
  let allGoals = []

  return getAllThoughts(firebase, authUser).then((result) => {
    thoughts = result

    const getGoalActions = firebase.db.collection('users').doc(authUser.uid).collection('actielijst').doc('labels')
    return getGoalActions.get().then(function (doc) {
      if (doc.exists) {
        if (doc.data().currentLabels) {
          doc.data().currentLabels.forEach((goal) => {
            allGoals.push(goal)
            tmpThought = thoughts.filter((t) => {
              if (t.labels) {
                if (t.labels[0]) {
                  return t.labels[0].UUID !== goal.UUID
                }
              }
            })
            if (goal.actions && goal.actions.length > 0) {
              goal.actions.map((action) => {
                if (!action.labels) {
                  //action['deadlineSavedState'] = (action.deadline) ? true : false;
                  action['labels'] = []
                  action.labels.push({
                    UUID: goal.UUID,
                    label: goal.labelNaam,
                    value: goal.labelNaam,
                  })
                } else {
                  //action['deadlineSavedState'] = (action.deadline) ? true : false;
                  action.labels.push({
                    UUID: goal.UUID,
                    label: goal.labelNaam,
                    value: goal.labelNaam,
                  })
                }
                goalActions.push(action)
              })
            }
          })
          //FILTER AND REMOVE OLD THOUGHTS THAT ARE MIGRATED TO
          //NEW TIMELINE GOALS
          /* eslint-disable */
          let newThoughtsList = []
          if (thoughts.length > 1) {
            thoughts.forEach((t) => {
              if (t.labels) {
                if (t.labels[0] && allGoals.some((goal) => goal.UUID === t.labels[0].UUID)) {
                } else {
                  newThoughtsList.push(t)
                }
              } else {
                newThoughtsList.push(t)
              }
            })
          }

          if (newThoughtsList && newThoughtsList.length > 1) {
            thoughts = newThoughtsList.filter((action) => {
              return action.UUID === undefined
            })
            //tmpThought = thoughts.filter( (t) => { if(t.labels) { return t.labels[0].UUID !== goal.UUID } })
          }
          joinedThoughtsAndActions = goalActions.concat(thoughts)
          const filteredArray = removeDuplicates(joinedThoughtsAndActions, 'aUUID')
          return filteredArray
        } else {
          return thoughts
        }
      } else {
        return thoughts
      }
    })
  })
}

export const addThought = (firebase, authUser, newAction) => {
  const getDocRefActions = firebase.db.collection('users').doc(authUser.uid).collection('actielijst').doc('acties')

  if (authUser) {
    let updatedGoal = []

    return getDocRefActions
      .set(
        {
          acties: firebase.fieldValue.arrayUnion(newAction),
        },
        { merge: true },
      )
      .then(function () {
        console.log('Added action to the mindbox')
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
  }
}

export const addAction = (firebase, authUser, goal, newAction) => {
  delete goal['rowPosition']

  const docRefGoals = firebase.db.collection('users').doc(authUser.uid).collection('actielijst').doc('labels')

  if (authUser) {
    let updatedGoal = []
    let foundGoal = {}

    return docRefGoals
      .get()
      .then(function (doc) {
        if (doc.exists) {
          if (doc.data().currentLabels) {
            doc.data().currentLabels.forEach((existingGoal) => {
              if (existingGoal.UUID === goal.UUID) {
                existingGoal.actions.unshift(newAction)
                foundGoal = existingGoal
                updatedGoal.push(existingGoal)
              } else {
                updatedGoal.push(existingGoal)
              }
            })
          } else {
            console.log('no labels where found')
          }
        }
      })
      .then(function (doc) {
        return docRefGoals
          .set({
            currentLabels: updatedGoal,
          })
          .then(function () {
            return foundGoal
          })
          .catch(function (error) {
            console.error('Error writing document: ', error)
          })
      })
  }
}

export const updateAction = (firebase, authUser, updatedAction, goal) => {
  let docRefActions = getDocRefGoals(firebase, authUser)
  let updatedGoal = []
  let updatedActions = []

  return docRefActions
    .get()
    .then(function (doc) {
      if (doc.exists) {
        if (doc.data().currentLabels) {
          doc.data().currentLabels.forEach((existingGoal) => {
            if (existingGoal.UUID === goal.UUID) {
              existingGoal.actions.forEach((currentAction) => {
                if (currentAction.aUUID === updatedAction.aUUID) {
                  updatedActions.push(updatedAction)
                } else {
                  updatedActions.push(currentAction)
                }
              })
              existingGoal.actions = updatedActions
              updatedGoal.push(existingGoal)
            } else {
              updatedGoal.push(existingGoal)
            }
          })
        }
      } else {
        console.log('no labels where found')
      }
    })
    .then(function (doc) {
      return docRefActions
        .set({
          currentLabels: updatedGoal,
        })
        .then(function () {
          console.log('updated action', updatedActions, updatedGoal)
          return updatedActions
        })
        .catch(function (error) {
          console.error('Error writing document: ', error)
        })
    })
}

export const updateActionToFB = (firebase, authUser, action, goal, actionOldTitle) => {
  let docRefActions = getDocRefGoals(firebase, authUser)

  let updatedGoal = []
  let updatedActions = []

  return docRefActions
    .get()
    .then(function (doc) {
      if (doc.exists) {
        if (doc.data().currentLabels) {
          doc.data().currentLabels.forEach((existingGoal) => {
            if (existingGoal.UUID === goal.UUID) {
              goal.actions.forEach((currentAction) => {
                if (currentAction.actieNaam === actionOldTitle) {
                  updatedActions.push(action)
                } else {
                  updatedActions.push(currentAction)
                }
              })
              existingGoal.actions = updatedActions
              updatedGoal.push(existingGoal)
            } else {
              updatedGoal.push(existingGoal)
            }
          })
        }
      } else {
        console.log('no labels where found')
      }
    })
    .then(function (doc) {
      return docRefActions
        .set({
          currentLabels: updatedGoal,
        })
        .then(function () {
          return true
        })
        .catch(function (error) {
          console.error('Error writing document: ', error)
        })
    })
}

export const deleteActionFromFB = (firebase, authUser, target, goal) => {
  const confirmDelete = window.confirm(`Wil je '${target.actieNaam}' verwijderen?`)

  if (confirmDelete) {
    let docRefActions = getDocRefGoals(firebase, authUser)
    let actionList = []

    let updatedGoals = []
    let goalActions = []

    return docRefActions
      .get()
      .then(function (doc) {
        if (doc.exists) {
          if (doc.data().currentLabels) {
            doc.data().currentLabels.forEach((existingGoal) => {
              if (existingGoal.UUID === target.UUID) {
                existingGoal.actions.forEach((action, index) => {
                  if (action.aUUID !== target.aUUID) {
                    goalActions.push(action)
                  }
                })

                existingGoal.actions = goalActions
                updatedGoals.push(existingGoal)
              } else {
                updatedGoals.push(existingGoal)
              }
            })
          }
        } else {
          return []
        }
      })
      .then(function (doc) {
        return docRefActions
          .set({
            currentLabels: updatedGoals,
          })
          .then(function () {
            return goalActions
          })
          .catch(function (error) {
            console.error('Error writing document: ', error)
          })
      })
  } else {
    return new Promise(() => {
      return true
    })
  }
}

export const updateActionTMP = (firebase, authUser, action) => {
  let docRefActions = getDocRefGoals(firebase, authUser)
  //let actions = getDocRefAction(firebase, authUser)
  let updatedActions = []
  let allGoals = []

  return docRefActions
    .get()
    .then(function (doc) {
      if (doc.exists) {
        if (doc.data().currentLabels) {
          doc.data().currentLabels.forEach((existingGoal) => {
            if (existingGoal.UUID === action.UUID) {
              existingGoal.actions.forEach((currentAction) => {
                if (currentAction.aUUID === action.aUUID) {
                  console.log('add action', action)
                  updatedActions.push(action)
                } else {
                  updatedActions.push(currentAction)
                }
              })
              updatedActions.sort(function (a, b) {
                return new Date(a.deadline) - new Date(b.deadline)
              })
              console.log('in updateActionTMP updatedActions', updatedActions)
              existingGoal.actions = updatedActions
              allGoals.push(existingGoal)
            } else {
              allGoals.push(existingGoal)
            }
          })
        }
      } else {
        console.log('found nothing')
      }
    })
    .then(function (doc) {
      /*actions.get().then((d) => {
        if (d.exists) {
          if (d.data().acties) {
            console.log('in then delete action', action)
            delete action.UUID
            delete action.deadline
            const arrayOfActions = d.data().acties
            let foundIndex = arrayOfActions.findIndex((i) => i.aUUID === action.aUUID)
            arrayOfActions[foundIndex] = action
            console.log('in arrayOfActions', arrayOfActions)
            actions.set({
              acties: arrayOfActions,
            })
          }
        } else {
          console.log('found nothing')
        }
      })*/
      return docRefActions
        .set({
          currentLabels: allGoals,
        })
        .then(function () {
          return updatedActions
        })
        .catch(function (error) {
          console.error('Error writing document: ', error)
        })
    })
}
