// Import Packages
import React from 'react'
import arrayMove from 'array-move'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'
import { withFirebase } from './../../../../components/Firebase'

// Import Module Components
import MijnWerkleven1Pagina1 from './../../components/MijnWerkleven1Pagina1'

// Import Functions
import checkFirstVisitPage from './../../../../functions/checkFirstVisitPage'
import setEditorState from './../../../../functions/setEditorState'
import savingStateToAPI from './../../../../functions/savingStateToAPI'
import savedStateToAPI from './../../../../functions/savedStateToAPI'
import resetSavingState from './../../../../functions/resetSavingState'

// Import Components
import ModuleNavigation from './../../../../components/ModuleNavigation'
import { getAllGoals } from '../../../../functions/goalsHelper'

const checkBoxArray = [
  'Werkplezier verhogen',
  'Skills verbeteren',
  'Keuze(s) maken',
  'Carrièreplan uitstippelen',
  'Doelen bereiken',
  'De wereld veroveren',
  'Anders, namelijk:',
]

class MijnWerkleven1 extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      redenStart: [],
      andersNamelijk: '',
      loading: true,
      authUser: '',
      saving: false,
      saved: false,
      allGoals: [],
    }

    // Algemene Form State functies
    this.handleChangeDoelDatum = this.handleChangeDoelDatum.bind(this)
    this.setEditorState = this.setEditorState.bind(this)
    this.handleChecked = this.handleChecked.bind(this)

    // Save functies
    this.savingStateToAPI = savingStateToAPI.bind(this)
    this.savedStateToAPI = savedStateToAPI.bind(this)
    this.resetSavingState = resetSavingState.bind(this)
    this.saveCurrentState = this.saveCurrentState.bind(this)
    this.saveCurrentStateAndExit = this.saveCurrentStateAndExit.bind(this)
  }

  handleChangeDoelDatum(event) {
    const doelDatum = event.target.value
    this.setState(
      {
        doelDatum: doelDatum,
      },
      () => {
        console.log(this.state)
      },
    )
  }

  // Handler voor de checkboxes op pagina 1
  handleChecked(event, checkboxValue, index) {
    console.log('mijn werk leven check', event, checkboxValue, index)

    const checked = event.target.checked
    let newState = this.state.redenStart
    if (checked) {
      newState.push(checkboxValue)
      this.setState(
        {
          redenStart: newState,
        },
        () => {
          this.saveCurrentState()
        },
      )
    } else {
      const index = newState.indexOf(checkboxValue)
      newState.splice(index, 1)
      this.setState(
        {
          redenStart: newState,
        },
        () => {
          this.saveCurrentState()
        },
      )
    }
  }

  // Algemene State Handler voor de Editor
  setEditorState(stateValue, fieldName) {
    console.log('Called')
    this.setState(
      {
        andersNamelijk: stateValue,
      },
      () => {
        console.log(this.state)
      },
    )
  }

  ////////////////////////////////////
  ///////////////////////////////////
  //** Algemene Database functies //
  /////////////////////////////////
  ////////////////////////////////

  // Save de huidige state naar de Database
  saveCurrentState() {
    let firebase = this.props.firebase

    if (!this.state.authUser) {
      return
    }

    var docRef = firebase.db
      .collection('users')
      .doc(this.state.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('1')

    this.savingStateToAPI()

    const that = this

    docRef
      .set({
        redenStart: this.state.redenStart,
        andersNamelijk: this.state.andersNamelijk,
        doelDatum: this.state.doelDatum,
      })
      .then(function () {
        that.savedStateToAPI()
        console.log('Document successfully written!')
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
  }

  // Save de huidige state naar de Database en verlaat de pagina
  saveCurrentStateAndExit() {
    let firebase = this.props.firebase

    var docRef = firebase.db
      .collection('users')
      .doc(this.state.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('1')

    this.savingStateToAPI()
    const savedStateToAPI = this.savedStateToAPI

    docRef
      .set({
        redenStart: this.state.redenStart,
        andersNamelijk: this.state.andersNamelijk,
        doelDatum: this.state.doelDatum,
      })
      .then(function () {
        console.log('Document successfully written!')
        const exitStatus = true
        savedStateToAPI(exitStatus)
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
  }

  // Haal de data op vanuit Firebase als het component gemount is
  componentDidMount() {
    let firebase = this.props.firebase
    let that = this

    firebase.auth.onAuthStateChanged(function (user) {
      if (user) {
        checkFirstVisitPage(user.uid, firebase, 'MW1', that.props.setFirstVisitStateUitleg)

        var docRef = firebase.db
          .collection('users')
          .doc(user.uid)
          .collection('modulen')
          .doc('mijnWerkleven')
          .collection('subModulen')
          .doc('1')

        // Haal data op uit de databse
        docRef
          .get()
          .then(function (doc) {
            if (doc.exists) {
              console.log('Document bestaat')

              that.setState(
                {
                  redenStart: doc.data().redenStart || [],
                  andersNamelijk: doc.data().andersNamelijk || '',
                  doelDatum: doc.data().doelDatum || '',
                  loading: false,
                  authUser: user.uid,
                },
                () => {
                  console.log(that.state)
                },
              )
            } else {
              console.log('Document bestaat niet')
              that.setState({
                redenStart: [],
                andersNamelijk: '',
                doelDatum: '',
                loading: false,
                authUser: user.uid,
              })
            }
          })
          .catch(function (error) {
            console.log('Error getting document:', error)
          })
      }
    })

    firebase.auth.onAuthStateChanged(function (user) {
      getAllGoals(firebase, user).then((result) => {
        that.setState({
          allGoals: [...result],
        })
      })
    })
  }

  render() {
    // Rendered de juiste pagina en wordt meegegeven aan de bottom-navigatie
    const huidigeOnderdeel = this.props.huidigeOnderdeel

    if (huidigeOnderdeel === '1') {
      return (
        <React.Fragment>
          <MijnWerkleven1Pagina1
            setEditorState={this.setEditorState}
            initialEditorState={this.state.andersNamelijk}
            loading={this.state.loading}
            initialRedenStart={this.state.redenStart}
            handleChecked={this.handleChecked}
            handleChange={this.handleChangeDoelDatum}
            doelDatum={this.state.doelDatum}
          />

          <ModuleNavigation
            currentState={this.state}
            volgendeOnderdeel={this.props.volgendeOnderdeel}
            vorigeOnderdeel={this.props.vorigeOnderdeel}
            huidigeOnderdeel={this.props.huidigeOnderdeel}
            huidigeSectie={this.props.huidigeSectie}
            saveCurrentState={this.saveCurrentState}
            saveCurrentStateAndExit={this.saveCurrentStateAndExit}
            saving={this.state.saving}
            saved={this.state.saved}
            goTo={'/mijn-werkleven/2/1'}
            isCompleted={
              this.state.allGoals?.some((g) => checkBoxArray.includes(g.labelNaam)) || this.state.redenStart.length
            }
          />
        </React.Fragment>
      )
    }
  }
}

export default withRouter(withFirebase(MijnWerkleven1))
