import React, { Component } from 'react'
import PropTypes from 'prop-types'

class FormFieldListItemContainer extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <li
        className={`flex  text-gray-700 shadow px-4 py-2 mb-2 relative
        ${
          this.props.isOpen
            ? 'flex-col rounded-none border-0 bg-white'
            : 'flex-row border rounded-full wa-list-container-bg'
        } 
        `}
        id={`list-item-${this.props.index}`}
      >
        {this.props.children}
      </li>
    )
  }
}

FormFieldListItemContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default FormFieldListItemContainer
