import React from 'react'

const BearsButton = (props) => {
  return (
    <button type="button" className="rounded-full cursor-default white text-white mr-2" onClick={props.toggleDoubts}>
      <div className="cursor-pointer teddy-bear block w-8 h-8" />
    </button>
  )
}

export default BearsButton
