import React from 'react'
import { Link } from 'react-router-dom'
import TimelineContainer from '../Timeline/TimelineContainer'
import TextHeadingTwo from '../../components/TextHeadingTwo'

import { monthToRowNumber } from '../../functions/goalsHelper'
import { checkGoalsOnMonth } from '../../functions/utils'

const DashboardSectionTimeline = (props) => {
  const goals = props.goals?.map((g) => ({ ...g, rowPosition: monthToRowNumber(g.deadline) }))
  const goalsWork = goals?.filter((g) => g.type === 'werk') || []
  const goalsPrivate = goals?.filter((g) => g.type === 'prive') || []
  const privateGoals = checkGoalsOnMonth(goalsPrivate, 12, false, 0)
  const workGoals = checkGoalsOnMonth(goalsWork, 12, false, 0)

  return (
    <>
      <TextHeadingTwo textCenter={true}>
        <Link to="/tijdlijn">7 | Tijdlijn</Link>
      </TextHeadingTwo>
      <div className="mt-6">
        <TimelineContainer
          firstVisit={false}
          goals={[]}
          goalWork={workGoals}
          goalPrivate={privateGoals}
          loading={false}
          setLoading={false}
          selectOneMonth={() => {}}
          totalMonths={12}
          selectedMonthName={0}
          setTotalMonths={60}
          setSelectedMonthName={() => {}}
          setSelectedAmountOfMonths={() => {}}
          zoomedIn={false}
          setZoomedIn={() => {}}
          isDashboard={true}
        />
      </div>
    </>
  )
}

export default DashboardSectionTimeline
