import React, { Component } from 'react'
import PropTypes from 'prop-types'

class FormFieldTextArea extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <textarea
        name={this.props.name}
        value={this.props.value}
        onChange={this.props.onChange}
        className="border w-full p-4"
      />
    )
  }
}

FormFieldTextArea.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

FormFieldTextArea.defaultProps = {
  value: '',
}

export default FormFieldTextArea
