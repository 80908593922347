import React from 'react'

import uuidv1 from 'uuid/v1'
import { checkGoalsOnMonth, makeDateObj, makeLabelSlug, getMonth, makeDateToday } from './utils'
import { toast } from 'react-toastify'
import checkFirstVisitPage from './checkFirstVisitPage'

const goalState = {
  active: 'active',
  failed: 'failed',
  done: 'done',
  passed: 'passed',
}

const day = (givenDate) => {
  let destructedDate = 0
  if (givenDate) {
    destructedDate = givenDate.split('-')
  }
  return parseInt(destructedDate[2])
}

const yearToNumber = (givenDate) => {
  let destructedDate = 0
  if (givenDate) {
    destructedDate = givenDate.split('-')
  }
  return parseInt(destructedDate[0] + ' ' + destructedDate[1] + ' ' + destructedDate[2])
}

const sortActions = (actions) => {
  if (actions && actions.length > 1) {
    actions.sort((a, b) => (yearToNumber(a.deadline) > yearToNumber(b.deadline) ? 1 : -1))
  }
  return actions
}

export const initGoal = (goal) => {
  const newGoal = {
    labelNaam: goal.labelNaam,
    labelSlug: makeLabelSlug(goal.labelNaam),
    UUID: goal.UUID,
    deadline: goal.deadline || makeDateToday(),
    type: goal.type || 'werk',
    state: goal.deadline ? checkGoalState(goal.state, goal.deadline) : 'active',
    rowPosition: goal.rowPosition || 1,
    actions: goal.actions ? sortActions(goal.actions) : [],
    notes: goal.notes || [],
    isDifferent: goal.isDifferent || false,
  }
  return newGoal
}

const createNewGoal = (goalTitle, goalType, deadline, notes, isDifferent) => {
  const newGoal = {
    UUID: uuidv1(),
    label: goalTitle,
    type: goalType || 'werk',
    labelNaam: goalTitle,
    labelSlug: makeLabelSlug(goalTitle),
    value: goalTitle,
    deadline: deadline,
    state: goalState.active,
    actions: [],
    notes: notes || [],
    isDifferent: isDifferent || false,
  }
  return newGoal
}

export const switchActionToGoal = () => {}

const countNoSetGoals = (goal, rowPosition) => {
  if (goal.rowPosition)
    if (goal.rowPosition == rowPosition) {
      return 1
    } else {
      return 0
    }
}

const countGoals = (goal, rowPosition) => {
  if (goal[0].rowPosition)
    if (goal[0].rowPosition == rowPosition && goal.length >= 3) {
      return goal.length
    } else {
      return 0
    }
}

export const checkAmountOfGoals = (goals, goalsWorks, goalsPrivate, goalType, deadline) => {
  let count = 0
  const addedGoalMonth = getMonth(deadline)

  if (goalsWorks || goalsPrivate) {
    if (goalType === 'werk') {
      if (goalsWorks && goalsWorks.length > 0) {
        goalsWorks.map((goal) => {
          //Business rule can only have max 3 active goals
          count = countGoals(goal, addedGoalMonth)
        })
      }
    } else {
      if (goalsPrivate && goalsPrivate.length > 0) {
        goalsPrivate.map((goal) => {
          //Business rule can only have max 3 active goals
          count = countGoals(goal, addedGoalMonth)
        })
      }
    }
  } else {
    const workGoals = goals.filter((e) => {
      return e.type === 'werk'
    })
    const privateGoals = goals.filter((e) => {
      return e.type === 'prive'
    })

    if (workGoals.length > 0 && goalType === 'werk') {
      let sameRowGoals = workGoals.filter((g) => {
        return getMonth(g.deadline) === addedGoalMonth
      })
      count = sameRowGoals.length
    } else if (privateGoals.length > 0 && goalType === 'prive') {
      let sameRowGoals = privateGoals.filter((g) => {
        return getMonth(g.deadline) === addedGoalMonth
      })
      count = sameRowGoals.length
    }
  }

  if (count >= 3) {
    return true
  } else {
    return false
  }
}

export const addGoalToDB = (firebase, authUser, goalTitle, goalType, deadline, notes, isDifferent) => {
  // Save to DB
  const newGoal = createNewGoal(goalTitle, goalType, deadline, notes, isDifferent)

  const docRef = firebase.db.collection('users').doc(authUser.uid).collection('actielijst').doc('labels')

  if (authUser) {
    return docRef
      .set(
        {
          currentLabels: firebase.fieldValue.arrayUnion(newGoal),
        },
        { merge: true },
      )
      .then(function () {
        console.log('Goal successfully written to db!')
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
        return false
      })
  }
}

export const addThoughtToGoal = (firebase, authUser, goal, thought) => {
  if (thought.deadline === undefined) {
    //console.log('add deadline', goal);
    thought['deadline'] = null
  }

  if (thought.UUID === undefined) {
    thought['UUID'] = goal.UUID
    thought['labels'] = []
    thought['labels'].push(goal)
  }

  let updatedGoal = []
  const docRefGoals = firebase.db.collection('users').doc(authUser.uid).collection('actielijst').doc('labels')

  //console.log('addThoughtToGoal', thought);

  if (authUser) {
    return docRefGoals
      .get()
      .then(function (doc) {
        if (doc.exists) {
          if (doc.data().currentLabels) {
            doc.data().currentLabels.forEach((existingGoal) => {
              if (existingGoal.UUID === goal.UUID) {
                if (thought.deadline === undefined) {
                  thought['deadline'] = existingGoal.deadline
                  thought['deadlineSavedState'] = existingGoal.deadline
                }
                existingGoal.actions.push(thought)
                updatedGoal.push(existingGoal)
              } else {
                updatedGoal.push(existingGoal)
              }
            })
          } else {
            console.log('no labels where found')
          }
        }
      })
      .then(function (doc) {
        return docRefGoals
          .set({
            currentLabels: updatedGoal,
          })
          .then(function () {
            return thought
          })
          .catch(function (error) {
            console.error('Error writing document: ', error)
          })
      })
  }
}

export const updateCurrentGoal = (firebase, authUser, goal, title, deadline, actions, state, notes) => {
  const docRefGoals = firebase.db.collection('users').doc(authUser.uid).collection('actielijst').doc('labels')

  let updatedGoal = []

  if (authUser) {
    return docRefGoals
      .get()
      .then(function (doc) {
        if (doc.exists) {
          if (doc.data().currentLabels) {
            doc.data().currentLabels.forEach((existingGoal) => {
              if (existingGoal.UUID === goal.UUID) {
                existingGoal.labelNaam = title
                existingGoal.label = title
                existingGoal.labelSlug = title
                existingGoal.deadline = deadline
                existingGoal.actions = actions
                existingGoal.type = goal.type
                existingGoal.rowPosition = goal.rowPosition || existingGoal.rowPosition || monthToRowNumber(deadline)
                existingGoal.state = state || goalState.active
                existingGoal.notes = notes
                console.log('update existing goal', existingGoal)
                updatedGoal.push(existingGoal)
              } else {
                updatedGoal.push(existingGoal)
              }
            })
          } else {
            console.log('no labels where found')
          }
        }
      })
      .then(function (doc) {
        return docRefGoals
          .set({
            currentLabels: updatedGoal,
          })
          .then(function () {
            return updatedGoal
          })
          .catch(function (error) {
            console.error('Error writing document: ', error)
          })
      })
  }
}

export const deleteCurrentGoal = (firebase, authUser, goal) => {
  const confirmDelete = window.confirm(`Wil je '${goal.labelNaam}' verwijderen?`)

  if (confirmDelete) {
    const docRefGoals = firebase.db.collection('users').doc(authUser.uid).collection('actielijst').doc('labels')

    if (authUser) {
      let updatedGoal = []
      return docRefGoals
        .get()
        .then(function (doc) {
          if (doc.exists) {
            if (doc.data().currentLabels) {
              doc.data().currentLabels.forEach((existingGoal) => {
                if (existingGoal.UUID !== goal.UUID) {
                  updatedGoal.push(existingGoal)
                }
              })
            } else {
              console.log('no labels where found')
            }
          }
        })
        .then(function (doc) {
          return docRefGoals
            .set({
              currentLabels: updatedGoal,
            })
            .then(function () {
              //console.log('deleted goal');
              return updatedGoal
            })
            .catch(function (error) {
              console.error('Error writing document: ', error)
            })
        })
    }
  } else {
    return new Promise(() => {
      console.log('changed mind goal')
      return true
    })
  }
}

const checkGoalState = (goalState, deadline) => {
  const dateToday = new Date()
  const deadlineDate = makeDateObj(deadline)

  if (dateToday > deadlineDate) {
    if (goalState === 'active') {
      return 'failed'
    } else if (goalState === 'done') {
      return 'passed'
    } else {
      return goalState
    }
  } else {
    return goalState
  }
}

export const getAllGoals = (firebase, authUser) => {
  if (authUser) {
    const docRef = firebase.db.collection('users').doc(authUser.uid).collection('actielijst').doc('labels')

    let initialGoals = []
    return docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          if (doc.data().currentLabels && doc.data().currentLabels.length > 0) {
            doc.data().currentLabels.forEach((goal) => {
              if (goal.labelNaam) {
                initialGoals.push(initGoal(goal))
              }
            })
          }
        } else {
          console.log('no labels where found')
        }
        return initialGoals
      })
      .catch(function (error) {
        console.log('Error getting document:', error)
      })
  }
}

/*
export const loadActionsDB(user, docRef) {
  // GET Request voor de labels
  // Deze worden vervolgens gemuteerd om in de navigatie weer gegeven te worden
  console.log('load db');
  let firebase = this.props.firebase;
  let that = this;

  var docRefLabel = firebase.db
    .collection('users')
    .doc(user.uid)
    .collection('actielijst')
    .doc('labels');

  docRefLabel
    .get()
    .then(function(doc) {
      if (doc.exists) {
        let initialState = [];
        console.log(doc.data());
        if (
          doc.data().currentLabels &&
          doc.data().currentLabels.length > 0
        ) {
          doc.data().currentLabels.forEach(item => {
            const itemValue = item.value || item.labelNaam;
            initialState.push({
              labelNaam: itemValue,
              labelSlug: that.makeLabelSlug(itemValue),
              UUID: item.UUID,
            });
          });
        }
        that.setState(
          {
            labels: initialState,
          },
          () => {
            console.log(that.state);
          },
        );
      } else {
        docRef.set({
          labels: [],
        });
      }
    })
    .catch(function(error) {
      console.log('Error getting document:', error);
    });
}*/

export const monthToRowNumber = (date) => {
  const dateToday = new Date()
  //console.log('date', date);
  if (date) {
    const currentYear = dateToday.getFullYear()
    const deconstructDate = date.split('-')
    const months = parseInt(date.split('-')[1])
    const years = parseInt(deconstructDate[0])
    //console.log('difference', (years - currentYear));
    const yearsInFuture = years - currentYear
    const totalAmountOfMonths = months + yearsInFuture * 12
    //console.log('totalAmountOfMonths', totalAmountOfMonths);
    return totalAmountOfMonths
  }
}
