import React, { createRef, useRef, useState, useEffect } from 'react'
import { CheckCircle, Move, Trash2, XCircle } from 'react-feather'
import { SortableElement } from 'react-sortable-hoc'
import countAnswers from '../../functions/answers'
import IconCheckedUnChecked from '../Atoms/Icon-Checked-UnChecked'

const SortableTodoItem = (props) => {
  const [amount, total] = countAnswers(props.item, props.answers)
  const [inputValue, setInputValue] = useState(props.item.title)

  const handleChange = (e) => {
    setInputValue(e.target.value)
  }

  return (
    <li
      key={props.key}
      className="draggable-list-item flex items-center text-gray-700 z-20 text-lg border-b relative py-1 px-4 md:py-3 mb-2 relative rounded-full wa-list-container-bg"
    >
      {props.item.editing === false ? <Move className="text-gray-400 inline-block mr-4 relative" /> : null}
      {props.item.editing === false ? (
        <span className="select-none text-sm md:text-base list-item-text inline-block relative text-black cursor-pointer">
          <span
            onClick={() => {
              props.changeTitle(props.item, props.type, props.subModulen)
            }}
          >
            {props.listPosition + 1}. {props.item.title}
          </span>
        </span>
      ) : (
        <input
          type="text"
          autoComplete="off"
          className="edit-array-item-title-input text-xs md:text-base mr-4 border-dashed border-b w-full h-14 pl-2 pr-4"
          name="text"
          value={inputValue}
          onChange={handleChange}
          onKeyDown={(e) => props.updateTitleItem(e, props.item, props.type, props.subModulen)}
          onBlur={(e) => props.loseFocus(props.subModulen, props.type)}
        />
      )}
      <div className="flex flex-row items-center">
        {props.item.leukerMaken && (
          <span className={`text-sm rounded-full md:text-base cursor-default z-3 right-0 mr-2 bg-wa-button-orange text-white px-2 py-1 md:px-5 md:py-2  
          ${(props.fIndex === 3 && props.item.voldoetHuidigWerkleven !== '') ? '' : 'mr-12'} `}>
            Better
          </span>
        )}
        {/* 1 is true 2 is false dont know why this not done with booleans*/}
        {(props.fIndex === 3 && props.item.voldoetHuidigWerkleven !== '') && (
          <IconCheckedUnChecked checked={props.item.voldoetHuidigWerkleven === '1'} />
        )}
        <span
          className={`w-14 rounded-full text-xs md:text-base cursor-default z-3 right-0 mr-2 text-white px-5 py-2 ${
            amount === total ? 'bg-wa-button-green' : 'bg-wa-bordeaux'
          }`}
        >
          {amount}/{total}
        </span>
        <span
          onClick={(e) => {
            props.removeItem(e, props.item, props.listPosition, props.fIndex, props.type, props.subModulen)
          }}
        >
          <Trash2 className="bin cursor-pointer inline-block relative -mt-1 mr-2 hover:text-red-700" size={20} />
        </span>
      </div>
    </li>
  )
}

export default SortableElement(SortableTodoItem)
