// Import Packages
import React from 'react'
import arrayMove from 'array-move'

import { withFirebase } from './../../../../components/Firebase'
import { withRouter } from 'react-router-dom'

import checkFirstVisitPage from './../../../../functions/checkFirstVisitPage'

// Import Module Components
import MijnWerkleven3Pagina1 from './../../components/MijnWerkleven3Pagina1'
import MijnWerkleven3Pagina2en3 from './../../components/MijnWerkleven3Pagina2en3'
import MijnWerkleven3Pagina4en5 from './../../components/MijnWerkleven3Pagina4en5'

// Import Functions
import handleChange from './../../../../functions/handleChange'
import setEditorState from './../../../../functions/setEditorState'
import savingStateToAPI from './../../../../functions/savingStateToAPI'
import savedStateToAPI from './../../../../functions/savedStateToAPI'
import resetSavingState from './../../../../functions/resetSavingState'

// Import Components
import ModuleNavigation from './../../../../components/ModuleNavigation'

// Main Container voor Module 3 van Mijn Werkleven

class MijnWerkleven3 extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      arrayItems: [],
      arrayItemsLeuk: [],
      omschrijving: '',
      werklevenOpties: [false, false, false, false],
      loading: true,
      authUser: '',
      saving: false,
      saved: false,
      completedArrayItemsLeuk: [],
    }

    // Algemene Form State functies
    this.handleChange = handleChange.bind(this)
    this.setEditorState = setEditorState.bind(this)
    this.handleChecked = this.handleChecked.bind(this)

    // Specifieke Pagina 2 Functies
    this.onSortEnd = this.onSortEnd.bind(this)
    this.toggleEditItem = this.toggleEditItem.bind(this)
    this.editTitleItem = this.editTitleItem.bind(this)
    this.addItem = this.addItem.bind(this)
    this.removeItem = this.removeItem.bind(this)

    // Specifieke Pagina 3 Functies
    this.toggleOpen = this.toggleOpen.bind(this)
    this.volgendeItem = this.volgendeItem.bind(this)

    // Save functies
    this.savingStateToAPI = savingStateToAPI.bind(this)
    this.savedStateToAPI = savedStateToAPI.bind(this)
    this.resetSavingState = resetSavingState.bind(this)
    this.saveCurrentState = this.saveCurrentState.bind(this)
    this.saveCurrentStateAndExit = this.saveCurrentStateAndExit.bind(this)

    // Minder Leuke functies
    this.toggleEditItemLeuk = this.toggleEditItemLeuk.bind(this)
    this.editTitleItemLeuk = this.editTitleItemLeuk.bind(this)
    this.onSortEndLeuk = this.onSortEndLeuk.bind(this)
    this.addItemLeuk = this.addItemLeuk.bind(this)
    this.removeItemLeuk = this.removeItemLeuk.bind(this)
    this.volgendeItemLeuk = this.volgendeItemLeuk.bind(this)
    this.handleChangeLeuk = this.handleChangeLeuk.bind(this)
    this.toggleOpenLeuk = this.toggleOpenLeuk.bind(this)
    this.setEditorStateLeuk = this.setEditorStateLeuk.bind(this)

    // Hulp functies
    this.setLeukerMakenBool = this.setLeukerMakenBool.bind(this)
    this.setOpenStateToFirstItem = this.setOpenStateToFirstItem.bind(this)

    const pathName = this.props.location.pathname.split('/')
    this.isItemsLeuk = false
    console.log(pathName)
    console.log(pathName[3] === '4')
    if (pathName[3] === '4') {
      this.isItemsLeuk = true
    }
  }

  // Handler voor de checkboxes op pagina 1
  handleChecked(event, checkboxValue, index) {
    let newState = this.state.werklevenOpties
    newState[index] = !newState[index]

    this.setState(
      {
        werklevenOpties: newState,
      },
      () => this.saveCurrentState(),
    )
  }

  // Hulp functie om de open state te resetten naar het eerste item
  // Wordt gebruikt na verwijderen en herordenen van items
  setOpenStateToFirstItem = (items) => {
    items.map((item, index) => {
      if (index === 0) {
        item.open = true
      } else {
        item.open = false
      }
      return item
    })
    return items
  }

  setLeukerMakenBool(boolState, index, arrayName, init) {
    console.log(boolState, index, arrayName, init)
    let newArray = this.state[arrayName]
    newArray[index].leukerMaken = boolState

    this.setState(
      {
        [arrayName]: newArray,
      },
      () => console.log(this.state),
    )
  }

  // Wijzigt de Array volgorde na Drag 'n Drop
  onSortEnd = ({ oldIndex, newIndex }) => {
    let reorderedItems = arrayMove(this.state.arrayItems, oldIndex, newIndex)
    reorderedItems = reorderedItems.map((item, index) => {
      item.index = index
      return item
    })
    reorderedItems = this.setOpenStateToFirstItem(reorderedItems)
    this.setState({
      arrayItems: reorderedItems,
    })
  }

  // Voegt een nieuw item toe aan arrayItems
  addItem(arrayItem) {
    let newValues = this.state.arrayItems
    let openState = false
    if (newValues.length === 0) {
      openState = true
    }
    newValues.push({
      index: newValues.length + 1,
      title: arrayItem.newItemValue,
      beschrijving: '',
      toelichting: '',
      blijvendStructureel: '',
      open: openState,
      editing: false,
    })
    this.setState({ arrayItems: newValues }, () => this.saveCurrentState())
  }

  // Verwijdert een item uit arrayItems
  //e, props.item, props.listPosition, props.fIndex, props.typ
  removeItem(event, item, listPosition, flowIndex, type) {
    const confirmDelete = window.confirm(`Weet je zeker dat je dit item wilt verwijderen?`)

    if (confirmDelete) {
      let newValues = this.state.arrayItems
      newValues.splice(listPosition, 1)
      this.setOpenStateToFirstItem(newValues)
      this.setState({ arrayItems: newValues }, () => this.saveCurrentState())
    }
  }

  // Toggle de editing state van een arrayItem
  toggleEditItem(itemIndex) {
    let newValues = this.state.arrayItems
    newValues[itemIndex].editing = !newValues[itemIndex].editing
    this.setState({ arrayItems: newValues })
  }

  // Wijzig de title van een arrayItem en reset de editing state
  editTitleItem(event, itemIndex) {
    let newValues = this.state.arrayItems
    newValues[itemIndex].title = event.target.value
    this.setState({ arrayItems: newValues })
  }

  // Open het volgende item op pagina 3
  volgendeItem(itemIndex) {
    let arrayItems = this.state.arrayItems
    arrayItems.forEach((item) => {
      item.open = false
    })

    const element = document.getElementById(`list-item-${itemIndex}`)
    const y = element.getBoundingClientRect().top + window.scrollY - 10

    const nextItem = itemIndex + 1
    arrayItems[nextItem].open = true
    this.setState(
      {
        arrayItems: arrayItems,
      },
      () => {
        this.saveCurrentState()
        window.scroll({
          top: y,
          behavior: 'smooth',
        })
      },
    )
  }

  // Toggle Open een specifiek item op pagina 3
  toggleOpen(itemIndex) {
    let arrayItems = this.state.arrayItems
    let openState = arrayItems[itemIndex].open
    arrayItems.forEach((item) => {
      item.open = false
    })
    arrayItems[itemIndex].open = !openState
    this.setState({
      arrayItems: arrayItems,
    })
  }
  //////////////////////////////////////////
  /////////////////////////////////////////
  //** Functies voor Array Items (Leuk) //
  ///////////////////////////////////////
  //////////////////////////////////////

  handleChangeLeuk(event, itemIndex) {
    console.log('itemIndex', itemIndex)
    let arrayItemsLeuk = this.state.arrayItemsLeuk
    arrayItemsLeuk[itemIndex - 1][event.target.name] = event.target.value
    this.setState({
      arrayItemsLeuk: arrayItemsLeuk,
    })
  }

  // Wijzigt de Array volgorde na Drag 'n Drop
  onSortEndLeuk = ({ oldIndex, newIndex }) => {
    let reorderedItems = arrayMove(this.state.arrayItemsLeuk, oldIndex, newIndex)
    reorderedItems = reorderedItems.map((item, index) => {
      item.index = index
      return item
    })
    reorderedItems = this.setOpenStateToFirstItem(reorderedItems)
    this.setState({
      arrayItemsLeuk: reorderedItems,
    })
  }

  // Voegt een nieuw item toe aan arrayItemsLeuk
  addItemLeuk(arrayItem) {
    let newValues = this.state.arrayItemsLeuk
    let openState = false
    if (newValues.length === 0) {
      openState = true
    }
    newValues.push({
      index: newValues.length + 1,
      title: arrayItem.newItemValue,
      beschrijving: '',
      toelichting: '',
      blijvendStructureel: '',
      open: openState,
      editing: false,
    })
    this.setState({ arrayItemsLeuk: newValues }, () => this.saveCurrentState())
  }

  // Verwijdert een item uit arrayItemsLeuk
  //e, props.item, props.listPosition, props.fIndex, props.typ
  removeItemLeuk(event, item, listPosition, flowIndex, type) {
    console.log('itemIndex', listPosition)
    let newValues = this.state.arrayItemsLeuk
    newValues.splice(listPosition, 1)
    this.setOpenStateToFirstItem(newValues)
    this.setState({ arrayItemsLeuk: newValues }, () => this.saveCurrentState())
  }

  // Toggle de editing state van een arrayItem
  toggleEditItemLeuk(itemIndex) {
    let newValues = this.state.arrayItemsLeuk
    newValues[itemIndex].editing = !newValues[itemIndex].editing
    this.setState({ arrayItemsLeuk: newValues })
  }

  // Wijzig de title van een arrayItem en reset de editing state
  editTitleItemLeuk(event, itemIndex) {
    let newValues = this.state.arrayItemsLeuk
    newValues[itemIndex].title = event.target.value
    this.setState({ arrayItemsLeuk: newValues })
  }

  // Open het volgende item op pagina 3
  volgendeItemLeuk(itemIndex) {
    let arrayItemsLeuk = this.state.arrayItemsLeuk
    arrayItemsLeuk.forEach((item) => {
      item.open = false
    })

    const nextItem = itemIndex + 1
    arrayItemsLeuk[nextItem].open = true

    const element = document.getElementById(`list-item-${itemIndex}`)
    const y = element.getBoundingClientRect().top + window.scrollY - 10

    this.setState(
      {
        arrayItemsLeuk: arrayItemsLeuk,
      },
      () => {
        this.saveCurrentState()
        window.scroll({
          top: y,
          behavior: 'smooth',
        })
      },
    )
  }

  // Toggle Open een specifiek item op pagina 3
  toggleOpenLeuk(itemIndex) {
    let arrayItemsLeuk = this.state.arrayItemsLeuk
    let openState = arrayItemsLeuk[itemIndex].open
    arrayItemsLeuk.forEach((item) => {
      item.open = false
    })
    arrayItemsLeuk[itemIndex].open = !openState
    this.setState({
      arrayItemsLeuk: arrayItemsLeuk,
    })
  }

  setEditorStateLeuk(stateValue, fieldName, itemIndex) {
    if (itemIndex !== undefined) {
      let arrayItemsLeuk = this.state.arrayItemsLeuk
      arrayItemsLeuk[itemIndex][fieldName] = stateValue
      this.setState({
        arrayItemsLeuk: arrayItemsLeuk,
      })
    } else {
      this.setState({
        [fieldName]: stateValue,
      })
    }
  }

  ////////////////////////////////////
  ///////////////////////////////////
  //** Algemene Database functies //
  /////////////////////////////////
  ////////////////////////////////

  // Save de huidige state naar de Database
  saveCurrentState = async () => {
    console.log('Saved called')
    let firebase = this.props.firebase

    if (!this.state.authUser) {
      return
    }

    var docRef = firebase.db
      .collection('users')
      .doc(this.state.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('3')

    this.savingStateToAPI()

    const that = this

    docRef
      .set({
        LeukePunten: this.state.arrayItemsLeuk,
        minderLeukePunten: this.state.arrayItems,
        omschrijving: this.state.omschrijving,
        werklevenOpties: this.state.werklevenOpties,
      })
      .then(function () {
        that.savedStateToAPI()
        let items = that.state.arrayItems
        if (this.isItemsLeuk) {
          items = that.state.arrayItemsLeuk
        }

        const arrayItems = items.map((i) => ({ ...i, editing: false }))

        if (this.isItemsLeuk) {
          this.setState({ arrayItemsLeuk: arrayItems })
        } else {
          this.setState({ arrayItems })
        }
        console.log('arrayItems', arrayItems)
        console.log('Document successfully written!')
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })

    try {
      let items = that.state.arrayItems
      if (this.isItemsLeuk) {
        items = that.state.arrayItemsLeuk
      }
      const arrayItems = items.map((i) => ({ ...i, editing: false }))

      await docRef.set({
        LeukePunten: this.isItemsLeuk ? arrayItems : this.state.arrayItemsLeuk,
        minderLeukePunten: !this.isItemsLeuk ? arrayItems : this.state.arrayItems,
        omschrijving: this.state.omschrijving,
        werklevenOpties: this.state.werklevenOpties,
      })
      that.savedStateToAPI()
      if (this.isItemsLeuk) {
        this.setState({ arrayItemsLeuk: arrayItems })
      } else {
        this.setState({ arrayItems })
      }
      console.log('arrayItems', arrayItems)
      console.log('Document successfully written!')
    } catch (error) {
      console.error('Error writing document: ', error)
    }
  }

  // Save de huidige state naar de Database en verlaat de pagina
  saveCurrentStateAndExit() {
    let firebase = this.props.firebase

    var docRef = firebase.db
      .collection('users')
      .doc(this.state.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('3')

    this.savingStateToAPI()
    const savedStateToAPI = this.savedStateToAPI

    docRef
      .set({
        LeukePunten: this.state.arrayItemsLeuk,
        minderLeukePunten: this.state.arrayItems,
        omschrijving: this.state.omschrijving,
        werklevenOpties: this.state.werklevenOpties,
      })
      .then(function () {
        console.log('Document successfully written!')
        const exitStatus = true
        savedStateToAPI(exitStatus)
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
  }

  changeTitle = (item, type) => {
    console.log('type', item, type, this.state.arrayItems)
    if (type === 'arrayItems') {
      const arrayItems = this.state.arrayItems.map((a, i) => {
        if (a.index === item.index) {
          return { ...a, editing: !a.editing }
        }
        return { ...a, editing: false }
      })

      this.setState({ arrayItems })
    } else {
      const arrayItemsLeuk = this.state.arrayItemsLeuk.map((a, i) => {
        if (a.index === item.index) {
          return { ...a, editing: !a.editing }
        }
        return a
      })

      this.setState({ arrayItemsLeuk })
    }
  }

  loseFocus = (subModule, type) => {
    console.log('subModule, type', subModule, type)
  }

  updateTitleItem = (e, item, type) => {
    if (e.key === 'Enter') {
      if (type === 'arrayItems') {
        const arrayItems = this.state.arrayItems.map((a, i) => {
          if (a.index === item.index) {
            return { ...a, editing: false, title: e.target.value }
          }
          return { ...a, editing: false }
        })

        this.setState({ arrayItems }, () => {
          this.saveCurrentState()
        })

        //this.setState({ arrayItems })
      } else {
        const arrayItemsLeuk = this.state.arrayItemsLeuk.map((a, i) => {
          if (a.index === item.index) {
            return { ...a, editing: false, title: e.target.value }
          }
          return a
        })

        this.setState({ arrayItemsLeuk }, () => {
          this.saveCurrentState()
        })

        //this.setState({ arrayItemsLeuk })
      }
      //const arrayItems = this.state.arrayItems.map((a, i) => ({ ...a,  editing: false }))
      //const arrayItemsLeuk = this.state.arrayItemsLeuk.map((a, i) => ({ ...a, editing: false }))
    }
  }

  // Haal de data op vanuit Firebase als het component gemount is
  componentDidMount() {
    let firebase = this.props.firebase
    let that = this

    firebase.auth.onAuthStateChanged(function (user) {
      if (user) {
        checkFirstVisitPage(user.uid, firebase, 'MW3', that.props.setFirstVisitStateUitleg)

        var docRef = firebase.db
          .collection('users')
          .doc(user.uid)
          .collection('modulen')
          .doc('mijnWerkleven')
          .collection('subModulen')
          .doc('3')

        // Haal data op uit de databse
        docRef
          .get()
          .then(function (doc) {
            if (doc.exists) {
              //   Make array for minder leuke items
              let initialState = []
              if (doc.data().LeukePunten !== undefined) {
                doc.data().minderLeukePunten.forEach((item, index) => {
                  if (index === 0) {
                    item.open = true
                    initialState.push(item)
                  } else {
                    item.open = false
                    initialState.push(item)
                  }
                })
              }

              //   Make array for leuke items
              let initialStateLeuk = []
              if (doc.data().LeukePunten !== undefined) {
                doc.data().LeukePunten.forEach((item, index) => {
                  if (index === 0) {
                    item.open = true
                    initialStateLeuk.push(item)
                  } else {
                    item.open = false
                    initialStateLeuk.push(item)
                  }
                })
              }

              that.setState({
                arrayItems: initialState,
                arrayItemsLeuk: initialStateLeuk,
                omschrijving: doc.data().omschrijving || '',
                werklevenOpties: doc.data().werklevenOpties || [false, false, false, false],
                loading: false,
                authUser: user.uid,
              })
            } else {
              that.setState({
                loading: false,
                authUser: user.uid,
              })
            }
          })
          .catch(function (error) {
            console.log('Error getting document:', error)
          })
      }
    })
  }

  render() {
    console.log('render', this.state.arrayItems)
    // Rendered de juiste pagina en wordt meegegeven aan de bottom-navigatie
    const huidigeOnderdeel = this.props.huidigeOnderdeel

    if (huidigeOnderdeel === '1') {
      return (
        <React.Fragment>
          <MijnWerkleven3Pagina1
            setEditorState={this.setEditorState}
            initialEditorState={this.state.omschrijving}
            loading={this.state.loading}
            initialWerklevenOpties={this.state.werklevenOpties}
            handleChecked={this.handleChecked}
          />
          <ModuleNavigation
            currentState={this.state}
            volgendeOnderdeel={this.props.volgendeOnderdeel}
            vorigeOnderdeel={this.props.vorigeOnderdeel}
            huidigeOnderdeel={this.props.huidigeOnderdeel}
            huidigeSectie={this.props.huidigeSectie}
            saveCurrentState={this.saveCurrentState}
            saveCurrentStateAndExit={this.saveCurrentStateAndExit}
            saving={this.state.saving}
            saved={this.state.saved}
            isCompleted={
              this.state.werklevenOpties[0] &&
              (this.state.omschrijving !== '<p><br></p>' || this.state.omschrijving !== '')
            }
            goToPrev={'/mijn-werkleven/2/2'}
          />
        </React.Fragment>
      )
    } else if (huidigeOnderdeel === '2' || huidigeOnderdeel === '3') {
      return (
        <React.Fragment>
          <MijnWerkleven3Pagina2en3
            toggleEditItem={this.toggleEditItem}
            editTitleItem={this.editTitleItem}
            updateTitleItem={this.updateTitleItem}
            onSortEnd={this.onSortEnd}
            huidigeOnderdeel={huidigeOnderdeel}
            arrayItems={this.state.arrayItems}
            addItem={this.addItem}
            removeItem={this.removeItem}
            volgendeItem={this.volgendeItem}
            toggleOpen={this.toggleOpen}
            handleChange={this.handleChange}
            setEditorState={this.setEditorState}
            userID={this.state.authUser}
            setLeukerMakenBool={this.setLeukerMakenBool}
            changeTitle={this.changeTitle}
            loseFocus={this.loseFocus}
            type={'arrayItems'}
          />
          <ModuleNavigation
            currentState={this.state}
            volgendeOnderdeel={this.props.volgendeOnderdeel}
            vorigeOnderdeel={this.props.vorigeOnderdeel}
            huidigeOnderdeel={this.props.huidigeOnderdeel}
            huidigeSectie={this.props.huidigeSectie}
            saveCurrentState={this.saveCurrentState}
            saveCurrentStateAndExit={this.saveCurrentStateAndExit}
            saving={this.state.saving}
            saved={this.state.saved}
            isCompleted={
              huidigeOnderdeel === '2' ||
              !this.state.arrayItems.some(
                (aIL) => aIL.leukerMaken === undefined || aIL.blijvendStructureel === '' || aIL.toelichting === '',
              )
            }
          />
        </React.Fragment>
      )
    } else if (huidigeOnderdeel === '4' || huidigeOnderdeel === '5') {
      return (
        <React.Fragment>
          <MijnWerkleven3Pagina4en5
            toggleEditItemLeuk={this.toggleEditItemLeuk}
            editTitleItemLeuk={this.editTitleItemLeuk}
            updateTitleItemLeuk={this.updateTitleItem}
            onSortEndLeuk={this.onSortEndLeuk}
            huidigeOnderdeel={huidigeOnderdeel}
            arrayItems={this.state.arrayItemsLeuk}
            addItemLeuk={this.addItemLeuk}
            removeItemLeuk={this.removeItemLeuk}
            volgendeItemLeuk={this.volgendeItemLeuk}
            toggleOpenLeuk={this.toggleOpenLeuk}
            handleChange={this.handleChangeLeuk}
            setEditorState={this.setEditorStateLeuk}
            userID={this.state.authUser}
            setLeukerMakenBool={this.setLeukerMakenBool}
            changeTitle={this.changeTitle}
            loseFocus={this.loseFocus}
            completedArrayItemsLeuk={this.state.completedArrayItemsLeuk}
          />
          <ModuleNavigation
            currentState={this.state}
            volgendeOnderdeel={this.props.volgendeOnderdeel}
            vorigeOnderdeel={this.props.vorigeOnderdeel}
            huidigeOnderdeel={this.props.huidigeOnderdeel}
            huidigeSectie={this.props.huidigeSectie}
            saveCurrentState={this.saveCurrentState}
            saveCurrentStateAndExit={this.saveCurrentStateAndExit}
            saving={this.state.saving}
            saved={this.state.saved}
            isCompleted={
              huidigeOnderdeel === '4' ||
              !this.state.arrayItemsLeuk.some((aIL) => aIL.leukerMaken === undefined || aIL.beschrijving === '')
            }
            goTo={'/mijn-werkleven/4/1'}
          />
        </React.Fragment>
      )
    }
  }
}

export default withRouter(withFirebase(MijnWerkleven3))
