import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'

import { SignInGoogle } from './../../components/SignIn'
import { withFirebase } from './../../components/Firebase'

import * as ROUTES from '../../css/constants/routes'
import * as ROLES from '../../css/constants/roles'

// This component is what we display on the /signup page
// It contains two other components: SignUpForm and SignInGoogle
// SignUpForm is the component SignUpFormBase wrapped in withRouter and Firebase wrappers
// SignInGoogle is imported from the /SignIn component and is used to let a user connect their Google account to register
// https://us20.admin.mailchimp.com/lists/members?id=24377#p:1-s:100-sa:last_update_time-so:false
// https://us20.api.mailchimp.com/3.0/lists/8a35dc45ad/signup-forms
const SignUpPage = () => (
  <div className="container mx-auto px-6 pt-24 flex flex-wrap flex-row justify-center">
    <div className="bg-white w-full md:w-3/5 rounded shadow flex">
      <div className="w-full md:w-1/2 px-8 pt-4 pb-8">
        <h1 className="mt-4 mb-2 font-bold text-gray-800 text-2xl">Account aanmaken</h1>
        {/* Form */}
        <SignUpForm />
        {/* Register with Google */}
        <SignInGoogle />
      </div>
      <div className="hidden md:block w-1/2 bg-green-200 rounded-r" id="login-image">
        <div className="bottom-0 z-10 text-white absolute p-6 text-left">
          <p className="font-thin italic mt-2">“Work to become, not to acquire.”</p>
          <div id="quote-author" className="flex mt-1">
            <span id="author-picture" className="block shadow h-6 w-6 mr-3 rounded-full bg-white" />
            <h3 className="font-bold">Elbert Hubbart</h3>
          </div>
        </div>
      </div>
    </div>
    <Link
      to={ROUTES.SIGN_IN}
      className="block w-full text-center align-baseline font-bold text-sm text-blue-500 hover:text-blue-800 my-4"
      href="#"
    >
      Heb je al een account? Klik hier
    </Link>
  </div>
)

// Initial state to set in contstructor and after submitting to reset the state
const INITIAL_STATE = {
  username: '',
  email: '',
  age: '',
  gender: '',
  passwordOne: '',
  passwordTwo: '',
  notifications: true,
  isAdmin: false,
  error: null,
  privacyCheck: false,
}

// Firebase returns the following ERROR_CODE if the email already exists
// If we get this code we return the message in ERROR_MSG_ACCOUNT_EXISTS
const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use'
const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`

const callMailChimp = async (data) => {
  console.log('call the monkey with 123', data)
  axios.post('https://us-central1-work-life-differently.cloudfunctions.net/mailchimp', data).then((r) => {
    console.log('r', r)
  })
}

// The component containing the form fields and register logic
class SignUpFormBase extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  onSubmit = (event) => {
    // If the two password fields are not the same we display an error
    if (this.state.passwordOne !== this.state.passwordTwo) {
      this.setState({
        error: { message: 'Your passwords are not the same' },
      })
      event.preventDefault()
      return
    }

    // Const form field variables from state
    const { username, email, age, gender, passwordOne, isAdmin, notifications } = this.state

    // For future use if Roles are implemented, for now we don't use this
    const roles = {}
    if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN
    }

    // Create a user
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        // After creating the user we save it to the database
        return this.props.firebase.user(authUser.user.uid).set(
          {
            username,
            email,
            age,
            gender,
            roles,
            notifications,
          },
          { merge: true },
        )
      })
      .then(() => {
        // After saving the user information to the db we send the verification email
        // '//mc.us20.list-manage.com/signup-form/subscribe?u=972d31d6bcba5d3206948fefc&id=000a77e1f0';
        return this.props.firebase.doSendEmailVerification()
      })
      .then(() => {
        let data = JSON.stringify({
          email_address: email,
          status: 'subscribed',
          merge_fields: {
            FNAME: username,
          },
        })
        callMailChimp(data)
        // We then reset the state to the initial state and push the location to the Dashboard
        this.setState({ ...INITIAL_STATE })
        this.props.history.push(ROUTES.DASHBOARD)
        // Display notification that there has been send an email containing a link to verify the users email
        toast('Er is een email verzonden met een bevestigingslink. 👍')
      })
      .catch((error) => {
        // Catch errors and display error message
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }
        this.setState({ error })
      })

    event.preventDefault()
  }

  // Change handler
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleGenderChange = (val) => this.setState({ gender: val })

  // Change handler for checkbox
  onChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  render() {
    const { username, email, age, passwordOne, passwordTwo, error, privacyCheck } = this.state

    return (
      <form className="pt-6" onSubmit={this.onSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Voornaam
          </label>
          <input
            required
            onChange={this.onChange}
            value={username}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="username"
            type="text"
            autoComplete="off"
            placeholder="First Name"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            E-mail
          </label>
          <input
            required
            onChange={this.onChange}
            value={email}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="email"
            type="text"
            autoComplete="off"
            placeholder="Email"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Leeftijd
          </label>
          <input
            required
            onChange={this.onChange}
            value={age}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="age"
            type="number"
            autoComplete="off"
            placeholder="Leeftijd"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="gender">
            Geslacht
          </label>
          <label>
            <input type="radio" name="gender" onChange={() => this.handleGenderChange('male')} value="male" required />{' '}
            <span className="text-sm text-gray-800 pr-4">Man</span>
          </label>
          <label>
            <input
              type="radio"
              name="gender"
              onChange={() => this.handleGenderChange('female')}
              value="Female"
              required
            />{' '}
            <span className="text-sm text-gray-800 pr-4">Vrouw</span>
          </label>
          <label>
            <input
              type="radio"
              name="gender"
              onChange={() => this.handleGenderChange('other')}
              value="Other"
              required
            />{' '}
            <span className="text-sm text-gray-800 pr-4">Anders</span>
          </label>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Wachtwoord
          </label>
          <input
            required
            minLength={6}
            onChange={this.onChange}
            value={passwordOne}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="passwordOne"
            type="password"
            placeholder="Wachtwoord"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Herhaal wachtwoord
          </label>
          <input
            minLength={6}
            onChange={this.onChange}
            value={passwordTwo}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            name="passwordTwo"
            type="password"
            placeholder="Herhaal wachtwoord"
            required
          />
        </div>
        <div className="mb-4 flex">
          <label className="inline-block text-gray-700 text-sm" htmlFor="privacyCheck">
            <input
              onChange={this.onChange}
              value={privacyCheck}
              className="mr-1 pointer"
              name="privacyCheck"
              type="checkbox"
              required
            />
            Ik ga akkoord met de{' '}
            <a
              className="text-blue-500 pointer underline"
              href="/voorwaarden-privacy"
              target="_blank"
              noreferrer
              noopener
            >
              Privacy Voorwaarden
            </a>{' '}
            en{' '}
            <a
              className="text-blue-500 pointer underline"
              href="/voorwaarden-algemeen"
              target="_blank"
              noreferrer
              noopener
            >
              Algemene Voorwaarden
            </a>
            . Ook geef ik hierbij WorkAppic toestemming voor het versturen van e-mails met informatie, tips en
            inspiratie over werkgerelateerde onderwerpen.
          </label>
        </div>
        <div className="flex flex-wrap items-center">
          {error && <p className="text-red-500 mb-4">{error.message}</p>}
          <input
            value="Maak account aan"
            type="submit"
            className="bg-transparent mt-2 mb-4 w-full hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-2 text-sm border border-blue-500 hover:border-transparent rounded"
          />
        </div>
      </form>
    )
  }
}

// Small component containing a link to the Signup page
const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
)

// Wrap SignUpForm with Router and Firebase
const SignUpForm = withRouter(withFirebase(SignUpFormBase))

export default SignUpPage
export { SignUpForm, SignUpLink }
