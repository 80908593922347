import React from 'react'

const InputField = ({ name, value, handleOnChange, placeholder, keyPress }) => (
  <input
    name={name}
    placeholder={placeholder}
    value={value}
    onChange={(e) => {
      handleOnChange(e)
    }}
    className="flex-1 text-xs md:text-base py-2 px-4 md:py-3 md:px-2 text-gray-700 border md:h-14 italic mr-4"
    onKeyDown={keyPress}
  />
)

export default InputField
