import React, { Component } from 'react'
import PropTypes from 'prop-types'

class TextHeadingTwo extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className="wa-light-green-bg flex items-center p-2">
        <h2 className={`font-bold text-base md:text-2xl ml-4 ${this.props.textCenter ? 'text-left' : ''}`}>
          {this.props.children}
        </h2>
      </div>
    )
  }
}

TextHeadingTwo.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default TextHeadingTwo
