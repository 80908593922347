import React, { Component } from 'react'
import PropTypes from 'prop-types'

const ContainerMain = (props) => {
  return (
    <div
      className={`h-full wa-main-bg -mt-6 wa-main-bg relative mx-auto flex-row md:flex container z-10 ${
        props.isDashboard ? '' : 'min-h-screen '
      } `}
    >
      {props.children}
    </div>
  )
}

ContainerMain.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default ContainerMain
