import React, { useState, useEffect } from 'react'
import ActielijstNotitie from '../ActielijstNotitie'
import { makeDateToday } from '../../functions/utils'
import AddButton from '../Atoms/AddButton'
import ButtonSmallGreen from '../Atoms/Button-Small-Green'
import TitleGreen from '../Atoms/Titles-Green'

const AddNotes = (props) => {
  const { notes } = props
  const [newNote, setNewNote] = useState('')

  const handleChange = (e) => {
    let value = e.target.value
    setNewNote(value)
  }

  const addNote = () => {
    let constructNote = {
      content: newNote,
      date: new Date(),
    }
    let tmpNotes = [...notes, constructNote]

    props.addNotes(tmpNotes)
  }

  const onSaveNote = (e) => {
    if (e.key === 'Enter') {
      addNote()
    }
  }

  return (
    <div className="flex flex-col w-full items-start mt-2">
      <TitleGreen title="Nieuwe notitie" />
      <textarea
        placeholder="Type hier je notitie..."
        name="nieuweNotitie"
        value={props.note}
        data-index={1}
        onChange={handleChange}
        className="text-base border rounded w-full md:p-4 md:mt-2 md:mb-2"
        onKeyDown={onSaveNote}
      />
      <ButtonSmallGreen title="Notitie toevoegen" onClick={addNote} isNotCompleted />

      <div className="flex flex-row w-full items-start md:mt-6">
        {notes
          ?.slice(0)
          .reverse()
          .map((note, index) => {
            return (
              <ActielijstNotitie
                key={index}
                notitieInhoud={note.inhoud ? note.inhoud : note.content}
                notitieDatum={note.date.seconds ? new Date(note.date.seconds * 1000) : note.date}
              />
            )
          })}
      </div>
    </div>
  )
}

export default AddNotes
