import React from 'react'
import { Link } from 'react-router-dom'
import { Loader } from 'react-feather'
import { toast } from 'react-toastify'
import ButtonSmallGreen from '../Atoms/Button-Small-Green'

function setWindowYToTop(e) {
  window.scrollTo(0, 0)
}

function isTrue(currentValue) {
  return currentValue === true
}

class ModuleNavigation extends React.Component {
  constructor(props) {
    super(props)
    this.validateNoEmptyFields = this.validateNoEmptyFields.bind(this)
  }

  validateNoEmptyFields(e, checkValue) {
    //   Check if omschrijving && array niet leeg zijn
    if (!checkValue) {
      e.preventDefault()
      toast.error('Vul alle velden in')
    } else {
      setWindowYToTop(e)
      this.props.saveCurrentState()
    }
  }

  render() {
    const volgendeOnderdeel = this.props.volgendeOnderdeel
    const vorigeOnderdeel = this.props.vorigeOnderdeel
    const huidigeOnderdeel = this.props.huidigeOnderdeel
    const huidigeSectie = this.props.huidigeSectie
    const saveCurrentState = this.props.saveCurrentState
    const saveCurrentStateAndExit = this.props.saveCurrentStateAndExit
    const currentState = this.props.currentState

    let checkValue = false

    // Maak variabelen aan voor de field validation

    // Check Waarden voor Sectie 1
    if (huidigeSectie === '1') {
      //   if (huidigeOnderdeel === '1') {
      //     checkValue = currentState.redenStart.length > 0;
      //   }
      checkValue = true
    }

    // Check Waarden voor Sectie 2
    if (huidigeSectie === '2') {
      checkValue = true
    }

    // Check Waarden voor Sectie 3
    if (huidigeSectie === '3') {
      checkValue = true
    }

    // Check Waarden voor Sectie 4
    if (huidigeSectie === '4') {
      checkValue = true
    }

    // Check Waarden voor Sectie 5
    if (huidigeSectie === '5') {
      checkValue = true
    }

    // key is de module, value is het aantal pagina's
    // Zo heeft module 3 ook 3 pagina's
    const sectieLengths = {
      1: 1,
      2: 2,
      3: 5,
      4: 4,
      5: 2,
      6: 6,
    }

    return (
      <div className="w-full mt-4 md:mt-12 flex-row md:flex justify-end">
        <ButtonSmallGreen
          title="Opslaan"
          onClick={() => {
            saveCurrentState()
            toast('Opgeslagen! 👍')
          }}
          isNotCompleted
        />
        {vorigeOnderdeel >= 1 ? (
          <Link
            to={`/mijn-werkleven/${huidigeSectie}/${vorigeOnderdeel}`}
            onClick={(e) => {
              setWindowYToTop(e)
            }}
            className="md-3 ml-1 md:mr-6 md:ml-6"
          >
            <ButtonSmallGreen title="Vorige" isNotCompleted />
          </Link>
        ) : (
          <Link to={this.props.goToPrev}>
            <span className="md-3 ml-1 md:mr-6 md:ml-6">
              <ButtonSmallGreen title="Vorige" isNotCompleted />
            </span>
          </Link>
        )}
        {volgendeOnderdeel <= sectieLengths[huidigeSectie] ? (
          <Link
            to={`/mijn-werkleven/${huidigeSectie}/${volgendeOnderdeel}`}
            onClick={(e) => {
              this.validateNoEmptyFields(e, checkValue)
            }}
          >
            <span className="md-3 ml-1 md:mr-6 md:ml-6">
              <ButtonSmallGreen title="Volgende" isNotCompleted={this.props.isCompleted} />
            </span>
          </Link>
        ) : (
          <Link
            to={this.props.goTo}
            onClick={(e) => {
              saveCurrentStateAndExit()
            }}
          >
            <span className="md-3 ml-1 md:mr-6 md:ml-6">
              <ButtonSmallGreen title="Voltooien" isNotCompleted={this.props.isCompleted} />
            </span>
          </Link>
        )}
      </div>
    )
  }
}

export default ModuleNavigation
