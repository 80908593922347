import React from 'react'
import { Link } from 'react-feather'

const SettingsButton = (props) => {
  return (
    <button
      type="button"
      className="rounded-full cursor-default white text-white mr-2"
      onClick={props.toggleGoalSettings}
    >
      <Link className="text-blue-400 cursor-pointer hover:text-blue-700" size={20} />
    </button>
  )
}

export default SettingsButton
