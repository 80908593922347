import React from 'react'

import { withFirebase } from '../Firebase'

const SignOutButton = ({ firebase }) => (
  <li className="ml-6 flex navigatie-item items-center" onClick={firebase.doSignOut}>
    <span className="transition-fast hover:translate-t-2px hover:text-gray-900 text-gray-600 font-medium">
      Sign Out
    </span>
  </li>
)

export default withFirebase(SignOutButton)
