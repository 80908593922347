import React from 'react'

import Lightbulb from './../../../../components/Lightbulb'
import Tooltip from 'react-tooltip-lite'

class MijnWerkleven4PaginaIntro extends React.Component {
  render() {
    return (
      <React.Fragment>
        <h2 className="font-bold text-gray-700 text-2xl">1. Wat wil ik?</h2>
        <div>
          <span className="text-gray-700 block mb-1">
            Waarschijnlijk heb je ook wel eens gedachten over een gewenst werkleven, een werkleven die helemaal voldoet
            aan wat jij wilt en waar jij het meest blij van wordt.
          </span>
          <span className="mb-2 text-gray-700 mt-4">Dit gewenste werkleven bestaat uit:</span>
          <ul className="mt-2 list-disc text-gray-700 pl-5">
            <li className="mb-2">
              Wat wil ik <span className="underline">hebben</span> in mijn werkleven? Hier valt alles onder wat je wilt
              ‘hebben’. Denk hierbij aan:
              <ul className="list-disc pl-5">
                <li>aantal dagen/uren per week</li>
                <li>bepaald salaris</li>
                <li>afstand woon-werk</li>
                <li>waarden die je in je werk wilt hebben (bijvoorbeeld vrijheid, zekerheid, plezier) </li>
                <li>en andere punten die je kunt ‘hebben’ in jouw werkleven </li>
              </ul>
            </li>
            <li className="mb-2">
              Wat wil ik <span className="underline">doen</span> in mijn werkleven? Hier valt alles onder wat je kunt
              ‘doen’. Denk hierbij aan:
              <ul className="list-disc pl-5">
                <li>bepaalde werkzaamheden/activiteiten</li>
                <li>een bepaalde functie of rol</li>
                <li>werken in een bepaalde branche</li>
                <li>werken met een bepaalde doelgroep of bepaalde klanten</li>
                <li>samenwerken met bepaalde collega’s</li>
                <li>en andere punten die je kunt ‘doen’ in jouw werkleven. </li>
              </ul>
            </li>
          </ul>
        </div>
      </React.Fragment>
    )
  }
}

export default MijnWerkleven4PaginaIntro
