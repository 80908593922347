import React from 'react'
import { Link } from 'react-router-dom'
import MiniButton from '../../../components/Atoms/MiniButton'

const Footer = (props) => {
  return (
    <div className={`w-full flex mt-4 mb-4 ${props.showAllBtnVisible ? ' justify-between' : 'justify-end'}`}>
      {props.showAllBtnVisible && (
        <MiniButton
          chevron={{ status: !props.displayAll }}
          title={props.displayAll ? 'Verberg' : 'Toon Alles'}
          onClick={props.showAll}
        />
      )}
      <Link to={props.goTo}>
        <MiniButton title={'+'} onClick={props.add} green large />
      </Link>
    </div>
  )
}

export default Footer
