import React from 'react'
import { toast } from 'react-toastify'
import { Plus, X } from 'react-feather'
import Tooltip from 'react-tooltip-lite'

import { withFirebase } from '../Firebase'
import Lightbulb from './../Lightbulb'
import Editor from './../Editor'
import SelectLabel from './../SelectLabel'
import PopUpVoegActieToe from './../PopUpVoegActieToe'

import FormFieldListItemIngevuldCounter from './../FormFieldListItemIngevuldCounter'
import FormFieldListItemTitle from './../FormFieldListItemTitle'
import FormFieldListItemChevronToggle from './../FormFieldListItemChevronToggle'
import FormFieldListItemLeukerMakenLabel from './../FormFieldListItemLeukerMakenLabel'
import FormFieldListItemContainer from './../FormFieldListItemContainer'
import FormFieldListItemButtonVolgendePunt from './../FormFieldListItemButtonVolgendePunt'
import HorizontalLineDivider from './../HorizontalLineDivider'
import FormFieldListItemLeukerBeterMaken from './../FormFieldListItemLeukerBeterMaken'

import FormFieldLabel from './../FormFieldLabel'
import FormFieldItemContainer from './../FormFieldItemContainer'
import FormFieldTextArea from './../FormFieldTextArea'

class ListItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      puntLeukerMaken: false,
    }
    this.openPuntLeukerMaken = this.openPuntLeukerMaken.bind(this)
    this.closeModule = this.closeModule.bind(this)
  }

  openPuntLeukerMaken(index) {
    this.setState({
      puntLeukerMaken: true,
    })
  }

  closeModule() {
    this.setState({
      puntLeukerMaken: false,
    })
  }

  countTotaalIngevuld(item) {
    let totaalIngevuld = 0

    if (item.beschrijving && item.beschrijving.length > 0 && item.beschrijving !== '<p><br></p>') {
      totaalIngevuld += 1
    }
    if (item.toelichting && item.toelichting.length > 0 && item.toelichting !== '<p><br></p>') {
      totaalIngevuld += 1
    }
    if (item.blijvendStructureel && item.blijvendStructureel.length > 0) {
      totaalIngevuld += 1
    }

    if (item.leukerMaken === true || item.leukerMaken === false) {
      totaalIngevuld += 1
    }
    return totaalIngevuld
  }

  render() {
    const item = this.props.item
    const totaalIngevuld = this.countTotaalIngevuld(item)

    return (
      <FormFieldListItemContainer index={this.props.index} isOpen={item.open}>
        <FormFieldListItemChevronToggle
          toggleOpen={() => this.props.toggleOpen(this.props.index)}
          openState={item.open}
        />

        <FormFieldListItemLeukerMakenLabel leukerMakenBool={item.leukerMaken} />

        <FormFieldListItemIngevuldCounter aantalIngevuld={totaalIngevuld} aantalInTeVullen={3} />

        <FormFieldListItemTitle
          toggleOpen={() => this.props.toggleOpen(this.props.index)}
          title={item.title}
          index={this.props.index}
        />

        {item.open === true ? (
          <div className="mt-2">
            <FormFieldItemContainer>
              <FormFieldLabel>Waarom vind je dit minder leuk?</FormFieldLabel>
              <Editor
                index={this.props.index}
                fieldName="toelichting"
                setEditorState={this.props.setEditorState}
                initialEditorState={item.toelichting}
              />
            </FormFieldItemContainer>

            <FormFieldItemContainer>
              <FormFieldLabel>
                Is het tijdelijk of blijvend?
                <Tooltip
                  content={
                    <div className="max-w-sm">
                      <p className="mb-2">
                        Tijdelijk: als je tijdelijk werkzaamheden van een collega overneemt of als je even met je
                        verkeerde been uit bed bent gestapt en daardoor snel geprikkeld bent ;)
                      </p>{' '}
                      <p>
                        Blijvend: als je dit punt al voor een langere periode minder leuk vindt of als het punt steeds
                        terugkomt.
                      </p>
                    </div>
                  }
                  className="inline-block z-20"
                >
                  <Lightbulb />
                </Tooltip>
              </FormFieldLabel>
              <FormFieldTextArea
                name="blijvendStructureel"
                value={item.blijvendStructureel}
                onChange={(e) => {
                  this.props.handleChange(e, this.props.index)
                }}
              />
            </FormFieldItemContainer>

            <FormFieldItemContainer>
              <FormFieldLabel>
                Kan je actie ondernemen om dit punt leuker/better te maken?
                <Tooltip
                  content={
                    <div className="max-w-sm">
                      <p className="mb-2">Kan je bijvoorbeeld:</p>
                      <ul className="list">
                        <li>dit bespreken met iemand op jouw werk?</li>
                        <li>dit punt toepassen in jouw huidige functie en organisatie?</li>
                        <li>een plan maken?</li>
                      </ul>
                      <div>
                        Kies je voor nee, dan kan dit beteken dat je accepteert dat dit een minder leuk punt is.
                      </div>
                    </div>
                  }
                  className="inline-block z-20"
                >
                  <Lightbulb />
                </Tooltip>
              </FormFieldLabel>
            </FormFieldItemContainer>

            <FormFieldListItemLeukerBeterMaken
              leukerMakenBool={item.leukerMaken}
              checked={item.leukerMaken}
              openPuntLeukerMaken={() => this.openPuntLeukerMaken(this.props.index)}
              itemIndex={this.props.index}
              arrayProp="arrayItems"
              setLeukerMakenBool={this.props.setLeukerMakenBool}
            />

            <FormFieldListItemButtonVolgendePunt
              index={this.props.index}
              length={this.props.length}
              volgendeItem={() => this.props.volgendeItem(this.props.index)}
            />
          </div>
        ) : null}
        {this.state.puntLeukerMaken ? (
          <PopUpVoegActieToe
            userID={this.props.userID}
            firebase={this.props.firebase}
            title={item.title}
            item={item}
            closeModule={this.closeModule}
          />
        ) : null}
      </FormFieldListItemContainer>
    )
  }
}

class ListWerkleven3ToggleableMinderLeuk extends React.Component {
  render() {
    const setLeukerMakenBool = this.props.setLeukerMakenBool
    const arrayItems = this.props.arrayItems
    const removeItem = this.props.removeItem
    const toggleOpen = this.props.toggleOpen
    const volgendeItem = this.props.volgendeItem
    const handleChange = this.props.handleChange
    const setEditorState = this.props.setEditorState
    const userID = this.props.userID
    const firebase = this.props.firebase

    return (
      <div id="main">
        <ol>
          {arrayItems.map((item, index) => {
            return (
              <ListItem
                key={index}
                item={item}
                index={index}
                setLeukerMakenBool={setLeukerMakenBool}
                firebase={firebase}
                userID={userID}
                removeItem={removeItem}
                toggleOpen={toggleOpen}
                volgendeItem={volgendeItem}
                handleChange={handleChange}
                setEditorState={setEditorState}
              />
            )
          })}
        </ol>
      </div>
    )
  }
}

export default withFirebase(ListWerkleven3ToggleableMinderLeuk)
