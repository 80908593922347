// Import Routes
import * as ROUTES from '../css/constants/routes'

// Handler om de save state te resetten
function resetSavingState(exit) {
  setTimeout(
    function () {
      this.setState({
        saving: false,
        saved: false,
      })
      if (exit) {
        console.log('TRIGGERERDDEDEDD')
        if (this.props.huidigeSectie === '5') {
          this.props.history.push('/to-do-lijst')
        } else {
          this.props.history.push(`/mijn-werkleven/${parseInt(this.props.huidigeSectie) + 1}/1/`)
          window.scrollTo(0, 0)
        }
      }
    }.bind(this),
    1500,
  )
}

export default resetSavingState
