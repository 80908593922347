import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { withFirebase } from './../../components/Firebase'

import * as ROUTES from '../../css/constants/routes'

// Default export
// Component containing the component PasswordForgetForm containing the form and logic
const PasswordForgetPage = () => (
  <div className="container mx-auto px-6 pt-24 flex flex-wrap flex-row justify-center">
    <div className="bg-white w-full md:w-3/5 rounded shadow-lg flex">
      <div className="w-full px-8 pt-4 pb-8">
        <h1 className="mt-4 mb-2 font-bold text-gray-800 text-2xl">Vraag een nieuw wachtwoord aan</h1>

        <PasswordForgetForm />
      </div>
    </div>
  </div>
)

// State to set in constructor and on submit
const INITIAL_STATE = {
  email: '',
  error: null,
}

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  // Submit handler
  onSubmit = (event) => {
    const { email } = this.state

    // Call method to reset passward in Firebase wrapper
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        // If succesful we reset state
        this.setState({ ...INITIAL_STATE })
        // Then we display the notification that the user has an email to reset their password
        toast('Wij hebben u zojuist een mail gestuurd voor het resetten van uw wachtwoord! 👍')
      })
      .catch((error) => {
        this.setState({ error })
      })

    event.preventDefault()
  }

  // Change handler
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, error } = this.state

    const isInvalid = email === ''

    return (
      <form onSubmit={this.onSubmit} className="mb-8">
        <input
          name="email"
          value={this.state.email}
          onChange={this.onChange}
          type="text"
          autoComplete="off"
          placeholder="E-mail Adres"
          className="bg-white focus:outline-0 w-full md:w-2/4 focus:shadow-outline border border-gray-300 rounded py-2 px-4 mb-2 block appearance-none leading-normal"
        />
        <button
          disabled={isInvalid}
          type="submit"
          className="py-2 px-4 inline-block bg-green-700 hover:bg-green-600 text-sm text-white md rounded shadow-md text-center"
        >
          Reset mijn wachtwoord
        </button>

        {error && (
          <p className="mt-5 bg-red-200 text-red-700 px-4 py-2 rounded border-2 border-red-600 font-bold">
            {error.message}
          </p>
        )}
      </form>
    )
  }
}

// Component that links to the password forget page
const PasswordForgetLink = () => (
  <p>
    <Link className="text-center underline w-full inline-block text-sm text-gray-700" to={ROUTES.PASSWORD_FORGET}>
      Wachtwoord vergeten?
    </Link>
  </p>
)

export default PasswordForgetPage

const PasswordForgetForm = withFirebase(PasswordForgetFormBase)

export { PasswordForgetForm, PasswordForgetLink }
