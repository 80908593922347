import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { withFirebase } from '../Firebase'
import { AuthUserContext } from '../Session'
import SignOutButton from '../SignOut'
import * as ROUTES from '../../css/constants/routes'

import { Heart, Menu } from 'react-feather'
import ToDoLijstSidebar from '../ToDoLijstMob/ToDoLijstMob'

class NavigationProfileElement extends Component {
  constructor(props) {
    super(props)
    // If we get passed a profilePictureURL from the App component we set it
    // Otherwise we request the Firebase database for it
    this.state = {
      popupOpen: false,
      profilePictureURL: this.props.profilePictureURL || null,
    }
    this.togglePopup = this.togglePopup.bind(this)
    this.getAndSetProfilePicture = this.getAndSetProfilePicture.bind(this)
  }

  togglePopup(e) {
    this.setState({
      popupOpen: !this.state.popupOpen,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.getAndSetProfilePicture()
    }
  }

  getAndSetProfilePicture() {
    const firebase = this.props.firebase
    const that = this
    firebase.storage
      .ref()
      .child(`profile_pictures/${this.props.authUser.uid}`)
      .getDownloadURL()
      .then(function (url) {
        that.setState({
          profilePictureURL: url,
        })
      })
      .catch(function (error) {
        // Handle any errors
      })
  }

  componentDidMount() {
    this.getAndSetProfilePicture()
  }

  render() {
    // Inline css to set the background image for the profile picture
    var divStyle = {
      backgroundImage: 'url(' + this.state.profilePictureURL + ')',
    }

    return (
      <React.Fragment>
        <div
          id="profile-avatar"
          style={this.state.profilePictureURL ? divStyle : {}}
          className="border-2 border-white shadow rounded-full h-10 w-10 inline-block bg-gray-500 cursor-pointer relative"
          onClick={this.togglePopup}
          ref={this.profileAvatar}
        >
          {this.state.popupOpen ? (
            <div className="absolute bg-white right-0 rounded mt-12 bg-gray-100 w-64 mr-2 text-gray-700 z-20 shadow add-tooltip add-tooltip-right">
              <ul className="py-2 text-gray-700 px-4 select-none">
                <PopupNavigationListElement route="/account" name="Account" togglePopup={this.togglePopup} />
                <li className="flex navigatie-item items-center cursor-pointer text-left">
                  <NavLink
                    activeClassName="is-active-submenu"
                    className="transition-fast hover:translate-t-2px hover:text-gray-900 w-full p-2 text-gray-600 font-medium"
                    to={`/signout`}
                    onClick={this.props.firebase.doSignOut}
                  >
                    Uitloggen
                  </NavLink>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </React.Fragment>
    )
  }
}

function PopupNavigationListElement(props) {
  return (
    <li
      className={`flex navigatie-item items-center cursor-pointer text-left ${
        props.noBorder ? '' : 'border-b border-gray-300'
      }`}
    >
      <NavLink
        exact
        activeClassName="is-active-submenu"
        className="transition-fast hover:translate-t-2px hover:text-gray-900 w-full p-2 text-gray-600 font-medium"
        to={props.route}
        onClick={props.togglePopup}
      >
        {props.name}
      </NavLink>
    </li>
  )
}

function MijnWerklevenMenuItem(props) {
  return (
    <li
      className={`flex navigatie-item items-center cursor-pointer text-left ${
        props.noBorder ? '' : 'border-b border-gray-300'
      }`}
    >
      <NavLink
        onClick={props.togglePopup}
        activeClassName="is-active-submenu"
        className="transition-fast hover:translate-t-2px hover:text-gray-900 w-full p-2 text-gray-600 font-medium"
        to={props.route}
      >
        {props.name}
      </NavLink>
    </li>
  )
}

class MijnWerklevenMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      popupOpen: false,
    }
    this.togglePopup = this.togglePopup.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
  }

  togglePopup() {
    this.setState({
      popupOpen: !this.state.popupOpen,
    })
  }

  onMouseLeave() {
    this.setState({ popupOpen: false })
  }

  render() {
    return (
      <React.Fragment>
        <li className="ml-6 flex navigatie-item items-center cursor-pointer relative" onClick={this.togglePopup}>
          <NavLink
            className="transition-fast hover:translate-t-2px hover:text-gray-900 text-gray-600 font-medium cursor-pointer"
            to={`/mijn-werkleven`}
            activeClassName="is-active"
            onClick={(e) => {
              e.preventDefault()
            }}
          >
            Mijn Werkleven
          </NavLink>
          {this.state.popupOpen ? (
            <div
              className="absolute bg-white right-0 rounded mt-12 bg-gray-100 w-64 mr-2 text-gray-700 z-20 shadow add-tooltip add-tooltip-middle"
              onMouseLeave={this.onMouseLeave}
            >
              <ul className="py-2 text-gray-700 px-4 select-none">
                <MijnWerklevenMenuItem route={`/mijn-werkleven/1/1`} name="1. Doel" togglePopup={this.togglePopup} />
                <MijnWerklevenMenuItem route={`/mijn-werkleven/2/1`} name="2. Cijfers" togglePopup={this.togglePopup} />
                <MijnWerklevenMenuItem
                  route={`/mijn-werkleven/3/1`}
                  name="3. Huidig Werkleven"
                  togglePopup={this.togglePopup}
                />
                <MijnWerklevenMenuItem
                  route={`/mijn-werkleven/4/1`}
                  name="4. Gewenste werkleven"
                  togglePopup={this.togglePopup}
                />
                <MijnWerklevenMenuItem
                  route={`/mijn-werkleven/5/1`}
                  name="5. Mogelijkheden"
                  togglePopup={this.togglePopup}
                />
                <MijnWerklevenMenuItem route={`/to-do-lijst`} name="6. Actielijst" togglePopup={this.togglePopup} />
                <MijnWerklevenMenuItem
                  route={`/tijdlijn`}
                  name="7. Tijdlijn"
                  noBorder={true}
                  togglePopup={this.togglePopup}
                />
              </ul>
            </div>
          ) : null}
        </li>
      </React.Fragment>
    )
  }
}

function NavigationListElement(props) {
  const name = props.name
  const route = props.route

  return (
    <li className="ml-6 flex navigatie-item items-center cursor-pointer">
      <NavLink
        activeClassName="is-active"
        exact
        className="transition-fast hover:translate-t-2px hover:text-gray-900 text-gray-600 font-medium cursor-pointer"
        to={route}
      >
        {name}
      </NavLink>
    </li>
  )
}

// Component to display the menu items
function NavigationAuth(props) {
  return (
    <div className="flex">
      <ul className="hidden md:flex mr-6">
        <NavigationListElement name="Dashboard" route={ROUTES.DASHBOARD} />
        <MijnWerklevenMenu />
        <NavigationListElement name="Actielijst" route={ROUTES.TO_DO_LIJST} />
        <NavigationListElement name="Tijdlijn" route={ROUTES.TIMELINE} />
      </ul>
      <NavigationProfileElement
        profilePictureURL={props.profilePictureURL}
        authUser={props.authUser}
        firebase={props.firebase}
      />
    </div>
  )
}

const NavigationNonAuth = () => (
  <React.Fragment>
    <ul>
      {/* <li>
        <Link to={ROUTES.LANDING}>Landing</Link>
      </li> */}
      <li>
        <Link to={ROUTES.SIGN_IN}>Sign In</Link>
      </li>
    </ul>
  </React.Fragment>
)

const Navigation = ({ firebase, profilePictureURL }) => (
  <nav className="bg-gray-100 w-full z-10 top-0 fixed z-20 shadow">
    <div className="flex container mx-auto items-center justify-between flex-wrap px-6">
      <Link to={`/`} className="main-logo inline-block" />
      <div>
        {process.env.NODE_ENV === 'production' ? <p>Development version: {process.env.REACT_APP_VERSION}</p> : null}
      </div>
      {/* Right side of the navigation, when user is logged in */}
      <div className="flex items-center justify-end">
        <AuthUserContext.Consumer>
          {(authUser) =>
            authUser ? (
              <NavigationAuth profilePictureURL={profilePictureURL} authUser={authUser} firebase={firebase} />
            ) : null
          }
        </AuthUserContext.Consumer>
      </div>
    </div>
    <AuthUserContext.Consumer>{(authUser) => (authUser ? <ToDoLijstSidebar /> : null)}</AuthUserContext.Consumer>
  </nav>
)

export default withFirebase(Navigation)
