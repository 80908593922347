import React from 'react'
import SubtitleQuestionair from '../Atoms/Subtitle-Questionair'
import QuestionAddition from '../Atoms/Question-Addition'

const NewPossibilities = (props) => {
  return (
    <>
      <SubtitleQuestionair>Nieuwe mogelijkheden</SubtitleQuestionair>
      <QuestionAddition>
        Of is er (ook) een verandering nodig zodat je dit kan bereiken in jouw huidige werkleven?
      </QuestionAddition>
      <br />
      <h3 className="font-bold">Binnen huidige werkleven</h3>
      <ul className="list-disc mt-2 mb-5 pl-5">
        <li>ontwikkeling/opleiding/cursus</li>
        <li>andere taken in huidige rol</li>
        <li>andere rol binnen huidige organisatie</li>
      </ul>
      <h3 className="font-bold">Buiten huidige werkleven</h3>
      <ul className="list-disc mt-2 mb-5 pl-5">
        <li>hobby/vrijwilligerswerk</li>
        <li>onderneming starten</li>
        <li>oriënteren carrièreswitch </li>
      </ul>

      <div className="flex mt-10 mb-4">
        <label className="bg-white si si-checkbox block shadow mr-2 rounded cursor-pointer hover:shadow-lg transition-short">
          <input
            className="mr-2 leading-tight cursor-pointer"
            type="checkbox"
            checked={props.item.nieuweMogelijkheid !== undefined ? props.item.nieuweMogelijkheid : false}
            onClick={() => {
              if (!props.item.nieuweMogelijkheid) {
                console.log('Clicked')
                props.togglePopUp('MogelijkheidGewenstPopUp')
              }
            }}
            onChange={() => {
              if (!props.item.nieuweMogelijkheid) {
                props.setNieuweMogelijkheidBool(props.index, props.typeOfImportant)
              }
            }}
          />
          <span className="text-xs md:text-base text-black select-none -mt-2 mr-8 si-label">Ja/Misschien</span>
        </label>

        <label className="bg-white si si-checkbox block mr-2 shadow rounded cursor-pointer hover:shadow-lg transition-short">
          <input
            className="mr-2 leading-tight cursor-pointer"
            type="checkbox"
            checked={props.item.nieuweMogelijkheid !== undefined ? !props.item.nieuweMogelijkheid : false}
            onChange={() => {
              props.setNieuweMogelijkheidBool(props.index, props.typeOfImportant, true)
            }}
          />
          <span className="text-xs md:text-base text-black select-none -mt-2 si-label">Nee</span>
        </label>
      </div>
    </>
  )
}

export default NewPossibilities
