// Import Packages
import React from 'react'
import Tooltip from 'react-tooltip-lite'
import format from 'date-fns/format'
import addMonths from 'date-fns/add_months'
import nlLocale from 'date-fns/locale/nl'

// Import Components
import Checkbox from './../../../../components/Checkbox'
import Editor from './../../../../components/Editor'
import Lightbulb from './../../../../components/Lightbulb'

// Import Loaders
import LoaderList from './../../../../components/Loaders/LoaderList'
import LoaderEditor from './../../../../components/Loaders/LoaderEditor'
import PopUpVoegDoelToe from '../../../../components/PopUpVoegDoelToe/PopUpVoegDoelToe'
import { deleteCurrentGoal, getAllGoals } from '../../../../functions/goalsHelper'
import { withAuthentication } from '../../../../components/Session'
import { withFirebase } from '../../../../components/Firebase'
import TitleQuestionair from '../../../../components/Atoms/Title-Questionair'
import Modal from '../../../../components/Template/Modal'

const checkBoxArray = [
  'Werkplezier verhogen',
  'Skills verbeteren',
  'Keuze(s) maken',
  'Carrièreplan uitstippelen',
  'Doelen bereiken',
  'De wereld veroveren',
  'Anders, namelijk:',
]

class MijnWerkleven1Pagina1 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSelectedGoal: '',
      customGoal: '',
      addGoalsPopup: false,
      initialRedenStart: this.props.initialRedenStart,
      allGoals: [],
      isDifferent: false,
    }
    this.toggleAddGoalPopup = this.toggleAddGoalPopup.bind(this)
    this.selectedGoalType = this.selectedGoalType.bind(this)
    this.setEditorState = this.setEditorState.bind(this)
    this.collectAllGoals = this.collectAllGoals.bind(this)
  }

  toggleAddGoalPopup() {
    this.setState({ addGoalsPopup: !this.state.addGoalsPopup })
  }

  selectedGoalType(event, checkboxValue) {
    let isChecked = event.target.checked

    this.props.handleChecked(event, checkboxValue)
    console.log(event.target.name, event.target.value)
    if (event.target.name === 'Anders, namelijk:') {
      this.setState({ isDifferent: true })
    }

    this.setState({ currentSelectedGoal: event.target.name }, () => {
      this.setState({ addGoalsPopup: isChecked })
    })

    if (!isChecked) {
      let firebase = this.props.firebase
      let that = this
      const foundGoal = this.state.allGoals.find((g) => g.labelNaam === event.target.name || g.isDifferent)
      deleteCurrentGoal(firebase, this.props.authUser, foundGoal).then((r) => {
        this.collectAllGoals()
      })
    }
  }

  // Algemene State Handler voor de Editor
  setEditorState(stateValue, fieldName) {
    let currentValue = this.state.customGoal
    this.setState(
      {
        customGoal: stateValue,
      },
      () => {
        if (currentValue === this.state.customGoal && currentValue !== '<p><br></p>') {
          this.setState(
            {
              //remove the <p> and </p> from the string
              currentSelectedGoal: this.state.customGoal.substr(3, this.state.customGoal.length - 7),
            },
            () => {
              this.setState({ addGoalsPopup: true }, () => {
                this.setState({ customGoal: '' })
              })
            },
          )
        }
      },
    )
  }

  collectAllGoals() {
    let firebase = this.props.firebase
    let that = this
    firebase.auth.onAuthStateChanged(function (user) {
      getAllGoals(firebase, user).then((result) => {
        that.setState({
          allGoals: [...result],
        })
      })
    })
  }

  componentDidUpdate(oldProps, prevState) {
    const newProps = this.props
    if (oldProps.initialRedenStart !== newProps.initialRedenStart) {
      this.setState({
        initialRedenStart: newProps.initialRedenStart,
      })
    }
    if (prevState.addGoalsPopup !== this.state.addGoalsPopup) {
      this.collectAllGoals()
    }
  }

  componentDidMount() {
    this.collectAllGoals()
  }

  render() {
    return (
      <React.Fragment>
        {this.state.addGoalsPopup ? (
          <Modal onClose={this.toggleAddGoalPopup}>
            <PopUpVoegDoelToe
              setLoading={(e) => {
                this.setState({ addGoalsPopup: true })
              }}
              closeModule={this.toggleAddGoalPopup}
              goals={this.state.allGoals}
              title={this.state.currentSelectedGoal}
              displayNotes={false}
              buttonTitle={'Zet doel op tijdlijn'}
              choiceType={false}
              isDifferent={this.state.isDifferent}
            />
          </Modal>
        ) : null}
        <TitleQuestionair>A. Doel WorkAppic</TitleQuestionair>
        <div id="omschrijving">
          <h3 className="font-bold text-gray-700 mb-1">Wat wil ik straks bereikt hebben met WorkAppic?</h3>

          {this.props.loading === false ? (
            <form>
              <div className="flex flex-col">
                {checkBoxArray.map((value, index) => {
                  return (
                    <Checkbox
                      handleChecked={this.selectedGoalType}
                      key={index}
                      index={index}
                      initialCheckboxValuesArray={this.state.initialRedenStart}
                      checkBoxValue={this.state.allGoals?.some(
                        (g) => g.labelNaam === value || (value === 'Anders, namelijk:' && g.isDifferent),
                      )}
                      name={value}
                    />
                  )
                })}
              </div>
            </form>
          ) : (
            <React.Fragment>
              <LoaderList />
              <LoaderEditor />
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default withAuthentication(withFirebase(MijnWerkleven1Pagina1))
