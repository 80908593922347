import React, { useState, useEffect, useRef } from 'react'
import { goalStyling, dayAndMonth } from '../../functions/utils'
import PopUpGoalSettings from '../../components/Template/PopUpGoalSettings'
import { updateCurrentGoal } from '../../functions/goalsHelper'
import { withAuthentication } from '../../components/Session'
import { withFirebase } from '../../components/Firebase'
import { CheckCircle, Circle, Settings } from 'react-feather'
import Modal from '../../components/Template/Modal'
import isBefore from 'date-fns/is_before'
import subDays from 'date-fns/sub_days'
import format from 'date-fns/format'
import nlLocale from 'date-fns/locale/nl'

const horizontalLine = {
  '&:after': {
    content: '',
    display: 'block',
    backgroundColor: 'gray',
    marginLeft: '-2px;',
  },
}

const TimelineNode = (props) => {
  const [goalActionList, setGoalActionList] = useState(false)
  const [toggleEditTitle, setToggleUpdateTitle] = useState(false)
  const [title, setTitle] = useState(props.goal.labelNaam)

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setToggleUpdateTitle(false)
          setTitle(props.goal.labelNaam)
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const handleChange = (e) => {
    setTitle(e.target.value)
  }

  const toggleGoalSettings = () => {
    if (!props.isDashboard) {
      setGoalActionList((prev) => !prev)
    }
  }

  const toggleGoalState = () => {
    if (!props.isDashboard) {
      let state = ''
      if (props.goal.state === 'failed') {
        state = props.goal.state === 'failed' ? 'passed' : 'failed'
      } else {
        state = props.goal.state === 'active' ? 'done' : 'active'
      }
      updateCurrentGoal(
        props.firebase,
        props.authUser,
        props.goal,
        props.goal.labelNaam,
        props.goal.deadline,
        props.goal.actions,
        state,
        props.goal.notes,
      ).then(() => {
        props.setLoading(true)
      })
    }
  }

  const countActiveActions = (actions) => {
    const activeActions = actions.filter((e) => {
      return !e.voltooid
    })
    return activeActions.length
  }

  const onSave = (e) => {
    if (e.key === 'Enter') {
      updateCurrentGoal(
        props.firebase,
        props.authUser,
        props.goal,
        title,
        props.goal.deadline,
        props.goal.actions,
        props.goal.state,
        props.goal.notes,
      ).then(() => {
        if (props.setLoading) {
          props.setLoading(true)
        }
        setToggleUpdateTitle(false)
      })
    }
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  const inputTitle = () => (
    <input
      ref={wrapperRef}
      className="text-black"
      name="title"
      onChange={handleChange}
      onKeyDown={onSave}
      value={title}
    />
  )

  return (
    <div
      key={props.goal.labelNaam + '_' + props.index}
      className={
        `flex flex-col md:flex-row  mt-8 ${
          props.goal.type === 'werk' ? 'justify-start node-margin-right' : 'justify-end sm:-ml-64 node-margin'
        } ` + goalStyling(props.goal.state)
      }
      style={{ ...horizontalLine }}
    >
      {goalActionList && (
        <Modal onClose={toggleGoalSettings}>
          <PopUpGoalSettings
            goal={props.goal}
            goals={props.goals}
            toggleGoalSettingPopUp={toggleGoalSettings}
            setLoading={props.setLoading}
          />
        </Modal>
      )}
      {props.goal.type === 'prive' && window.innerWidth >= 500 && (
        <div className={`md:w-20 flex flex-col justify-center`}>
          <div
            style={{ marginLeft: '-2px' }}
            className={
              isBefore(props.goal.deadline, subDays(new Date(), 1))
                ? 'text-sm font-normal text-red-700'
                : 'text-sm font-normal'
            }
          >
            {dayAndMonth(props.goal.deadline)}
          </div>
        </div>
      )}
      <div
        className={`flex node-lg md:w-1/2 sm:w-20 md:text-lg sm:text-xs rounded-full items-center justify-center sm:mt-0 mt-3 py-2 px-4 md:px-2 lg:px-10 lg:px-6 text-white 
        ${
          props.goal.type === 'werk'
            ? 'bg-wa-button-green hover:bg-green-800'
            : 'bg-wa-button-turquoise hover:bg-gray-500'
        }`}
      >
        <div className="flex w-full items-center">
          {props.goal.state === 'done' || props.goal.state === 'passed' ? (
            <span
              className={'cursor-pointer actie-item-voltooid '}
              onClick={(e) => {
                toggleGoalState(e)
              }}
            >
              <CheckCircle
                size={window.innerWidth >= 500 ? 30 : 15}
                style={{
                  backgroundColor: 'white',
                  borderRadius: '50%',
                }}
                className="wa-green-color -mt-1 relative select-none pointer-events-none actie-icon-voltooid border-2 border-white"
              />
            </span>
          ) : (
            <span
              className={'cursor-pointer actie-item-onvoltooid '}
              onClick={(e) => {
                toggleGoalState(e)
              }}
            >
              <Circle
                size={window.innerWidth >= 500 ? 30 : 15}
                style={{
                  borderRadius: '50%',
                }}
                className="text-white -mt-1 relative select-none pointer-events-none actie-icon-onvoltooid"
              />
              <CheckCircle
                size={window.innerWidth >= 500 ? 30 : 15}
                style={{
                  backgroundColor: 'white',
                  borderRadius: '50%',
                }}
                className="wa-green-color -mt-1 relative select-none pointer-events-none actie-icon-voltooid border-2 border-white"
              />
            </span>
          )}
          <span
            className={'cursor-pointer ml-1 md:ml-4 w-4/5'}
            onClick={() => {
              toggleGoalSettings(props.goal)
            }}
          >
            <div className={'flex flex-col'}>
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  setToggleUpdateTitle(true)
                }}
                className="wa-text-mini container node-text-lg cursor-pointer lg:text-lg sm:text-xs md:text-base md:text-sm text-left truncate ..."
              >
                {toggleEditTitle ? inputTitle() : title}
              </div>
              <div className="flex text-xs items-center" style={{ marginTop: '-2px' }}>
                <div className="text-xs md:text-sm" style={{ marginTop: '-1px' }}>
                  ({countActiveActions(props.goal.actions)}) Acties
                </div>
              </div>
            </div>
          </span>
          {!props.isDashboard && (
            <span
              className="flex items-center"
              onClick={() => {
                toggleGoalSettings(props.goal)
              }}
            >
              <Settings
                size={window.innerWidth >= 500 ? 25 : 15}
                className="text-xs relative cursor-pointer relative"
              />
            </span>
          )}
        </div>
      </div>
      {props.goal.type === 'werk' && window.innerWidth >= 500 && (
        <div className="md:w-20 flex flex-col justify-center">
          <div
            className={
              isBefore(props.goal.deadline, subDays(new Date(), 1))
                ? 'text-sm font-normal text-red-700'
                : 'text-sm font-normal'
            }
          >
            {dayAndMonth(props.goal.deadline)}
          </div>
        </div>
      )}
    </div>
  )
}

export default withAuthentication(withFirebase(TimelineNode))
