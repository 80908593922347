import React, { useState, useEffect } from 'react'
import { withAuthentication } from '../../components/Session'
import ContainerPage from '../../components/ContainerPage'
import MainHeader from '../../components/MainHeader'
import ContainerMain from '../../components/ContainerMain'
import checkFirstVisitPage from '../../functions/checkFirstVisitPage'
import ButtonMain from '../../components/ButtonMain'
import TimelineContainer from './TimelineContainer'

import { makeDate, makeLabelSlug, makeDateObj, checkGoalsOnMonth } from '../../functions/utils'
import { createActionObj } from '../../functions/actionsHelper'
import PageExplanation from '../../components/Template/Page-Explanation'
import TimelineExplanation from './TimelineExplanation'

const day = (givenDate) => {
  let destructedDate = 0
  if (givenDate) {
    destructedDate = givenDate.split('-')
  }
  return parseInt(destructedDate[2])
}

const sortActions = (actions) => {
  //console.log('actions', actions)
  if (actions && actions.length > 1) {
    actions.sort((a, b) => (day(a.deadline) > day(b.deadline) ? 1 : -1))
  }
  //console.log('actions sorted', actions)
  return actions
}

const Timeline = (props) => {
  const [firstVisit, setFirstVisit] = useState(undefined)
  const [goals, setGoals] = useState([])

  const [goalPrivate, setPrivateGoals] = useState([])
  const [goalWork, setWorkGoals] = useState([])

  const [actions, setActies] = useState([])
  const [loading, setLoading] = useState(true)
  const [zoomedIn, setZoomedIn] = useState(false)

  const [totalMonths, setTotalMonths] = useState(60)
  const [selectedMonthName, setSelectedMonthName] = useState('')
  const [selectedMonthNumber, setSelectedMonthNumber] = useState()
  const [user, setUser] = useState(undefined)

  const monthToRowNumber = (date) => {
    const dateToday = new Date()
    //console.log('date', date);
    if (date) {
      const currentYear = dateToday.getFullYear()
      const deconstructDate = date.split('-')
      const months = parseInt(date.split('-')[1])
      const years = parseInt(deconstructDate[0])
      //console.log('difference', (years - currentYear));
      const yearsInFuture = years - currentYear
      const totalAmountOfMonths = months + yearsInFuture * 12
      //console.log('totalAmountOfMonths', totalAmountOfMonths);
      return totalAmountOfMonths
    }
  }

  const setFirstVisitStateUitleg = (isFirstVisit) => {
    setFirstVisit(isFirstVisit)
  }

  const readInfo = () => {
    let docRef = props.firebase.db.collection('users').doc(user.uid).collection('modulen').doc('firstVisit')

    docRef.get().then((doc) => {
      if (doc.exists && doc.data()['timeline']) {
        docRef
          .set({ ...doc.data(), timeline: false })
          .then(function () {})
          .catch(function (error) {
            console.error('Error writing document: ', error)
          })
      } else {
        docRef
          .set({ actionlist: false })
          .then(function () {
            console.log('Document successfully written!')
          })
          .catch(function (error) {
            console.error('Error writing document: ', error)
          })
      }
    })
  }

  //ZOOM IN on one month
  const selectOneMonth = (monthNumber, monthName) => {
    setSelectedMonthName(monthName)
    setSelectedMonthNumber(monthNumber)
    let workGoals = []
    let privateGoals = []
    //collect goal by rowPosition work and prive
    goalWork.map((goalSet) => {
      goalSet.map((goal, index) => {
        if (goal.rowPosition === monthNumber) {
          goal['zoomedRowPosition'] = index
          return workGoals.push(goal)
        }
      })
    })
    goalPrivate.map((goalSet, index) => {
      goalSet.map((goal) => {
        if (goal.rowPosition === monthNumber) {
          goal['zoomedRowPosition'] = index
          return privateGoals.push(goal)
        }
      })
    })

    console.log('setPrivateGoals', setPrivateGoals)

    setTotalMonths(1)
    setWorkGoals([workGoals])
    setPrivateGoals([privateGoals])
    setZoomedIn(true)
  }

  const setSelectedAmountOfMonths = (months) => {
    setTotalMonths(months)
    setLoading(true)
  }

  const getGoalActions = (goals, UUID) => {
    let newArrayOfActions = []
    if (goals) {
      goals.map((goal) => {
        if (goal.labels) {
          if (goal.labels[0].UUID === UUID) {
            newArrayOfActions.push(goal)
          }
        }
      })
    }

    return newArrayOfActions
  }

  const checkGoalState = (goalState, deadline) => {
    //console.log('goalState', goalState)
    const dateToday = new Date()
    const deadlineDate = makeDateObj(deadline)

    if (dateToday > deadlineDate) {
      if (goalState === 'active') {
        return 'failed'
      } else if (goalState === 'done') {
        return 'passed'
      } else {
        return goalState
      }
    } else {
      return goalState
    }
  }

  const findAndMergeActions = (oldActions, goal) => {
    let findActions = []
    let returnLeftOvers = oldActions

    if (oldActions.length > 0) {
      oldActions.map((action, index) => {
        if (action.labels && action.UUID === undefined) {
          if (action.labels.length > 0) {
            if (action.labels[0].UUID === goal.UUID) {
              const newAction = createActionObj(action.actieNaam, goal.UUID, action.deadline, action.notes)
              findActions.push(newAction)
              returnLeftOvers.splice(index, 1)
            }
          }
        }
      })
    }

    if (goal.actions.length > 0) {
      goal.actions.map((action) => {
        findActions.map((tmp, index) => {
          if (tmp.actieNaam === action.actieNaam) {
            findActions.pop(index)
          }
        })
        findActions.push(action)
      })
    }
    return [findActions, returnLeftOvers]
  }

  async function fetchData() {
    let firebase = props.firebase
    let found = []

    firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user)

        let initialGoals = []
        let initialStateWork = []
        let initialStatePrivate = []
        let leftOvers = []

        let oldActions = []

        let docRefActions = firebase.db.collection('users').doc(user.uid).collection('actielijst').doc('acties') //labels are goals now

        let docRefGoals = firebase.db.collection('users').doc(user.uid).collection('actielijst').doc('labels') //labels are goals now

        docRefActions.get().then(function (doc) {
          if (doc.exists) {
            if (doc.data().acties && doc.data().acties.length > 0) {
              doc.data().acties.forEach((action) => {
                //A thought does not have a UUID,
                // it does not belong to a goal
                if (action.UUID === undefined) {
                  //console.log('push thougth')
                  oldActions.push(action)
                }
              })
            }
          }
        })

        docRefGoals
          .get()
          .then(function (doc) {
            if (doc.exists) {
              if (doc.data().currentLabels && doc.data().currentLabels.length > 0) {
                doc.data().currentLabels.forEach((goal) => {
                  console.log('goal', goal)
                  if (goal.labelNaam) {
                    initialGoals.push({
                      labelNaam: goal.labelNaam,
                      labelSlug: makeLabelSlug(goal.labelNaam),
                      UUID: goal.UUID,
                      deadline: goal.deadline,
                      type: goal.type,
                      state: checkGoalState(goal.state, goal.deadline),
                      rowPosition: monthToRowNumber(goal.deadline),
                      actions: sortActions(goal.actions) || [],
                      notes: goal.notes || [],
                    })
                    if (goal.type === undefined) {
                      //zoek in de oude acties naar labels met uuid
                      //en als die hetzelfde is doe die dan hier in de actions
                      let findActions = []
                      oldActions.map((action) => {
                        if (action.labels) {
                          if (action.labels[0].UUID === goal.UUID) {
                            const newAction = createActionObj(
                              action.actieNaam,
                              goal.UUID,
                              action.deadline,
                              action.notes,
                            )
                            findActions.push(newAction)
                          }
                        }
                      })

                      initialStateWork.push({
                        labelNaam: goal.labelNaam,
                        labelSlug: makeLabelSlug(goal.labelNaam),
                        UUID: goal.UUID,
                        deadline: '2020-01-01',
                        type: 'werk',
                        state: checkGoalState('active', '2020-01-01'),
                        rowPosition: 1,
                        //NEXT GET THE ACTIONS AND PUT THEM HERE BASED ON UUID
                        actions: findActions.length > 0 ? sortActions(findActions) : sortActions(goal.actions) || [],
                        notes: goal.notes || [],
                      })
                    }
                    if (goal.type === 'werk') {
                      let [findActionsWork, leftOversWerk] = findAndMergeActions(oldActions, goal)
                      console.log('leftOversWerk', leftOversWerk)
                      console.log('findActionsWork', findActionsWork)
                      leftOvers = leftOversWerk
                      //console.log('work actions', findActionsWork);
                      initialStateWork.push({
                        labelNaam: goal.labelNaam,
                        labelSlug: makeLabelSlug(goal.labelNaam),
                        UUID: goal.UUID,
                        deadline: goal.deadline,
                        type: goal.type,
                        state: checkGoalState(goal.state, goal.deadline),
                        rowPosition: monthToRowNumber(goal.deadline),
                        actions:
                          findActionsWork.length > 0 ? sortActions(findActionsWork) : sortActions(goal.actions) || [],
                        notes: goal.notes || [],
                      })
                    }

                    if (goal.type === 'prive') {
                      let [findActionsPrivate, leftOversPrive] = findAndMergeActions(oldActions, goal)

                      found = leftOversPrive.filter((val) => !leftOvers.includes(val))
                      //console.log('private actions', findActionsPrivate)
                      initialStatePrivate.push({
                        labelNaam: goal.labelNaam,
                        labelSlug: makeLabelSlug(goal.labelNaam),
                        UUID: goal.UUID,
                        deadline: goal.deadline,
                        type: goal.type,
                        state: checkGoalState(goal.state, goal.deadline),
                        rowPosition: monthToRowNumber(goal.deadline),
                        actions:
                          findActionsPrivate.length > 0
                            ? sortActions(findActionsPrivate)
                            : sortActions(goal.actions) || [],
                        notes: goal.notes || [],
                      })
                    }
                  }
                })
              }
            } else {
              console.log('no labels where found')
            }

            const monthsPerSetWork = checkGoalsOnMonth(initialStateWork, totalMonths, zoomedIn, selectedMonthNumber)
            const monthsPerSetPrivate = checkGoalsOnMonth(
              initialStatePrivate,
              totalMonths,
              zoomedIn,
              selectedMonthNumber,
            )

            const spread = [...initialStateWork, ...initialStatePrivate]

            setGoals(spread)
            setPrivateGoals(monthsPerSetPrivate)
            setWorkGoals(monthsPerSetWork)

            setLoading(false)
          })
          .catch(function (error) {
            console.log('Error getting document:', error)
          })
      }
    })
  }

  useEffect(() => {
    let firebase = props.firebase
    if (user) {
      checkFirstVisitPage(user.uid, firebase, 'timeline', setFirstVisitStateUitleg)
    }
  }, [user])

  useEffect(() => {
    if (loading) {
      fetchData()
    }
  }, [goals, loading])

  return (
    <ContainerPage>
      <MainHeader title="7. Tijdlijn" />
      <TimelineContainer
        firstVisit={firstVisit}
        goals={goals}
        goalWork={goalWork}
        goalPrivate={goalPrivate}
        loading={loading}
        setLoading={setLoading}
        selectOneMonth={selectOneMonth}
        totalMonths={totalMonths}
        selectedMonthName={selectedMonthName}
        setTotalMonths={setTotalMonths}
        setSelectedMonthName={setSelectedMonthName}
        setSelectedAmountOfMonths={setSelectedAmountOfMonths}
        zoomedIn={zoomedIn}
        setZoomedIn={setZoomedIn}
        readInfo={readInfo}
      />
    </ContainerPage>
  )
}

export default withAuthentication(Timeline)
