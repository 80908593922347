import React, { useEffect, useState } from 'react'
import ButtonMain from '../../components/ButtonMain'
import InfoUitlegModule from '../../components/InfoUitlegModule'
import PageExplanation from '../../components/Template/Page-Explanation'
import ContainerPage from '../../components/ContainerPage'
import Tooltip from 'react-tooltip-lite'
import Lightbulb from '../../components/Lightbulb'

const TimelineExplanation = ({ firstVisit }) => {
  const [isOpend, setIsOpend] = useState(firstVisit)

  const toggle = (e) => {
    e.preventDefault()
    setIsOpend((prev) => !prev)
  }

  useEffect(() => {
    setIsOpend(firstVisit)
  }, [firstVisit])

  return (
    <PageExplanation firstVisit={isOpend} toggle={toggle}>
      {/* Eerste x uitleg popup */}
      <div className="text-gray-800">
        <h4 className="font-bold ">Uitleg Tijdlijn</h4>
        <React.Fragment>
          <p>Dit is de tijdlijn waar je overzichtelijk jouw doelen ziet staan.</p>

          <ul className="pl-5">
            <li>- De doelen die je al hebt bedacht tijdens WorkAppic staan al op de tijdlijn. </li>
            <li>
              - De doelen die nog geen deadline hebben gekregen staan op 1 januari in het rood. Geef een deadline aan
              deze doelen.{' '}
            </li>
            <li>
              - Zet alle doelen die je in werk en privé hebt op deze tijdlijn. Je kunt doelen toevoegen, aanpassen,
              verwijderen of afvinken.
            </li>
            <li>- Gebruik de tijdlijn om te kijken of doelen elkaar in de weg zitten of juist versterken.</li>
            <li>- Je kunt in elk doel nieuwe acties toevoegen en die weer een deadline geven.</li>
            <li>- Om te zorgen dat je focus houdt, kan je maximaal 3 doelen per maand aanmaken.</li>
          </ul>
          <br />

          <p>
            Handigheidje: WorkAppic herinnert jou aan het uitvoeren van de acties en doelen door het sturen van
            e-mailnotificaties
          </p>
        </React.Fragment>
      </div>
    </PageExplanation>
  )
}

TimelineExplanation.defaultProps = {
  firstVisit: false,
}

export default TimelineExplanation
