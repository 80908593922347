import React from 'react'
import RadioButton from '../Atoms/RadioButton'

const ToggleGoalType = (props) => (
  <div className="flex flex-row mt-2">
    <RadioButton
      label={props.labelOne}
      required={false}
      name={props.name}
      value={props.valueOne}
      checked={props.selectedType === 'werk'}
      onToggle={props.onToggle}
    />
    <RadioButton
      label={props.labelTwo}
      required={false}
      name={props.name}
      value={props.valueTwo}
      checked={props.selectedType === 'prive'}
      onToggle={props.onToggle}
    />
  </div>
)

export default ToggleGoalType
