import React, { useState, useEffect } from 'react'
import { Plus, Settings, Tag } from 'react-feather'
import Tooltip from 'react-tooltip-lite'
import Lightbulb from '../Lightbulb'
import { NavLink } from 'react-router-dom'
import TimelineExplanation from '../../pages/Timeline/TimelineExplanation'
import { getAllGoals } from '../../functions/goalsHelper'
import { withAuthentication } from '../Session'
import { withFirebase } from '../Firebase'
import { pages } from '../../text.json'
import AddButton from '../Atoms/AddButton'

const SidebarGoals = (props) => {
  const [allActions, setAllActions] = useState([])

  const countNoDoneActions = (goal) => {
    if (goal) {
      let currentAction = allActions.filter((a) => {
        return a.UUID === goal.UUID
      })
      let notDoneActions = currentAction.filter((a) => {
        return a.voltooid === false
      })
      return notDoneActions.length
    } else {
      return 0
    }
  }

  useEffect(() => {
    setAllActions(props.pureActions)
  }, [props.pureActions])

  return (
    <div>
      <div>
        <div className="border-t-2 border-white border p-5">
          <span className="inline text-xl text-white">
            Doelen
            <Tooltip
              content={
                <div className="max-w-sm p-4 pl-6">
                  <p>
                    <b>{pages.actionlist.explanationTitleGoals1}</b>
                  </p>
                  <p>
                    <b>{pages.actionlist.explanationTitleGoals2}</b>
                  </p>

                  <br />
                  <p>
                    <b>{pages.actionlist.explanationTitleGoals3}</b>
                  </p>
                  <ul className="list-disc">
                    {pages.actionlist.explanationPointsGoals3?.map((t) => (
                      <li>{t}</li>
                    ))}
                  </ul>

                  <br />
                  <p>
                    <b>{pages.actionlist.explanationTitleGoals4}</b>
                  </p>
                  <ul className="list-disc">
                    {pages.actionlist.explanationPointsGoals4?.map((t) => (
                      <li>{t}</li>
                    ))}
                  </ul>

                  <br />
                  <p>
                    <b>{pages.actionlist.explanationTitleGoals5}</b>
                  </p>
                  <ul className="list-disc">
                    {pages.actionlist.explanationPointsGoals5?.map((t) => (
                      <li>{t}</li>
                    ))}
                  </ul>

                  <br />
                  <p>
                    <b>{pages.actionlist.explanationTitleGoals6}</b>
                  </p>
                </div>
              }
              className="inline-block z-20"
            >
              <Lightbulb />
            </Tooltip>
          </span>
          <ul className="mt-2" style={{ width: '100%' }}>
            {props.goals && props.goals.length > 0
              ? props.goals.map((goal, index) => {
                  return (
                    <>
                      <NavLink
                        key={goal.UUID}
                        activeClassName="is-active cursor-default font-normal text-white underline"
                        className="transition-fast hover:translate-t-2px hover:text-white text-white"
                        to={`/to-do-lijst/${goal.labelSlug}`}
                        exact
                        onClick={(e) => {
                          if (props.navOpen) {
                            props.toggleNav()
                          }
                          props.setSelectedGoal(e, goal)
                        }}
                      >
                        <li className="flex flex-row w-full items-center mt-4 mb-4 border-b">
                          <span className="w-full">
                            {goal.labelNaam}
                            <span className="count ml-2 text-sm">
                              ( {goal.actions ? countNoDoneActions(goal) : 0} )
                            </span>
                          </span>
                          <span>
                            <Settings
                              className="ml-2 text-sm relative cursor-pointer -mt-1 relative"
                              onClick={(e) => {
                                props.setOpenSelectedGoalSettings(e, goal)
                              }}
                            />
                          </span>
                        </li>
                      </NavLink>
                    </>
                  )
                })
              : null}
          </ul>
          <AddButton
            title="+ Doel"
            onClick={() => {
              props.popupGoalDetails((prev) => !prev)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default withAuthentication(withFirebase(SidebarGoals))
