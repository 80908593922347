import React, { Component } from 'react'
import PropTypes from 'prop-types'

class FormFieldListItemIngevuldCounter extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div
        className={`absolute px-4 text-base py-1 md:w-18 ${
          this.props.aantalIngevuld === this.props.aantalInTeVullen ? 'wg-dark-green-bg' : 'wa-bg-bordeaux'
        } top-0 right-0 rounded-full text-white mt-2 mr-4`}
      >
        {this.props.aantalIngevuld}/{this.props.aantalInTeVullen}
      </div>
    )
  }
}

FormFieldListItemIngevuldCounter.propTypes = {
  aantalIngevuld: PropTypes.number,
  aantalInTeVullen: PropTypes.number,
}

export default FormFieldListItemIngevuldCounter
