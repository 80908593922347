// Import Packages
import React from 'react'
import arrayMove from 'array-move'

import { withFirebase } from './../../../../components/Firebase'
import { withRouter } from 'react-router-dom'

import checkFirstVisitPage from './../../../../functions/checkFirstVisitPage'

// Import Module Components
import MijnWerkleven5Pagina1en2 from './../../components/MijnWerkleven5Pagina1en2'

// Import Functions
import handleChange from './../../../../functions/handleChange'
import setEditorState from './../../../../functions/setEditorState'
import savingStateToAPI from './../../../../functions/savingStateToAPI'
import savedStateToAPI from './../../../../functions/savedStateToAPI'
import resetSavingState from './../../../../functions/resetSavingState'

// Import Components
import ModuleNavigation from './../../../../components/ModuleNavigation'

// Main Container voor Module 5 van Mijn Werkleven
class MijnWerkleven5 extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      arrayItems: [],
      arrayItemsLeuk: [],
      omschrijving: '',
      werklevenOpties: [],
      loading: true,
      authUser: '',
      saving: false,
      saved: false,
    }

    // Algemene Form State functies
    this.handleChange = handleChange.bind(this)
    this.setEditorState = setEditorState.bind(this)
    this.handleChecked = this.handleChecked.bind(this)

    // Specifieke Pagina 2 Functies
    this.onSortEnd = this.onSortEnd.bind(this)
    this.toggleEditItem = this.toggleEditItem.bind(this)
    this.editTitleItem = this.editTitleItem.bind(this)
    this.addItem = this.addItem.bind(this)
    this.removeItem = this.removeItem.bind(this)

    // Specifieke Pagina 3 Functies
    this.toggleOpen = this.toggleOpen.bind(this)
    this.volgendeItem = this.volgendeItem.bind(this)

    this.voegPlusOfMinpuntToe = this.voegPlusOfMinpuntToe.bind(this)
    this.verwijderPlusOfMinpunt = this.verwijderPlusOfMinpunt.bind(this)

    this.handleSliderChange = this.handleSliderChange.bind(this)

    // Save functies
    this.savingStateToAPI = savingStateToAPI.bind(this)
    this.savedStateToAPI = savedStateToAPI.bind(this)
    this.resetSavingState = resetSavingState.bind(this)
    this.saveCurrentState = this.saveCurrentState.bind(this)
    this.saveCurrentStateAndExit = this.saveCurrentStateAndExit.bind(this)

    // Hulp functies
    this.setOpenStateToFirstItem = this.setOpenStateToFirstItem.bind(this)
    this.changeTitle = this.changeTitle.bind(this)
    this.updateTitleItem = this.updateTitleItem.bind(this)
  }

  changeTitle = (item, type) => {
    if (type === 'mogelijheden') {
      const arrayItems = this.state.arrayItems.map((a, i) => {
        if (a.index === item.index) {
          return { ...a, editing: !a.editing }
        }
        return { ...a, editing: false }
      })

      this.setState({ arrayItems })
    } else {
      const arrayItemsLeuk = this.state.arrayItemsLeuk.map((a, i) => {
        if (a.index === item.index) {
          return { ...a, editing: !a.editing }
        }
        return a
      })
      this.setState({ arrayItemsLeuk })
    }
  }

  loseFocus = (subModule, type) => {
    console.log('subModule, type', subModule, type)
  }

  updateTitleItem = (e, item, type) => {
    if (e.key === 'Enter') {
      const arrayItems = this.state.arrayItems.map((a, i) => {
        if (a.index === item.index) {
          return { ...a, editing: false, title: e.target.value }
        }
        return { ...a, editing: false }
      })
      this.setState({ arrayItems }, () => {
        this.saveCurrentState()
      })
    }
  }

  // Algemene Change Handler
  handleSliderChange(itemIndex, value) {
    console.log(itemIndex)
    console.log(value)
    let arrayItems = this.state.arrayItems
    arrayItems[itemIndex]['cijfer'] = value
    this.setState(
      {
        arrayItems: arrayItems,
      },
      () => {
        console.log(this.state)
      },
    )
  }

  voegPlusOfMinpuntToe(event, name, index) {
    event.preventDefault()
    // console.log(name);
    // console.log(index);
    let nieuweArray = this.state.arrayItems
    // console.log(nieuweArray);
    // console.log(nieuweArray[index][name]);
    nieuweArray[index][name].push(event.target[name].value)
    event.target.reset()
    this.setState(
      {
        arrayItems: nieuweArray,
      },
      () => this.saveCurrentState(),
    )
  }

  verwijderPlusOfMinpunt(name, index, arrayIndex) {
    let nieuweArray = this.state.arrayItems
    nieuweArray[index][name].splice(arrayIndex, 1)
    this.setState(
      {
        arrayItems: nieuweArray,
      },
      () => this.saveCurrentState(),
    )
  }

  // Handler voor de checkboxes op pagina 1
  handleChecked(event, checkboxValue) {
    const checked = event.target.checked
    let newState = this.state.werklevenOpties
    if (checked) {
      newState.push(checkboxValue)
      this.setState({
        werklevenOpties: newState,
      })
    } else {
      const index = newState.indexOf(checkboxValue)
      newState.splice(index, 1)
      this.setState({
        werklevenOpties: newState,
      })
    }
  }

  // Hulp functie om de open state te resetten naar het eerste item
  // Wordt gebruikt na verwijderen en herordenen van items
  setOpenStateToFirstItem = (items) => {
    items.map((item, index) => {
      if (index === 0) {
        item.open = true
      } else {
        item.open = false
      }
      return item
    })
    return items
  }

  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  //** Functies voor Array Items (Minder Leuk) //
  //////////////////////////////////////////////
  /////////////////////////////////////////////

  // Wijzigt de Array volgorde na Drag 'n Drop
  onSortEnd = ({ oldIndex, newIndex }) => {
    let reorderedItems = arrayMove(this.state.arrayItems, oldIndex, newIndex)
    reorderedItems = reorderedItems.map((item, index) => {
      item.index = index
      return item
    })
    reorderedItems = this.setOpenStateToFirstItem(reorderedItems)
    this.setState({
      arrayItems: reorderedItems,
    })
  }

  // Voegt een nieuw item toe aan arrayItems
  addItem(arrayItem) {
    let newValues = this.state.arrayItems
    let openState = false
    if (newValues.length === 0) {
      openState = true
    }
    newValues.push({
      index: newValues.length + 1,
      title: arrayItem.newItemValue,
      beschrijving: '',
      plusPunten: [],
      minPunten: [],
      toelichting: '',
      blijvendStructureel: '',
      open: openState,
      editing: false,
    })
    this.setState({ arrayItems: newValues }, () => this.saveCurrentState())
  }

  // Verwijdert een item uit arrayItems
  removeItem(event, item, listPosition, flowIndex, type) {
    const confirmDelete = window.confirm(`Weet je zeker dat je dit item wilt verwijderen?`)

    if (confirmDelete) {
      let newValues = this.state.arrayItems
      newValues.splice(listPosition, 1)
      this.setOpenStateToFirstItem(newValues)
      this.setState({ arrayItems: newValues }, () => this.saveCurrentState())
    }
  }

  // Toggle de editing state van een arrayItem
  toggleEditItem(itemIndex) {
    let newValues = this.state.arrayItems
    newValues[itemIndex].editing = !newValues[itemIndex].editing
    this.setState({ arrayItems: newValues })
  }

  // Wijzig de title van een arrayItem en reset de editing state
  editTitleItem(event, itemIndex) {
    let newValues = this.state.arrayItems
    newValues[itemIndex].title = event.target.value
    this.setState({ arrayItems: newValues })
  }

  // Open het volgende item op pagina 3
  volgendeItem(itemIndex) {
    let arrayItems = this.state.arrayItems
    arrayItems.forEach((item) => {
      item.open = false
    })

    const element = document.getElementById(`list-item-${itemIndex}`)
    const y = element.getBoundingClientRect().top + window.scrollY - 10

    const nextItem = itemIndex + 1
    if (arrayItems[nextItem]) {
      arrayItems[nextItem].open = true
      this.setState(
        {
          arrayItems: arrayItems,
        },
        () => {
          window.scroll({
            top: y,
            behavior: 'smooth',
          })
        },
      )
    }
  }

  // Toggle Open een specifiek item op pagina 3
  toggleOpen(itemIndex) {
    let arrayItems = this.state.arrayItems
    let openState = arrayItems[itemIndex].open
    arrayItems.forEach((item) => {
      item.open = false
    })
    arrayItems[itemIndex].open = !openState
    this.setState({
      arrayItems: arrayItems,
    })
  }

  ////////////////////////////////////
  ///////////////////////////////////
  //** Algemene Database functies //
  /////////////////////////////////
  ////////////////////////////////

  // Save de huidige state naar de Database
  saveCurrentState() {
    let firebase = this.props.firebase

    if (!this.state.authUser) {
      return
    }

    var docRef = firebase.db
      .collection('users')
      .doc(this.state.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('5')

    this.savingStateToAPI()

    const that = this

    docRef
      .set({
        LeukePunten: this.state.arrayItemsLeuk,
        mijnWerkMogelijkheden: this.state.arrayItems,
        omschrijving: this.state.omschrijving,
        werklevenOpties: this.state.werklevenOpties,
      })
      .then(function () {
        that.savedStateToAPI()
        console.log('Document successfully written!')
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
  }

  // Save de huidige state naar de Database en verlaat de pagina
  saveCurrentStateAndExit() {
    let firebase = this.props.firebase

    var docRef = firebase.db
      .collection('users')
      .doc(this.state.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('5')

    this.savingStateToAPI()
    const savedStateToAPI = this.savedStateToAPI

    // minderLeukePunten -> mijnWerkMogelijkheden

    docRef
      .set({
        // LeukePunten: this.state.arrayItemsLeuk,
        mijnWerkMogelijkheden: this.state.arrayItems,
        omschrijving: this.state.omschrijving,
        werklevenOpties: this.state.werklevenOpties,
      })
      .then(function () {
        console.log('Document successfully written!')
        const exitStatus = true
        savedStateToAPI(exitStatus)
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
  }

  // Haal de data op vanuit Firebase als het component gemount is
  componentDidMount() {
    let firebase = this.props.firebase
    let that = this

    firebase.auth.onAuthStateChanged(function (user) {
      if (user) {
        checkFirstVisitPage(user.uid, firebase, 'MW5', that.props.setFirstVisitStateUitleg)

        var docRef = firebase.db
          .collection('users')
          .doc(user.uid)
          .collection('modulen')
          .doc('mijnWerkleven')
          .collection('subModulen')
          .doc('5')

        // Haal data op uit de databse
        docRef
          .get()
          .then(function (doc) {
            if (doc.exists) {
              //   Make array for minder leuke items
              let initialState = []
              if (doc.data().mijnWerkMogelijkheden !== undefined) {
                doc.data().mijnWerkMogelijkheden.forEach((item, index) => {
                  if (index === 0) {
                    item.open = true
                    initialState.push(item)
                  } else {
                    item.open = false
                    initialState.push(item)
                  }
                })
              }

              that.setState({
                arrayItems: initialState,
                omschrijving: doc.data().omschrijving || '',
                werklevenOpties: doc.data().werklevenOpties || [],
                loading: false,
                authUser: user.uid,
              })
            } else {
              that.setState({
                loading: false,
                authUser: user.uid,
              })
            }
          })
          .catch(function (error) {
            console.log('Error getting document:', error)
          })
      }
    })
  }

  render() {
    // Rendered de juiste pagina en wordt meegegeven aan de bottom-navigatie
    const huidigeOnderdeel = this.props.huidigeOnderdeel

    if (huidigeOnderdeel === '1' || huidigeOnderdeel === '2') {
      return (
        <React.Fragment>
          <MijnWerkleven5Pagina1en2
            toggleEditItem={this.toggleEditItem}
            editTitleItem={this.editTitleItem}
            onSortEnd={this.onSortEnd}
            huidigeOnderdeel={huidigeOnderdeel}
            arrayItems={this.state.arrayItems}
            addItem={this.addItem}
            removeItem={this.removeItem}
            volgendeItem={this.volgendeItem}
            toggleOpen={this.toggleOpen}
            handleChange={this.handleChange}
            loseFocus={this.loseFocus}
            setEditorState={this.setEditorState}
            userID={this.state.authUser}
            voegPlusOfMinpuntToe={this.voegPlusOfMinpuntToe}
            verwijderPlusOfMinpunt={this.verwijderPlusOfMinpunt}
            handleSliderChange={this.handleSliderChange}
            changeTitle={this.changeTitle}
            updateTitleItem={this.updateTitleItem}
          />
          <ModuleNavigation
            currentState={this.state}
            volgendeOnderdeel={this.props.volgendeOnderdeel}
            vorigeOnderdeel={this.props.vorigeOnderdeel}
            huidigeOnderdeel={this.props.huidigeOnderdeel}
            huidigeSectie={this.props.huidigeSectie}
            saveCurrentState={this.saveCurrentState}
            saveCurrentStateAndExit={this.saveCurrentStateAndExit}
            saving={this.state.saving}
            saved={this.state.saved}
            isCompleted={
              !this.state.arrayItems?.some(
                (aIL) =>
                  aIL.beschrijving === '<p><br></p>' ||
                  aIL.beschrijving === '' ||
                  aIL.blijvendStructureel === '<p><br></p>' ||
                  aIL.blijvendStructureel === '' ||
                  aIL.cijfer === '' ||
                  aIL.toelichting === '' ||
                  aIL.toelichting === '<p><br></p>',
              )
            }
            goTo={'/to-do-lijst'}
            goToPrev={'/mijn-werkleven/4/4'}
          />
        </React.Fragment>
      )
    }
  }
}

export default withRouter(withFirebase(MijnWerkleven5))
