import React from 'react'
import { Loader } from 'react-feather'

class ModuleSaved extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div id="saved" className="w-screen h-screen z-40 left-0 top-0 opacity-25 fixed" />
        <div className="flex z-50 justify-center items-center w-screen h-screen top-0 left-0 fixed">
          <div
            className="px-4 py-3 shadow-lg bg-green-500 z-40 fixed left-0 bottom-0 ml-6 mb-6 rounded"
            id="loader_container"
          >
            {this.props.saving ? <Loader className="inline-block text-white" id="loader_loading" size={30} /> : null}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ModuleSaved
