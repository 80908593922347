import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withFirebase } from '../Firebase'
import { AuthUserContext } from '../Session'

import { toast } from 'react-toastify'
import SelectLabel from './../SelectLabel'
import PopUpVoegActieToe from './../PopUpVoegActieToe'
import Lightbulb from './../Lightbulb'
import Tooltip from 'react-tooltip-lite'
import { X, Plus } from 'react-feather'

function TweeOptiesButton(props) {
  return (
    <div className="mt-5">
      <label className="font-bold mb-2" id={props.label}>
        {props.label}
        {props.lightBulb ? (
          <Tooltip content={props.lightBulb} className="inline-block z-20">
            <Lightbulb />
          </Tooltip>
        ) : null}
      </label>
      <div className="flex-row">
        <ButtonNaarActieLijst toggleAddActie={props.toggleAddActie}>{props.labelActieButton}</ButtonNaarActieLijst>
        <div
          onClick={props.closePopup}
          className="w-2/3 py-2 px-5 bg-wa-button-green hover:bg-green-800 font-bold text-white md:text-lg rounded-full shadow-md inline-block mt-5 w-full text-center cursor-pointer"
        >
          {props.labelSluitPopup}
        </div>
      </div>
    </div>
  )
}

function TwijfelButton(props) {
  const nummer = props.flowNummer
  return (
    <div
      onClick={() => props.setFlowNummer(nummer)}
      className={`relative button-width mr-auto ml-auto md:py-2 px-5 hover:bg-green-800 font-bold text-white md:text-lg rounded-full shadow-md inline-block mt-4 md:mt-5 w-full text-center cursor-pointer wa-bg-orange`}
    >
      {props.children}
    </div>
  )
}

function TextArea(props) {
  return (
    <div className="w-full">
      <label className="font-bold mb-2" id={props.label}>
        {props.label}
        {props.lightBulb ? (
          <Tooltip content={props.lightBulb} className="inline-block z-20">
            <Lightbulb />
          </Tooltip>
        ) : null}
      </label>
      <textarea name={props.label} className="border w-full p-4" />
    </div>
  )
}

function ButtonNaarActieLijst(props) {
  return (
    <div
      onClick={props.toggleAddActie}
      className=" py-2 px-5 bg-wa-bordeaux hover:bg-green-800 font-bold text-white md:text-lg rounded-full shadow-md inline-block mt-5 w-full text-center cursor-pointer"
    >
      {props.children}
    </div>
  )
}

class ToDoLijstTwijfel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      twijfelFlowNummer: null,
      twijfelFlowPopup: true,
    }
    this.setFlowNummer = this.setFlowNummer.bind(this)
    this.toggleAddActie = this.toggleAddActie.bind(this)
  }

  setFlowNummer(nummer) {
    this.setState(
      {
        twijfelFlowNummer: nummer,
      },
      () => console.log(this.state),
    )
  }

  toggleAddActie() {
    this.setState(
      {
        twijfelFlowPopup: !this.state.twijfelFlowPopup,
      },
      () => console.log(this.state),
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.state.twijfelFlowPopup ? (
          <div className="md:justify-center md:p-3 relative info-uitleg-popup transition-short">
            {this.state.twijfelFlowNummer === null ? (
              <div className="flex flex-col items-center justify-center md:w-2/3 md:mr-auto md:ml-auto">
                <TwijfelButton setFlowNummer={this.setFlowNummer} flowNummer={1} orange>
                  Ik kan het niet
                </TwijfelButton>
                <TwijfelButton setFlowNummer={this.setFlowNummer} flowNummer={2}>
                  Ik weet niet hoe
                </TwijfelButton>
                <TwijfelButton setFlowNummer={this.setFlowNummer} flowNummer={3}>
                  Ik durf niet
                </TwijfelButton>
                <TwijfelButton setFlowNummer={this.setFlowNummer} flowNummer={4}>
                  Ik ben bang wat anderen vinden
                </TwijfelButton>
                <TwijfelButton setFlowNummer={this.setFlowNummer} flowNummer={5}>
                  Ik heb er geen geld/tijd voor
                </TwijfelButton>
                <TwijfelButton setFlowNummer={this.setFlowNummer} flowNummer={6}>
                  Ik twijfel of ik het wil
                </TwijfelButton>
              </div>
            ) : null}

            {this.state.twijfelFlowNummer === 1 ? (
              <React.Fragment>
                <TextArea label="Wat denk ik dat ik niet (genoeg) kan?" />
                <TextArea label="Hoe weet ik dat?" />
                <TextArea label="Wat kan ik al wel?" />
                <TextArea label="Wat kan ik nog niet?" />
                <TweeOptiesButton
                  label="Kan ik iets doen zodat ik dit wel kan?"
                  lightBulb={
                    <div>
                      Bijvoorbeeld:
                      <ul>
                        <li>- aan iemand vragen</li>
                        <li>- op internet opzoeken</li>
                        <li>- een cursus doen</li>
                        <li>- hardop tegen jezelf zggen dat je het wel kan</li>
                      </ul>
                    </div>
                  }
                  labelSluitPopup="Nee, ik kan het!"
                  labelActieButton="Ja, voeg actie toe"
                  closePopup={this.props.closePopup}
                  toggleAddActie={this.toggleAddActie}
                />
              </React.Fragment>
            ) : null}

            {this.state.twijfelFlowNummer === 2 ? (
              <React.Fragment>
                <TextArea label="Wat weet ik niet precies?" />
                <TextArea label="Hoe weet ik dat?" />
                <TextArea label="Wat weet ik al wel?" />
                <TextArea label="Wat weet ik nog niet?" />
                <TweeOptiesButton
                  label="Kan ik iets doen zodat ik dit wel weet?"
                  lightBulb={
                    <div>
                      Bijvoorbeeld:
                      <ul>
                        <li>- aan iemand vragen</li>
                        <li>- op internet opzoeken</li>
                        <li>- een cursus doen</li>
                        <li>- oefenen</li>
                      </ul>
                    </div>
                  }
                  labelSluitPopup="Nee, ik weet het!"
                  labelActieButton="Ja, voeg actie toe"
                  closePopup={this.props.closePopup}
                  toggleAddActie={this.toggleAddActie}
                />
              </React.Fragment>
            ) : null}

            {this.state.twijfelFlowNummer === 3 ? (
              <React.Fragment>
                <TextArea label="Wat denk ik dat ik niet durf / waar ben ik bang voor?" />
                <TextArea label="Hoe weet ik dat?" />
                <TextArea label="Wat durf ik wel?" />
                <TextArea label="Wat is de worstcasescenario? Wat is het ergste dat er zou kunnen gebeuren?" />
                <TextArea label="Wat gebeurt er als ik het niet doe?" />
                <TextArea label="Wat levert het mij op als ik het wel doe?" />
                <TweeOptiesButton
                  label="Heb ik nog iets nodig om het wel te durven?"
                  labelSluitPopup="Nee, ik durf het!"
                  labelActieButton="Ja, voeg actie toe"
                  closePopup={this.props.closePopup}
                  toggleAddActie={this.toggleAddActie}
                />
              </React.Fragment>
            ) : null}

            {this.state.twijfelFlowNummer === 4 ? (
              <React.Fragment>
                <TextArea label="Met wie houd ik (te veel) rekening?" />
                <TextArea label="Wat denk ik dat zij hiervan zullen vinden?" />
                <TextArea label="Hoe weet ik dat? Heb ik dat van hun zelf persoonijk gehoord of aan hun gevraagd?" />
                <TweeOptiesButton
                  label="Is het voor mij echt belangrijk om te weten?"
                  labelSluitPopup="Nee, het boeit me niet meer"
                  labelActieButton="Ja, voeg actie toe"
                  closePopup={this.props.closePopup}
                  toggleAddActie={this.toggleAddActie}
                />
              </React.Fragment>
            ) : null}

            {this.state.twijfelFlowNummer === 5 ? (
              <React.Fragment>
                <TextArea label="Waar denk ik dat ik geen geld/tijd voor over heb?" />
                <TextArea label="Hoe weet ik dat?" />
                <div className="mt-5">
                  <label className="font-bold mb-2">
                    Heb ik er echt geen geld
                    <Tooltip
                      content={
                        <div>
                          Tip voor tijd:
                          <ul>
                            <li>- maak een schema van de hele week (maandag t/m vrijdag)</li>
                            <li>
                              - vul per dag in hoeveel tijd je besteedt aan een activiteit totdat je aan de 24 uur per
                              dag komt.
                            </li>
                            <li>- kan je tijd vrijmaken voor de actie uit de actielijst?</li>
                            <li>- kan je minder tijd besteden aan een activiteit?</li>
                            <li>- kan je jouw activiteiten beter of efficiënter plannen?</li>
                          </ul>
                        </div>
                      }
                      className="inline-block z-20"
                    >
                      <Lightbulb />
                    </Tooltip>{' '}
                    of tijd
                    <Tooltip
                      content={
                        <div>
                          Tip voor geld:
                          <ul>
                            <li>- maak een schema van alle kosten en uitgaven die je hebt (vaste en variabele)</li>
                            <li>- kan je geld vrijmaken voor de actie uit de actielijst?</li>
                            <li>- kan je kosten besparen?</li>
                            <li>- kan je minder geld besteden aan iets?</li>
                          </ul>
                        </div>
                      }
                      className="inline-block z-20"
                    >
                      <Lightbulb />
                    </Tooltip>{' '}
                    voor (over)? Of DENK ik dat ik er geen geld/tijd voor (over) heb?
                  </label>
                  <textarea className="border w-full p-4" />
                </div>
                <TextArea label="Hoeveel tijd/geld heb ik er wel voor (over)? " />
                <TextArea label="Is dit voldoende of heb ik er meer tijd/geld voor nodig?" />
                <TweeOptiesButton
                  label="Heb ik nog iets nodig om hier meer geld/tijd voor vrij te maken?"
                  labelSluitPopup="Nee, ik heb er genoeg tijd/geld voor (over)"
                  labelActieButton="Ja, voeg actie toe"
                  closePopup={this.props.closePopup}
                  toggleAddActie={this.toggleAddActie}
                />
              </React.Fragment>
            ) : null}

            {this.state.twijfelFlowNummer === 6 ? (
              <React.Fragment>
                <TextArea label="Waar twijfel ik over?" />
                <TextArea label="Waarom twijfel ik daar over? " />
                <TextArea label="Wat wil ik wel?" />
                <TextArea label="Wat wil ik niet?" />
                <TextArea label="Welke oplossing is er zodat ik wel heb wat ik wil en niet heb wat ik niet wil?" />
                <TextArea label="Hoe ga ik deze oplossing uitvoeren/ onderzoeken? " />
                <TweeOptiesButton
                  label="Kan ik iets doen om deze oplossing uit te voeren of te onderzoeken?"
                  labelSluitPopup="Nee, ik twijfel niet meer"
                  labelActieButton="Ja, voeg actie toe"
                  closePopup={this.props.closePopup}
                  toggleAddActie={this.toggleAddActie}
                />
              </React.Fragment>
            ) : null}
          </div>
        ) : (
          <AuthUserContext.Consumer>
            {(authUser) => (
              <PopUpVoegActieToe
                addLabelFromTwijfelFlow={this.props.addLabelFromTwijfelFlow}
                voegNieuweTwijfelToeVanuitTwijfelFlow={this.props.voegNieuweTwijfelToeVanuitTwijfelFlow}
                closeTwijfelFlow={this.props.closePopup}
                userID={authUser.uid}
                firebase={this.props.firebase}
                closeModule={this.toggleAddActie}
              />
            )}
          </AuthUserContext.Consumer>
        )}
      </React.Fragment>
    )
  }
}

export default withFirebase(ToDoLijstTwijfel)
