import React, { useState, useEffect, useCallback } from 'react'

import TrashButton from '../Atoms/Trash-Button'
import SettingsButton from '../Atoms/Settings-Button'
import BearsButton from '../Atoms/Bears-Button'

import { withAuthentication } from '../Session'
import { withFirebase } from '../Firebase'
import ToDoLijstTwijfel from '../../components/ToDoLijstTwijfel'

import { addGoalToDB } from '../../functions/goalsHelper'
import PopUpActionsEdit from '../../components/Template/PopUp-Actions-Edit'

import { getAllGoals } from '../../functions/goalsHelper'
import Modal from '../Template/Modal'

const ActionTools = (props) => {
  const [goals, setGoals] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [toggleDoubts, setToggleDoubts] = useState(false)
  const [popupGoalActionSettings, setTogglePopupGoalActionSettings] = useState(false)

  const addNewGoalFromAction = (newGoal) => {
    const newGoalName = newGoal
    //switch or add
    addGoalToDB(props.firebase, props.authUser, newGoalName, props.goal.type, props.goal.deadline).then(() => {
      console.log('Goal added')
    })
  }

  const toggleActionPopUp = () => {
    props.setLoading(true)
    setTogglePopupGoalActionSettings((prev) => !prev)
  }

  useEffect(() => {
    if (isLoading) {
      getAllGoals(props.firebase, props.authUser).then((result) => {
        setGoals(result)
        setIsLoading(false)
      })
    }
  }, [goals])

  return (
    <>
      {popupGoalActionSettings && (
        <Modal onClose={toggleActionPopUp}>
          <PopUpActionsEdit
            togglePopUp={toggleActionPopUp}
            action={props.action}
            goal={props.goal}
            goals={props.goals}
            firebase={props.firebase}
            authUser={props.authUser}
            setLoading={setIsLoading}
            doubtFlow={props.doubtFlow}
            setToggleDoubts={setToggleDoubts}
            isOpen={popupGoalActionSettings}
            updatedAction={props.updatedAction}
            setSelectedGoal={props.setSelectedGoal}
            selectedAction={props.selectedAction}
          />
        </Modal>
      )}
      {toggleDoubts && (
        <Modal
          onClose={() => {
            setToggleDoubts(false)
          }}
        >
          <ToDoLijstTwijfel
            voegNieuweTwijfelToeVanuitTwijfelFlow={() => {}}
            authUserID={{}}
            closePopup={() => {
              setToggleDoubts(false)
            }}
          />
        </Modal>
      )}
      <BearsButton toggleDoubts={setToggleDoubts} />
      <SettingsButton toggleGoalSettings={setTogglePopupGoalActionSettings} />
      <TrashButton deleteAction={props.deleteAction} />
    </>
  )
}

export default withAuthentication(withFirebase(ActionTools))
