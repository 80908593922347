import React from 'react'
import { withRouter } from 'react-router-dom'
import { withFirebase } from './../../../../components/Firebase'

import ModuleNavigation from './../../../../components/ModuleNavigation'

import ModuleSaved from './../../../../components/ModuleSaved'

// Import Module Components
import MijnWerkleven2Pagina1 from '../../components/2-CurrentWorkLife2A'
import DesiredWorkLife2B from '../../components/2-DesiredWorkLife2B'
import MijnWerkleven2Pagina3 from './../../components/MijnWerkleven2Pagina3'

// Import Functions
import setEditorState from './../../../../functions/setEditorState'
import savingStateToAPI from './../../../../functions/savingStateToAPI'
import savedStateToAPI from './../../../../functions/savedStateToAPI'
import resetSavingState from './../../../../functions/resetSavingState'
import checkFirstVisitPage from './../../../../functions/checkFirstVisitPage'

class MijnWerkleven2 extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      HWCijfer: '0',
      HWToelichting: '',
      HWSmiley: 0,
      HWGevoel: '',
      GWCijfer: '0',
      GWToelichting: '',
      GWSmiley: 0,
      GWGevoel: '',
      loading: true,
      authUser: '',
      saving: false,
      saved: false,
    }

    this.setEditorState = this.setEditorState.bind(this)
    this.handleSliderChange = this.handleSliderChange.bind(this)
    this.handleChange = this.handleChange.bind(this)

    // Save functies
    this.savingStateToAPI = savingStateToAPI.bind(this)
    this.savedStateToAPI = savedStateToAPI.bind(this)
    this.resetSavingState = resetSavingState.bind(this)
    this.saveCurrentState = this.saveCurrentState.bind(this)
    this.saveCurrentStateAndExit = this.saveCurrentStateAndExit.bind(this)
  }

  handleSliderChange = (value, key) => {
    console.log(value, key)
    this.setState(
      {
        [key]: value,
      },
      () => {
        console.log(this.state)
      },
    )
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  setEditorState(stateValue, fieldName, itemIndex) {
    // Algemene State Handler voor de Editor
    if (itemIndex !== undefined) {
      let arrayItems = this.state.arrayItems
      arrayItems[itemIndex][fieldName] = stateValue
      this.setState({
        arrayItems: arrayItems,
      })
    } else {
      this.setState({
        [fieldName]: stateValue,
      })
    }
  }

  // Save de huidige state naar de Database en verlaat de pagina
  saveCurrentState() {
    let firebase = this.props.firebase
    const that = this

    /*
    var docRef = firebase.db
      .collection('users')
      .doc(this.state.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('2')
        .collection('cijfers')
        .doc('cijfers')


    this.savingStateToAPI()
    const savedStateToAPI = this.savedStateToAPI


    docRef
      .set({
        huidigCijfers: newHuidigCijfersArray,
        gewenstCijfers: newGewenstCijfersArray,
      })
      .then(function () {
        console.log('Mijn Werkleven 2 - Pagina 1 & 2 opgeslagen')
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
    */
    //  Nu database entry saven voor alle cijfers

    var docRefCijfers = firebase.db
      .collection('users')
      .doc(this.state.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('2')
      .collection('cijfers')
      .doc('cijfers')

    /*let huidigDatum
    let gewenstDatum

    if (
      this.state.alleCijfers !== undefined &&
      this.state.alleCijfers.huidigCijfers !== undefined &&
      this.state.alleCijfers.huidigCijfers[0] !== undefined &&
      this.state.alleCijfers.huidigCijfers[0].datum !== undefined &&
      this.state.alleCijfers.gewenstCijfers !== undefined &&
      this.state.alleCijfers.gewenstCijfers[0] !== undefined &&
      this.state.alleCijfers.gewenstCijfers[0].datum !== undefined
    ) {
      console.log('if state eerste')
      huidigDatum = this.state.alleCijfers.huidigCijfers[0].datum
      gewenstDatum = this.state.alleCijfers.gewenstCijfers[0].datum
    } else {
      console.log('else state eerste')
      huidigDatum = new Date()
      gewenstDatum = new Date()
    }*/

    const huidigCijfer = {
      cijfer: this.state.HWCijfer,
      smiley: this.state.HWSmiley,
      gevoel: this.state.HWGevoel,
      hogerLager: this.state.HWToelichting,
      externOfIntern: '',
      datum: new Date(),
    }

    const gewenstCijfer = {
      cijfer: this.state.GWCijfer,
      toelichting: this.state.GWToelichting,
      smiley: this.state.GWSmiley,
      gevoel: this.state.GWGevoel,
      datum: new Date(),
    }

    let newHuidigCijfersArray
    let newGewenstCijfersArray

    if (this.state.alleCijfers !== undefined) {
      newHuidigCijfersArray = this.state.alleCijfers.huidigCijfers
      newHuidigCijfersArray.push(huidigCijfer)

      newGewenstCijfersArray = this.state.alleCijfers.gewenstCijfers
      newGewenstCijfersArray.push(gewenstCijfer)
    } else {
      newHuidigCijfersArray = [huidigCijfer]
      newGewenstCijfersArray = [gewenstCijfer]
    }

    // Update de Database voor pagina drie, waar alle cijfers staan
    docRefCijfers
      .get()
      .then(function (doc) {
        if (doc.exists) {
          docRefCijfers
            .set({
              huidigCijfers: newHuidigCijfersArray,
              gewenstCijfers: newGewenstCijfersArray,
            })
            .then(function () {
              console.log('Mijn Werkleven 2 - Pagina 3 opgeslagen')
              savedStateToAPI()
            })
            .catch(function (error) {
              console.error('Error writing document: ', error)
            })
        } else {
          docRefCijfers
            .set({
              huidigCijfers: [
                {
                  cijfer: that.state.HWCijfer,
                  smiley: that.state.HWSmiley,
                  gevoel: that.state.HWGevoel,
                  hogerLager: that.state.HWToelichting,
                  externOfIntern: '',
                  datum: new Date(),
                },
              ],
              gewenstCijfers: [
                {
                  cijfer: that.state.GWCijfer,
                  toelichting: that.state.GWToelichting,
                  smiley: that.state.GWSmiley,
                  gevoel: that.state.GWGevoel,
                  datum: new Date(),
                },
              ],
            })
            .then(function () {
              console.log('Document successfully written!')
              savedStateToAPI()
            })
            .catch(function (error) {
              console.error('Error writing document: ', error)
            })
          console.log('No such document!')
        }
      })
      .catch(function (error) {
        console.log('Error getting document:', error)
      })
  }

  // Save de huidige state naar de Database en verlaat de pagina
  saveCurrentStateAndExit() {
    let firebase = this.props.firebase
    const that = this

    console.log('save current exit')

    /*
    var docRef = firebase.db
      .collection('users')
      .doc(this.state.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('2')

    this.savingStateToAPI()
    const savedStateToAPI = this.savedStateToAPI
    const that = this

    docRef
      .set({
        HWCijfer: this.state.HWCijfer,
        HWToelichting: this.state.HWToelichting,
        HWSmiley: this.state.HWSmiley,
        HWGevoel: this.state.HWGevoel,
        GWCijfer: this.state.GWCijfer,
        GWToelichting: this.state.GWToelichting,
        GWSmiley: this.state.GWSmiley,
        GWGevoel: this.state.GWGevoel,
      })
      .then(function () {
        console.log('Document successfully written!')
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
    */

    var docRefCijfers = firebase.db
      .collection('users')
      .doc(this.state.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('2')
      .collection('cijfers')
      .doc('cijfers')

    let huidigDatum
    let gewenstDatum
    if (this.state.alleCijfers !== undefined) {
      huidigDatum = this.state.alleCijfers.huidigCijfers[0].datum
      gewenstDatum = this.state.alleCijfers.gewenstCijfers[0].datum
    } else {
      huidigDatum = new Date()
      gewenstDatum = new Date()
    }

    const huidigCijfer = {
      cijfer: this.state.HWCijfer,
      smiley: this.state.HWSmiley,
      gevoel: this.state.HWGevoel,
      hogerLager: this.state.HWToelichting,
      externOfIntern: '',
      datum: huidigDatum,
    }
    const gewenstCijfer = {
      cijfer: this.state.GWCijfer,
      toelichting: this.state.GWToelichting,
      smiley: this.state.GWSmiley,
      gevoel: this.state.GWGevoel,
      datum: gewenstDatum,
    }

    let newHuidigCijfersArray
    let newGewenstCijfersArray

    if (this.state.alleCijfers !== undefined) {
      newHuidigCijfersArray = [...this.state.alleCijfers.huidigCijfers, huidigCijfer]
      //newHuidigCijfersArray[0] = huidigCijfer

      newGewenstCijfersArray = [...this.state.alleCijfers.gewenstCijfers, gewenstCijfer]
      //newGewenstCijfersArray[0] = gewenstCijfer
    } else {
      newHuidigCijfersArray = [huidigCijfer]
      newGewenstCijfersArray = [gewenstCijfer]
    }

    //console.log('newHuidigCijfersArray, newGewenstCijfersArray', newHuidigCijfersArray, newGewenstCijfersArray)

    // Update de Database voor pagina drie, waar alle cijfers staan
    docRefCijfers
      .get()
      .then(function (doc) {
        if (doc.exists) {
          docRefCijfers
            .set({
              huidigCijfers: newHuidigCijfersArray,
              gewenstCijfers: newGewenstCijfersArray,
            })
            .then(function () {
              //console.log('Document successfully written!')
              const exitStatus = true
              savedStateToAPI(exitStatus)
            })
            .catch(function (error) {
              console.error('Error writing document: ', error)
            })
        } else {
          docRefCijfers
            .set({
              huidigCijfers: [
                {
                  cijfer: that.state.HWCijfer,
                  smiley: that.state.HWSmiley,
                  gevoel: that.state.HWGevoel,
                  hogerLager: that.state.HWToelichting,
                  externOfIntern: '',
                  datum: new Date(),
                },
              ],
              gewenstCijfers: [
                {
                  cijfer: that.state.GWCijfer,
                  toelichting: that.state.GWToelichting,
                  smiley: that.state.GWSmiley,
                  gevoel: that.state.GWGevoel,
                  datum: new Date(),
                },
              ],
            })
            .then(function () {
              //console.log('Document successfully written!')
              const exitStatus = true
              savedStateToAPI(exitStatus)
            })
            .catch(function (error) {
              console.error('Error writing document: ', error)
            })
          console.log('No such document!')
        }
      })
      .catch(function (error) {
        console.log('Error getting document:', error)
      })
  }

  componentDidMount() {
    let firebase = this.props.firebase
    let that = this

    function getAllCijfers(user) {
      // Set Ref for database
      var docRef = firebase.db
        .collection('users')
        .doc(user.uid)
        .collection('modulen')
        .doc('mijnWerkleven')
        .collection('subModulen')
        .doc('2')
        .collection('cijfers')
        .doc('cijfers')

      // Haal data op uit de databae <3
      docRef
        .get()
        .then(function (doc) {
          if (doc.exists) {
            //console.log(doc.data())
            that.setState(
              {
                alleCijfers: doc.data(),
              },
              () => {
                console.log(that.state)
              },
            )
          } else {
            console.log('DOENSt EXICST')
          }
        })
        .catch(function (error) {
          console.log('Error getting document:', error)
        })
    }

    firebase.auth.onAuthStateChanged(function (user) {
      if (user) {
        getAllCijfers(user)

        // Function to set first visit state
        checkFirstVisitPage(user.uid, firebase, 'MW2_1', that.props.setFirstVisitStateUitleg)

        // Set Ref for database
        var docRef = firebase.db
          .collection('users')
          .doc(user.uid)
          .collection('modulen')
          .doc('mijnWerkleven')
          .collection('subModulen')
          .doc('2')
          .collection('cijfers')
          .doc('cijfers')

        // Haal data op uit de databse
        docRef
          .get()
          .then(function (doc) {
            if (doc.exists) {
              const { huidigCijfers, gewenstCijfers } = doc.data()

              that.setState(
                {
                  HWCijfer: doc.data().huidigCijfers[huidigCijfers.length - 1].cijfer || null,
                  HWToelichting: doc.data().huidigCijfers[huidigCijfers.length - 1].toelichting || null,
                  HWSmiley: doc.data().huidigCijfers[huidigCijfers.length - 1].smiley || null,
                  HWGevoel: doc.data().huidigCijfers[huidigCijfers.length - 1].gevoel || null,

                  GWCijfer: doc.data().gewenstCijfers[gewenstCijfers.length - 1].cijfer || null,
                  GWToelichting: doc.data().gewenstCijfers[gewenstCijfers.length - 1].toelichting || null,
                  GWSmiley: doc.data().gewenstCijfers[gewenstCijfers.length - 1].smiley || null,
                  GWGevoel: doc.data().gewenstCijfers[gewenstCijfers.length - 1].gevoel || null,

                  loading: false,
                  authUser: user.uid,
                },
                () => console.log(that.state),
              )
            } else {
              that.setState({
                loading: false,
                authUser: user.uid,
              })
            }
          })
          .catch(function (error) {
            console.log('Error getting document:', error)
          })
      }
    })
  }

  render() {
    // Rendered de juiste pagina en wordt meegegeven aan de bottom-navigatie
    const huidigeOnderdeel = this.props.huidigeOnderdeel

    if (huidigeOnderdeel === '1') {
      return (
        <React.Fragment>
          <MijnWerkleven2Pagina1
            loading={this.state.loading}
            handleChange={this.handleChange}
            setEditorState={this.setEditorState}
            initialEditorStateHWToelichting={this.state.HWToelichting}
            initialEditorStateHWGevoel={this.state.HWGevoel}
            HWSliderValue={this.state.HWCijfer}
            loading={this.state.loading}
            initialWerklevenOpties={this.state.werklevenOpties}
            handleChecked={this.handleChecked}
            handleSliderChange={this.handleSliderChange}
            HWSmiley={this.state.HWSmiley}
          />
          <ModuleNavigation
            currentState={this.state}
            volgendeOnderdeel={this.props.volgendeOnderdeel}
            vorigeOnderdeel={this.props.vorigeOnderdeel}
            huidigeOnderdeel={this.props.huidigeOnderdeel}
            huidigeSectie={this.props.huidigeSectie}
            saveCurrentState={this.saveCurrentState}
            saveCurrentStateAndExit={this.saveCurrentStateAndExit}
            saving={this.state.saving}
            saved={this.state.saved}
            isCompleted={
              this.state.HWCijfer !== '0' &&
              this.state.HWToelichting !== '' &&
              this.state.HWToelichting !== '<p><br></p>' &&
              this.state.HWSmiley !== 0
            }
            goToPrev={'/mijn-werkleven/1/1'}
          />
        </React.Fragment>
      )
    } else if (huidigeOnderdeel === '2') {
      return (
        <React.Fragment>
          <DesiredWorkLife2B
            loading={this.state.loading}
            handleChange={this.handleChange}
            initialEditorStateGWToelichting={this.state.GWToelichting}
            initialEditorStateGWGevoel={this.state.GWGevoel}
            GWSliderValue={this.state.GWCijfer}
            handleSliderChange={this.handleSliderChange}
            setEditorState={this.setEditorState}
            initialEditorState={this.state.omschrijving}
            loading={this.state.loading}
            initialWerklevenOpties={this.state.werklevenOpties}
            handleChecked={this.handleChecked}
            GWSmiley={this.state.GWSmiley}
          />
          <ModuleNavigation
            currentState={this.state}
            volgendeOnderdeel={this.props.volgendeOnderdeel}
            vorigeOnderdeel={this.props.vorigeOnderdeel}
            huidigeOnderdeel={this.props.huidigeOnderdeel}
            huidigeSectie={this.props.huidigeSectie}
            saveCurrentState={this.saveCurrentState}
            saveCurrentStateAndExit={this.saveCurrentStateAndExit}
            saving={this.state.saving}
            saved={this.state.saved}
            isCompleted={
              this.state.GWCijfer !== '0' &&
              this.state.GWToelichting !== '' &&
              this.state.GWToelichting !== '<p><br></p>' &&
              this.state.GWSmiley !== 0 &&
              this.state.GWGevoel !== '<p><br></p>' &&
              this.state.GWGevoel !== ''
            }
            goToPrev={'/mijn-werkleven/1/1'}
            goTo={'/mijn-werkleven/3/1'}
          />
        </React.Fragment>
      )
    }
  }
}

export default withRouter(withFirebase(MijnWerkleven2))
