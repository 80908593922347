import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { withAuthentication } from './../../components/Session'
import ContainerPage from './../../components/ContainerPage'
import PopupStepper from './../../components/PopupStepper'
import MainHeader from './../../components/MainHeader'
import HorizontalLineDivider from './../../components/HorizontalLineDivider'
import DashboardHeading from './../../components/DashboardHeading'
import DashboardSectionActionlist from './Dashboard-Section-Actionlist'
import DashboardSectionTimeline from './Dashboard-Section-Timeline'
import DashboardSectionNeedHelp from './DashboardSectionNeedHelp'
import DashboardSectionCurrentWorkLife from './Dashboard-Section-CurrentWorkLife'
import DashboardSectionWantedWorkLife from './Dashboard-Section-WantedWorkLife'
import DashboardSectionRating from './Dashboard-Section-Rating'
import DashboardSectionPossibilities from './Dashboard-Section-Possibilities'
import DashboardSectionGoals from './Dashboard-Section-Goals'
import { getAllGoals } from '../../functions/goalsHelper'
import { addThought, createNewAction, getAllActions } from '../../functions/actionsHelper'
import { flowObjects } from '../../utils/flowObjects'
import { getNotificationSettings, setNotificationSettings } from '../../functions/accountHelper'

import arrayMove from 'array-move'
import { isInitVisit } from '../../functions/accountHelper'
import { compose } from 'recompose'
import { useHistory } from 'react-router-dom'
import { withFirebase } from '../../components/Firebase'

const checkBoxArray = [
  'Werkplezier verhogen',
  'Skills verbeteren',
  'Keuze(s) maken',
  'Carrièreplan uitstippelen',
  'Doelen bereiken',
  'De wereld veroveren',
  'anders, namelijk:',
]

const Dashboard = (props) => {
  const [userUploadsProfilePicture, setUserUploadsProfilePicture] = useState('')
  const [user, setUser] = useState(null)
  const [displayExplanation, setDisplayExplanation] = useState(false)
  const [reload, setReloading] = useState(false)
  const [update, setUpdate] = useState(false)

  const [goals, setGoals] = useState([])
  const [goalPrivate, setGoalPrivate] = useState([])
  const [goalWork, setGoalWork] = useState([])
  const [actions, setActions] = useState([])
  const [flow, setFlow] = useState([])
  const [ratings, setRatings] = useState({})
  const [flowCopy, setFlowCopy] = useState([])
  const [currentSubModulen, setCurrentSubModulen] = useState(null)
  const history = useHistory()

  const [data, setData] = useState({
    doel: null,
    cijfers: null,
    huidigWerkleven: null,
    gewenstWerkleven: null,
    mogelijkheden: null,
  })
  const [originalData, setOriginalData] = useState(null)

  const changeTitle = (item, type, subModulen) => {
    let updatedData = data[subModulen][type].map((i) => {
      if (i.index === item.index) {
        return { ...i, editing: true }
      }
      return i
    })

    setData((prev) => ({
      ...prev,
      [subModulen]: {
        ...data[subModulen],
        [type]: updatedData,
      },
    }))
  }

  const updateTitleItem = (e, item, type, subModulen) => {
    if (e.key === 'Enter') {
      setCurrentSubModulen(subModulen)
      const title = e.target.value

      const updatedSubModulenType = originalData[subModulen][type].map((v) => {
        if (item.index === v.index) {
          return { ...v, title }
        }
        return v
      })

      syncDisplayData(updatedSubModulenType, subModulen, type)
    }
  }

  const loseFocus = (subModulen, type) => {
    let currentData = { ...data[subModulen] }
    const sub = currentData[type]
    const updateSub = sub.map((s) => ({ ...s, editing: false }))

    setData((prev) => ({
      ...prev,
      [subModulen]: { ...currentData, [type]: updateSub },
    }))
  }

  const editTitleItem = (e, pos, item, type) => {}

  /**
   *
   * @param {string} uid user id
   * @param {number} index index of the module ranging from 1-5
   *
   */
  const collect = async (uid, index) => {
    return new Promise(async (resolve, reject) => {
      try {
        let docRef = props.firebase.db
          .collection('users')
          .doc(uid)
          .collection('modulen')
          .doc('mijnWerkleven')
          .collection('subModulen')
          .doc(index.toString())

        const doc = await docRef.get()
        if (doc.exists) {
          resolve(doc.data())
        } else {
          resolve([])
        }
      } catch (e) {
        reject(true)
        console.error('cannot retrieve document', e.message)
      }
    })
  }

  /**
   *
   * @param {string} uid user id
   * @param {number} index index of the module ranging from 1-5
   *
   */
  const init = async (uid) => {
    let docRef = props.firebase.db.collection('users').doc(uid).collection('modulen').doc('firstVisit')

    docRef.get().then((doc) => {
      if (doc.exists) {
        if (doc.data()['dashboard'] === undefined) {
          // turn on notifications
          setDisplayExplanation(true)
          docRef
            .set({ dashboard: false })
            .then(function () {
              console.log('Document successfully written!')
            })
            .catch(function (error) {
              console.error('Error writing document: ', error)
            })
        } else {
          setDisplayExplanation(false)
        }
      } else {
        setNotificationSettings(props.firebase, uid, true )

        setDisplayExplanation(true)
      }
    })
  }

  const sortListItems = (oIndex, nIndex, subModulen, type) => {
    const isShowMore = data[subModulen][type].length > 5

    // update order index (field in db)
    let reorderedItems = arrayMove(originalData[subModulen][type], oIndex, nIndex)
    // update order index
    reorderedItems = reorderedItems.map((item, index) => ({
      ...item,
      index,
    }))

    let updatedReorderedItems = reorderedItems

    if (!isShowMore) {
      updatedReorderedItems = reorderedItems.slice(0, 5)
    }

    // update originalData
    setOriginalData((prev) => ({
      ...prev,
      [subModulen]: {
        ...originalData[subModulen],
        [type]: reorderedItems,
      },
    }))

    const updatedSubModulen = {
      ...originalData[subModulen],
      [type]: updatedReorderedItems,
    }

    setData((prev) => ({
      ...prev,
      [subModulen]: updatedSubModulen,
    }))
  }

  const deleteItem = (e, item, index, fIndex, type, subModulen) => {
    e.preventDefault()
    const confirmDelete = window.confirm(`Wil je '${item.title}' verwijderen?`)

    if (confirmDelete) {
      setCurrentSubModulen(subModulen)
      const updatedSubModulenType = originalData[subModulen][type].filter((m) => m.index !== item.index)
      syncDisplayData(updatedSubModulenType, subModulen, type)
    }
  }

  const showItems = (subModulen, type) => {
    const isShowMore = data[subModulen][type].length === 5
    let currentData = { ...data[subModulen] }
    let updatedType = originalData[subModulen][type]
    if (isShowMore) {
      setData((prev) => ({
        ...prev,
        [subModulen]: { ...currentData, [type]: updatedType },
      }))
    } else {
      setData((prev) => ({
        ...prev,
        [subModulen]: { ...currentData, [type]: updatedType.slice(0, 5) },
      }))
    }
  }

  /**
   *
   * @param {object} updatedSubModulenType updated originalData
   * @param {string} subModulen
   * @param {string} type
   */
  const syncDisplayData = (updatedSubModulenType, subModulen, type) => {
    const isShowMore = data[subModulen][type].length > 5

    setOriginalData((prev) => ({
      ...prev,
      [subModulen]: {
        ...originalData[subModulen],
        [type]: updatedSubModulenType,
      },
    }))

    if (isShowMore) {
      setData((prev) => ({
        ...prev,
        [subModulen]: {
          ...data[subModulen],
          [type]: updatedSubModulenType,
        },
      }))
    } else {
      const limitedUpdatedSubModulenType = updatedSubModulenType.slice(0, 5)

      setData((prev) => ({
        ...prev,
        [subModulen]: {
          ...data[subModulen],
          [type]: limitedUpdatedSubModulenType,
        },
      }))
    }
  }

  const updateItem = async () => {
    let subModulen
    let document
    switch (currentSubModulen) {
      case 'huidigWerkleven':
        subModulen = '3'
        document = originalData.huidigWerkleven
        break

      case 'gewenstWerkleven':
        subModulen = '4'
        document = originalData.gewenstWerkleven
        break

      case 'mogelijkheden':
        subModulen = '5'
        document = originalData.mogelijkheden
        break

      default:
        subModulen = '3'
        document = originalData.huidigWerkleven
        break
    }

    try {
      await props.firebase.db
        .collection('users')
        .doc(props.authUser.uid)
        .collection('modulen')
        .doc('mijnWerkleven')
        .collection('subModulen')
        .doc(subModulen)
        .update({ ...document })
      setCurrentSubModulen(null)
    } catch (e) {
      console.error('something horrible happened', e.message)
    }
  }

  useEffect(() => {
    if (originalData && currentSubModulen) {
      updateItem()
    }
  }, [data])

  useEffect(() => {
    if (!props.authUser) return null

    const user = props.authUser?.providerData[0]
    setUser(user)

    let docRef = props.firebase.db
      .collection('users')
      .doc(props.authUser.uid)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('1')

    docRef.get().then(function (doc) {
      if (doc.exists) {
        console.log('all cool')
      } else {
        console.log('no flow found')
        setFlow([...flowObjects])

        let index = 0
        while (index < 6) {
          try {
            props.firebase.db
              .collection('users')
              .doc(props.authUser.uid)
              .collection('modulen')
              .doc('mijnWerkleven')
              .collection('subModulen')
              .doc((index + 1).toString())
              .set({ ...flow[index] })
          } catch (e) {
            console.error('something horrible happened', e.message)
          }
          index++
        }
      }
    })
  }, [])

  const hasReadExplanation = () => {
    const docRef = props.firebase.db.collection('users').doc(props.authUser.uid).collection('modulen').doc('firstVisit')
    docRef
      .set({
        dashboard: false,
      })
      .then(() => {
        console.log('HAS SET TARGET')
        setDisplayExplanation(false)
      })
      .catch((e) => {
        console.log('failed update first visit', e)
      })
  }

  const initData = async (uid) => {
    // Doel
    const doel = await collect(uid, 1)
    // Cijfers Huidig & Gewenst Werkleven
    const cijfers = await collect(uid, 2)
    // Huidig werkleven
    const huidigWerkleven = await collect(uid, 3)
    // Gewenst werkleven
    const gewenstWerkleven = await collect(uid, 4)
    // Mogelijkheden
    const mogelijkheden = await collect(uid, 5)

    const visit = await init(uid)

    setOriginalData({
      doel,
      cijfers,
      huidigWerkleven,
      gewenstWerkleven,
      mogelijkheden,
    })

    let displayHuidigWerkleven
    let displayMogelijkheden
    let displayGewenstWerkleven

    /**
     * handle display, cut to 5 items if more than 5
     */
    // (Huidig werkleven) #3
    if (!_.isEmpty(huidigWerkleven)) {
      let LeukePunten = huidigWerkleven.LeukePunten
      let minderLeukePunten = huidigWerkleven.minderLeukePunten

      if (LeukePunten.length > 5) {
        LeukePunten = LeukePunten.slice(0, 5)
      }
      if (minderLeukePunten.length > 5) {
        minderLeukePunten = minderLeukePunten.slice(0, 5)
      }

      displayHuidigWerkleven = {
        ...huidigWerkleven,
        LeukePunten,
        minderLeukePunten,
      }
    }

    // (Gewenst werkleven) #4
    if (!_.isEmpty(gewenstWerkleven)) {
      let belangrijkePunten = gewenstWerkleven.belangrijkePunten
      let watWilIkPunten = gewenstWerkleven.watWilIkPunten

      if (belangrijkePunten.length > 5) {
        belangrijkePunten = belangrijkePunten.slice(0, 5)
      }

      if (watWilIkPunten.length > 5) {
        watWilIkPunten = watWilIkPunten.slice(0, 5)
      }

      displayGewenstWerkleven = {
        ...gewenstWerkleven,
        watWilIkPunten,
        belangrijkePunten,
      }
    }

    // (Mogelijkheden) #5
    if (!_.isEmpty(mogelijkheden)) {
      let mijnWerkMogelijkheden = mogelijkheden.mijnWerkMogelijkheden

      if (mijnWerkMogelijkheden.length > 5) {
        mijnWerkMogelijkheden = mijnWerkMogelijkheden.slice(0, 5)
      }

      displayMogelijkheden = {
        ...mogelijkheden,
        mijnWerkMogelijkheden,
      }
    }

    setData({
      doel,
      cijfers,
      huidigWerkleven: displayHuidigWerkleven,
      gewenstWerkleven: displayGewenstWerkleven,
      mogelijkheden: displayMogelijkheden,
    })
  }

  useEffect(() => {
    if (!props.authUser) return null

    const user = props.authUser?.providerData[0]
    setUser(user)

    initData(props.authUser.uid)

    try {
      let ratings = props.firebase.db
        .collection('users')
        .doc(props.authUser.uid)
        .collection('modulen')
        .doc('mijnWerkleven')
        .collection('subModulen')
        .doc('2')
        .collection('cijfers')
        .doc('cijfers')

      ratings.get().then((doc) => {
        if (doc.exists) {
          console.log('exist', { ...doc.data() })
          setRatings({ ...doc.data() })
        }
      })
    } catch (e) {
      console.error('something horrible happened', e.message)
    }

    getAllActions(props.firebase, props.authUser).then((result) => {
      setActions([...result])
    })

    getAllGoals(props.firebase, props.authUser).then((goals) => {
      // We only display goals that are selected in the first step not the time line
      if (goals?.length) {
        setGoals([...goals])
      }
    })
    setReloading(false)
  }, [reload])

  const nextStep = () => {
    history.push(`/mijn-werkleven/1/1`)
  }

  return (
    <ContainerPage>
      <MainHeader title={user?.displayName ? `Hi ${user?.displayName.trim()},` : `Hi,`} />

      {/* Toon de 'Uitleg Dashboard' stepper bij het eerste bezoek of het togglen van de uitlegDashboardPopUpOpen State */}
      {displayExplanation && (
        <PopupStepper
          userUploadsProfilePicture={userUploadsProfilePicture}
          username={user?.displayName}
          toggleDashboardPopUp={hasReadExplanation}
          nextPage={nextStep}
        />
      )}

      {/* Toon de 'Uitleg WorkAppic' popup bij het afronden van de Werkleven Module of bij het togglen van uitlegAnalysePopUpOpen State
        {firstVisitLoaded && <DashboardUitlegModuleAnalyse toggleAnalysePopUp={setDisplayExplanation} />}
       */}

      {/* Main Dashboard Container */}
      <div className="flex">
        <div className="mx-auto flex flex-col container pt-6 pb-6 z-10 -mt-6 bg-white">
          {/* Heading met tekst en uitleg-popup buttons */}
          <DashboardHeading toggleDashboardPopUp={setDisplayExplanation} toggleAnalysePopUp={setDisplayExplanation} />

          <HorizontalLineDivider color="wa-border-bordeaux" />

          {/* Sectie 1 - Doel */}
          <DashboardSectionGoals
            workLife={data.doel}
            goals={goals.filter((g) => checkBoxArray.includes(g.labelNaam) || g.isDifferent)}
            goalPrivate={goalPrivate}
            goalWork={goalWork}
          />

          {/* Sectie 2 - Cijfers */}
          <DashboardSectionRating ratingsWorkLife={flow[1]} ratings={ratings} />

          {/* Sectie 3 - Huidig Werkleven */}
          <DashboardSectionCurrentWorkLife
            showAll={showItems}
            total={{
              minderLeukePunten: originalData?.huidigWerkleven?.minderLeukePunten?.length,
              LeukePunten: originalData?.huidigWerkleven?.LeukePunten?.length,
            }}
            currentWorkLife={data.huidigWerkleven}
            sortListItems={sortListItems}
            changeTitle={changeTitle}
            updateTitleItem={updateTitleItem}
            loseFocus={loseFocus}
            deleteItem={deleteItem}
          />

          {/* Sectie Vier */}
          <DashboardSectionWantedWorkLife
            showAll={showItems}
            total={{
              belangrijkePunten: originalData?.gewenstWerkleven?.belangrijkePunten?.length,
              watWilIkPunten: originalData?.gewenstWerkleven?.watWilIkPunten?.length,
            }}
            desiredWorkLife={data.gewenstWerkleven}
            sortListItems={sortListItems}
            changeTitle={changeTitle}
            updateTitleItem={updateTitleItem}
            editTitleItem={editTitleItem}
            deleteItem={deleteItem}
          />

          {/* Sectie 5 - Mogelijkheden */}
          <div className="flex flex-col md:flex-row">
            <DashboardSectionPossibilities
              possibilities={data.mogelijkheden}
              showAll={showItems}
              total={{ mijnWerkMogelijkheden: originalData?.mogelijkheden?.mijnWerkMogelijkheden?.length }}
              add={() => {}}
              changeTitle={changeTitle}
              updateTitleItem={updateTitleItem}
              editTitleItem={editTitleItem}
              sortListItems={sortListItems}
              deleteItem={deleteItem}
            />

            {/* Sectie 6 - Actionlist */}
            <DashboardSectionActionlist
              actions={actions}
              reload={reload}
              setReloading={setReloading}
              allActions={actions}
            />
          </div>

          {/* Sectie 7 - Tijdlijn */}
          <DashboardSectionTimeline goals={goals} goalsWork={goalWork} goalsPrivate={goalPrivate} />

          {/* Sectie 8 - Need help */}
          <DashboardSectionNeedHelp />
        </div>
      </div>
    </ContainerPage>
  )
}

export default withAuthentication(Dashboard)
