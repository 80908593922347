import React, { useEffect, useState } from 'react'
import { AlertTriangle, X } from 'react-feather'
import PopUpContainer from '../PopUpContainer'
import Trash from '../Atoms/Trash-Button'
import EditInputField from '../Molecules/Edit-Input-field'
import DeadLinePicker from '../Organisms/DeadLine-Picker'
import AddActionBar from '../Molecules/Add-Action-Bar'
import ActionToolsBar from '../Organisms/ActionToolsBar'
import PrimaryActionButton from '../Atoms/PrimaryAction-Button'
import TitleInput from '../Atoms/Title-Input'
import ActionDoubtsTools from '../Organisms/Action-Doubts-Tools'
import ActionSelectGoals from '../Organisms/Action-Select-Goals'
import { getAllGoals } from '../../functions/goalsHelper'
import { createActionObj, updateActionObj, updateActionTMP, updateActionToFB } from '../../functions/actionsHelper'
import { toast } from 'react-toastify'
import TitleGreen from '../Atoms/Titles-Green'

const PopUpActionsEdit = (props) => {
  const [action, setAction] = useState({ ...props.action })
  const [toggleDoubts, setToggleDoubts] = useState(false)
  const [popupGoalSettings, setPopupGoalSettings] = useState(false)

  const [actionOldTitle, setActionOldTitle] = useState()
  const [actionTitle, setActionTitle] = useState(props.action.actieNaam)
  const [actionDeadline, setActionDeadline] = useState(props.action.deadline)
  const [actionNote, setActionNote] = useState(props.action.notes)

  const [actionHowAction, setActionHowAction] = useState(props.action.hoeForm.hoeActie)
  const [actionHowOthers, setActionHowOthers] = useState(props.action.hoeForm.hoeAndere)
  const [actionHowNeed, setActionHowNeed] = useState(props.action.hoeForm.hoeNodig)
  const [actionHowSaved, setActionHowSaved] = useState(props.action.hoeForm.savedState)

  useEffect(() => {
    props.setSelectedGoal(props.action)
    setAction(props.action)
  }, [props.selectedAction])

  const handleChange = (e) => {
    e.preventDefault()
    let value = e.target.value
  }

  const setAnActionDeadline = (e) => {
    setActionDeadline(e.target.value)
  }

  const newValue = (e) => {
    const name = e.target.name
    const value = e.target.value
    setAction((inputs) => ({ ...inputs, [name]: value }))
  }

  const howValues = (e) => {
    const name = e.target.name
    const value = e.target.value
    let hoeForm = {
      [name]: [value],
    }
    setAction((inputs) => ({ ...inputs, [name]: value }))
  }

  const setAddNotes = (notes) => {
    setActionNote(notes)
    setAction((inputs) => ({ ...inputs, ['notes']: notes }))
  }

  const toggleDoubt = () => {
    console.log('CLOSE POPUP')
    props.togglePopUp()
    setPopupGoalSettings((prev) => !prev)
    setToggleDoubts((prev) => !prev)
    props.doubtFlow()
  }

  const selectedNewDeadline = (newSelectedDate) => {
    setActionDeadline(newSelectedDate)
  }

  const setNewActionTitle = (e) => {
    setActionTitle(e.target.value)
  }

  const updateAction = () => {
    let updatedAction = {}
    let actionTmp = {}

    //new created actions have an aUUID old ones not.

    if (action.aUUID) {
      actionTmp.hoeForm = {
        openState: false,
        savedState: actionHowSaved,
        hoeActie: actionHowAction,
        hoeAndere: actionHowOthers,
        hoeNodig: actionHowNeed,
      }

      const updatedAction = updateActionObj(
        action,
        props.goal.UUID,
        actionTitle,
        actionDeadline,
        actionNote,
        actionTmp.hoeForm,
        action.toggleVoltooid,
      )
      console.log('updatedAction comes back', updatedAction)
      updateActionTMP(props.firebase, props.authUser, updatedAction).then((result) => {
        //setAction(result);
        props.setLoading(true)
        if (props.updatedAction) {
          props.updatedAction(result)
        }
        toast('Actie is gewijzigd! 👍')
      })
    } else {
      //Old actions need to be updated with properties
      const updatedAction = createActionObj(actionTitle, props.action.UUID, actionDeadline, action.notes)
      updatedAction.hoeForm = {
        openState: false,
        savedState: actionHowSaved,
        hoeActie: actionHowAction,
        hoeAndere: actionHowOthers,
        hoeNodig: actionHowNeed,
      }
      updateActionToFB(props.firebase, props.authUser, updatedAction, props.goal, actionOldTitle).then(() => {
        props.setLoading(true)
      })
    }
  }

  return (
    <>
      <div className="w-full">
        <TitleGreen title="Actie" />
        <TitleInput
          name={'actieNaam'}
          handleOnChange={(e) => {
            setNewActionTitle(e.target.value)
          }}
          placeholder={actionTitle}
          value={actionTitle}
        />
        <div className="mt-6 border-b-2 border-gray-300 border-dashed w-full flex justify-around items-center"></div>
      </div>

      <ActionDoubtsTools
        action={props.action}
        updateAction={updateAction}
        handleChange={handleChange}
        setToggleDoubts={toggleDoubt}
        setToggleDoubts={props.setToggleDoubts}
        togglePopUp={props.togglePopUp}
        setHow={newValue}
        actionHow={action}
        isOpen={props.isOpen}
        actionHowAction={actionHowAction}
        setActionHowAction={setActionHowAction}
        actionHowOthers={actionHowOthers}
        setActionHowOthers={setActionHowOthers}
        actionHowNeed={actionHowNeed}
        setActionHowNeed={setActionHowNeed}
        actionHowSaved={actionHowSaved}
        setActionHowSaved={setActionHowSaved}
      />
      <ActionSelectGoals
        goals={props.goals}
        goal={props.goal}
        item={props.action}
        handleLabelChange={props.addNewGoalFromAction}
        setActionDeadline={setAnActionDeadline}
        actionDeadline={actionDeadline}
        actionNotes={action.notes}
        setActionNotes={setAddNotes}
        update={updateAction}
      />
      <div className="flex justify-end mt-2">
        <PrimaryActionButton handleClick={updateAction}>Opslaan</PrimaryActionButton>
      </div>
    </>
  )
}

export default PopUpActionsEdit
