import React from 'react'

const ButtonSmallRed = (props) => {
  return (
    <button
      onClick={props.onClick}
      className="w-56 h-10 text-base mt-1 cursor-pointer bg-wa-button-bordeaux hover:bg-green-800 text-white rounded-full px-5 py-1"
    >
      {props.title}
    </button>
  )
}

export default ButtonSmallRed
