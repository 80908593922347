import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'

import { withFirebase } from '../../components/Firebase'
import { withAuthentication } from '../../components/Session'
import uuidv1 from 'uuid/v1'

import PopUpContainer from '../PopUpContainer'
import AddNotes from '../Organisms/Add-Notes'
import DeadLinePicker from '../Organisms/DeadLine-Picker'
import TitleInput from '../Atoms/Title-Input'
import RadioButton from '../Atoms/RadioButton'

import ToggleGoalType from '../Molecules/Toggle-GoalType'

import PrimaryActionButton from '../Atoms/PrimaryAction-Button'
import { addGoalToDB, checkAmountOfGoals } from '../../functions/goalsHelper'
import { makeLabelSlug } from '../../functions/utils'
import { AlertTriangle, X } from 'react-feather'
import ButtonSmallGreen from '../Atoms/Button-Small-Green'
import TitleGreen from '../Atoms/Titles-Green'

const TITLE_TEXT = 'Type hier je doel...'

const PopUpVoegDoelToe = (props) => {
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)

  const [goal, setGoal] = useState({})
  const [goalTitle, setGoalTitle] = useState(props.title)
  const [goalType, setGoalType] = useState(props.selectedType)
  const [deadline, setDeadline] = useState()
  const [note, setNote] = useState([])
  const [notesCollection, setNotesCollection] = useState([])
  const [displayNotes, setDisplayNotes] = useState(props.displayNotes || true)
  const [displayWarning, setDisplayWarning] = useState(false)

  const submitNewGoal = (e) => {
    setDisplayWarning(false)

    e.preventDefault()

    if (goalTitle === TITLE_TEXT) {
      toast('Vul een eigen doelnaam in')
      return
    } else if (!deadline) {
      setDisplayWarning(true)
      toast('Een doel moet een einddatum hebben!')
      return
    } else if (
      checkAmountOfGoals(props.goals, props.goalWork, props.goalPrivate, props.selectedType || goalType, deadline)
    ) {
      toast('Je mag maar 3 actieve doelen per maand hebben, houdt focus!')
      return
    } else {
      console.log('props.isDifferent', props.isDifferent, goalTitle)
      addGoalToDB(
        props.firebase,
        props.authUser,
        goalTitle,
        goalType,
        deadline,
        notesCollection,
        props.isDifferent,
      ).then(() => {
        props.setLoading(true)
        props.closeModule()
        toast('Doel is toegevoegd aan Actielijst en Tijdlijn! 👍')
      })
    }
  }

  useEffect(() => {}, [goal])

  const addNotes = (note) => {
    setNotesCollection(note)
    /*setNotesCollection(prev => [
                  ...prev,
                  { content: note, date: Date.now() },
                ]);*/
  }

  const onToggleGoalType = (e) => {
    console.log('toggled', e.currentTarget.value)
  }

  const newValue = (e) => {
    const name = e.target.name
    const value = e.target.value
    setGoal((inputs) => ({ ...inputs, [name]: value }))
  }

  const update = (selectedDeadline) => {
    if (selectedDeadline) {
      setDeadline(selectedDeadline)
    }
    console.log('update', selectedDeadline, goal.deadline)
  }

  return (
    <>
      <div className="flex">
        <TitleGreen title="Doel" />
      </div>
      <div className="flex w-full flex-col items-end">
        <X
          className="absolute right-0 top-0 mr-4 mt-4 cursor-pointer"
          onClick={(e) => {
            props.closeModule(e)
          }}
        />
      </div>
      <TitleInput
        name={'goalName'}
        handleOnChange={(e) => {
          setGoalTitle(e.target.value)
        }}
        placeholder={TITLE_TEXT}
        value={goalTitle}
      />
      {props.choiceType && (
        <ToggleGoalType
          title={'Type hier je doelnaam...'}
          name={'goal_type'}
          labelOne={'Werk'}
          valueOne={'werk'}
          labelTwo={'Prive'}
          valueTwo={'prive'}
          onToggle={setGoalType}
          selectedType={goalType}
        />
      )}
      <DeadLinePicker
        setActionDeadline={newValue}
        actionDeadline={deadline}
        edit={update}
        iText={
          'Door een streefdatum te verbinden aan jouw doelen, worden jouw doelen automatisch op jouw tijdlijn geplaatst. Je kan je tijdlijn altijd aanpassen.'
        }
      />
      {displayWarning && (
        <div className="mt-5">
          <AlertTriangle className="inline-block mr-2 relative text-orange-600 icon-position" />
          <span>Datum moet nog worden toegevoegd</span>
        </div>
      )}
      {props.displayNotes && <AddNotes notes={notesCollection} value={note} addNotes={addNotes} />}
      <div className="flex justify-end mt-5">
        <ButtonSmallGreen title="Zet doel op tijdlijn" onClick={submitNewGoal} />
      </div>
    </>
  )
}

export default withAuthentication(withRouter(withFirebase(PopUpVoegDoelToe)))
