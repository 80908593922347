const regex = /(<([^>]+)>)/gi

const strip = (stringValue) => {
  if (typeof stringValue === 'string') {
    return stringValue.replace(regex, '').length
  } else if (typeof stringValue === 'boolean') {
    return stringValue
  } else if (typeof stringValue === 'array') {
  }

  return stringValue ? 1 : 0
}

const countAnswers = (givenAnswers, answers) => {
  if (givenAnswers && answers) {
    const countGivenAnswers = answers.filter((key) => {
      if (Array.isArray(key)) {
        return key.some((k) => givenAnswers[k] && givenAnswers[k].length)
      }
      return givenAnswers[key] && givenAnswers[key] !== 0
    })
    return [countGivenAnswers.length, answers.length]
  }
  return [0, 0]
}

export default countAnswers
