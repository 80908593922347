import React from 'react'
import { withRouter } from 'react-router-dom'
import { withFirebase } from './../../../../components/Firebase'

import ModuleNavigation from './../../../../components/ModuleNavigation'

import ModuleSaved from './../../../../components/ModuleSaved'

import checkFirstVisitPage from './../../../../functions/checkFirstVisitPage'

// Import Module Components
import MijnWerkleven2Pagina1 from '../../components/2-CurrentWorkLife2A'
import DesiredWorkLife2B from '../../components/2-DesiredWorkLife2B'
import MijnWerkleven2Pagina3 from './../../components/MijnWerkleven2Pagina3'

// Import Functions
import setEditorState from './../../../../functions/setEditorState'
import savingStateToAPI from './../../../../functions/savingStateToAPI'
import savedStateToAPI from './../../../../functions/savedStateToAPI'

// Handler om de save state te resetten
// Indien de exit boolean true is dan wordt de gebruiker doorgestuurd naar pagina 3
function resetSavingState(exit) {
  setTimeout(
    function () {
      this.setState({
        saving: false,
        saved: false,
      })
      if (exit) {
        this.props.history.push('/mijn-werkleven/2/3')
      }
    }.bind(this),
    1500,
  )
}

class MijnWerkleven2Overzicht extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gewenstCijfers: [],
      huidigCijfers: [],
      initialAPIDataReceived: false,
    }

    this.setEditorState = this.setEditorState.bind(this)
    this.handleSliderChange = this.handleSliderChange.bind(this)
    this.handleChange = this.handleChange.bind(this)

    // Save functies
    this.savingStateToAPI = savingStateToAPI.bind(this)
    this.savedStateToAPI = savedStateToAPI.bind(this)
    this.resetSavingState = resetSavingState.bind(this)
    this.saveCurrentState = this.saveCurrentState.bind(this)
    this.saveCurrentStateAndExit = this.saveCurrentStateAndExit.bind(this)
    this.removeCijferHuidigWerkleven = this.removeCijferHuidigWerkleven.bind(this)
    this.voegCijferToeAanStateNaOpslaan = this.voegCijferToeAanStateNaOpslaan.bind(this)
    this.updateStateNaOpslaanNieuwGWCijfer = this.updateStateNaOpslaanNieuwGWCijfer.bind(this)
  }

  updateStateNaOpslaanNieuwGWCijfer(nieuwCijfer) {
    let nieuweState = this.state.gewenstCijfers
    nieuweState.push(nieuwCijfer)
    this.setState(
      {
        gewenstCijfers: nieuweState,
      },
      () => {
        console.log(this.state)
      },
    )
  }

  handleChange = (event, itemIndex, huidigOfGewenst) => {
    let arrayItems = {}
    if (huidigOfGewenst === 'huidig') {
      arrayItems = this.state.huidigCijfers
      arrayItems[itemIndex][event.target.name] = event.target.value
      console.log('arrayItems', arrayItems)
      this.setState(
        {
          huidigCijfers: arrayItems,
        },
        () => console.log(this.state),
      )
    } else {
      arrayItems = this.state.huidigCijfers
      arrayItems[itemIndex][event.target.name] = event.target.value
      this.setState(
        {
          gewenstCijfers: arrayItems,
        },
        () => console.log(this.state),
      )
    }
  }

  setEditorState(stateValue, fieldName, itemIndex, huidigOfGewenst) {
    // Algemene State Handler voor de Editor

    if (itemIndex !== undefined) {
      let arrayItems = {}
      if (huidigOfGewenst === 'huidig') {
        arrayItems = this.state.huidigCijfers
        arrayItems[itemIndex][fieldName] = stateValue
        this.setState(
          {
            huidigCijfers: arrayItems,
          },
          () => console.log(this.state),
        )
      } else {
        arrayItems = this.state.huidigCijfers
        arrayItems[itemIndex][fieldName] = stateValue
        this.setState(
          {
            gewenstCijfers: arrayItems,
          },
          () => console.log(this.state),
        )
      }
    }
  }

  handleSliderChange = (value, keyName, itemIndex, huidigOfGewenst) => {
    let arrayItems = {}
    if (huidigOfGewenst === 'huidig') {
      arrayItems = this.state.huidigCijfers
      arrayItems[itemIndex][keyName] = value
      this.setState(
        {
          huidigCijfers: arrayItems,
        },
        () => console.log(this.state),
      )
    } else {
      arrayItems = this.state.huidigCijfers
      arrayItems[itemIndex][keyName] = value
      this.setState(
        {
          gewenstCijfers: arrayItems,
        },
        () => console.log(this.state),
      )
    }
  }

  ////////////////////////////////////
  ///////////////////////////////////
  //** Algemene Database functies //
  /////////////////////////////////
  ////////////////////////////////

  // Save de huidige state naar de Database
  saveCurrentState(isLast) {
    let firebase = this.props.firebase

    if (!this.state.authUser) {
      return
    }

    var docRef = firebase.db
      .collection('users')
      .doc(this.state.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('2')
      .collection('cijfers')
      .doc('cijfers')

    this.savingStateToAPI()

    const that = this

    var docRef2 = firebase.db
      .collection('users')
      .doc(this.state.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('2')

    const gwLife = this.state.gewenstCijfers[this.state.gewenstCijfers.length - 1]
    const hwLife = this.state.huidigCijfers[this.state.huidigCijfers.length - 1]

    if (isLast) {
      docRef2
        .set({
          GWCijfer: gwLife.cijfer,
          GWGevoel: gwLife.gevoel,
          GWSmiley: gwLife.smiley,
          GWToelichting: gwLife.toelichting,
          HWCijfer: hwLife.cijfer,
          HWGevoel: hwLife.gevoel,
          HWSmiley: hwLife.smiley,
          HWToelichting: hwLife.externOfIntern,
        })
        .then(function () {
          console.log('Updated ratings successfully!')
        })
    }

    docRef
      .set({
        huidigCijfers: this.state.huidigCijfers,
        gewenstCijfers: this.state.gewenstCijfers,
      })
      .then(function () {
        that.savedStateToAPI()
        console.log('Document successfully written!')
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
  }

  removeCijferHuidigWerkleven(Arrayindex) {
    let firebase = this.props.firebase

    if (!this.state.authUser) {
      return
    }

    // Make new array
    let newHuidigWerklevenCijferArray = this.state.huidigCijfers
    newHuidigWerklevenCijferArray.splice(Arrayindex, 1)

    const that = this

    var docRef = firebase.db
      .collection('users')
      .doc(this.state.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('2')
      .collection('cijfers')
      .doc('cijfers')

    docRef
      .set({
        huidigCijfers: newHuidigWerklevenCijferArray,
        gewenstCijfers: this.state.gewenstCijfers,
      })
      .then(function () {
        console.log('Document successfully written!')
        that.setState({
          huidigCijfers: newHuidigWerklevenCijferArray,
        })
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
  }

  voegCijferToeAanStateNaOpslaan(cijferObject) {
    let newHuidigWerklevenCijferArray = this.state.huidigCijfers
    newHuidigWerklevenCijferArray.push(cijferObject)
    this.setState({
      huidigCijfers: newHuidigWerklevenCijferArray,
    })
  }

  // Save de huidige state naar de Database en verlaat de pagina
  saveCurrentStateAndExit() {
    let firebase = this.props.firebase

    var docRef = firebase.db
      .collection('users')
      .doc(this.state.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('2')
      .collection('cijfers')
      .doc('cijfers')
    const gwLife = this.state.gewenstCijfers[this.state.gewenstCijfers.length - 1]
    const hwLife = this.state.huidigCijfers[this.state.huidigCijfers.length - 1]

    firebase.db
      .collection('users')
      .doc(this.state.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('2')
      .set({
        GWCijfer: gwLife.cijfer,
        GWGevoel: gwLife.gevoel,
        GWSmiley: gwLife.GWSmiley,
        GWToelichting: gwLife.externOfIntern,
        HWCijfer: hwLife.cijfer,
        HWGevoel: hwLife.gevoel,
        HWSmiley: hwLife.GWSmiley,
        HWToelichting: hwLife.externOfIntern,
      })

    this.savingStateToAPI()
    const savedStateToAPI = this.savedStateToAPI

    docRef
      .set({
        huidigCijfers: this.state.huidigCijfers,
        gewenstCijfers: this.state.gewenstCijfers,
      })
      .then(function () {
        console.log('Document successfully written!')
        const exitStatus = true
        savedStateToAPI(exitStatus)
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
  }

  componentDidMount() {
    let firebase = this.props.firebase
    let that = this

    firebase.auth.onAuthStateChanged(function (user) {
      if (user) {
        checkFirstVisitPage(user.uid, firebase, 'MW2_2', that.props.setFirstVisitStateUitleg)
        var docRefCijfers = firebase.db
          .collection('users')
          .doc(user.uid)
          .collection('modulen')
          .doc('mijnWerkleven')
          .collection('subModulen')
          .doc('2')
          .collection('cijfers')
          .doc('cijfers')

        docRefCijfers.get().then(function (doc) {
          console.log('API AANGEROEPEN!')
          if (doc.exists) {
            that.setState({
              huidigCijfers: doc.data().huidigCijfers,
              gewenstCijfers: doc.data().gewenstCijfers,
              authUser: user.uid,
              initialAPIDataReceived: true,
            })
          } else {
            that.setState({
              authUser: user.uid,
              initialAPIDataReceived: true,
            })
          }
        })
      }
    })
  }

  render() {
    // Rendered de juiste pagina en wordt meegegeven aan de bottom-navigatie
    const huidigeOnderdeel = this.props.huidigeOnderdeel
    return (
      <React.Fragment>
        <MijnWerkleven2Pagina3
          toggleEditItem={this.toggleEditItem}
          editTitleItem={this.editTitleItem}
          onSortEnd={this.onSortEnd}
          updateStateNaOpslaanNieuwGWCijfer={this.updateStateNaOpslaanNieuwGWCijfer}
          huidigeOnderdeel={huidigeOnderdeel}
          arrayItems={this.state.arrayItems}
          addItem={this.addItem}
          huidigCijfers={this.state.huidigCijfers}
          gewenstCijfers={this.state.gewenstCijfers}
          removeItem={this.removeItem}
          volgendeItem={this.volgendeItem}
          toggleOpen={this.toggleOpen}
          setEditorState={this.setEditorState}
          userID={this.state.authUser}
          handleChange={this.handleChange}
          handleSliderChange={this.handleSliderChange}
          saveCurrentState={this.saveCurrentState}
          history={this.props.history}
          firebase={this.props.firebase}
          authUser={this.state.authUser}
          initialAPIDataReceived={this.state.initialAPIDataReceived}
          removeCijferHuidigWerkleven={this.removeCijferHuidigWerkleven}
          voegCijferToeAanStateNaOpslaan={this.voegCijferToeAanStateNaOpslaan}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(withFirebase(MijnWerkleven2Overzicht))
