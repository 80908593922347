import React from 'react'

import AuthUserContext from './context'
import { withFirebase } from '../Firebase'
import IphoneInstall from '../IphoneInstall'

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        authUser: JSON.parse(localStorage.getItem('authUser')),
        showInstallMessage: false,
      }
    }

    // Detects if device is on iOS
    isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod/.test(userAgent)
    }
    // Detects if device is in standalone mode
    isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone

    componentDidMount() {
      // Checks if should display install popup notification:
      if (this.isIos() && !this.isInStandaloneMode()) {
        this.setState({ showInstallMessage: true })
      }

      this.listener = this.props.firebase.onAuthUserListener(
        (authUser) => {
          localStorage.setItem('authUser', JSON.stringify(authUser))
          this.setState({ authUser })
        },
        () => {
          localStorage.removeItem('authUser')
          this.setState({ authUser: null })
        },
      )
    }

    componentWillUnmount() {
      this.listener()
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} authUser={this.state.authUser} />
          {this.state.showInstallMessage && <IphoneInstall />}
        </AuthUserContext.Provider>
      )
    }
  }

  return withFirebase(WithAuthentication)
}

export default withAuthentication
