import React from 'react'

const PrimaryActionButton = (props) => {
  return (
    <button
      type="submit"
      className="text-base ml-auto md:mt-1 cursor-pointer bg-wa-button-green hover:bg-green-800 text-white rounded-full px-5 py-1 font-bold"
      onClick={(e) => {
        props.handleClick(e)
      }}
    >
      {props.children}
    </button>
  )
}

export default PrimaryActionButton
