import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Home } from 'react-feather'
import ButtonSmallRed from '../Atoms/Button-Small-Red'

class DashboardHeading extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className="flex flex-col md:flex-row justify-between md:items-center bg-white pl-2 pr-2">
        <h1 className="font-bold text-xl md:text-2xl text-black">Dashboard Workappic</h1>
        <div className="flex">
          <ButtonSmallRed
            title="Uitleg WorkAppic"
            onClick={(e) => {
              this.props.toggleDashboardPopUp((prev) => !prev)
            }}
          />
        </div>
      </div>
    )
  }
}

DashboardHeading.propTypes = {
  toggleDashboardPopUp: PropTypes.func.isRequired,
  toggleAnalysePopUp: PropTypes.func.isRequired,
}

export default DashboardHeading
