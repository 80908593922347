import React from 'react'
import TitleInput from '../Atoms/Title-Input'
import EditButton from '../Atoms/Edit-Button'
import ButtonSmallGreen from '../Atoms/Button-Small-Green'

const EditInputField = ({ currentTitle, handleOnChange, edit, name }) => {
  return (
    <div className="w-full flex flex-row items-center">
      <TitleInput value={currentTitle} handleOnChange={handleOnChange || edit} name={name} />
    </div>
  )
}

export default EditInputField
