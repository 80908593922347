import React from 'react'
import { Link } from 'react-router-dom'
import TextHeadingTwo from '../../components/TextHeadingTwo'
import DashboardCardCijferChart from '../../components/DashboardCardCijferChart'
import Ratings from './Ratings/Ratings'

const DashboardRating = (props) => {
  const { GWCijfer, HWCijfer, GWSmiley, HWSmiley, HWToelichting } = props.ratingsWorkLife || {}
  const { huidigCijfers, gewenstCijfers } = props.ratings
  console.log('ratings', props.ratings)
  if (!huidigCijfers || !gewenstCijfers) return null
  return (
    <div className="flex-col md:flex">
      <div>
        <Link to={'/mijn-werkleven/2/1'}>
          <TextHeadingTwo textCenter={true}>2 | Cijfers Huidig & Gewenst Werkleven</TextHeadingTwo>
        </Link>
      </div>
      <div className="flex flex-col md:flex-row bg-white pt-4 pb-4">
        <Ratings
          title="Huidig"
          content="Cijfer Toevoegen"
          rating={parseFloat(huidigCijfers[huidigCijfers.length - 1].cijfer)}
          smiley={parseInt(huidigCijfers[huidigCijfers.length - 1].smiley)}
        />

        <DashboardCardCijferChart arrayItems={props.ratings} />

        <Ratings
          title="Gewenst"
          content="Cijfer Aanpassen"
          rating={parseFloat(gewenstCijfers[gewenstCijfers.length - 1].cijfer)}
          smiley={parseInt(gewenstCijfers[gewenstCijfers.length - 1].smiley)}
        />
      </div>
    </div>
  )
}

export default DashboardRating
