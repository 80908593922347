// Algemene State Handler voor de Editor
function setEditorState(stateValue, fieldName, itemIndex) {
  if (itemIndex !== undefined) {
    let arrayItems = this.state.arrayItems
    arrayItems[itemIndex][fieldName] = stateValue
    this.setState({
      arrayItems: arrayItems,
    })
  } else {
    this.setState({
      [fieldName]: stateValue,
    })
  }
}

export default setEditorState
