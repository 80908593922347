import React, { Component } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { Menu, Edit, Trash2 } from 'react-feather'
import SortableTodoItem from '../Organisms/Sortable-Todo-Item'

const SortableItem = SortableElement(
  ({
    item,
    index,
    listPosition,
    removeItem,
    editTitleItem,
    toggleEditItem,
    amountQuestions,
    changeTitle,
    loseFocus,
    type,
    updateTitleItem,
    answers,
  }) => (
    <SortableTodoItem
      item={item}
      listPosition={listPosition}
      editTitleItem={editTitleItem}
      toggleEditItem={toggleEditItem}
      removeItem={removeItem}
      amountQuestions={amountQuestions}
      changeTitle={changeTitle}
      loseFocus={loseFocus}
      type={type}
      updateTitleItem={updateTitleItem}
      answers={answers}
    />
  ),
)

class SortableComponent extends Component {
  render() {
    return (
      <SortableList
        items={this.props.arrayItems}
        toggleEditItem={this.props.toggleEditItem}
        editTitleItem={this.props.editTitleItem}
        removeItem={this.props.removeItem}
        onSortEnd={this.props.onSortEnd}
        amountQuestions={this.props.amountQuestions}
        distance={5}
        changeTitle={this.props.changeTitle}
        loseFocus={this.props.loseFocus}
        type={this.props.type}
        updateTitleItem={this.props.updateTitleItem}
        answers={this.props.answers}
      />
    )
  }
}

const SortableList = SortableContainer(
  ({
    items,
    removeItem,
    editTitleItem,
    toggleEditItem,
    amountQuestions,
    changeTitle,
    loseFocus,
    type,
    updateTitleItem,
    answers,
  }) => {
    return (
      <ol>
        {items !== undefined
          ? items.map((value, index) => {
              console.log(index)
              return (
                <SortableItem
                  listPosition={index}
                  key={`item-${index}`}
                  index={index}
                  item={value}
                  removeItem={removeItem}
                  editTitleItem={editTitleItem}
                  toggleEditItem={toggleEditItem}
                  amountQuestions={amountQuestions}
                  changeTitle={changeTitle}
                  loseFocus={loseFocus}
                  type={type}
                  updateTitleItem={updateTitleItem}
                  answers={answers}
                />
              )
            })
          : null}
      </ol>
    )
  },
)

class TodoForm extends React.Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    // this.refs.itemName.focus();
  }

  onSubmit(event) {
    event.preventDefault()
    var newItemValue = this.refs.itemName.value

    if (newItemValue) {
      this.props.addItem({ newItemValue })
      this.refs.form.reset()
    }
  }
  render() {
    return (
      <form ref="form" onSubmit={this.onSubmit} className="text-gray-700 py-2 mb-2 mt-4 relative flex">
        <input
          type="text"
          autoComplete="off"
          ref="itemName"
          className="mr-1 wa-input-mb md:mr-4 border-dashed border-b md:w-full h-14 pl-4 pr-4"
          placeholder="Voeg punt toe"
        />
        <button
          type="submit"
          className="wa-green-button-mb md:border-2 bg-wa-button-light-green hover:bg-blue-600 hover:text-white px-5 py-1 rounded-full inline-block font-bold text-blue-600 md:whitespace-no-wrap shadow"
        >
          Voeg toe
        </button>
      </form>
    )
  }
}

class TodoApp extends React.Component {
  render() {
    const arrayItems = this.props.arrayItems
    const removeItem = this.props.removeItem
    const addItem = this.props.addItem
    const onSortEnd = this.props.onSortEnd
    const editTitleItem = this.props.editTitleItem
    const toggleEditItem = this.props.toggleEditItem
    const amountQuestions = this.props.amountQuestions

    return (
      <div id="main">
        <SortableComponent
          onSortEnd={onSortEnd}
          arrayItems={arrayItems}
          removeItem={removeItem}
          editTitleItem={editTitleItem}
          toggleEditItem={toggleEditItem}
          amountQuestions={amountQuestions}
          changeTitle={this.props.changeTitle}
          loseFocus={this.props.loseFocus}
          type={this.props.type}
          updateTitleItem={this.props.updateTitleItem}
          answers={this.props.answers}
        />
        <TodoForm addItem={addItem} />
      </div>
    )
  }
}

export default TodoApp
