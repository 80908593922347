import React from 'react'

const DashboardSectionNeedHelp = () => {
  return (
    <div className="text-sx ml-2 mr-2 md:w-1/2 p-4 mt-16 mb-16 wa-footer-bg md:mr-auto md:ml-auto flex  flex-col md:items-center">
      <div className="flex md:items-center">
        <div class="text-xs">Interesse in andere tools</div>
        <div className="icon-tools"></div>
      </div>
      <div className="flex md:items-center">
        <div class="text-xs">Persoonlijk support nodig van een expert</div>
        <div className="icon-expert"></div>
      </div>
      <div className="flex md:items-center">
        <div class="text-xs">Een deep dive maken in een van onze experiences?</div>
        <div className="icon-experience"></div>
      </div>
      <div className="flex">
        <div class="text-xs">
          <a href="https://www.worklifebetter.nl" target="_blank" className="underline" class="text-xs md:text-base">
            Je vindt alles op het platform van WorkLifeBetter!
          </a>
        </div>
      </div>
    </div>
  )
}

export default DashboardSectionNeedHelp
