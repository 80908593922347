export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const DASHBOARD = '/'
export const ACCOUNT = '/account'
export const PASSWORD_FORGET = '/pw-forget'
export const ADMIN = '/admin'
export const ADMIN_DETAILS = '/admin/:id'
export const TO_DO_LIJST = '/to-do-lijst'
export const TIMELINE = '/tijdlijn'
export const MODULEN_WERKLEVEN_SECTIE_ONDERDEEL = '/mijn-werkleven/:sectie/:onderdeel'
export const MIJN_WERKLEVEN = '/mijn-werkleven'
export const VOORWARDEN_PRIVACY = '/voorwaarden-privacy'
export const VOORWARDEN_ALGEMEEN = '/voorwaarden-algemeen'
