import React from 'react'
import { CheckCircle, Circle } from 'react-feather'

const ActionToolTitle = ({ action, title, state, toggleState, index, changeTitle, editAction, setEditAction }) => {
  return (
    <>
      {state ? (
        <span
          className="cursor-pointer"
          data-index={index}
          onClick={(e) => {
            toggleState(e, action)
          }}
        >
          <CheckCircle className="inline-block mr-2 relative wa-green-color -mt-1 relative select-none pointer-events-none" />
        </span>
      ) : (
        <span
          className="cursor-pointer actie-item-onvoltooid"
          data-index={index}
          onClick={(e) => {
            toggleState(e, action)
          }}
        >
          <CheckCircle className="mr-2 relative wa-green-color -mt-1 relative select-none pointer-events-none actie-icon-voltooid" />
          <Circle className="mr-2 relative wa-green-color -mt-1 relative select-none pointer-events-none actie-icon-onvoltooid" />
        </span>
      )}
      {!editAction ? (
        <span
          className="flex w-full wa-green-color flex-grow cursor-pointer"
          onClick={() => {
            setEditAction((prev) => !prev)
          }}
        >
          {title}
        </span>
      ) : (
        <input
          type="text"
          autoComplete="off"
          className="edit-array-item-title-input mr-4 border-dashed border-b w-full h-14 pl-2 pr-4"
          value={title}
          onChange={(e) => {
            changeTitle(e, action)
          }}
          onKeyDown={(e) => changeTitle(e, action)}
        />
      )}
    </>
  )
}

export default ActionToolTitle
