import React, { Component, useEffect } from 'react'
import format from 'date-fns/format'
import nlLocale from 'date-fns/locale/nl'
import { Calendar } from 'react-feather'

function ActielijstNotitie(props) {
  return (
    <div className="px-5 py-5 rounded mt-3 shadow-md">
      <h3 className="text-gray-700 font-bold mb-1">
        <Calendar className="inline-block mr-3 relative text-gray-700 font-bold -mt-2 relative" />
        {format(props.notitieDatum, 'DD MMMM YYYY - HH:mm', {
          locale: nlLocale,
        })}
      </h3>
      <p>{props.notitieInhoud}</p>
    </div>
  )
}

export default ActielijstNotitie
