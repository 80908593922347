import React from 'react'
import ContentLoader from 'react-content-loader'

const LoaderSlider = () => (
  <ContentLoader height={22} className="w-full mt-20 justify-center">
    <rect x="0" y="0" rx="3" ry="3" width="100%" height="22" />
  </ContentLoader>
)

export default LoaderSlider
