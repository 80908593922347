import React from 'react'
import ContentLoader from 'react-content-loader'

const LoaderList = () => (
  <ContentLoader height={120} width={400}>
    <circle cx="5" cy="8" r="4" />
    <rect x="15" y="5" rx="5" ry="5" width="220" height="5" />

    <circle cx="5" cy="25" r="4" />
    <rect x="15" y="22" rx="5" ry="5" width="220" height="5" />

    <circle cx="5" cy="42" r="4" />
    <rect x="15" y="39" rx="5" ry="5" width="220" height="5" />

    <circle cx="5" cy="59" r="4" />
    <rect x="15" y="56" rx="5" ry="5" width="220" height="5" />

    <circle cx="5" cy="76" r="4" />
    <rect x="15" y="73" rx="5" ry="5" width="220" height="5" />

    <circle cx="5" cy="93" r="4" />
    <rect x="15" y="90" rx="5" ry="5" width="220" height="5" />
  </ContentLoader>
)

export default LoaderList
