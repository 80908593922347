import React, { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import Tooltip from 'react-tooltip-lite'

import { withFirebase } from '../Firebase'
import Lightbulb from './../Lightbulb'
import { addThought, createNewAction, updateActionObj } from '../../functions/actionsHelper'
import { addThoughtToGoal } from '../../functions/goalsHelper'
import { withAuthentication } from '../Session'
import Modal from '../../components/Template/Modal'
import SelectLabel from '../SelectLabel'

const PopUpVoegActieToe = (props) => {
  const [nieuweActie, setNieuweActie] = useState({
    actieNaam: '',
    hoeActie: '',
    hoeNodig: '',
    hoeAndere: '',
    labels: [],
  })
  const [saving, setSAving] = useState(false)
  const [saved, setSaved] = useState(false)
  const [selectedLabel, setSelectedLabel] = useState([])
  const inputElement = useRef(null)

  const arrLength = 4
  const [elRefs, setElRefs] = React.useState([])

  React.useEffect(() => {
    // add or remove refs
    setElRefs((elRefs) =>
      Array(arrLength)
        .fill()
        .map((_, i) => elRefs[i]),
    )
  }, [arrLength])

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus()
    }
  }, [inputElement.current])

  const handleLabelChange = (newValue, initialize) => {
    setSelectedLabel(newValue)
  }

  // Functie om een nieuw aangemaakt label op te slaan naar Firebase
  const saveNewLabelToDatabase = (nieuwLabel) => {
    const firebase = props.firebase
    const docRef = firebase.db.collection('users').doc(props.userID).collection('actielijst').doc('labels')

    docRef
      .update({
        currentLabels: firebase.fieldValue.arrayUnion(nieuwLabel),
      })
      .then(function () {
        console.log('Doel successfully written to db!')
        if (props.addLabelFromTwijfelFlow) {
          props.addLabelFromTwijfelFlow(nieuwLabel)
        }
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
  }

  const handleChange = (event) => {
    if (event.target === undefined) {
      setSelectedLabel(event)
      setNieuweActie((prev) => ({ ...prev, [event.name]: [event.value] }))
    } else {
      const name = event.target.name
      const value = event.target.value
      setNieuweActie((prev) => ({ ...prev, [name]: value }))
    }
  }

  const saveToDatabase = (exitStatus) => {
    const firebase = props.firebase

    let newActionObj = {
      actieNaam: nieuweActie.actieNaam,
      hoeForm: {
        hoeActie: nieuweActie.hoeActie,
        hoeNodig: nieuweActie.hoeNodig,
        hoeAndere: nieuweActie.hoeAndere,
        openState: true,
        savedState: true,
      },
      labels: nieuweActie.labels,
      voltooid: false,
    }

    const newAction = createNewAction(newActionObj)
    const updateNewCreatedAction = updateActionObj(
      newAction,
      selectedLabel.UUID,
      nieuweActie.actieNaam,
      null,
      null,
      nieuweActie.hoeForm,
      false,
    )

    if (selectedLabel.UUID) {
      addThoughtToGoal(firebase, props.authUser, selectedLabel, updateNewCreatedAction).then((result) => {
        toast('Actie is toegevoegd aan actielijst! 👍')
      })
    } else {
      addThought(firebase, props.authUser, newAction).then(() => {
        toast('Actie is toegevoegd aan actielijst! 👍')
      })
    }

    setNieuweActie({
      actieNaam: '',
      hoeActie: '',
      hoeNodig: '',
      hoeAndere: '',
      labels: [],
    })

    setSaved(true)
  }

  return (
    <Modal onClose={props.closeModule}>
      <h3 className="font-bold text-xl text-gray-700">
        Voeg een actie toe{' '}
        {props.title ? (
          <React.Fragment>
            voor
            <span className="italic ml-1">"{props.title}"</span>
          </React.Fragment>
        ) : null}
      </h3>

      <h3 className="font-bold text-gray-700 mb-1 mt-4">Wat kan je doen?</h3>
      <input
        key="actieNaam1"
        required
        name="actieNaam"
        className="border w-full px-2 py-1"
        type="text"
        autoComplete="off"
        value={nieuweActie.actieNaam}
        onChange={(e) => {
          handleChange(e)
        }}
        ref={elRefs[0]}
      />
      <h3 className="font-bold text-gray-700 mb-1 mt-4">Hoe ga je dat doen?</h3>
      <input
        key="hoeActie1"
        name="hoeActie"
        className="border w-full px-2 py-1"
        type="text"
        autoComplete="off"
        value={nieuweActie.hoeActie}
        onChange={(e) => {
          handleChange(e)
        }}
        ref={elRefs[1]}
      />
      <h3 className="font-bold text-gray-700 mb-1 mt-4">
        Wat heb je hiervoor nodig?
        <Tooltip
          content={
            <div className="max-w-sm">
              <p>Bijvoorbeeld:</p>
              <ul>
                <li>- middelen (tijd, geld, tools)</li>
                <li>- skills</li>
                <li>- kennis of informatie</li>
                <li>- hulp van anderen</li>
                <li>- niks, gewoon doen :)</li>
              </ul>
            </div>
          }
          className="inline-block z-20"
        >
          <Lightbulb />
        </Tooltip>
      </h3>
      <input
        key="hoeNodig1"
        name="hoeNodig"
        className="border w-full px-2 py-1"
        type="text"
        autoComplete="off"
        value={nieuweActie.hoeNodig}
        onChange={(e) => {
          handleChange(e)
        }}
        ref={elRefs[2]}
      />
      <h3 className="font-bold text-gray-700 mb-1 mt-4">Koppel de actie aan een doel</h3>
      <SelectLabel handleChange={handleChange} ref={elRefs[3]} name="labels" items={nieuweActie.labels} />
      <div className="flex flex-col flex-wrap">
        <button
          key="buttonAdd"
          type="submit"
          onClick={nieuweActie?.actieNaam?.length > 0 ? () => saveToDatabase(false) : null}
          className="py-2 px-5 mt-3 text-center bg-wa-button-green hover:bg-green-800 font-bold text-white md:text-base rounded-full cursor-pointer"
        >
          Voeg toe aan Actielijst
        </button>
      </div>
    </Modal>
  )
}

export default withAuthentication(withFirebase(PopUpVoegActieToe))
