import React from 'react'
import Tooltip from 'react-tooltip-lite'

import RadioButton from './../../../../components/RadioButton'
import Slider from './../../../../components/Slider'
import Editor from './../../../../components/Editor'
import LoaderEditor from './../../../../components/Loaders/LoaderEditor'
import LoaderCheckboxList from './../../../../components/Loaders/LoaderCheckboxList'
import LoaderSlider from './../../../../components/Loaders/LoaderSlider'
import Lightbulb from './../../../../components/Lightbulb'
import TitleQuestionair from './../../../../components/Atoms/Title-Questionair'
import SmileySelection from '../../../../components/Template/Smiley-Selection'

const DesiredWorkLife2B = (props) => {
  return (
    <>
      <TitleQuestionair>B. Gewenst werkleven</TitleQuestionair>

      <h3 className="font-bold text-back mb-2">
        Stel je eens voor dat je een cijfer kan geven aan jouw gewenste werkleven. Welk cijfer geef je?
      </h3>
      {props.loading === false ? (
        <Slider sliderValue={props.GWSliderValue} onChange={props.handleSliderChange} keyName="GWCijfer" />
      ) : (
        <LoaderSlider />
      )}
      <div className="mt-5">
        <h3 className="font-bold text-black mb-2">Waarom geef je dat cijfer?</h3>
        {props.loading === false ? (
          <Editor
            setEditorState={props.setEditorState}
            fieldName="GWToelichting"
            initialEditorState={props.initialEditorStateGWToelichting}
          />
        ) : (
          <LoaderEditor />
        )}
      </div>
      <SmileySelection
        handleChange={props.handleChange}
        title="Hoe zou je je dan voelen? Kies een smiley."
        smileyType="GWSmiley"
        smiley={props.GWSmiley}
      />
      <div className="mt-8">
        <h3 className="font-bold text-gray-700 mb-2">
          Hoe is dat dan aan jou te zien of te merken?
          <Tooltip
            content={
              <div className="max-w-sm">
                <div>
                  <ul>
                    <li>Ben je vrolijker? </li>
                    <li>Voel je je meer ontspannen?</li>
                    <li>Heb je meer energie?</li>
                    <li>Ben je productiever? </li>
                    <li>Ben je meer gemotiveerd?</li>
                    <li>Loop je weer fluitend rond?</li>
                    <li>Ben je weer helemaal aan het shinen?</li>
                    <li>Stap je makkelijker met je goede been uit bed? </li>
                  </ul>
                </div>
              </div>
            }
            className="inline-block z-20"
          >
            <Lightbulb />
          </Tooltip>
        </h3>
        {props.loading === false ? (
          <Editor
            setEditorState={props.setEditorState}
            fieldName="GWGevoel"
            initialEditorState={props.initialEditorStateGWGevoel}
          />
        ) : (
          <LoaderEditor />
        )}
      </div>
    </>
  )
}

export default DesiredWorkLife2B
