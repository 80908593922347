import React, { useMemo } from 'react'

import RadioButton from '../RadioButton'
import smiley1 from '../../assets/images/icons/smiley-1.png'
import smiley2 from '../../assets/images/icons/smiley-2.png'
import smiley3 from '../../assets/images/icons/smiley-3.png'
import smiley4 from '../../assets/images/icons/smiley-4.png'
import smiley5 from '../../assets/images/icons/smiley-5.png'

const SmileySelection = (props) => {
  console.log('smiley props', props)
  const smileyNumber = useMemo(() => {
    return parseInt(props.smiley)
  }, [props.smiley])
  return (
    <div className="mt-5">
      <h3 className="font-bold text-black mb-2">{props.title}</h3>
      <fieldset className="flex text-2xl" onChange={(e) => props.handleChange(e)}>
        <RadioButton checked={smileyNumber === 0} smiley={smiley1} value="0" name={props.smileyType} />
        <RadioButton checked={smileyNumber === 1} smiley={smiley2} value="1" name={props.smileyType} />
        <RadioButton checked={smileyNumber === 2} smiley={smiley3} value="2" name={props.smileyType} />
        <RadioButton checked={smileyNumber === 3} smiley={smiley4} value="3" name={props.smileyType} />
        <RadioButton checked={smileyNumber === 4} smiley={smiley5} value="4" name={props.smileyType} />
      </fieldset>
    </div>
  )
}

export default SmileySelection
