import React, { Component } from 'react'

import { AuthUserContext, withAuthentication } from './../../components/Session'
import { withFirebase } from './../../components/Firebase'
import { PasswordForgetForm } from './../../components/PasswordForget'
import PasswordChangeForm from './../../components/PasswordChange'
import CardProfile from './../../components/Cards/CardProfile'
import MainHeader from './../../components/MainHeader'
import ToggleButton from '../../components/Molecules/Toggle-Button'

import { User } from 'react-feather'
import { toast } from 'react-toastify'

import { getNotificationSettings, setNotificationSettings } from '../../functions/accountHelper'
import checkFirstVisitPage from '../../functions/checkFirstVisitPage'

const SIGN_IN_METHODS = [
  {
    id: 'password',
    provider: null,
  },
  {
    id: 'google.com',
    provider: 'googleProvider',
  },
]

class Account extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profilePictureURL: this.props.profilePictureURL || null,
    }
  }

  componentDidMount() {
    const firebase = this.props.firebase
    const that = this

    console.log('this.props.authUser', this.props.authUser)

    firebase.storage
      .ref()
      .child(`profile_pictures/${this.props.authUser.uid}`)
      .getDownloadURL()
      .then(function (url) {
        that.setState(
          {
            profilePictureURL: url,
          },
          () => console.log(that.state),
        )
      })
      .catch(function (error) {
        // Handle any errors
      })
  }

  render() {
    return (
      <div className="min-h-full pb-16" id="page-container">
        <MainHeader title="Account" />
        <div className="flex">
          <div className="-mt-12 relative mx-auto container p-6 z-10">
            <div className="flex">
              <CardProfile url={this.state.profilePictureURL} />
              <AuthUserContext.Consumer>
                {(authUser) => (
                  <div className="bg-white shadow-xl h-full rounded-lg lg:w-3/4 w-full overflow-hidden px-5 py-8">
                    <CardProfile url={this.state.profilePictureURL} inline={true} />
                    <h1 className="font-bold text-xl md:text-2xl text-gray-700 mb-4 lg:block md:hidden">Account</h1>
                    <p>{this.props.authUser.email}</p>
                    <br />
                    <h2 className="mb-2 font-bold text-gray-700">Reset mijn wachtwoord</h2>
                    <PasswordForgetForm />
                    <h2 className="mb-2 font-bold text-gray-700">Verander mijn wachtwoord</h2>
                    <hr className="mb-2" />
                    <PasswordChangeForm />
                    <h2 className="mb-2 font-bold text-gray-700">Link mijn account met een social login</h2>
                    <hr className="mb-2" />
                    <LoginManagement authUser={authUser} />
                  </div>
                )}
              </AuthUserContext.Consumer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class LoginManagementBase extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeSignInMethods: [],
      error: null,
      notifications: false,
    }
  }

  componentDidMount() {
    this.fetchSignInMethods()

    let firebase = this.props.firebase
    let that = this

    firebase.auth.onAuthStateChanged(function (user) {
      if (user) {
        getNotificationSettings(firebase, user).then((result) => {
          that.setState({ notifications: result })
        })
      }
    })
  }

  fetchSignInMethods = () => {
    this.props.firebase.auth
      .fetchSignInMethodsForEmail(this.props.authUser.email)
      .then((activeSignInMethods) => this.setState({ activeSignInMethods, error: null }))
      .catch((error) => this.setState({ error }))
  }

  onSocialLoginLink = (provider) => {
    this.props.firebase.auth.currentUser
      .linkWithPopup(this.props.firebase[provider])
      .then(this.fetchSignInMethods)
      .catch((error) => this.setState({ error }))
  }

  onDefaultLoginLink = (password) => {
    const credential = this.props.firebase.emailAuthProvider.credential(this.props.authUser.email, password)

    this.props.firebase.auth.currentUser
      .linkAndRetrieveDataWithCredential(credential)
      .then(this.fetchSignInMethods)
      .catch((error) => this.setState({ error }))
  }

  onUnlink = (providerId) => {
    this.props.firebase.auth.currentUser
      .unlink(providerId)
      .then(this.fetchSignInMethods)
      .catch((error) => this.setState({ error }))
  }

  onToggleNotifcations = () => {
    let firebase = this.props.firebase
    let that = this
    that.setState({ notifications: !that.state.notifications }, () => {
      firebase.auth.onAuthStateChanged(function (user) {
        if (user) {
          setNotificationSettings(firebase, user.uid, that.state.notifications).then((result) => {})
        }
      })

      if (that.state.notifications) {
        toast('De notificaties staan aan!')
      } else {
        toast('De notificaties staan uit!')
      }
    })
  }

  render() {
    const { activeSignInMethods, error, notifications } = this.state

    return (
      <div>
        <ul>
          {SIGN_IN_METHODS.map((signInMethod) => {
            const onlyOneLeft = activeSignInMethods.length === 1
            const isEnabled = activeSignInMethods.includes(signInMethod.id)

            return (
              <li key={signInMethod.id} className="inline-block">
                {signInMethod.id !== 'password' ? (
                  <SocialLoginToggle
                    onlyOneLeft={onlyOneLeft}
                    isEnabled={isEnabled}
                    signInMethod={signInMethod}
                    onLink={this.onSocialLoginLink}
                    onUnlink={this.onUnlink}
                  />
                ) : null}
              </li>
            )
          })}
        </ul>
        {error && error.message}
        <br />
        {this.state.notifications}
        <ToggleButton
          title={'Zet notificaties'}
          on={'Aan'}
          off={'Uit'}
          onChange={this.onToggleNotifcations}
          value={notifications}
        />
      </div>
    )
  }
}

const SocialLoginToggle = ({ onlyOneLeft, isEnabled, signInMethod, onLink, onUnlink }) =>
  isEnabled ? (
    <button
      type="button"
      onClick={() => onUnlink(signInMethod.id)}
      disabled={onlyOneLeft}
      className="mt-3 mr-2 sm:mt-0 py-2 px-4 inline-block shadow border font-semibold text-gray-700 rounded shadow-md"
    >
      Deactivate {signInMethod.id}
    </button>
  ) : (
    <button
      type="button"
      onClick={() => onLink(signInMethod.provider)}
      className="mt-3 mr-2 sm:mt-0 py-2 px-4 inline-block shadow border font-semibold text-gray-700 rounded shadow-md"
    >
      Link {signInMethod.id}
    </button>
  )

const LoginManagement = withFirebase(LoginManagementBase)

export default withAuthentication(Account)
