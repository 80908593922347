import React from 'react'
import { Link } from 'react-router-dom'

import DashboardContainerCards from '../../components/DashboardContainerCards'
import TextHeadingTwo from '../../components/TextHeadingTwo'
import DashboardDoelenSlider from '../../components/DashboardDoelenSlider'
import MiniButton from '../../components/Atoms/MiniButton'

const DashboardSectionGoals = (props) => {
  console.log('props.goals ====== ', props.goals)
  return (
    <DashboardContainerCards>
      <TextHeadingTwo textCenter={true}>
        <Link to="/mijn-werkleven/1/1">1 | Doel WorkAppic </Link>
      </TextHeadingTwo>

      <div className="flex flex-row md:mt-4 mt-2 w-full">
        <DashboardDoelenSlider goals={props.goals} goalPrivate={props.goalPrivate} goalWork={props.goalWork} />
        <span className="md:mr-6">
          <Link to={'/mijn-werkleven/1/1'}>
            <MiniButton title={'+'} onClick={props.add} green large />
          </Link>
        </span>
      </div>
    </DashboardContainerCards>
  )
}

export default DashboardSectionGoals
