import React, { useState, useEffect } from 'react'

const SelectTimeSpan = ({ isPast, selectTimeSpan, currentYear, date, months, selectedYears, setZoomedIn }) => {
  return (
    <li className={'border-b border-gray-300 hover:translate-t-2px hover:text-gray-900'}>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => {
          selectedYears(
            currentYear > date.getFullYear()
              ? date.getFullYear() + ' - ' + currentYear
              : currentYear + ' - ' + date.getFullYear(),
          )
          selectTimeSpan(isPast ? -months : months)
          setZoomedIn(false)
        }}
      >
        {currentYear > date.getFullYear() ? (
          <>
            <span>
              {date.getFullYear()} tot {currentYear}
            </span>
          </>
        ) : (
          <>
            <span>
              {currentYear} tot {date.getFullYear()}
            </span>
          </>
        )}
      </span>
    </li>
  )
}

const SelectAbleList = ({
  availableYears,
  currentYear,
  selectTimeSpan,
  isPast,
  selectedYears,
  setLoading,
  zoomedIn,
  setZoomedIn,
}) => {
  return (
    <ul className={'mt-2 p-0 ml-0'}>
      {(availableYears || []).map((dateObj) => (
        //show amount of months when less then 12, otherwise show years
        <SelectTimeSpan
          currentYear={currentYear}
          selectTimeSpan={selectTimeSpan}
          isPast={isPast}
          date={dateObj.date}
          months={dateObj.months}
          selectedYears={selectedYears}
          zoomedIn={zoomedIn}
          setZoomedIn={setZoomedIn}
        />
      ))}
    </ul>
  )
}

const TimelineSelect = ({
  selectableTimeSpan,
  selectTimeSpan,
  isPast,
  setLoading,
  zoomedIn,
  setZoomedIn,
  isDashboard,
}) => {
  const [isOpend, setIsOpend] = useState(false)
  const [availableYears, setAvailableYears] = useState([])
  const [selectedYear, setSelectedYear] = useState('2022 - 2025')

  const currentDate = new Date()
  const nextYear = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDay())
  const currentYear = currentDate.getFullYear()

  const selectedYears = (years) => {
    setSelectedYear(years)
    setLoading(true)
  }

  useEffect(() => {
    console.log('selectableTimeSpan', selectableTimeSpan)
    const availableYears = selectableTimeSpan.map((month) => {
      const newDate = new Date()
      return {
        date: new Date(newDate.setMonth(newDate.getMonth() + month)),
        months: month,
      }
    })
    setAvailableYears(availableYears)
  }, [])

  return (
    <>
      {!isDashboard && (
        <div
          className={
            'absolute wa-dropdown-timeline-mb md:right-10 rounded bg-red-100 mr-2 w-32 text-gray-700 highInOrder shadow text-center  md:p-1 border-green-300 border-2; rounded cursor-pointer'
          }
          onClick={() => {
            setIsOpend((prev) => !prev)
          }}
        >
          <div>
            <span className="font-normal text-left text-gray-800 text-sm ">
              {zoomedIn ? 'Kies jaren' : selectedYear}
            </span>{' '}
            <span className="font-bold h-0.5 text-left wa-bordeaux-color w-24 text-xl ml-2">&or;</span>
            {isOpend && (
              <SelectAbleList
                availableYears={availableYears}
                currentYear={currentYear}
                isPast={isPast}
                selectTimeSpan={selectTimeSpan}
                selectedYears={selectedYears}
                zoomedIn={zoomedIn}
                setZoomedIn={setZoomedIn}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default TimelineSelect
