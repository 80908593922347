import React from 'react'
import { toast } from 'react-toastify'
import { Loader } from 'react-feather'
import { Link } from 'react-router-dom'

const Stepper = ({ canSave, isLoading, previous, next, nextTitle = 'Volgende' }) => {
  return (
    <div className="bg-white md:p-3 flex w-full flex-row justify-end ">
      {canSave && (
        <button
          onClick={() => {
            //saveCurrentState();
            toast('Opgeslagen! 👍')
          }}
          className="w-56 h-10 px-5 py-1 text-base cursor-pointer bg-wa-button-green text-white rounded-full"
        >
          {isLoading ? <Loader className="mr-2 inline-block" id="loader_loading" size={30} /> : null}
          Opslaan
        </button>
      )}
      <Link
        to={previous}
        className="flex items-center justify-center w-56 h-10 px-5 py-1 text-base cursor-pointer bg-wa-button-green text-white rounded-full mr-4 ml-4"
      >
        Vorige
      </Link>

      <Link
        to={next}
        className="flex items-center justify-center w-56 h-10 px-5 py-1 text-base cursor-pointer bg-wa-button-bordeaux text-white rounded-full"
      >
        {nextTitle}
      </Link>
    </div>
  )
}

export default Stepper
