import React, { useEffect, useState } from 'react'

import EditInputField from '../Molecules/Edit-Input-field'
import AddActionBar from '../Molecules/Add-Action-Bar'
import DeadLinePicker from '../Organisms/DeadLine-Picker'
import ActionToolsBar from '../Organisms/ActionToolsBar'
import Trash from '../Atoms/Trash-Button'
import { withAuthentication } from '../Session'
import { withFirebase } from '../Firebase'
import { addAction, createActionObj, updateAction } from '../../functions/actionsHelper'
import { deleteCurrentGoal } from '../../functions/goalsHelper'
import AddNotes from '../Organisms/Add-Notes'
import { toast } from 'react-toastify'
import ToDoLijstTwijfel from '../ToDoLijstTwijfel'
import TitleGreen from '../Atoms/Titles-Green'
import { pages, popups } from '../../text.json'
import ButtonSmallGreen from '../Atoms/Button-Small-Green'
import isBefore from 'date-fns/is_before'
import subDays from 'date-fns/sub_days'

const PopUpGoalSettings = (props) => {
  const [newActionTitle, setNewActionTitle] = useState('')
  const [goalTitle, setGoalTitle] = useState(props.goal.labelNaam)
  const [goalDeadline, setGoalDeadline] = useState(props.goal.deadline)
  const [notesCollection, setNotesCollection] = useState(props.goal.notes)
  const [completedActions, setCompletedActions] = useState([])
  const [toggleDoubtFlow, setToggleDoubtFlow] = useState(false)
  const [actions, setActions] = useState([])
  const [sortedActions, setSortedActions] = useState()
  const [selectedAction, setSelectedAction] = useState()

  const handleInput = (e) => {
    const value = e.target.value
    setNewActionTitle(value)
  }

  const handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    setGoalDeadline(value)
  }

  const updateDeadLine = (date) => {
    setGoalDeadline(date)
  }

  const doubtFlow = () => {
    setToggleDoubtFlow((prev) => !prev)
  }

  const deleteGoal = () => {
    deleteCurrentGoal(props.firebase, props.authUser, props.goal).then((result) => {
      if (props.updateAfterDelete) {
        props.updateAfterDelete(result, props.goal)
      }
      props.setLoading(true)
      props.toggleGoalSettingPopUp()
    })
  }

  const updateCurrentAction = (action) => {
    updateAction(props.firebase, props.authUser, action, props.goal).then((result) => {
      let completed = result?.filter((a) => {
        return a.voltooid == true
      })

      setCompletedActions(completed)

      let sortedActions = result?.sort(function (a, b) {
        return new Date(a.deadline) - new Date(b.deadline)
      })

      setSortedActions([...sortedActions])
      props.setLoading(true)
    })
  }

  const deleteAction = (actions) => {
    setSortedActions([...actions])
  }

  const updatedAction = (actions) => {
    console.log('83 single updated actions', actions)
    setSortedActions([...actions])
  }

  const addNewTitle = (newTitle) => {
    let foundActions = 0
    let foundActionsInProp = 0

    if (newTitle) {
      foundActionsInProp = props.goal.actions.filter((a) => {
        return a.a === newTitle
      })
      foundActions = actions.filter((a) => {
        return a.actieNaam === newTitle
      })

      if (foundActions.length === 0 && foundActionsInProp.length === 0) {
        const newAction = createActionObj(newTitle, props.goal.UUID, props.goal.deadline)

        addAction(props.firebase, props.authUser, props.goal, newAction).then((result) => {
          let sortedActions = result.actions.sort(function (a, b) {
            return new Date(a.deadline) - new Date(b.deadline)
          })
          console.log('sortedActions', sortedActions)
          setSortedActions([...sortedActions])
          setNewActionTitle('')
          toast('Actie toegevoegd aan doel! 👍')
        })
      } else {
        toast('Deze actie bestaat al!')
      }
    }
  }

  const handleNewAction = () => {
    let title = newActionTitle

    if (title) {
      addNewTitle(title)
    }
  }

  const updateGoalWithValues = (isClose) => {
    const indexCurrentGoal = props.goals.findIndex((g) => g.UUID === props.goal.UUID)
    const allGoals = props.goals

    const updatedGoal = {
      ...props.goal,
      labelNaam: goalTitle,
      actions: sortedActions,
      deadline: goalDeadline,
      state: isBefore(new Date(goalDeadline), subDays(new Date(), 1)) ? 'failed' : 'active',
      notes: notesCollection,
    }

    allGoals[indexCurrentGoal] = updatedGoal

    try {
      props.firebase.db
        .collection('users')
        .doc(props.authUser.uid)
        .collection('actielijst')
        .doc('labels')
        .update({ currentLabels: allGoals })
        .then(() => {
          toast('Opgeslagen! 👍')
        })
    } catch (e) {
      console.error('something horrible happened', e.message)
    }

    props.setLoading(true)
  }

  const addNotes = (notes) => {
    setNotesCollection(notes)
    updateGoalWithValues()
  }

  const setSelectedGoal = (action) => {
    setSelectedAction(action)
  }

  /*useEffect(() => {
    setSortedActions([...actions])
  }, [actions])*/

  useEffect(() => {
    console.log('all actions', props.goal.actions, sortedActions)

    if (sortedActions?.length > 0) {
      let curActions = sortedActions?.filter((a) => {
        return a.UUID === props.goal.UUID
      })
      let completedActions = curActions.filter((a) => {
        return a.voltooid === true
      })
      setCompletedActions(
        completedActions.sort(function (a, b) {
          return new Date(a.deadline) - new Date(b.deadline)
        }),
      )
      let sortedActionsNew = curActions.sort(function (a, b) {
        return new Date(a.deadline) - new Date(b.deadline)
      })

      setSortedActions([...sortedActionsNew])
    } else {
      let completedActions = props.goal.actions.filter((a) => {
        return a.voltooid === true
      })
      setCompletedActions(
        completedActions.sort(function (a, b) {
          return new Date(a.deadline) - new Date(b.deadline)
        }),
      )

      let sortedActionsNew = props.goal.actions.sort(function (a, b) {
        return new Date(a.deadline) - new Date(b.deadline)
      })

      console.log('props.goal.actions, sortedActions', props.goal.actions, sortedActions)
      setSortedActions([...sortedActionsNew])
    }
  }, [props.goal.actions])

  console.log('all actions', sortedActions)

  return (
    <div className="overflow-y-scroll max-height-modal pr-4 -mr-4">
      <div className="flex">
        <TitleGreen title="Doel" />
      </div>
      <div className="flex w-full flex-row justify-end absolute right-0 top-0 mr-12 mt-4 cursor-pointer">
        <Trash deleteAction={deleteGoal} type={'ACTION'} name={props.goal.labelNaam} UUID={props.goal.UUID} />
      </div>

      <div className="flex flex-col items-start">
        <EditInputField
          currentTitle={goalTitle}
          handleOnChange={(e) => {
            setGoalTitle(e.target.value)
          }}
          edit={updateGoalWithValues}
        />
        <DeadLinePicker
          setActionDeadline={handleInputChange}
          actionDeadline={goalDeadline}
          edit={updateDeadLine}
          iText={pages.timeline.tipTextDate}
        />
      </div>

      <div>
        <div className="flex flex-col items-start mt-6">
          <TitleGreen title="Acties" />
          <AddActionBar
            addAction={handleNewAction}
            value={newActionTitle}
            handleOnChange={handleInput}
            placeholder={popups.addaction}
          />
        </div>
        {sortedActions
          ?.filter((a) => !a.voltooid)
          .map((action, index) => {
            return (
              <ActionToolsBar
                key={action.actieNaam + '_' + index}
                goal={props.goal}
                goals={props.goals}
                action={action}
                index={index}
                updateCurrentAction={updateCurrentAction}
                toggleGoalSettingPopUp={props.toggleGoalSettingPopUp}
                setLoading={props.setLoading}
                showBear={false}
                showSettings={false}
                isDone={false}
                completedActions={[]}
                doubtFlow={doubtFlow}
                deleteAction={deleteAction}
                updatedAction={updatedAction}
                setSelectedGoal={setSelectedGoal}
                setSortedActions={setActions}
              />
            )
          })}
        {sortedActions
          ?.filter((a) => a.voltooid)
          .map((action, index) => {
            return (
              <ActionToolsBar
                key={action.actieNaam + '_' + index}
                goal={props.goal}
                goals={props.goals}
                action={action}
                index={index}
                updateCurrentAction={updateCurrentAction}
                toggleGoalSettingPopUp={props.toggleGoalSettingPopUp}
                setLoading={props.setLoading}
                showBear={false}
                showSettings={false}
                isDone={true}
                completedActions={sortedActions.filter((a) => a.voltooid)}
                doubtFlow={doubtFlow}
                deleteAction={deleteAction}
                updatedAction={updatedAction}
                setSelectedGoal={setSelectedGoal}
                selectedAction={selectedAction}
                setSortedActions={setActions}
              />
            )
          })}
      </div>
      <AddNotes notes={notesCollection} addNotes={addNotes} />
      <div className="flex justify-end mt-2">
        <ButtonSmallGreen title="Opslaan" onClick={updateGoalWithValues} />
      </div>
      {toggleDoubtFlow ? (
        <ToDoLijstTwijfel
          addLabelFromTwijfelFlow={props.addLabelFromTwijfelFlow}
          voegNieuweTwijfelToeVanuitTwijfelFlow={props.voegNieuweTwijfelToeVanuitTwijfelFlow}
          authUserID={props.authUserID}
          closePopup={doubtFlow}
        />
      ) : null}
    </div>
  )
}

export default withAuthentication(withFirebase(PopUpGoalSettings))
