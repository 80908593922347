import React from 'react'
import Editor from './../Editor'
import SelectLabel from './../SelectLabel'
import { withFirebase } from '../Firebase'
import Tooltip from 'react-tooltip-lite'
import { toast } from 'react-toastify'
import Lightbulb from './../Lightbulb'
import PopUpVoegActieToe from './../PopUpVoegActieToe'
import { ChevronDown, ChevronUp, Plus, X } from 'react-feather'

import FormFieldListItemIngevuldCounter from './../FormFieldListItemIngevuldCounter'
import FormFieldListItemTitle from './../FormFieldListItemTitle'
import FormFieldListItemChevronToggle from './../FormFieldListItemChevronToggle'
import FormFieldListItemLeukerMakenLabel from './../FormFieldListItemLeukerMakenLabel'
import FormFieldListItemContainer from './../FormFieldListItemContainer'
import FormFieldListItemButtonVolgendePunt from './../FormFieldListItemButtonVolgendePunt'
import HorizontalLineDivider from './../HorizontalLineDivider'
import FormFieldListItemLeukerBeterMaken from './../FormFieldListItemLeukerBeterMaken'

import FormFieldLabel from './../FormFieldLabel'
import FormFieldItemContainer from './../FormFieldItemContainer'
import FormFieldTextArea from './../FormFieldTextArea'

class ListItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      puntLeukerMaken: false,
    }
    this.openPuntLeukerMaken = this.openPuntLeukerMaken.bind(this)
    this.closeModule = this.closeModule.bind(this)
  }

  openPuntLeukerMaken(index) {
    this.setState({
      puntLeukerMaken: true,
    })
  }

  closeModule() {
    this.setState({
      puntLeukerMaken: false,
    })
  }

  countTotaalIngevuld(item) {
    let totaalIngevuld = 0
    if (item.beschrijving && item.beschrijving.length > 0 && item.beschrijving !== '<p><br></p>') {
      totaalIngevuld += 1
    }
    if (item.leukerMaken === true || item.leukerMaken === false) {
      totaalIngevuld += 1
    }
    return totaalIngevuld
  }

  render() {
    const item = this.props.item
    let totaalIngevuld = this.countTotaalIngevuld(item)

    let itemChecked = false
    if (item.leukerMaken === true) {
      itemChecked = true
    }

    return (
      <FormFieldListItemContainer index={this.props.index} isOpen={item.open}>
        <FormFieldListItemChevronToggle
          toggleOpen={() => this.props.toggleOpen(this.props.index)}
          openState={item.open}
        />

        <FormFieldListItemLeukerMakenLabel leukerMakenBool={item.leukerMaken} />

        <FormFieldListItemIngevuldCounter aantalIngevuld={totaalIngevuld} aantalInTeVullen={2} />

        <FormFieldListItemTitle
          toggleOpen={() => this.props.toggleOpen(this.props.index)}
          title={item.title}
          index={this.props.index}
        />

        {item.open === true ? (
          <div className="bg-white">
            <div className="mt-2">
              {/* <FormFieldItemContainer>
              <FormFieldLabel>Beschrijving leuke punt</FormFieldLabel>
              <Editor
                index={this.props.index}
                fieldName="beschrijving_1"
                setEditorState={this.props.setEditorState}
                initialEditorState={item.beschrijving_1}
              />
            </FormFieldItemContainer> */}

              <FormFieldItemContainer>
                <FormFieldLabel>Waarom vind je dit leuk?</FormFieldLabel>
                <Editor
                  index={this.props.index}
                  fieldName="beschrijving"
                  setEditorState={this.props.setEditorState}
                  initialEditorState={item.beschrijving}
                />
              </FormFieldItemContainer>

              <FormFieldItemContainer>
                <FormFieldLabel>
                  Kan je actie ondernemen om dit punt nog leuker/better maken?
                  <Tooltip
                    content={
                      <div>
                        <span>Kan je bijvoorbeeld:</span>
                        <ul className="list-disc">
                          <li>dit verder uitbreiden in jouw huidige werkleven?</li>
                          <li>meer tijd hieraan besteden?</li>
                        </ul>
                      </div>
                    }
                    className="inline-block z-20"
                  >
                    <Lightbulb />
                  </Tooltip>
                </FormFieldLabel>
                <FormFieldListItemLeukerBeterMaken
                  leukerMakenBool={item.leukerMaken}
                  checked={item.leukerMaken === true ? true : false}
                  openPuntLeukerMaken={() => this.openPuntLeukerMaken(this.props.index)}
                  itemIndex={this.props.index}
                  arrayProp="arrayItemsLeuk"
                  setLeukerMakenBool={this.props.setLeukerMakenBool}
                />
              </FormFieldItemContainer>

              <FormFieldListItemButtonVolgendePunt
                index={this.props.index}
                length={this.props.length}
                volgendeItem={() => this.props.volgendeItem(this.props.index)}
              />
            </div>
          </div>
        ) : null}
        {this.state.puntLeukerMaken ? (
          <PopUpVoegActieToe
            userID={this.props.userID}
            firebase={this.props.firebase}
            title={item.title}
            closeModule={this.closeModule}
          />
        ) : null}
      </FormFieldListItemContainer>
    )
  }
}

class ListWerkleven3ToggleableLeukePunten extends React.Component {
  render() {
    const setLeukerMakenBool = this.props.setLeukerMakenBool
    const arrayItems = this.props.arrayItems
    const removeItem = this.props.removeItem
    const toggleOpen = this.props.toggleOpen
    const volgendeItem = this.props.volgendeItem
    const handleChange = this.props.handleChange
    const setEditorState = this.props.setEditorState
    const userID = this.props.userID
    const firebase = this.props.firebase

    return (
      <div id="main">
        <ol>
          {arrayItems.map((item, index) => {
            return (
              <ListItem
                key={index}
                item={item}
                index={index}
                setLeukerMakenBool={setLeukerMakenBool}
                toggleOpen={toggleOpen}
                length={arrayItems.length}
                volgendeItem={volgendeItem}
                handleChange={handleChange}
                setEditorState={setEditorState}
                userID={userID}
                firebase={firebase}
              />
            )
          })}
        </ol>
      </div>
    )
  }
}

export default withFirebase(ListWerkleven3ToggleableLeukePunten)
