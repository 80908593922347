import React from 'react'
import { HelpCircle, Settings } from 'react-feather'

const emptyTimeLine = {
  gridRow: '7',
  gridRowStart: '1',
  gridRowEnd: '12',
  zIndex: '55',
  marginTop: '16px',
}

const TimelineQuestionMark = (props) => {
  return (
    <div className="flex flex-col items-center" style={{ ...emptyTimeLine, gridColumn: props.column }}>
      <div>
        <HelpCircle size={window.innerWidth >= 500 ? 150 : 50} color="#2D3748" />
      </div>
      <div className="mt-5 font-bold text-center text-gray-800 text-xs md:text-2xl">
        Met welke doelen bereik
        <br /> jij jouw ideale (werk)leven?
      </div>
    </div>
  )
}

export default TimelineQuestionMark
