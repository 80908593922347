import React, { useEffect, useState } from 'react'
import TextHeadingTwo from '../../components/TextHeadingTwo'
import { Link } from 'react-router-dom'
import ActionListItem from './Actionlist/Actionlist-Item'
import Footer from './WorkLife/footer'

const DashboardSectionActionlist = (props) => {
  const actionNoDeadline = props.actions.filter((a) => !a.deadline && !a.voltooid)

  console.log(actionNoDeadline)

  const [showAll, setShowAll] = useState(false)
  const [displayedActions, setDisplayedActions] = useState([])

  useEffect(() => {
    if (showAll) {
      setDisplayedActions([...actionNoDeadline])
    } else {
      setDisplayedActions([...actionNoDeadline.splice(0, 5)])
    }
  }, [showAll, actionNoDeadline.length])

  return (
    <div className="md:w-1/2 inline-block align-top">
      <TextHeadingTwo textCenter={true}>
        <Link to="/to-do-lijst">6 | Actielijst (zonder datum)</Link>
      </TextHeadingTwo>
      <div className="w-full flex flex-col pr-6 py-2">
        <ul className="w-full mr-2">
          {displayedActions
            ?.sort((a, b) => a.originalIndex < b.originalIndex)
            .map((a, i) => (
              <ActionListItem
                key={a.actieNaam + '_' + i}
                index={i}
                item={a}
                title={a.actieNaam}
                completed={a.voltooid}
                openModel={props.openModel}
                setActionListItem={props.setActionListItem}
                setReloading={props.setReloading}
                actionNoDeadline={actionNoDeadline}
                allActions={props.allActions}
              />
            ))}
        </ul>
        <Footer
          showAll={() => setShowAll((val) => !val)}
          add={props.add}
          displayAll={showAll}
          showAllBtnVisible={actionNoDeadline.length > 5}
          goTo={'/to-do-lijst'}
        />
      </div>
    </div>
  )
}
export default DashboardSectionActionlist
