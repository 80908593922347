import React from 'react'
import HorizontalLineDivider from '../HorizontalLineDivider'

const SubtitleQuestionair = (props) => {
  return (
    <>
      <h2 className="font-extrabold text-lg wg-dark-green mt-10">{props.children}</h2>
      <HorizontalLineDivider />
    </>
  )
}

export default SubtitleQuestionair
