import React from 'react'
import MogelijkhedenLijst from './../../../../components/MogelijkhedenLijst'
import ListWerkleven5ToggleableMogelijkheden from './../../../../components/ListWerkleven5ToggleableMogelijkheden'
import Lightbulb from './../../../../components/Lightbulb'
import Tooltip from 'react-tooltip-lite'
import TitleQuestionair from '../../../../components/Atoms/Title-Questionair'

const ANSWERS_POSSIBLE = ['beschrijving', 'blijvendStructureel', 'toelichting', 'cijfer', ['minpunten', 'pluspunten']]

class MijnWerkleven5Pagina1en2 extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.huidigeOnderdeel === '1' ? (
          <TitleQuestionair>A. Mogelijkheden - lijstje</TitleQuestionair>
        ) : (
          <TitleQuestionair>B. Mogelijkheden - analyseren</TitleQuestionair>
        )}
        {this.props.huidigeOnderdeel === '1' ? (
          <div className="mt-4">
            <h3 className="font-bold text-gray-700  mb-4">
              Welke mogelijkheden heb jij om jouw gewenste werkleven te realiseren? Maak hieronder een lijstje.
              <Tooltip
                content={
                  <div>
                    <p>Mogelijkheden kunnen zijn:</p>
                    <p className="font-bold">Binnen huidige werkleven:</p>
                    <ul className="list-disc">
                      <li>ontwikkeling/opleiding/cursus</li>
                      <li>andere taken in huidige rol</li>
                      <li>andere rol binnen huidige organisatie</li>
                    </ul>
                    <p>Buiten huidige werkleven:</p>
                    <ul className="list-disc">
                      <li>hobby/vrijwilligerswerk</li>
                      <li>onderneming starten</li>
                      <li>oriënteren carrièreswitch</li>
                    </ul>
                    <p>Of een combinatie hiervan:</p>
                    <ul className="list-disc">
                      <li>2 soorten banen/bedrijven combineren</li>
                      <li>combinatie werken als werknemer en ondernemer</li>
                    </ul>
                  </div>
                }
                className="inline-block z-20"
              >
                <Lightbulb />
              </Tooltip>
            </h3>
          </div>
        ) : (
          <div className="mt-4">
            <h3 className="font-bold text-gray-700 mb-4">Let’s dive deeper. Beantwoordt per punt de vragen.</h3>
          </div>
        )}
        <div id="omschrijving">
          {this.props.huidigeOnderdeel === '1' ? (
            <React.Fragment>
              <MogelijkhedenLijst
                toggleEditItem={this.props.toggleEditItem}
                editTitleItem={this.props.editTitleItem}
                onSortEnd={this.props.onSortEnd}
                arrayItems={this.props.arrayItems}
                addItem={this.props.addItem}
                removeItem={this.props.removeItem}
                changeTitle={this.props.changeTitle}
                loseFocus={this.props.loseFocus}
                updateTitleItem={this.props.updateTitleItem}
                type={'mogelijheden'}
                answers={ANSWERS_POSSIBLE}
              />
            </React.Fragment>
          ) : (
            <ListWerkleven5ToggleableMogelijkheden
              handleSliderChange={this.props.handleSliderChange}
              arrayItems={this.props.arrayItems}
              voegPlusOfMinpuntToe={this.props.voegPlusOfMinpuntToe}
              verwijderPlusOfMinpunt={this.props.verwijderPlusOfMinpunt}
              addItem={this.props.addItem}
              removeItem={this.props.removeItem}
              toggleOpen={this.props.toggleOpen}
              volgendeItem={this.props.volgendeItem}
              handleChange={this.props.handleChange}
              setEditorState={this.props.setEditorState}
              userID={this.props.userID}
            />
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default MijnWerkleven5Pagina1en2
