import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Tooltip from 'react-tooltip-lite'
import { pages } from '../../text.json'
import Lightbulb from '../../components/Lightbulb'
import AddButton from '../../components/Atoms/AddButton'
import Modal from '../../components/Template/Modal'
import ActielijstItemPopup from '../../components/ActielijstItemPopup'
import InfoUitlegModule from '../../components/InfoUitlegModule'
import ButtonSmallGreen from '../../components/Atoms/Button-Small-Green'

const ActionlistSidebar = (props) => {
  const [modalType, setModalType] = useState(false)
  return (
    <>
      {modalType && (
        <Modal
          onClose={() => {
            props.setReloading(true)
            setModalType(false)
          }}
        >
          <ActielijstItemPopup
            item={props.item}
            closePopup={() => {
              props.setReloading(true)
              setModalType(false)
            }}
            setReloading={props.setReloading}
            allGoals={props.currentGoals}
          />
        </Modal>
      )}
      <div className="p-5">
        <span className="text-white font-lg block w-40">
          <span className="text-2xl">Actielijst</span>
          <Tooltip
            content={
              <div className="max-w-sm">
                <p>
                  <b>{pages.actionlist.explanationTitle}</b>
                </p>
                <br />
                <ul className="list-disc">
                  {pages.actionlist.explanationPoints?.map((t, i) => (
                    <li key={'explain_' + i}>{t}</li>
                  ))}
                </ul>
              </div>
            }
            className="inline-block z-20"
          >
            <Lightbulb />
          </Tooltip>
        </span>
        <div>
          <ul className="mt-2">
            {props.currentAction?.map((al, index) => {
              return (
                <li
                  key={al.title + '_' + index}
                  className="text-white mb-2 font-xl cursor-pointer"
                  onClick={() => {
                    props.setPageIndex(index)
                  }}
                >
                  <span>{al.title}</span>
                  <span className="count ml-2 text-sm text-white no-underline	">({al.actions?.length})</span>
                </li>
              )
            })}
          </ul>
        </div>
        <AddButton
          title="+ Actie"
          onClick={() => {
            setModalType(true)
          }}
        />
      </div>
    </>
  )
}

export default ActionlistSidebar
