import React from 'react'
import { NavLink } from 'react-router-dom'
import { Tag, Menu, Plus, X, ArrowDownCircle, Calendar } from 'react-feather'
import Lightbulb from './../Lightbulb'
import Tooltip from 'react-tooltip-lite'

import PopUpVoegDoelToe from '../../components/PopUpVoegDoelToe/PopUpVoegDoelToe'
import InfoUitlegModule from '../InfoUitlegModule'
import ButtonMain from '../ButtonMain'
import SidebarGoals from '../Template/Sidebar-Goals'
import { getAllGoals } from '../../functions/goalsHelper'
import { withAuthentication } from '../Session'
import { withFirebase } from '../Firebase'

function MainMenuLijstNav(props) {
  return (
    <React.Fragment>
      <div className="p-5">
        <ul className="mt-2">
          <li className="text-gray-700 mb-2 font-bold font-xl">
            <NavLink
              activeClassName="is-active cursor-default font-bold text-gray-900"
              className="transition-fast hover:translate-t-2px hover:text-gray-900 text-gray-600 font-medium"
              to="/"
              exact
              onClick={() => {
                if (props.navOpen) {
                  props.toggleNav()
                }
              }}
            >
              - Dashboard
            </NavLink>
          </li>
          <li className="text-gray-700 mb-2 font-bold font-xl">
            <NavLink
              activeClassName="is-active cursor-default font-bold text-gray-900"
              className="transition-fast hover:translate-t-2px hover:text-gray-900 text-gray-600 font-medium"
              to="/mijn-werkleven/1/1"
              exact
              onClick={() => {
                if (props.navOpen) {
                  props.toggleNav()
                }
              }}
            >
              1. Doel
            </NavLink>
          </li>
          <li className="text-gray-700 mb-2 font-bold font-xl">
            <NavLink
              activeClassName="is-active cursor-default font-bold text-gray-900"
              className="transition-fast hover:translate-t-2px hover:text-gray-900 text-gray-600 font-medium"
              to="/mijn-werkleven/2/1"
              exact
              onClick={() => {
                if (props.navOpen) {
                  props.toggleNav()
                }
              }}
            >
              2. Cijfers
            </NavLink>
          </li>
          <li className="text-gray-700 mb-2 font-bold font-xl">
            <NavLink
              activeClassName="is-active cursor-default font-bold text-gray-900"
              className="transition-fast hover:translate-t-2px hover:text-gray-900 text-gray-600 font-medium"
              to="/mijn-werkleven/3/1"
              exact
              onClick={() => {
                if (props.navOpen) {
                  props.toggleNav()
                }
              }}
            >
              3. Huidige Werkleven
            </NavLink>
          </li>
          <li className="text-gray-700 mb-2 font-bold font-xl">
            <NavLink
              activeClassName="is-active cursor-default font-bold text-gray-900"
              className="transition-fast hover:translate-t-2px hover:text-gray-900 text-gray-600 font-medium"
              to="/mijn-werkleven/4/1"
              exact
              onClick={() => {
                if (props.navOpen) {
                  props.toggleNav()
                }
              }}
            >
              4. Gewenste Werkleven
            </NavLink>
          </li>
          <li className="text-gray-700 mb-2 font-bold font-xl">
            <NavLink
              activeClassName="is-active cursor-default font-bold text-gray-900"
              className="transition-fast hover:translate-t-2px hover:text-gray-900 text-gray-600 font-medium"
              to="/mijn-werkleven/5/1"
              exact
              onClick={() => {
                if (props.navOpen) {
                  props.toggleNav()
                }
              }}
            >
              5. Mogelijkheden
            </NavLink>
          </li>
          <li className="text-gray-700 mb-2 font-bold font-xl">
            <NavLink
              activeClassName="is-active cursor-default font-bold text-gray-900"
              className="transition-fast hover:translate-t-2px hover:text-gray-900 text-gray-600 font-medium"
              to="/to-do-lijst"
              exact
              onClick={() => {
                if (props.navOpen) {
                  props.toggleNav()
                }
              }}
            >
              6. Actielijst
            </NavLink>
          </li>{' '}
          <li className="text-gray-700 mb-2 font-bold font-xl">
            <NavLink
              activeClassName="is-active cursor-default font-bold text-gray-900"
              className="transition-fast hover:translate-t-2px hover:text-gray-900 text-gray-600 font-medium"
              to="/tijdlijn"
              exact
              onClick={() => {
                if (props.navOpen) {
                  props.toggleNav()
                }
              }}
            >
              7. Tijdlijn
            </NavLink>
          </li>
        </ul>
      </div>
    </React.Fragment>
  )
}

class ToDoLijstMob extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navOpen: false,
      voegLabelToe: false,
      addGoals: false,
      goals: [],
    }
    this.toggleNav = this.toggleNav.bind(this)
    this.toggleLabelToevoegenPopup = this.toggleLabelToevoegenPopup.bind(this)
  }

  toggleNav(goal) {
    //if(goal) {
    //this.props.setSelectedGoal(goal);
    //}
    this.setState({
      navOpen: !this.state.navOpen,
    })
  }

  toggleLabelToevoegenPopup() {
    this.setState({
      voegLabelToe: !this.state.voegLabelToe,
    })
    this.props.toggleOpenedAddGoal()
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.opendPopup !== prevProps.opendPopup || this.props.goals !== prevProps.goals) {
      let firebase = this.props.firebase
      let that = this

      firebase.auth.onAuthStateChanged(function (user) {
        getAllGoals(firebase, user).then((result) => {
          that.setState({
            goals: result,
          })
        })
      })
    }
  }

  componentDidMount() {
    let that = this
    let firebase = that.props.firebase

    firebase.auth.onAuthStateChanged(function (user) {
      getAllGoals(firebase, user).then((result) => {
        that.setState({
          goals: result,
        })
      })
    })
  }

  render() {
    return (
      <div className="w-full md:w-1/4 md:mr-6 mb-6 md:mb-0">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="block md:hidden">
            <span
              className="block font-bold text-xl md:text-2xl text-gray-700 pt-4 pb-3 flex justify-center items-center cursor-pointer"
              onClick={this.toggleNav}
            >
              <ArrowDownCircle className="inline-block mr-2 relative text-green-600 icon-position md:hidden" />
            </span>

            {this.state.navOpen ? (
              <>
                <MainMenuLijstNav navOpen={true} toggleNav={this.toggleNav} />
              </>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

export default withAuthentication(withFirebase(ToDoLijstMob))
