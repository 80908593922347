import React, { Component } from 'react'
import PropTypes from 'prop-types'
import HorizontalLineDivider from '../HorizontalLineDivider'

class FormFieldListItemTitle extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <>
        <div
          className="wa-input-mb text-lg cursor-pointer md:pl-6 pl-2 md:mt-0 ml-5 inline-block text-black select-none"
          onClick={this.props.toggleOpen}
        >
          {this.props.index + 1}. {this.props.title}
        </div>
      </>
    )
  }
}

FormFieldListItemTitle.propTypes = {
  toggleOpen: PropTypes.func,
  title: PropTypes.string,
}

export default FormFieldListItemTitle
