import React from 'react'
import { Trash2 } from 'react-feather'

const TrashButton = ({ deleteAction }) => {
  return (
    <button
      type="button"
      className="rounded-full cursor-default white px-1 z-30 text-white"
      onClick={() => {
        deleteAction()
      }}
    >
      <Trash2 className="wa-bordeaux-color cursor-pointer inline-block relative hover:text-red-700" size={20} />
    </button>
  )
}

export default TrashButton
