import React from 'react'
import MinderLeukePuntenLijst from './../../../../components/MinderLeukePuntenLijst'
import ListWerkleven3ToggleableMinderLeuk from './../../../../components/ListWerkleven3ToggleableMinderLeuk'
import Lightbulb from './../../../../components/Lightbulb'
import Tooltip from 'react-tooltip-lite'
import TitleQuestionair from '../../../../components/Atoms/Title-Questionair'

const ANSWERS_NOT_NICE = ['blijvendStructureel', 'leukerMaken', 'toelichting']
const ANSWERS_NICE = ['beschrijving', 'blijvendStructureel', 'toelichting', 'open']

class MijnWerkleven3Pagina2en3 extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.huidigeOnderdeel === '2' ? (
          <TitleQuestionair>B. Vind ik niet leuk - lijstje</TitleQuestionair>
        ) : (
          <TitleQuestionair>C. Vind ik niet leuk - analyseren</TitleQuestionair>
        )}
        <div id="omschrijving" className="mt-4">
          {this.props.huidigeOnderdeel === '2' ? (
            <React.Fragment>
              <h3 className="font-bold text-gray-700  mb-4">
                Wat vind jij niet leuk aan jouw huidige werkleven? Maak hieronder een lijstje.
                <Tooltip
                  content={
                    <div>
                      <p>Stel jezelf de volgende vragen:</p>
                      <ul className="list-disc">
                        <li>Wat kost mij energie?</li>
                        <li>Wat zorgt voor minder plezier?</li>
                        <li>Waar krijg ik spanning van?</li>
                        <li>Wat geeft mij weinig voldoening?</li>
                        <li>Waar hoor ik mijzelf over klagen? </li>
                        <li>Waar pieker ik over?</li>
                        <li>Waar lig ik wakker van? </li>
                      </ul>
                    </div>
                  }
                  className="inline-block z-20"
                >
                  <Lightbulb />
                </Tooltip>
              </h3>
              <MinderLeukePuntenLijst
                toggleEditItem={this.props.toggleEditItem}
                editTitleItem={this.props.editTitleItem}
                updateTitleItem={this.props.updateTitleItem}
                onSortEnd={this.props.onSortEnd}
                arrayItems={this.props.arrayItems}
                addItem={this.props.addItem}
                removeItem={this.props.removeItem}
                changeTitle={this.props.changeTitle}
                loseFocus={this.props.loseFocus}
                amountQuestions={3}
                type={this.props.type}
                answers={ANSWERS_NOT_NICE}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h3 className="font-bold text-gray-700  mb-4">Let’s dive deeper. Beantwoordt per punt de vragen.</h3>
              <ListWerkleven3ToggleableMinderLeuk
                setLeukerMakenBool={this.props.setLeukerMakenBool}
                arrayItems={this.props.arrayItems}
                addItem={this.props.addItem}
                removeItem={this.props.removeItem}
                toggleOpen={this.props.toggleOpen}
                volgendeItem={this.props.volgendeItem}
                handleChange={this.props.handleChange}
                setEditorState={this.props.setEditorState}
                userID={this.props.userID}
              />
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default MijnWerkleven3Pagina2en3
