import React from 'react'
import PropTypes from 'prop-types'

import format from 'date-fns/format'
import nlLocale from 'date-fns/locale/nl'
import differenceInWeeks from 'date-fns/difference_in_weeks'
import differenceInMonths from 'date-fns/difference_in_months'
import differenceInYears from 'date-fns/difference_in_years'

import { AreaChart, Area, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import CustomTooltip from '../../components/Organisms/Custom-Tooltip'

import ChartButton from '../Atoms/Chart-Button'

class WerklevenCijferChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeFilter: 'alles',
    }
    this.switchFilter = this.switchFilter.bind(this)
  }

  switchFilter(filterName) {
    this.setState({
      activeFilter: filterName,
    })
  }

  render() {
    let data = this.props.arrayItems?.map((object) => ({ ...object }))

    function weekFilter(data) {
      return data.filter((item) => {
        const diff = differenceInWeeks(new Date(), item.datum.toDate())
        if (diff <= 1) {
          return item
        }
      })
    }

    function maandFilter(data) {
      return data.filter((item) => {
        const diff = differenceInMonths(new Date(), item.datum.toDate())
        if (diff <= 1) {
          return item
        }
      })
    }

    function jaarFilter(data) {
      return data.filter((item) => {
        const diff = differenceInYears(new Date(), item.datum.toDate())
        if (diff <= 1) {
          return item
        }
      })
    }

    if (this.state.activeFilter === 'week') {
      data = weekFilter(data)
    } else if (this.state.activeFilter === 'maand') {
      data = maandFilter(data)
    } else if (this.state.activeFilter === 'jaar') {
      data = jaarFilter(data)
    }

    data.forEach((item) => {
      if (typeof item.datum.toDate === 'function') {
        item.datum = format(item.datum.toDate(), 'DD/MM/YYYY', {
          locale: nlLocale,
        })
      } else {
        item.datum = format(item.datum, 'DD/MM/YYYY', {
          locale: nlLocale,
        })
      }
      item.cijfer = parseInt(item.cijfer)
    })

    return (
      <React.Fragment>
        <div className="py-4 md:absolute sm:relative xs:relative top-0 right-0 flex z-40 justify-center md:justify-end">
          <ChartButton
            green={this.state.activeFilter !== 'week'}
            title={'Week'}
            onClick={() => {
              this.switchFilter('week')
            }}
          />
          <ChartButton
            green={this.state.activeFilter !== 'maand'}
            title={'Maand'}
            onClick={() => {
              this.switchFilter('maand')
            }}
          />
          <ChartButton
            green={this.state.activeFilter !== 'jaar'}
            title={'Jaar'}
            onClick={() => {
              this.switchFilter('jaar')
            }}
          />
          <ChartButton
            green={this.state.activeFilter !== 'alles'}
            title={'Alles'}
            onClick={() => {
              this.switchFilter('alles')
            }}
          />
        </div>
        <ResponsiveContainer width="100%" height="85%">
          <AreaChart data={data}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#4FD1C5" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#4FD1C5" stopOpacity={0} />
              </linearGradient>
            </defs>
            <YAxis type="number" domain={[0, 'dataMax + 1']} interval={0} ticks={[0, 2, 4, 6, 8, 10]} />
            <XAxis dataKey="datum" interval={0} angle={-45} textAnchor="end" height={75} padding={{ right: 10 }} />
            <Area
              isAnimationActive={false}
              dot={{ stroke: '#34898a', strokeWidth: 10 }}
              type="monotone"
              dataKey="cijfer"
              stroke="#34898a"
              strokeWidth={4}
              fillOpacity={1}
              fill="url(#colorUv)"
            />
            <Tooltip content={<CustomTooltip />} />
          </AreaChart>
        </ResponsiveContainer>
      </React.Fragment>
    )
  }
}

const DashboardCardCijferChart = (props) => {
  return (
    <div className="w-full mb-6 md:mb-0 md:w-3/4 md:mr-6 md:ml-6">
      <div id="DashboardCardCijferChart" className="bg-white relative h-full overflow-hidden md:pt-5 md:pb-5 md:pr-0">
        {props.arrayItems.huidigCijfers?.length ? (
          <WerklevenCijferChart arrayItems={props.arrayItems.huidigCijfers} />
        ) : (
          <span className="italic font-sm text-gray-700 w-full text-center mt-10 block">
            Hier komen je Huidig Werkleven cijfers te staan
          </span>
        )}
      </div>
    </div>
  )
}

DashboardCardCijferChart.propTypes = {
  arrayItems: PropTypes.object,
}

export default DashboardCardCijferChart
