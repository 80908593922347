import React, { Component } from 'react'
import PropTypes from 'prop-types'

class FormFieldListItemButtonVolgendePunt extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return this.props.index + 1 !== this.props.length ? (
      <div className="flex mb-6">
        <button
          className="bg-wa-button-green hover:bg-green-800 px-5 py-1 inline-block font-bold text-white rounded whitespace-no-wrap shadow w-full text-center"
          onClick={this.props.volgendeItem}
        >
          Volgende punt verwerken
        </button>
      </div>
    ) : null
  }
}

FormFieldListItemButtonVolgendePunt.propTypes = {
  index: PropTypes.number,
  length: PropTypes.number,
}

export default FormFieldListItemButtonVolgendePunt
