import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ContainerPage extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <div className="h-full page-container wa-main-bg">{this.props.children}</div>
  }
}

ContainerPage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default ContainerPage
