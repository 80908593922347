import React from 'react'
import { Link } from 'react-router-dom'
import { withAuthentication } from './../../../../components/Session'

import InfoUitlegModule from './../../../../components/InfoUitlegModule'

import MijnWerkleven1 from './../../pages/MijnWerkleven1'
import MijnWerkleven2 from './../../pages/MijnWerkleven2'
import MijnWerkleven2Overzicht from './../../pages/MijnWerkleven2Overzicht'
import MijnWerkleven3 from './../../pages/MijnWerkleven3'
import MijnWerkleven4 from './../../pages/MijnWerkleven4'
import MijnWerkleven5 from './../../pages/MijnWerkleven5'
import TitleQuestionair from '../../../../components/Atoms/Title-Questionair'
import { pages } from '../../../../text.json'

function Sidebar(props) {
  const huidigeOnderdeel = parseInt(props.huidigeOnderdeel)
  const sidebarArray = props.sidebarArray

  // Used to load the instructions on only page /mijn-werkleven/2/1 and /mijn-werkleven/2/2, and exclude /mijn-werkleven/2/3, the overview page
  const sectie2BoolPagina1en2 = props.huidigeOnderdeel === '1' || props.huidigeOnderdeel === '2'

  return (
    <div className="w-full md:w-1/4 mb-6 md:mb-0 bg-wa-green wa-min-height">
      <div className="overflow-hidden">
        <div className="border-gray-300 p-5">
          {/* <h2 className="font-bold text-gray-700 text-2xl">
            {props.moduleTitel}
          </h2> */}
          <h2 className="text-white font-bold text-2xl mb-2">Stappen</h2>
          <ul className="mt-2 text-base">
            {sidebarArray.map((arrayItem, index) => {
              const huidigeNavigatieOnderdeel = index + 1
              if (huidigeOnderdeel === huidigeNavigatieOnderdeel) {
                return (
                  <li key={index} className="text-white mb-2 underline">
                    {/* <span className="mr-1">
                      {huidigeNavigatieOnderdeel}.
                    </span> */}
                    {arrayItem}
                  </li>
                )
              } else {
                const navigationLink = `/mijn-werkleven/${props.huidigeSectie}/${huidigeNavigatieOnderdeel}`
                return (
                  <li key={index} className="mb-2">
                    <Link to={navigationLink} className="text-white cursor-pointer">
                      {/* <span className="mr-1">
                        {huidigeNavigatieOnderdeel}.
                      </span> */}
                      {arrayItem}
                    </Link>
                  </li>
                )
              }
            })}
          </ul>
        </div>
      </div>

      <InfoUitlegModule firstVisit={props.firstVisit} moduleDatabaseValue={props.moduleDatabaseValue}>
        {props.huidigeSectie === '1' ? (
          <div className="text-gray-800">
            <h4 className="font-bold ">Uitleg Doel</h4>
            <p>
              Doelen geven richting aan jouw werkleven. Met welk doel ben jij WorkAppic gestart? Kies een of meerdere
              doelen uit de lijst. Je kan je doelen altijd aanpassen.
            </p>
          </div>
        ) : null}

        {props.huidigeSectie === '2' && sectie2BoolPagina1en2 ? (
          <div className="text-gray-800">
            <h4 className="font-bold ">{pages.stepTwoYourGoals.explanationTitle}</h4>
            <p>{pages.stepTwoYourGoals.explanation}</p>
          </div>
        ) : null}
        {props.huidigeSectie === '3' ? (
          <div className="text-gray-800">
            <h4 className="font-bold ">{pages.stepThreeYourGoals.explanationTitle}</h4>
            <p>{pages.stepThreeYourGoals.explanation}</p>
            <ul className="list-disc  px-5">
              {pages.stepThreeYourGoals.explanationPoints?.map((t) => (
                <li>{t}</li>
              ))}
            </ul>
            <p>{pages.stepThreeYourGoals.explanationTip}</p>
          </div>
        ) : null}

        {props.huidigeSectie === '4' ? (
          <div className="text-gray-800">
            <h4 className="font-bold ">Uitleg Gewenst werkleven</h4>
            <br />
            <p>Hier maak je inzichtelijk hoe je jouw gewenste werkleven vorm kunt geven.</p>
            <ul className="list-disc  px-5">
              <li>Maak een lijstje met wat je niet wilt en wat je wel wilt.</li>
              <li>Elk punt ga je daarna analyseren.</li>
              <li>
                Hoe beter je elk punt analyseert, hoe scherper jouw inzicht in je gewenste werkleven wordt. Je zult
                zien, dit is de easy way to go naar jouw ideale werkleven!
              </li>
            </ul>
          </div>
        ) : null}

        {props.huidigeSectie === '5' ? (
          <div className="text-gray-800">
            <h4 className="font-bold ">Uitleg Mogelijkheden</h4>
            <p className="mb-2">
              Maak inzichtelijk welke mogelijkheden je hebt om jouw gewenste werkleven te realiseren.
            </p>
            <p className="mb-2">
              Op deze pagina staan de mogelijkheden die je in vorige stappen hebt ingevuld. WorkAppic heeft de
              mogelijkheid ‘huidig werkleven blijven doen’ standaard toegevoegd.
            </p>
            <ul className="list-disc px-5">
              <li>Vul jouw lijst met mogelijkheden aan</li>
              <li>Beschrijf wat elke mogelijkheid voor jou inhoudt</li>
              <li>Elke mogelijkheid ga je daarna analyseren.</li>
              <li>
                Hoe beter je elke mogelijkheid analyseert, hoe scherper het perspectief op jouw gewenste werkleven
                wordt.
              </li>
              <li>Je zult zien, dit is de easy way to go naar jouw ideale werkleven.</li>
            </ul>
          </div>
        ) : null}
      </InfoUitlegModule>
    </div>
  )
}

class ModuleContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstVisitLoaded: false,
      firstVisit: false,
    }
    this.setFirstVisitStateUitleg = this.setFirstVisitStateUitleg.bind(this)
  }

  setFirstVisitStateUitleg(firstVisitBoolean) {
    this.setState({
      firstVisitLoaded: true,
      firstVisit: firstVisitBoolean,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.huidigeSectie !== this.props.huidigeSectie) {
      this.setState({
        firstVisitLoaded: false,
      })
    }
  }

  render() {
    const volgendeOnderdeel = this.props.volgendeOnderdeel
    const vorigeOnderdeel = this.props.vorigeOnderdeel
    const huidigeOnderdeel = this.props.huidigeOnderdeel
    const huidigeSectie = this.props.huidigeSectie

    // Sidebar navigation items for the modules
    const sidebarArray1 = ['A. Doel Workappic']
    const sidebarArray2 = ['A. Huidig werkleven', 'B. Gewenst werkleven']
    const sidebarArray3 = [
      'A. Wat doe ik?',
      'B. Vind ik niet leuk - lijstje',
      'C. Vind ik niet leuk - analyseren',
      'D. Vind ik leuk - lijstje',
      'E. Vind ik leuk - analyseren',
    ]
    const sidebarArray4 = [
      'A. Wil ik niet - lijstje',
      'B. Wil ik niet - analyseren',
      'C. Wil ik wel - lijstje',
      'D. Wil ik wel - analyseren',
    ]
    const sidebarArray5 = ['A. Mogelijkheden - lijstje', 'B. Mogelijkheden - analyseren']

    // Set conditional width to hide the sidebar on certain pages
    let mainWidth = ''
    let mainContainer = ''
    if (huidigeSectie === '2' && huidigeOnderdeel === '3') {
      mainWidth = 'w-full'
      mainContainer = 'w-full overflow-hidden'
    } else {
      mainWidth = 'w-full md:w-3/4 '
      mainContainer = 'bg-white w-full min-h-screen overflow-hidden md:px-8 px-4 py-8 pb-16'
    }

    return (
      <React.Fragment>
        {/* Sidebar */}
        {huidigeSectie === '1' ? (
          <Sidebar
            firstVisit={this.state.firstVisit}
            firstVisitLoaded={this.state.firstVisitLoaded}
            huidigeSectie={huidigeSectie}
            huidigeOnderdeel={huidigeOnderdeel}
            moduleTitel="Mijn Huidige Werkleven"
            sidebarArray={sidebarArray1}
            moduleDatabaseValue="werkleven1"
          />
        ) : null}
        {huidigeSectie === '2' && huidigeOnderdeel !== '3' ? (
          <Sidebar
            firstVisit={this.state.firstVisit}
            firstVisitLoaded={this.state.firstVisitLoaded}
            huidigeSectie={huidigeSectie}
            huidigeOnderdeel={huidigeOnderdeel}
            moduleTitel="Mijn Cijfers"
            sidebarArray={sidebarArray2}
            moduleDatabaseValue="werkleven2"
          />
        ) : null}
        {huidigeSectie === '3' ? (
          <Sidebar
            firstVisit={this.state.firstVisit}
            firstVisitLoaded={this.state.firstVisitLoaded}
            huidigeSectie={huidigeSectie}
            huidigeOnderdeel={huidigeOnderdeel}
            moduleTitel="Mijn Huidige Werkleven"
            sidebarArray={sidebarArray3}
            moduleDatabaseValue="werkleven3"
          />
        ) : null}
        {huidigeSectie === '4' ? (
          <Sidebar
            firstVisit={this.state.firstVisit}
            firstVisitLoaded={this.state.firstVisitLoaded}
            huidigeSectie={huidigeSectie}
            huidigeOnderdeel={huidigeOnderdeel}
            moduleTitel="Mijn Gewenste Werkleven"
            sidebarArray={sidebarArray4}
            moduleDatabaseValue="werkleven4"
          />
        ) : null}
        {huidigeSectie === '5' ? (
          <Sidebar
            firstVisit={this.state.firstVisit}
            firstVisitLoaded={this.state.firstVisitLoaded}
            huidigeSectie={huidigeSectie}
            huidigeOnderdeel={huidigeOnderdeel}
            moduleTitel="Mijn Gewenste Werkleven"
            sidebarArray={sidebarArray5}
            moduleDatabaseValue="werkleven5"
          />
        ) : null}

        {/* Main Content */}
        <div className={mainWidth}>
          <div className={mainContainer}>
            {huidigeSectie === '1' ? (
              <MijnWerkleven1
                volgendeOnderdeel={volgendeOnderdeel}
                vorigeOnderdeel={vorigeOnderdeel}
                huidigeOnderdeel={huidigeOnderdeel}
                huidigeSectie={huidigeSectie}
                setFirstVisitStateUitleg={this.setFirstVisitStateUitleg}
              />
            ) : null}
            {huidigeSectie === '2' && (huidigeOnderdeel === '1' || huidigeOnderdeel === '2') ? (
              <MijnWerkleven2
                volgendeOnderdeel={volgendeOnderdeel}
                vorigeOnderdeel={vorigeOnderdeel}
                huidigeOnderdeel={huidigeOnderdeel}
                huidigeSectie={huidigeSectie}
                setFirstVisitStateUitleg={this.setFirstVisitStateUitleg}
              />
            ) : null}
            {huidigeSectie === '2' && huidigeOnderdeel === '3' ? (
              <MijnWerkleven2Overzicht
                volgendeOnderdeel={volgendeOnderdeel}
                vorigeOnderdeel={vorigeOnderdeel}
                huidigeOnderdeel={huidigeOnderdeel}
                huidigeSectie={huidigeSectie}
                setFirstVisitStateUitleg={this.setFirstVisitStateUitleg}
              />
            ) : null}

            {huidigeSectie === '3' ? (
              <MijnWerkleven3
                volgendeOnderdeel={volgendeOnderdeel}
                vorigeOnderdeel={vorigeOnderdeel}
                huidigeOnderdeel={huidigeOnderdeel}
                huidigeSectie={huidigeSectie}
                setFirstVisitStateUitleg={this.setFirstVisitStateUitleg}
              />
            ) : null}

            {huidigeSectie === '4' ? (
              <MijnWerkleven4
                volgendeOnderdeel={volgendeOnderdeel}
                vorigeOnderdeel={vorigeOnderdeel}
                huidigeOnderdeel={huidigeOnderdeel}
                huidigeSectie={huidigeSectie}
                setFirstVisitStateUitleg={this.setFirstVisitStateUitleg}
              />
            ) : null}

            {huidigeSectie === '5' ? (
              <MijnWerkleven5
                volgendeOnderdeel={volgendeOnderdeel}
                vorigeOnderdeel={vorigeOnderdeel}
                huidigeOnderdeel={huidigeOnderdeel}
                huidigeSectie={huidigeSectie}
                setFirstVisitStateUitleg={this.setFirstVisitStateUitleg}
              />
            ) : null}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withAuthentication(ModuleContent)
