import React, { Component } from 'react'

import { withFirebase } from '../Firebase'
import {toast} from "react-toastify";

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
}

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = (event) => {
    const { passwordOne } = this.state
    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
          toast('Het wachtwoord is gewijzigd! 👍')
      })
      .catch((error) => {
        this.setState({ error })
      })

    event.preventDefault()
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { passwordOne, passwordTwo, error } = this.state

    const isInvalid = passwordOne !== passwordTwo || passwordOne === ''

    return (
      <form onSubmit={this.onSubmit} className="mb-8">
        <input
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          placeholder="New Password"
          className="bg-white focus:outline-0 w-full md:w-2/4 focus:shadow-outline border border-gray-300 rounded py-2 px-4 mb-2 block appearance-none leading-normal"
        />
        <input
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          placeholder="Confirm New Password"
          className="bg-white focus:outline-0  w-full md:w-2/4 focus:shadow-outline border border-gray-300 rounded py-2 px-4 mb-2 block appearance-none leading-normal"
        />
        <button
          disabled={isInvalid}
          type="submit"
          className="py-2 px-4 inline-block bg-wa-button-green hover:bg-green-600 text-sm text-white md rounded-full shadow-md"
        >
          Verander mijn wachtwoord
        </button>

        {error && <p>{error.message}</p>}
      </form>
    )
  }
}

export default withFirebase(PasswordChangeForm)
