import React, { useEffect, useCallback } from 'react'
import PrimaryButton from '../Atoms/Primary-Button'
import InputField from '../Atoms/Input-Field'
import PrimaryActionButton from '../Atoms/PrimaryAction-Button'
import TitleGreen from '../Atoms/Titles-Green'
import ButtonSmallGreen from '../Atoms/Button-Small-Green'

const AddActionBar = (props) => {
  const keyPress = (e) => {
    if (e.keyCode == 13) {
      props.addAction()
    }
  }

  return (
    <div className="w-full mb-4">
      <div className="flex md:flex block items-center">
        <InputField
          name={'nieuweActie'}
          value={props.value}
          keyPress={keyPress}
          placeholder={props.placeholder}
          handleOnChange={props.handleOnChange}
        />
        <ButtonSmallGreen title="Voeg actie toe" onClick={props.addAction} isNotCompleted />
      </div>
    </div>
  )
}

export default AddActionBar
