import { flowObjects } from '../utils/flowObjects'

const getDocRef = (firebase, authUser) => {
  return firebase.db.collection('users').doc(authUser.uid)
}

export const getNotificationSettings = (firebase, user) => {
  const docRef = firebase.db.collection('users').doc(user.uid)
  let notifications = false

  return docRef
    .get()
    .then(function (doc) {
      if (doc.data()) {
        notifications = doc.data().notifications === undefined ? true : doc.data().notifications
      }
    })
    .then(function (doc) {
      return docRef
        .set(
          {
            notifications: notifications,
          },
          { merge: true },
        )
        .then(function () {
          return notifications
        })
        .catch(function (error) {
          console.log('Error getting document:', error)
        })
    })
}

export const setNotificationSettings = (firebase, uid, value) => {
  const docRef = firebase.db.collection('users').doc(uid)

  return docRef
    .set(
      {
        notifications: value,
      }
    )
    .then(function () {
      console.log('Notification set to account', value)
    })
    .catch(function (error) {
      console.log('Error getting document:', error)
    })
}
