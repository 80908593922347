import React, { Component } from 'react'
import PropTypes from 'prop-types'

class DashboardContainerCards extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className={`flex ${this.props.outerClassName}`}>
        <div className={`bg-white h-full w-full overflow-hidden ${this.props.className} md:mb-10 mb-4`}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

DashboardContainerCards.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  outerClassName: PropTypes.string,
  className: PropTypes.string,
}

DashboardContainerCards.defaultProps = {
  outerClassName: '',
  className: '',
}

export default DashboardContainerCards
