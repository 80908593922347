import React from 'react'

class MainHeader extends React.Component {
  render() {
    return (
      <div className="w-full block bg-indigo-700 z-0" id="main-header">
        <h1 className="wa-main-title-subpage md:text-6xl text-7xl w-full text-center">{this.props.title}</h1>
      </div>
    )
  }
}

export default MainHeader
