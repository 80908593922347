import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import Possibility from './Possibility'

const ANSWERS_POSSIBLE = ['beschrijving', 'blijvendStructureel', 'toelichting', 'cijfer', ['minpPunten', 'plusPunten']]

const SortablePossibility = (props) => {
  console.log('props', props)
  return (
    <ul className="flex-row justify-center mt-4 mr-4">
      {props.possibilities?.map((p, index) => (
        <Possibility
          index={index}
          listPosition={index + 1}
          key={p.title + '_' + index}
          title={p.title}
          item={p}
          answers={ANSWERS_POSSIBLE}
          changeTitle={props.changeTitle}
          updateTitleItem={props.updateTitleItem}
          editTitleItem={props.editTitleItem}
          type={'mijnWerkMogelijkheden'}
          deleteItem={props.deleteItem}
          fIndex={props.fIndex}
          subModulen={props.subModulen}
        />
      ))}
    </ul>
  )
}

export default SortableContainer(SortablePossibility)
