import React from 'react'
import Editor from './../Editor'
import RadioButton from './../RadioButton'
import SelectLabel from './../SelectLabel'
import { withFirebase } from '../Firebase'
import { toast } from 'react-toastify'
import Tooltip from 'react-tooltip-lite'
import Lightbulb from './../Lightbulb'

import PopUpVoegActieToe from './../PopUpVoegActieToe'
import PopUpVoegMogelijkheidToe from './../PopUpVoegMogelijkheidToe'

import FormFieldListItemIngevuldCounter from './../FormFieldListItemIngevuldCounter'
import FormFieldListItemTitle from './../FormFieldListItemTitle'
import FormFieldListItemChevronToggle from './../FormFieldListItemChevronToggle'
import FormFieldListItemLeukerMakenLabel from './../FormFieldListItemLeukerMakenLabel'
import FormFieldListItemContainer from './../FormFieldListItemContainer'
import FormFieldListItemButtonVolgendePunt from './../FormFieldListItemButtonVolgendePunt'
import HorizontalLineDivider from './../HorizontalLineDivider'
import FormFieldListItemLeukerBeterMaken from './../FormFieldListItemLeukerBeterMaken'

import FormFieldLabel from './../FormFieldLabel'
import FormFieldItemContainer from './../FormFieldItemContainer'
import FormFieldTextArea from './../FormFieldTextArea'

import { ChevronDown, ChevronUp, Plus, X } from 'react-feather'
import NewPossibilities from '../Template/New-Possibilities'
import CurrentWorklife from '../Template/Current-Worklife'
import ProcessNextPoint from '../Organisms/Process-Next-Point'

class ListItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      puntLeukerMaken: false,
      PuntToevoegenAlsActie: false,
    }
    this.openPuntLeukerMaken = this.openPuntLeukerMaken.bind(this)
    this.closeModule = this.closeModule.bind(this)
    this.toggleActieToevoegen = this.toggleActieToevoegen.bind(this)
  }

  openPuntLeukerMaken(index) {
    // this.props.setLeukerMakenBool(index, 'arrayItemsBelangrijk');
    this.setState({
      puntLeukerMaken: true,
    })
  }

  toggleActieToevoegen() {
    this.setState({
      PuntToevoegenAlsActie: !this.state.PuntToevoegenAlsActie,
    })
  }

  closeModule() {
    this.setState({
      puntLeukerMaken: false,
    })
  }

  render() {
    const item = this.props.item

    let totaalIngevuld = 0

    if (item.beschrijving && item.beschrijving.length > 0 && item.beschrijving !== '<p><br></p>') {
      totaalIngevuld += 1
    }
    if (
      item.beschrijving_uitgebreid &&
      item.beschrijving_uitgebreid.length > 0 &&
      item.beschrijving_uitgebreid !== '<p><br></p>'
    ) {
      totaalIngevuld += 1
    }
    if (item.toelichting && item.toelichting.length > 0) {
      totaalIngevuld += 1
    }
    if (item.leukerMaken === true || item.leukerMaken === false) {
      totaalIngevuld += 1
    }
    if (item.nieuweMogelijkheid === true || item.nieuweMogelijkheid === false) {
      totaalIngevuld += 1
    }

    let itemChecked = false
    if (item.leukerMaken === true) {
      itemChecked = true
    }

    return (
      <FormFieldListItemContainer index={this.props.index} isOpen={item.open}>
        <FormFieldListItemChevronToggle
          toggleOpen={() => this.props.toggleOpen(this.props.index)}
          openState={item.open}
        />

        <FormFieldListItemLeukerMakenLabel leukerMakenBool={item.leukerMaken} />

        <FormFieldListItemIngevuldCounter aantalIngevuld={totaalIngevuld} aantalInTeVullen={4} />

        <FormFieldListItemTitle
          toggleOpen={() => this.props.toggleOpen(this.props.index)}
          title={item.title}
          index={this.props.index}
        />

        {item.open === true ? (
          <div className="mt-2">
            <FormFieldItemContainer>
              <FormFieldLabel>Waarom wil je dit niet?</FormFieldLabel>
              <Editor
                index={this.props.index}
                fieldName="beschrijving_uitgebreid"
                setEditorState={this.props.setEditorState}
                initialEditorState={item.beschrijving_uitgebreid}
              />
            </FormFieldItemContainer>

            <CurrentWorklife
              item={item}
              index={this.props.index}
              toggleActieToevoegen={this.toggleActieToevoegen}
              handleChange={this.props.handleChange}
              setLeukerMakenBool={this.props.setLeukerMakenBool}
              name="toelichting"
              itemType={item.toelichting}
              title="Huidige Werkleven"
              question="Is dit van toepassing in jouw huidige werkleven?"
              typeOfGoal="Kan je actie ondernemen zodat je dit leuker/better kan maken in jouw huidige werkleven?"
              typeOfImportant="arrayItemsBelangrijk"
              reversedPositive
            />

            {/* ja helaas wel , nee gelukkig niet als ja dan komt kruisje op dashboard*/}

            <NewPossibilities
              item={item}
              index={this.props.index}
              togglePopUp={this.openPuntLeukerMaken}
              setNieuweMogelijkheidBool={this.props.setNieuweMogelijkheidBool}
              typeOfImportant="arrayItemsBelangrijk"
            />

            {this.props.index + 1 !== this.props.length ? (
              <ProcessNextPoint volgendeItem={this.props.volgendeItem} index={this.props.index} />
            ) : null}
          </div>
        ) : null}
        {this.state.puntLeukerMaken ? (
          <PopUpVoegMogelijkheidToe
            userID={this.props.userID}
            firebase={this.props.firebase}
            title={item.title}
            closeModule={this.closeModule}
          />
        ) : null}
        {this.state.PuntToevoegenAlsActie ? (
          <PopUpVoegActieToe
            userID={this.props.userID}
            firebase={this.props.firebase}
            title={item.title}
            closeModule={this.toggleActieToevoegen}
          />
        ) : null}
      </FormFieldListItemContainer>
    )
  }
}

class ListWerkleven4ToggleableVoorwaarden extends React.Component {
  render() {
    const setLeukerMakenBool = this.props.setLeukerMakenBool
    const setNieuweMogelijkheidBool = this.props.setNieuweMogelijkheidBool
    const arrayItems = this.props.arrayItems
    const removeItem = this.props.removeItem
    const toggleOpen = this.props.toggleOpen
    const volgendeItem = this.props.volgendeItem
    const handleChange = this.props.handleChange
    const setEditorState = this.props.setEditorState
    const userID = this.props.userID
    const firebase = this.props.firebase

    return (
      <div id="main">
        <ol>
          {arrayItems.map((item, index) => {
            return (
              <ListItem
                setLeukerMakenBool={setLeukerMakenBool}
                setNieuweMogelijkheidBool={setNieuweMogelijkheidBool}
                key={index}
                item={item}
                index={index}
                toggleOpen={toggleOpen}
                length={arrayItems.length}
                volgendeItem={volgendeItem}
                handleChange={handleChange}
                setEditorState={setEditorState}
                userID={userID}
                firebase={firebase}
              />
            )
          })}
        </ol>
      </div>
    )
  }
}

export default withFirebase(ListWerkleven4ToggleableVoorwaarden)
