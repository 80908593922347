import React, { useState, useEffect } from 'react'
import { X } from 'react-feather'

const PageExplanation = (props) => {
  return (
    <React.Fragment>
      <div
        onClick={(e) => {
          props.toggle(e)
        }}
        className="bg-white cursor-pointer shadow-xl rounded-lg overflow-hidden"
      >
        <div className="flex p-2 mt-2">
          <h2 className="font-hairline py-2 text-center text-green-600 text-xl border-green-600 border-2 rounded w-full">
            Uitleg
          </h2>
        </div>
      </div>
      {props.firstVisit ? (
        <div>
          <div className="w-screen h-screen z-40 left-0 top-0 bg-black opacity-25 fixed" />
          <div className="flex z-50 justify-center items-center w-screen h-screen top-0 left-0 fixed">
            <div className="max-w-xl bg-white rounded shadow-lg p-8 relative info-uitleg-popup overflow-y-scroll">
              <X
                className="absolute right-0 top-0 mr-2 mt-2 cursor-pointer"
                onClick={(e) => {
                  props.toggle(e)
                }}
              />
              {/* <h3 className="font-bold text-xl text-gray-700 mb-4">
                  Gebruiksaanwijzing
                </h3> */}
              {props.children}
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default PageExplanation
