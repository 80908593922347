import React, { useState, useRef } from 'react'
import countAnswers from '../../../functions/answers'
import { SortableElement } from 'react-sortable-hoc'
import { Move, Trash2 } from 'react-feather'

const Possibility = (props) => {
  const { cijfer } = props.item
  const [amount, total] = countAnswers(props.item, props.answers)
  const arrLength = 1
  const [elRefs, setElRefs] = React.useState([])

  const [inputValue, setInputValue] = useState(props.item.title)

  const handleChange = (e) => {
    setInputValue(e.target.value)
  }

  console.log('cijfer', cijfer)

  React.useEffect(() => {
    // add or remove refs
    setElRefs((elRefs) =>
      Array(arrLength)
        .fill()
        .map((_, i) => elRefs[i]),
    )
  }, [arrLength])

  return (
    <li
      key={props.item.index + '_key'}
      className="draggable-list-item flex items-center text-gray-700 z-20 text-lg border-b relative px-4 py-3 mb-2 relative rounded-full wa-list-container-bg"
    >
      {props.item.editing === false ? <Move className="text-gray-400 inline-block mr-4 relative" /> : null}
      {props.item.editing === false ? (
        <span className="select-none list-item-text inline-block relative text-black cursor-pointer">
          <span
            onClick={() => {
              props.changeTitle(props.item, props.type, props.subModulen)
            }}
          >
            {props.listPosition + 1}. {props.item.title}
          </span>
        </span>
      ) : (
        <input
          type="text"
          autoComplete="off"
          className="edit-array-item-title-input mr-4 border-dashed border-b w-full h-14 pl-2 pr-4"
          value={inputValue}
          onChange={handleChange}
          onKeyDown={(e) => props.updateTitleItem(e, props.item, props.type, props.subModulen)}
        />
      )}
      <div className="flex flex-row items-center">
        {cijfer && (
          <div
            className={`grid place-items-center max-h-button text-xs rounded-lg text-white mr-2 px-5 py-2 ${
              parseInt(cijfer) >= 5.5 ? 'wa-bg-green' : parseInt(cijfer) <= 3 ? 'wa-bg-bordeaux' : 'wa-bg-orange'
            }`}
          >
            {cijfer}
          </div>
        )}
        <div
          className={`flex items-center rounded-full text-base cursor-default right-0 mr-2 text-white px-5 py-2 bg-wa-bordeaux ${
            amount === total ? 'bg-wa-button-green' : 'bg-wa-bordeaux '
          }`}
        >
          {amount}/{total}
        </div>
        <div
          className="mt-1"
          onClick={(e) => {
            props.deleteItem(e, props.item, props.listPosition, props.fIndex, props.type, props.subModulen)
          }}
        >
          <Trash2 className="bin cursor-pointer inline-block relative -mt-1 mr-2 hover:text-red-700" size={20} />
        </div>
      </div>
    </li>
  )
}

export default SortableElement(Possibility)
