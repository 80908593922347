import React from 'react'

import Slider from './../../../../components/Slider'
import Editor from './../../../../components/Editor'
import LoaderEditor from './../../../../components/Loaders/LoaderEditor'
import LoaderSlider from './../../../../components/Loaders/LoaderSlider'
import TitleQuestionair from '../../../../components/Atoms/Title-Questionair'
import SmileySelection from '../../../../components/Template/Smiley-Selection'

const CurrentWorkLife2A = (props) => {
  console.log('props', props)
  return (
    <>
      <TitleQuestionair>A. Huidig werkleven </TitleQuestionair>

      <h3 className="font-bold text-black mb-2">Welk cijfer geef je jouw huidige werkleven?</h3>
      {props.loading === false ? (
        <Slider sliderValue={props.HWSliderValue} onChange={props.handleSliderChange} keyName="HWCijfer" />
      ) : (
        <LoaderSlider />
      )}
      <div className="mt-5">
        <h3 className="font-bold text-black mb-2">Waarom geef je dat cijfer?</h3>
        {props.loading === false ? (
          <Editor
            setEditorState={props.setEditorState}
            fieldName="HWToelichting"
            initialEditorState={props.initialEditorStateHWToelichting}
          />
        ) : (
          <LoaderEditor />
        )}
      </div>
      <SmileySelection
        handleChange={props.handleChange}
        title="Hoe voel je je bij dat cijfer? Kies een smiley."
        smileyType="HWSmiley"
        smiley={props.HWSmiley}
      />
    </>
  )
}

export default CurrentWorkLife2A
