import React, { useState, useEffect } from 'react'

import Tooltip from 'react-tooltip-lite'
import Lightbulb from '../Lightbulb'
import ButtonSmallGreen from '../Atoms/Button-Small-Green'
import TitleGreen from '../Atoms/Titles-Green'
import { toast } from 'react-toastify'
import DatePicker from 'react-date-picker'

const DeadLinePicker = (props) => {
  const [selectedDeadline, setSelectedDeadline] = useState()
  const [buttonName, setButtonName] = useState('Voeg streefdatum toe')
  const [date, setDate] = useState()

  const handleCalendarClose = () => {
    selectedDeadlineDate()
  }

  const selectedDeadlineDate = () => {
    if (date) {
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const year = date.getFullYear()

      const newdate = year + '-' + month + '-' + day

      setSelectedDeadline(newdate)
      props.setActionDeadline({ target: { name: 'deadline', value: newdate } })
      setButtonName('Wijzig de streefdatum')
    } else {
      setSelectedDeadline('')
    }
  }

  const updateWithSelection = (e) => {
    if (typeof selectedDeadline === 'string') {
      props.edit(selectedDeadline)
    } else {
      if (date) {
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const year = date.getFullYear()

        const newdate = year + '-' + month + '-' + day
        props.edit(newdate)
      }
    }
    toast('Streefdatum is toegevoegd')
    setButtonName('Streefdatum')
  }

  useEffect(() => {
    if (props.actionDeadline) {
      setDate(new Date(props.actionDeadline))
      setSelectedDeadline(new Date(props.actionDeadline))
      setButtonName('Streefdatum')
    }
  }, [props.actionDeadline])

  return (
    <div className="flex flex-col md:flex-row w-full md:flex-row md:items-start mb-2 mt-2 md:mt-6">
      <div className="flex flex-row md:flex-row md:items-start mb-1 mt-2 md:mb-2">
        <TitleGreen title="Streefdatum" />
        <span className="mr-8 ml-6">
          <Tooltip
            content={
              <div className="max-w-sm p-4 pl-6">
                <div>{props.iText}</div>
              </div>
            }
            className="inline-block"
          >
            <Lightbulb />
          </Tooltip>
        </span>
      </div>
      <div className="flex flex-col md:flex-row md:justify-between w-full small-input">
        <DatePicker
          selected={date}
          onChange={(date) => setDate(date)}
          onCalendarClose={handleCalendarClose}
          name={'deadline'}
          value={date}
          format={'dd-MM-y'}
          locale={'nl-NL'}
          dayPlaceholder="dd"
          monthPlaceholder="mm"
          yearPlaceholder="yyyy"
          required
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleCalendarClose()
            }
          }}
        />
        <ButtonSmallGreen onClick={updateWithSelection} title={buttonName} isNotCompleted />
      </div>
    </div>
  )
}
export default DeadLinePicker
