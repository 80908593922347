import React, { useState } from 'react'

const ActionlistAddNewAction = (props) => {
  const [title, setTitle] = useState('')

  const onTitleEdit = (e) => {
    if (e.key === 'Enter') {
      props.setNewAction(title)
      setTitle('')
    }
  }

  return (
    <div className="flex mb-6">
      <input
        name="nieuweActie"
        value={title}
        placeholder="Voeg easy een actie toe..."
        className="mr-4 py-2 px-5 md:py-3 md:px-6 w-full wa-text-gray border-b h-14 "
        onChange={(e) => setTitle(e.target.value)}
        onKeyDown={(e) => {
          onTitleEdit(e)
        }}
      />
      <button
        type="submit"
        className="text-xs md:w-full px-3 md:w-auto md:mt-3 sm:mt-0 md:py-2 md:px-5 md:py-3 md:px-6 bg-green-600 hover:bg-green-800 font-bold text-white md:text-base rounded-full shadow-md"
        id="quick-add-button"
        onClick={() => {
          props.setNewAction(title)
        }}
      >
        Toevoegen
      </button>
    </div>
  )
}

export default ActionlistAddNewAction
