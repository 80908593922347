import React from 'react'
import SubtitleQuestionair from '../Atoms/Subtitle-Questionair'

const ProcessNextPoint = (props) => {
  return (
    <React.Fragment>
      <SubtitleQuestionair>Volgende punt</SubtitleQuestionair>
      <button
        className="wg-dark-green-bg hover:bg-green-800 px-5 py-1 inline-block font-bold text-white rounded whitespace-no-wrap shadow w-full text-center mb-5"
        onClick={() => {
          props.volgendeItem(props.index)
        }}
      >
        Volgende punt verwerken
      </button>
    </React.Fragment>
  )
}

export default ProcessNextPoint
