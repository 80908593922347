import React from 'react'
import { isBefore, subDays } from 'date-fns'
import { makeDate } from '../../functions/utils'

const Deadline = ({ date, state, onClick }) => {
  const formatedDate = makeDate(date)
  return (
    <>
      {' '}
      {date ? (
        <div
          className={
            isBefore(date, subDays(new Date(), 1)) && state !== true
              ? 'flex flex-col w-48 px-4 mr-4 text-center justify-center ml-auto wa-bg-bordeaux text-xs md:text-sm rounded-full'
              : 'flex flex-col w-48 px-4 mr-4 text-center justify-center ml-auto wa-bg-green text-xs md:text-sm rounded-full'
          }
          onClick={onClick}
        >
          {formatedDate}
        </div>
      ) : null}
    </>
  )
}

export default Deadline
