import React from 'react'
import radioCheckbox from '../../assets/images/icons/radio_checked.png'
import radioUnCheckbox from '../../assets/images/icons/radio_unChecked.png'

const RadioButton = ({ label, required, name, value, checked, onToggle }) => {
  return (
    <div
      className="flex mr-2 mt-2"
      onClick={(e) => {
        onToggle(value)
      }}
    >
      <label
        className="bg-white text-2xl block font-extrabold shadow pr-2 cursor-pointer hover:shadow-lg transition-short"
        for={name}
      >
        <span className="wa-green-color">{label}</span>
      </label>
      {checked ? (
        <div className="flex items-center">
          <input
            type="radio"
            name={name}
            value={value}
            className="inline-block text-gray-700"
            defaultChecked={checked}
            required
          />
          <img src={radioCheckbox} width={16} height={16} />
        </div>
      ) : (
        <div
          className="flex items-center"
          onClick={(e) => {
            console.log('clicked')
          }}
        >
          <input type="radio" name={name} value={value} className="inline-block" defaultChecked={checked} />
          <img src={radioUnCheckbox} width={16} height={16} />
        </div>
      )}
    </div>
  )
}

export default RadioButton
