import React, { useState, useEffect, useMemo } from 'react'
import { withRouter, NavLink, Link } from 'react-router-dom'
import { withFirebase } from '../../components/Firebase'
import { withAuthentication } from '../../components/Session'

import MainHeader from '../../components/MainHeader'
import ContainerPage from '../../components/ContainerPage'
import ContainerMain from '../../components/ContainerMain'
import ActionlistSidebar from './Actionlist-Sidebar'
import ActionListItem from '../Dashboard/Actionlist/Actionlist-Item'
import GoallistSideBar from './Goallist-Sidebar'
import ActionlistAddNewAction from './Actionlist-AddNewAction'
import { createNewAction, createNewThought } from '../../functions/actionsHelper'
import ButtonSmallGreen from '../../components/Atoms/Button-Small-Green'
import { toast } from 'react-toastify'

const ACTION_TYPES = [
  'Acties zonder datum: koppel een doel en een streefdatum aan de actie.',
  'Acties met datum:',
  'Vandaag',
  'Deze Week',
  'Voltooid',
]

const Actielijst = (props) => {
  const [actions, setActions] = useState([])
  const [goalActions, setGoalActions] = useState([])
  const [goals, setGoals] = useState([])
  const [pageIndex, setPageIndex] = useState(0)
  const [reload, setReload] = useState(false)
  const [newAction, setNewAction] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [firstVisit, setFirstVisit] = useState(false)

  const currentAction = useMemo(() => {
    const [year, month, day] = new Date().toISOString().split('T')[0].split('-')
    const joinActions = [...actions, ...goalActions]

    const withOutDate = {
      index: 0,
      title: 'Acties zonder datum',
      pageTitle: ACTION_TYPES[0],
      actions: joinActions?.filter((a) => !a.deadline && !a.voltooid),
    }

    const withDate = {
      index: 1,
      title: 'Acties met datum',
      pageTitle: ACTION_TYPES[1],
      actions: joinActions?.filter((a) => a.deadline && !a.voltooid),
    }

    const withDateToday = {
      index: 2,
      title: 'Vandaag',
      pageTitle: ACTION_TYPES[2],
      actions: joinActions?.filter(
        (a) =>
          a.deadline?.split('-')[0] === year &&
          a.deadline?.split('-')[1] === month &&
          a.deadline?.split('-')[2] === day &&
          !a.voltooid,
      ),
    }

    const withDateThisWeek = {
      index: 3,
      title: 'Deze week',
      pageTitle: ACTION_TYPES[3],
      actions: joinActions?.filter(
        (a) =>
          a.deadline?.split('-')[0] === year &&
          a.deadline?.split('-')[1] === month &&
          a.deadline?.split('-')[2] <= parseInt(day) + (7 - new Date().getDay()) &&
          a.deadline?.split('-')[2] > parseInt(day) - new Date().getDay() &&
          !a.voltooid,
      ),
    }

    const withCompleted = {
      index: 4,
      title: 'Voltooid',
      pageTitle: ACTION_TYPES[4],
      actions: joinActions?.filter((a) => a.voltooid),
    }

    return [withOutDate, withDate, withDateToday, withDateThisWeek, withCompleted]
  }, [actions, goalActions])

  useEffect(() => {
    if (newAction) {
      const originalIndex = actions.length
      const updatedActionObj = createNewThought(newAction, originalIndex)

      try {
        props.firebase.db
          .collection('users')
          .doc(props.authUser.uid)
          .collection('actielijst')
          .doc('acties')
          .set({ acties: [...actions, updatedActionObj] })
          .then(() => {
            setReload(true)
            toast('Actie is toegevoegd aan Actielijst! 👍')
          })
      } catch (e) {
        console.error('something horrible happened', e.message)
      }
    }
  }, [newAction])

  const firstVisitCheck = (firebase, uid) => {
    const docRef = firebase.collection('users').doc(uid).collection('modulen').doc('firstVisit')

    // API Call
    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          if (doc.data()['actionlist'] === undefined) {
            setFirstVisit(true)
          }
        }
      })
      .catch(function (error) {
        console.log('Error getting document:', error)
      })
  }

  useEffect(() => {
    if (!props.authUser) return null

    const user = props.authUser

    firstVisitCheck(props.firebase.db, user.uid)

    try {
      let docRef = props.firebase.db.collection('users').doc(user.uid).collection('actielijst').doc('acties')

      docRef.get().then(function (doc) {
        if (doc.exists) {
          const allActions = doc.data().acties || []
          setActions([...allActions])
          setReload(false)
        }
      })

      setReload(false)
      let docRefGoals = props.firebase.db.collection('users').doc(user.uid).collection('actielijst').doc('labels')

      docRefGoals.get().then(function (doc) {
        if (doc.exists) {
          const allGoals = doc.data()?.currentLabels
          const actionsInGoals = allGoals.map((g) => g.actions)

          setGoalActions([...actionsInGoals.flat(1)])
          setGoals(doc.data()?.currentLabels)
        }
      })
    } catch (e) {
      console.error('cannot retrieve document', e.message)
    }
    setReload(false)
  }, [reload])

  return (
    <ContainerPage>
      <MainHeader title="6. Actielijst" />
      <ContainerMain>
        <div className="w-full md:w-1/4 mb-6 md:mb-0 bg-wa-green wa-min-height ">
          <ActionlistSidebar
            currentGoals={goals}
            currentAction={currentAction}
            setPageIndex={setPageIndex}
            setReloading={setReload}
          />
          <div className="border-t-2 border-white"></div>
          <GoallistSideBar
            currentGoals={goals}
            reload={setReload}
            firstVisit={firstVisit}
            user={props.authUser}
            firebase={props.firebase.db}
          />
        </div>
        <div className="w-full md:w-3/4 bg-white px-2 py-2 md:px-5 md:py-8">
          {pageIndex === 0 && (
            <ActionlistAddNewAction setNewAction={setNewAction} reload={setReload} isLoading={isLoading} />
          )}
          <h1 className="font-bold text-xl md:text-2xl text-gray-700 mb-4">{currentAction[pageIndex].pageTitle}</h1>
          {!currentAction[pageIndex]?.actions.length && (
            <div className="mt-2 italic text-gray-600">Er zijn nog geen items</div>
          )}
          <div>
            {currentAction[pageIndex]?.actions
              ?.sort((a, b) => {
                const dateA = a.deadline && new Date(a.deadline),
                  dateB = b.deadline && new Date(b.deadline)
                return dateA - dateB
              })
              .map((a, i) => (
                <ActionListItem
                  key={a.actieNaam + i + '_list'}
                  item={a}
                  title={a.actieNaam}
                  completed={a.voltooid}
                  deadline={a.deadline}
                  openModel={() => {}}
                  setActionListItem={() => {}}
                  index={a.index || i}
                  setReloading={setReload}
                  allActions={actions}
                  allGoals={goals}
                />
              ))}
          </div>
          <div className="w-full flex items-center justify-between md:mt-12 md:flex-row md:flex md:justify-end">
            <ButtonSmallGreen
              title="Opslaan"
              onClick={() => {
                toast('Opgeslagen! 👍')
              }}
              isNotCompleted
            />
            <span className="mt-4 mb-4 md:mr-6 md:ml-6">
              <Link to={`/mijn-werkleven/5/1`}>
                <ButtonSmallGreen title="Vorige" isNotCompleted />
              </Link>
            </span>
            <Link to={`/tijdlijn`}>
              <ButtonSmallGreen title="Volgende" isNotCompleted />
            </Link>
          </div>
        </div>
      </ContainerMain>
    </ContainerPage>
  )
}

export default withAuthentication(withRouter(withFirebase(Actielijst)))
