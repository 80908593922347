import React, { useState, useEffect } from 'react'

import { X, AlertTriangle } from 'react-feather'

import {
  getAllActions,
  updateThought,
  createActionObj,
  addAction,
  updateThoughtToAction,
  createNewAction,
  createNewThought,
  updateAction,
} from '../../functions/actionsHelper'

import { getAllGoals } from '../../functions/goalsHelper'
import { withAuthentication } from '../Session'
import { withFirebase } from '../Firebase'
import PrimaryActionButton from '../Atoms/PrimaryAction-Button'
import { toast } from 'react-toastify'
import AddNotes from '../Organisms/Add-Notes'
import DeadLinePicker from '../Organisms/DeadLine-Picker'
import GoalsDropdown from '../Organisms/Goals-Dropdown'
import TitleGreen from '../Atoms/Titles-Green'
import TitleInput from '../Atoms/Title-Input'
import How from '../Template/How'
import EditInputField from '../Molecules/Edit-Input-field'
import ButtonSmallGreen from '../Atoms/Button-Small-Green'
import ToDoLijstTwijfel from '../ToDoLijstTwijfel'
import Modal from '../Template/Modal'

const ActielijstItemPopup = (props) => {
  const { item, index, titleDeadline, deadline, actionDeadline, labels } = props
  const [action, setAction] = useState({})
  const [allActions, setAllActions] = useState([])
  const [newAction, setNewActions] = useState(undefined)
  const [isNew, setIsNew] = useState(false)
  const [selectedGoal, setSelectedGoal] = useState('')
  const [remove, setRemove] = useState(false)
  const [toggleDoubtFlow, setToggleDoubtFlow] = useState(false)
  const [isAddedToGoal, setIsAddedToGoal] = useState(false)

  useEffect(() => {
    getAllActions(props.firebase, props.authUser).then((allActions) => {
      setAllActions(allActions)
    })

    if (item === undefined) {
      let newAction = createNewAction(action)
      setAction(newAction)
      setIsNew(true)
    } else {
      setAction(item)
    }
  }, [item])

  const close = () => {
    let updatedActionObj = {}

    if (remove) {
      const leftovers = allActions?.filter((a) => a.aUUID !== action.aUUID)

      try {
        props.firebase.db
          .collection('users')
          .doc(props.authUser.uid)
          .collection('actielijst')
          .doc('acties')
          .set({ acties: leftovers })
      } catch (e) {
        console.error('something horrible happened', e.message)
      }
    }

    if (!action.UUID) {
      if (props.index === undefined && action.actieNaam) {
        if (action.deadline || action.notes) {
          updatedActionObj = createNewAction(action)
        } else {
          updatedActionObj = createNewThought(action.actieNaam)
        }
        setNewActions(updatedActionObj)

        try {
          props.firebase.db
            .collection('users')
            .doc(props.authUser.uid)
            .collection('actielijst')
            .doc('acties')
            .set({ acties: [...allActions, updatedActionObj] })
        } catch (e) {
          console.error('something horrible happened', e.message)
        }
      } else {
        const findIndex = allActions?.findIndex((a) => a.aUUID === action.aUUID)
        allActions[findIndex] = action
        try {
          props.firebase.db
            .collection('users')
            .doc(props.authUser.uid)
            .collection('actielijst')
            .doc('acties')
            .set({ acties: allActions })
        } catch (e) {
          console.error('something horrible happened', e.message)
        }
      }
    } else {
      getAllGoals(props.firebase, props.authUser).then((g) => {
        const findGoal = g.find((g) => g.UUID === props.item.UUID)
        updateAction(props.firebase, props.authUser, action, findGoal).then((r) => {
          toast('Opgeslagen! 👍')
        })
      })
    }

    toast('Opgeslagen! 👍')

    if (isAddedToGoal) {
      const newActions = props.allActions?.filter((a, i) => a.aUUID !== props.item.aUUID)

      try {
        props.firebase.db
          .collection('users')
          .doc(props.authUser.uid)
          .collection('actielijst')
          .doc('acties')
          .set({ acties: [...newActions] })
          .then(() => {
            toast('Actie is toegevoegd aan Actielijst ! 👍')
            props.setReloading(true)
          })
      } catch (e) {
        console.error('something horrible happened', e.message)
      }
    }
  }

  const updateDeadline = (e) => {
    setAction((prev) => ({ ...prev, deadline: e }))
  }

  const handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name
    setAction((prev) => ({ ...prev, [name]: value }))
  }

  const handleGoalSelection = (goal) => {
    setSelectedGoal(goal.label)
    getAllGoals(props.firebase, props.authUser).then((g) => {
      const findGoal = g.find((g) => g.UUID === goal.UUID)
      const newAction = createActionObj(action.actieNaam, goal.UUID, findGoal.deadline)
      addAction(props.firebase, props.authUser, findGoal, newAction).then(() => {
        setIsAddedToGoal(true)
        toast('Actie toegevoegd aan doel! 👍')
        console.log('allActions', props.allActions)
      })
    })
    //setRemove(true)
  }

  const addNotes = (note) => {
    setAction((prev) => ({ ...prev, notes: note }))
  }

  const handleHoeOfTwijfelChange = (e) => {
    let hoeForm = {}
    let name = e.target.name
    hoeForm[name] = e.target.value
    let value = e.target.value

    setAction((prev) => ({ ...prev, hoeForm: { ...prev.hoeForm, [name]: value } }))
  }

  const submitHoeForm = (e) => {
    let actions = allActions

    const actionIndex = allActions?.findIndex((a) => a.aUUID === action.aUUID)
    actions[actionIndex] = action

    try {
      props.firebase.db
        .collection('users')
        .doc(props.authUser.uid)
        .collection('actielijst')
        .doc('acties')
        .set({ acties: actions })
    } catch (e) {
      console.error('something horrible happened', e.message)
    }
  }

  return (
    <React.Fragment>
      <div className="flex md:mt-0">
        <TitleGreen title="Actie" />
      </div>
      <div className="flex w-full flex-col items-end" />
      <TitleInput
        name="actieNaam"
        handleOnChange={(e) => {
          handleInputChange(e)
        }}
        placeholder={action?.actieNaam || 'Type hier je actie...'}
        value={action?.actieNaam || ''}
      />

      {/* Divider Line */}
      <How
        item={action}
        index={index}
        setModalType={props.setModalType}
        handleHoeOfTwijfelChange={handleHoeOfTwijfelChange}
        submitHoeForm={submitHoeForm}
        setToggleDoubtFlow={setToggleDoubtFlow}
      />

      <div className="w-full">
        <GoalsDropdown
          goals={props.allGoals}
          item={action}
          standardLabel={action?.labels || []}
          index={index}
          handleGoalSelection={handleGoalSelection}
        />
        <div className="flex flex-col">
          <DeadLinePicker
            setActionDeadline={handleInputChange}
            actionDeadline={action?.deadline || ''}
            edit={updateDeadline}
          />
        </div>

        {!deadline && props.action && !props.action?.deadline && (
          <div className="mt-5">
            <AlertTriangle className="inline-block mr-2 relative text-orange-600 icon-position" />
            <span>Datum moet nog worden toegevoegd</span>
          </div>
        )}
      </div>

      {/* Divider Line */}
      <div className="h-1 block w-full border-dashed mt-1 md:mt-2 border-b-2 border-gray-300"></div>

      <AddNotes notes={action?.notes || []} addNotes={addNotes} />

      <div className="flex justify-end mt-2">
        <ButtonSmallGreen
          title="Opslaan"
          onClick={() => {
            close(false)
          }}
        />
      </div>
      {toggleDoubtFlow && (
        <Modal
          onClose={(e) => {
            setToggleDoubtFlow(false)
          }}
        >
          <ToDoLijstTwijfel
            addLabelFromTwijfelFlow={props.addLabelFromTwijfelFlow}
            voegNieuweTwijfelToeVanuitTwijfelFlow={props.voegNieuweTwijfelToeVanuitTwijfelFlow}
            authUserID={props.authUserID}
            closePopup={setToggleDoubtFlow}
          />
        </Modal>
      )}
    </React.Fragment>
  )
}

export default withAuthentication(withFirebase(ActielijstItemPopup))
