import React, { useState, useEffect } from 'react'
import TimelineGrid from './TimelineGrid'
import ContainerMain from '../../components/ContainerMain'
import TimelineExplanation from './TimelineExplanation'
import PopUpVoegDoelToe from '../../components/PopUpVoegDoelToe/PopUpVoegDoelToe'
import Stepper from '../../components/Template/Stepper'
import ContainerPage from '../../components/ContainerPage'
import { ExternalLink } from 'react-feather'
import TimelineSelect from './TimelineSelectTime'
import InfoUitlegModule from '../../components/InfoUitlegModule'
import { pages } from '../../text.json'
import Modal from '../../components/Template/Modal'
import { useHistory } from 'react-router-dom'

//className="pb-6 mb-6 border-b-2 border-gray-300 border-dashed"
const divStyle = {
  minHeight: '450px',
  backgroundColor: '#fff',
  display: 'grid',
  gridTemplateRows: 'repeat(12, [row], minmax(37px, auto))',
  gridGap: '0px',
  gridTemplateColumns: '1fr 48px 1fr',
}

const line = {
  height: '100%',
  width: window.innerWidth >= 500 ? '14px' : '2px',
  backgroundColor: '#fff',
  gridColumn: '2',
  gridRowStart: '1',
  gridRowEnd: '60',
  margin: '0 auto',
}

const TimelineContainer = ({
  goals,
  setLoading,
  setSelectedAmountOfMonths,
  zoomedIn,
  setZoomedIn,
  firstVisit,
  loading,
  navOpen,
  toggleNav,
  goalWork,
  selectOneMonth,
  goalPrivate,
  totalMonths,
  setTotalMonths,
  selectedMonthName,
  setSelectedMonthName,
  isDashboard,
  readInfo,
}) => {
  const future = new Date()
  future.setMonth(future.getMonth() + 12)
  const history = useHistory()

  const [closePopUpGoalDetails, setClosePopUpGoalDetails] = useState(false)

  const [explanation, setExplanation] = useState(false)
  const [selectedType, setSelectedType] = useState('')
  const [scrollPosition, setScrollPosition] = useState(0)
  const [monthsWork, setMonthsWork] = useState(0)
  const [monthsStart, setMonthsStart] = useState(0)
  const [amountOfMonths, setAmountOfMonths] = useState(totalMonths)
  const [amountOfYears, setAmountOfYears] = useState([
    {
      row: 1,
      date: new Date(),
    },
    {
      row: 11,
      date: future,
    },
  ])
  const selectableTimeSpan = [-60, -24, -12, 12, 24, 60]

  const formatDate = (specificDate) => {
    const month = specificDate.getMonth()
    const day = String(specificDate.getDate()).padStart(2, '0')
    const year = specificDate.getFullYear()
    return day + ' ' + month + ' ' + year
  }

  const constructDate = (totalMonths) => {
    const dateToday = new Date()
    dateToday.setMonth(dateToday.getMonth() + totalMonths)
    return dateToday
  }

  const getDateMonthsFromNow = () => {
    const today = new Date()
    today.setMonth(today.getMonth() + monthsWork)
    return formatDate(today)
  }

  const formatDateToday = () => {
    const currentDate = new Date()
    //currentDate.setMonth(currentDate.getMonth() + );
    return formatDate(currentDate)
  }

  const construcRows = (amountOfMonths, futureDate) => {
    console.log('amountOfMonths', amountOfMonths)
    let tmp = [
      {
        row: 1,
        date: new Date(),
      },
    ]
    if (amountOfMonths < 12) {
      tmp.push({ row: 2, date: futureDate })
    } else {
      for (let i = 12; i <= amountOfMonths; i += 12) {
        tmp.push({
          row: i,
          date: constructDate(i),
        })
      }
    }
    return tmp
  }

  const selectTimeSpan = (amountOfMonths) => {
    setAmountOfYears([])
    if (amountOfMonths > 12) {
      setMonthsStart(amountOfMonths - 12)
      formatDateToday()
    } else {
      setMonthsStart(amountOfMonths)
      setMonthsWork(amountOfMonths)
    }

    setTotalMonths(amountOfMonths)
    setSelectedMonthName('')

    const futureDate = new Date()
    futureDate.setMonth(futureDate.getMonth() + amountOfMonths)

    //We store it first before setting it to state duo to sync
    const tmp = construcRows(amountOfMonths, futureDate)

    setAmountOfYears((prev) => [...prev, ...tmp])
    setAmountOfMonths(amountOfMonths)
    line.gridRowEnd = amountOfMonths + 1
    setSelectedAmountOfMonths(amountOfMonths)
    setLoading(true)
  }

  const handleScroll = (e) => {
    const scrollY = e.target.scrollTop
    const amountOfMonths = scrollY / 30

    if (amountOfMonths > 1) {
      setMonthsWork(amountOfMonths)
      setMonthsStart(12 - Math.round(amountOfMonths))
    }
    setScrollPosition(scrollY)
  }

  const addGoalByType = (e, typeOfgoal) => {
    e.preventDefault()
    //Quick way to block from dashboard add action
    if (!isDashboard) {
      setClosePopUpGoalDetails((prev) => !prev)
      setSelectedType(typeOfgoal)
    } else {
      history.push('/tijdlijn')
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, true)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <React.Fragment>
      <ContainerMain isDashboard>
        {closePopUpGoalDetails ? (
          <Modal
            onClose={() => {
              setClosePopUpGoalDetails((prev) => !prev)
            }}
          >
            <PopUpVoegDoelToe
              goals={goals}
              goalWork={goalWork}
              goalPrivate={goalPrivate}
              setLoading={setLoading}
              closeModule={() => {
                setClosePopUpGoalDetails((prev) => !prev)
              }}
              selectedType={selectedType}
              displayNotes={true}
              buttonTitle={'Maak doel aan'}
              choiceType={true}
            />
          </Modal>
        ) : null}

        <div className={'bg-white w-full overflow-hidden pt-0 px-0'}>
          <div className="absolute md:mt-8 z-40">
            <span>
              <InfoUitlegModule firstVisit={firstVisit} readInfo={readInfo}>
                <div className="text-gray-800">
                  <h4 className="font-bold">{pages.timeline.explanationTitle}</h4>
                  <React.Fragment>
                    <p className="mt-2">{pages.timeline.explanation}</p>
                    <ul className="list-disc">
                      {pages.timeline.explanationPoints?.map((t, i) => (
                        <li key={t + i}>{t}</li>
                      ))}
                    </ul>
                  </React.Fragment>
                </div>
              </InfoUitlegModule>
            </span>
          </div>
          <div className="flex-column md:flex mb-6 px-2 md:px-8">
            <TimelineGrid
              title={'Werk'}
              divStyle={divStyle}
              line={line}
              years={amountOfYears}
              countMonths={monthsWork}
              goals={goals}
              goalWork={goalWork}
              goalPrivate={goalPrivate}
              months={getDateMonthsFromNow()}
              dateToday={formatDateToday()}
              onScroll={() => {
                handleScroll()
              }}
              selectableTimeSpan={selectableTimeSpan}
              selectTimeSpan={selectTimeSpan}
              setLoading={setLoading}
              amountOfMonths={totalMonths}
              selectOneMonth={selectOneMonth}
              selectedMonthName={selectedMonthName}
              setClosePopUpGoalDetails={setClosePopUpGoalDetails}
              setSelectedAmountOfMonths={setSelectedAmountOfMonths}
              zoomedIn={zoomedIn}
              setZoomedIn={setZoomedIn}
              addGoalByType={addGoalByType}
              loading={loading}
              isDashboard={isDashboard}
              firstVisit={firstVisit}
            />
            <div
              className="absolute"
              style={{
                right: '12rem',
                top: !isDashboard ? '2.5rem' : '',
              }}
            >
              <TimelineSelect
                title={'Tijdlijn Toekomst'}
                selectableTimeSpan={selectableTimeSpan}
                selectTimeSpan={selectTimeSpan}
                isPast={false}
                setLoading={setLoading}
                zoomedIn={zoomedIn}
                setZoomedIn={setZoomedIn}
                isDashboard={isDashboard}
              />
            </div>
          </div>
          {!isDashboard && (
            <div className="w-full flex flex-row items-center">
              <div className="w-1/4"></div>
              <Stepper canSave={true} isLoading={false} previous={`/to-do-lijst`} next={'/'} nextTitle={'Voltooien'} />
            </div>
          )}
        </div>
      </ContainerMain>
    </React.Fragment>
  )
}

export default TimelineContainer
