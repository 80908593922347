import React, { useState, useEffect } from 'react'
import { ChevronDown, ChevronUp, Plus } from 'react-feather'
import ActielijstHoeForm from '../ActielijstHoeForm'

const How = (props) => {
  const [toggleHow, setToggleHow] = useState(false)

  useEffect(() => {
    console.log('props', props.item)
  }, [props.item])

  return (
    <>
      <div className="h-1 block w-full border-dashed mt-6 mt-2 border-b-2 border-gray-300"></div>
      <div className="mb-6 border-b-2 border-gray-300 border-dashed w-full flex justify-start items-center">
        <div className="inline-block flex justify-center items-middle">
          <div
            className="my-1 md:my-4 cursor-pointer mr-40 hover:bg-blue-500 hover:text-white rounded px-5 py-1 border-blue-500 border font-bold wa-blue-color"
            data-index={props.index}
            data-name="hoeForm"
            onClick={() => {
              setToggleHow((prev) => !prev)
            }}
          >
            {toggleHow ? (
              <ChevronUp className="pointer-events-none inline-block relative -mt-1 mr-1" />
            ) : (
              <ChevronDown className="pointer-events-none inline-block relative -mt-1 mr-1" />
            )}
            Hoe
          </div>
        </div>
        <div className="inline-block flex justify-center items-middle"></div>
      </div>

      {toggleHow && (
        <ActielijstHoeForm
          item={props.item}
          index={props.index}
          handleHoeOfTwijfelChange={props.handleHoeOfTwijfelChange}
          submitHoeForm={props.submitHoeForm}
        />
      )}
    </>
  )
}

export default How
