import app from 'firebase/app'
import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/firebase-storage'

import { config_dev, config } from '../../credentials'

class Firebase {
  constructor() {
    app.initializeApp(config)
    /* Helper */
    this.fieldValue = app.firestore.FieldValue
    this.emailAuthProvider = app.auth.EmailAuthProvider

    /* Firebase APIs */
    this.auth = app.auth()
    this.db = app.firestore()
    // ADD THESE LINES
    if (window.location.hostname === 'localhost') {
      console.log('localhost detected!')
      app.functions().useEmulator('localhost', 5001)
      app.auth().useEmulator('http://localhost:9099/')
      this.db.useEmulator('localhost', 8085)
    }

    this.storage = app.storage()

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider()
    this.facebookProvider = new app.auth.FacebookAuthProvider()
    this.twitterProvider = new app.auth.TwitterAuthProvider()
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password)

  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password)

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider)

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider)

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider)

  doSignOut = () => this.auth.signOut()

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email)

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    })

  doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password)

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data()

            // // default empty roles
            // if (!dbUser.roles) {
            //   dbUser.roles = {};
            // }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            }

            next(authUser)
          })
      } else {
        fallback()
      }
    })

  // *** User API ***

  user = (uid) => this.db.doc(`users/${uid}`)

  users = () => this.db.collection('users')

  // *** Message API ***

  message = (uid) => this.db.doc(`messages/${uid}`)

  messages = () => this.db.collection('messages')
}

export default Firebase
