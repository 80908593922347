export const initialState = {
  thoughts: [],
  actions: [],
  goals: [],
}

const reducer = (state, action) => {
  console.log('reducer', action)
  switch (action.type) {
    case 'ADD_THOUGHT':
      return {
        ...state,
        thoughts: [...state.thoughts, action.thought],
      }
    case 'REMOVE_THOUGHT':
      return {
        ...state,
        thoughts: state.thoughts.filter((th) => th.id !== action.id),
      }
    case 'ADD_ACTION':
      return {
        ...state,
        actions: [...state.actions, action.action],
      }
    case 'SET_GOALS':
      return {
        ...state,
        goals: [...state.goals, action.goals],
      }
    default:
      return state
  }
}

export default reducer
