export const makeLabelSlug = (val) => {
  /* eslint-disable */
  return val
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

const monthsOfTheYear = ['Jan', 'Feb', 'Mrt', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']

export const makeDate = (givenDate) => {
  //jaar maand en dag
  if (givenDate) {
    const destructedDate = givenDate.split('-')
    //const newDateFormat = new Date();
    //newDateFormat.setFullYear(destructedDate[0], parseInt(destructedDate[1]), destructedDate[2]);
    const newDateFormat =
      destructedDate[2] + ' ' + monthsOfTheYear[parseInt(destructedDate[1]) - 1] + ' ' + destructedDate[0]
    return newDateFormat
  }
}

export const noFormatDateToday = () => {
  const today = new Date()
  const day = today.getDate()
  const month = today.getMonth() + 1
  const year = today.getFullYear()
  let adjustMonth = month < 10 ? '0' + month : month
  const format = year + '-' + adjustMonth + '-' + day
  return format
}

export const makeDateObj = (givenDate) => {
  const newDateFormat = new Date()
  if (givenDate) {
    let newDate = givenDate + ' '
    const destructedDate = newDate.split('-')
    newDateFormat.setFullYear(parseInt(destructedDate[0]), parseInt(destructedDate[1]) - 1, parseInt(destructedDate[2]))
  }
  return newDateFormat
}

export const dayAndMonth = (givenDate) => {
  let dayAndMonth = ''
  if (givenDate) {
    let newDate = givenDate + ' '
    const destructedDate = newDate.split('-')
    dayAndMonth = destructedDate[2] + ' ' + monthsOfTheYear[parseInt(destructedDate[1]) - 1]
  }
  return dayAndMonth
}

export const makeDateToday = () => {
  const newDateFormat = new Date()
  const day = newDateFormat.getDate()
  const month = newDateFormat.getMonth()
  const year = newDateFormat.getFullYear()
  const writtenMonth = monthsOfTheYear[parseInt(month)]
  return day + ' ' + writtenMonth + ' ' + year
}

export const goalStyling = (state) => {
  //console.log('state', state)
  switch (state) {
    case 'active':
      return 'text-' + 'black' + '-600'
    case 'failed':
      return 'text-' + 'red' + '-600'
    case 'done':
      return 'line-through text-' + 'green' + '-600'
    case 'passed':
      return 'line-through text-' + 'gray' + '-600'
  }
}

export const getMonth = (deadline) => {
  const destructedDate = deadline.split('-')
  return parseInt(destructedDate[1])
}

// [{
//     5: value1,
//     key2: value2
// }]

export const day = (givenDate) => {
  let destructedDate = 0
  if (givenDate) {
    destructedDate = givenDate.split('-')
  }
  return parseInt(destructedDate[2])
}

const yearToNumber = (givenDate) => {
  let destructedDate = 0
  if (givenDate) {
    destructedDate = givenDate.split('-')
  }
  const total = destructedDate[0] + '' + destructedDate[1] + '' + destructedDate[2]
  return parseInt(total)
}

export const sortActions = (actions) => {
  if (actions.length > 1) {
    actions.sort((a, b) => (yearToNumber(a.deadline) > yearToNumber(b.deadline) ? 1 : -1))
  }
  return actions
}

export const sortByDay = (setOfMonths) => {
  if (setOfMonths.length > 1) {
    setOfMonths.sort((a, b) => (day(a.deadline) > day(b.deadline) ? 1 : -1))
  }

  return setOfMonths
}

export const checkNextMonth = (goals, monthNumber, currentRowPosition, monthCount, zoomedIn, selectedMonthNumber) => {
  let totalCollection = []
  let collectedByMonth = []

  for (let i = 0; i < goals.length; i++) {
    if (zoomedIn) {
      if (goals[i].rowPosition === selectedMonthNumber) {
        collectedByMonth.push(goals[i])
      }
    } else {
      if (goals[i].rowPosition === monthNumber && monthNumber <= monthCount) {
        //console.log(goals[i].labelNaam)
        collectedByMonth.push(goals[i])
      }
    }
  }
  let sortedGoals = sortByDay(collectedByMonth)

  return sortedGoals
}

export const checkGoalsOnMonth = (goals, monthCount, zoomedIn, selectedMonthNumber) => {
  let allMonths = []
  let currentRowPosition = 0
  let prevRowPosition = 0

  if (goals.length > 0) {
    for (let i = 0; i < goals.length; i++) {
      let collectedMonths = []
      if (prevRowPosition != goals[i].rowPosition) {
        collectedMonths = checkNextMonth(
          goals,
          goals[i].rowPosition,
          currentRowPosition,
          monthCount,
          zoomedIn,
          selectedMonthNumber,
        )
      }
      prevRowPosition = goals[i].rowPosition
      if (collectedMonths.length > 0) {
        allMonths.push(collectedMonths)
      }
    }
  }
  /*if (allMonths.length >= 1){
      sortByDay(allMonths)
    }*/

  return allMonths
}
