import React from 'react'
import { Link } from 'react-router-dom'
import DashboardContainerCards from '../../components/DashboardContainerCards'
import TextHeadingTwo from '../../components/TextHeadingTwo'
import DashboardVerticalColumnContainer from '../../components/DashboardVerticalColumnContainer'
import SortableList from '../../components/Organisms/SortableContainer'
import LikeNoLike from './WorkLife/like-no-like'

const ANSWERS_NOT_NICE = ['blijvendStructureel', 'leukerMaken', 'toelichting']
const ANSWERS_NICE = ['beschrijving', 'open']

const DashboardSectionCurrentWorkLife = (props) => {
  const { LeukePunten, minderLeukePunten } = props.currentWorkLife || []

  const onSortEndOne = ({ oldIndex, newIndex }) => {
    props.sortListItems(oldIndex, newIndex, 'huidigWerkleven', 'minderLeukePunten')
  }

  const onSortEndTwo = ({ oldIndex, newIndex }) => {
    props.sortListItems(oldIndex, newIndex, 'huidigWerkleven', 'LeukePunten')
  }

  return (
    <DashboardContainerCards>
      <Link to={'/mijn-werkleven/3/1'}>
        <TextHeadingTwo textCenter={true}>3 | Huidig Werkleven</TextHeadingTwo>
      </Link>
      <DashboardVerticalColumnContainer>
        <div className="flex flex-col md:flex-row w-full">
          <LikeNoLike
            title="Vind ik niet leuk"
            amount={props.total.minderLeukePunten}
            showAll={() => props.showAll('huidigWerkleven', 'minderLeukePunten')}
            displayAll={minderLeukePunten?.length >= props.total.minderLeukePunten}
            add={() => {}}
            goTo={'/mijn-werkleven/3/2'}
          >
            <SortableList
              distance={2}
              items={minderLeukePunten}
              removeItem={props.deleteItem}
              editTitleItem={props.editTitleItem}
              changeTitle={props.changeTitle}
              toggleEditItem={() => {}}
              answers={ANSWERS_NOT_NICE}
              onSortEnd={onSortEndOne}
              amountQuestions={3}
              updateTitleItem={props.updateTitleItem}
              loseFocus={props.loseFocus}
              type={'minderLeukePunten'}
              subModulen={'huidigWerkleven'}
              fIndex={2}
            />
          </LikeNoLike>
          <LikeNoLike
            title="Vind ik leuk"
            amount={props.total.LeukePunten}
            showAll={() => props.showAll('huidigWerkleven', 'LeukePunten')}
            displayAll={LeukePunten?.length >= props.total.LeukePunten}
            add={() => {}}
            like
            goTo={'/mijn-werkleven/3/4'}
          >
            <SortableList
              distance={2}
              items={LeukePunten}
              removeItem={props.deleteItem}
              editTitleItem={props.editTitleItem}
              changeTitle={props.changeTitle}
              toggleEditItem={() => {}}
              answers={ANSWERS_NICE}
              onSortEnd={(e) => {
                onSortEndTwo(e, 'test')
              }}
              amountQuestions={2}
              updateTitleItem={props.updateTitleItem}
              loseFocus={props.loseFocus}
              type={'LeukePunten'}
              subModulen={'huidigWerkleven'}
              fIndex={2}
            />
          </LikeNoLike>
        </div>
      </DashboardVerticalColumnContainer>
    </DashboardContainerCards>
  )
}

export default DashboardSectionCurrentWorkLife
