import React from 'react'
import radioCheckbox from '../../assets/images/icons/radio_checked.png'
import radioUnCheckbox from '../../assets/images/icons/radio_unChecked.png'

function RadioButton(props) {
  return (
    <>
      <label className="flex items-center bg-white block shadow rounded px-2 md:px-4 md:py-2 cursor-pointer hover:shadow-lg transition-short">
        {props.checked ? (
          <>
            <input
              type="radio"
              name={props.name}
              value={props.value}
              className="inline-block mr-2"
              checked={props.checked}
              required
            />
            <img src={radioCheckbox} width={24} height={24} className="mr-1" />
          </>
        ) : (
          <>
            <input
              type="radio"
              name={props.name}
              value={props.value}
              className="inline-block mr-2"
              checked={props.checked}
            />
            <img src={radioUnCheckbox} width={24} height={24} className="mr-1" />
          </>
        )}
        {props.labelValue ? props.labelValue : <img src={props.smiley} height={24} width={24} />}
        <div className="ml-2">{props.labelText}</div>
      </label>
    </>
  )
}

export default RadioButton
