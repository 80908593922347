import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MainHeader from './../../components/MainHeader'
import { Package } from 'react-feather'

function Divider() {
  return <div className="h-1 block w-full mb-2 mt-2 border-b-2 border-gray-300" />
}

// Simple component containing a shitload of text
// This component contains the Privacy policy
class VoorwaardenPrivacy extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className="min-h-full pb-16 page-container">
        <MainHeader title="Privacy Reglement WorkAppic" />
        <div className="flex">
          <div className="mx-auto container p-6 pt-12 text-gray-700">
            <h1 className="font-bold text-xl md:text-2xl text-gray-700">Privacy Reglement WorkAppic</h1>
            <Divider />
            <p className="mb-2">
              WorkAppic is opgezet door de ondernemers Glenn van den Berg en Nienke van der Zanden - van den Berg van
              het bedrijf Mens & Werkplezier.
            </p>
            <p className="mb-2">
              WorkAppic verwerkt privacygevoelige gegevens oftewel persoonsgegevens van bezoekers aan de website
              www.workappic.com.
            </p>
            <p className="mb-2">
              Glenn en Nienke zijn de verantwoordelijke voor de gegevensverwerking van WorkAppic. Wij vinden een
              zorgvuldige omgang met persoonsgegevens en daarmee het bewaken van jouw privacy heel belangrijk.
              Persoonlijke gegevens worden door ons dan ook zorgvuldig verwerkt en beveiligd. Graag brengen wij jou op
              de hoogte van welke persoonlijke data wij verzamelen en verwerken en waarom. Wij raden je aan om deze
              informatie zorgvuldig door te lezen zodat je begrijpt hoe wij jouw persoonsgegevens verwerken en wat jouw
              rechten zijn.
            </p>
            <p className="mb-2">
              Bij onze verwerking houden wij ons aan de eisen die de Algemene Verordening Gegevensbescherming (AVG)
              stelt. Dat betekent onder andere dat wij:
            </p>
            <ul className="list-disc pl-5">
              <li>
                duidelijk vermelden met welke doeleinden wij persoonsgegevens verwerken. Dat doen wij via deze
                privacyverklaring;
              </li>
              <li>
                onze verzameling van persoonsgegevens beperken tot alleen de persoonsgegevens die nodig zijn voor
                legitieme doeleinden;
              </li>
              <li>
                jou eerst vragen om uitdrukkelijke toestemming om jouw persoonsgegevens te verwerken in gevallen waarin
                jouw toestemming is vereist;
              </li>
              <li>
                passende beveiligingsmaatregelen nemen om jouw persoonsgegevens te beschermen en dat ook eisen van
                partijen die in onze opdracht persoonsgegevens verwerken;
              </li>
              <li>
                je recht respecteren om jouw persoonsgegevens op je aanvraag ter inzage te bieden, te corrigeren of te
                verwijderen.Deze privacyverklaring is voor het laatst aangepast op 11-12-2019.{' '}
              </li>
            </ul>
            <h2 className="text-lg font-bold mb-2 mt-5">Gebruik van persoonsgegevens</h2>
            <p className="mb-2">
              Door het gebruiken van onze dienst via de website laat je bepaalde gegevens bij ons achter. Dat kunnen
              persoonsgegevens zijn. Wij bewaren en gebruiken uitsluitend de persoonsgegevens die rechtstreeks door jou
              worden opgegeven, in het kader van de door jou gevraagde dienst, of waarvan bij opgave duidelijk is dat ze
              aan ons worden verstrekt om te verwerken. Wij zullen de persoonsgegevens niet voor andere doeleinden
              gebruiken, tenzij jij daar vooraf toestemming voor hebt gegeven, of wij dit op grond van de wet mogen of
              moeten doen. Als je onze dienst gebruikt verzamelen wij bepaalde informatie, zelfs als je geen vragen
              beantwoordt of vraag indient. Welke informatie dat is lees je hieronder.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">Bezoek aan het online platform</h2>
            <p className="mb-2">
              Wij verzamelen data die door de internet browser van bezoekers aan het online platform wordt verstuurd,
              maar ook informatie over het zoekgedrag. Deze informatie omvat onder andere een IP-adres, welke browser
              wordt gebruikt, welk besturingssysteem wordt gebruikt, zoekgedrag zoals de datum, tijd en duur van het
              bezoek aan het platform, toegang tot het platform (direct of door een link of via een zoekmachine), de
              informatie die wordt bekeken en de zoektermen die worden gebruikt. Deze informatie gebruiken wij om onze
              navigatie te vereenvoudigen en onze website te verbeteren.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">In jouw account</h2>
            <p className="mb-2">
              Om ervoor te zorgen dat jij de app kunt gebruiken, vragen we je om een account aan te maken. Daarvoor moet
              je een e-mailadres opgeven, je naam en een zelf bedacht wachtwoord aanmaken. Je kunt ook inloggen met je
              account van Google. Wij zullen in het laatste geval alleen de benodigde informatie van Google ontvangen,
              zoals je gebruikersnaam en je e-mailadres. Deze informatie is nodig om vast te stellen dat jouw identiteit
              klopt en om onze dienst te kunnen uitvoeren.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">Doeleinden van verwerking</h2>
            <p className="mb-2">Wij verwerken jouw persoonsgegevens in het algemeen voor de volgende doeleinden:</p>
            <ul className="list-disc pl-5">
              <li>het verlenen van toegang tot www.workappic.com; </li>
              <li>het gebruik maken van de functionaliteiten op www.workappic.com; </li>
              <li>het verbeteren van onze diensten;</li>
              <li>om contact met jou op te nemen indien je hierom hebt verzocht;</li>
              <li>het afhandelen van klachten;</li>
              <li>
                het opbouwen van een algoritme dat zorgt voor aanbod van content die specifiek voor jou van toepassing
                is.
              </li>
              <li>
                het doen van marketingactiviteiten ten behoeve van onze dienstverlening, zoals het versturen van een
                nieuwsbrief of notificatie, echter uitsluitend na jouw toestemming;
              </li>
              <li>
                het opmaken van anonieme statistieken (dus jouw gebruik van de app zonder dat jouw persoonsgegevens
                daaraan zijn gekoppeld) die we gebruiken voor doelgroep-en marktanalyse en het optimaliseren van
                WorkAppic.
              </li>
              <li>om mogelijke extra diensten en voordelen aan te bieden die voor jou interessant kunnen zijn.</li>
              <li>om onwettig en/of ongewenst gebruik van de website tegen te gaan.</li>
            </ul>
            <h2 className="text-lg font-bold mb-2 mt-5">Bewaartermijnen</h2>
            <p className="mb-2">
              Wij bewaren je gegevens niet langer dan noodzakelijk en wettelijk toegestaan is om de in deze
              privacyverklaring genoemde doelen te bereiken.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">Contactformulier of e-mail</h2>
            <p className="mb-2">
              Als je een contactformulier op de website invult, of ons een e-mail stuurt, dan gebruiken we jouw gegevens
              alleen om contact met je op te nemen en om jouw vraag of klacht af te handelen. Deze correspondentie zal
              mogelijk worden bewaard in ons data systeem voor eventuele latere referentie of opvolging, maar niet
              langer dan noodzakelijk.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">Publicatie</h2>
            <p className="mb-2">
              Wij publiceren jouw gegevens nooit zonder jouw toestemming. Als je een vraag wordt gesteld, kunnen wij jou
              vragen of deze vraag en antwoord gepubliceerd mogen worden op de website of de app. Dit gebeurt alleen als
              je hiervoor toestemming geeft. Deze toestemming kan je altijd weer intrekken. Neem hiervoor contact met
              ons op via het contactformulier op de website www.worklifedifferently.com of via de contactgegevens
              onderaan deze verklaring.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">Advertenties</h2>
            <p className="mb-2">
              Onze website of app vertoont generieke informatie van bepaalde content die als tip of challenge passen
              binnen de aangeboden thema’s, waarbij geen persoonsgegevens worden verzameld. Wij bouwen nadrukkelijk geen
              profiel van je op, om op die manier persoonlijke advertenties aan je te tonen.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">Cookies</h2>
            <p className="mb-2">
              Net als andere websites, gebruiken wij een standaard technologie ‘cookies’ genaamd (zie de uitleg
              hieronder ‘Wat is een cookie’) en een webserver log om informatie te verzamelen over hoe WorkAppic wordt
              gebruikt. Informatie die door cookies kan worden verzameld, kan zijn de datum en tijd van bezoek aan
              WorkAppic, de pagina’s die werden bezocht, de tijd die werd doorgebracht op WorkAppic en de website die
              vlak voor en na het bezoek aan WorkAppic werden bezocht
            </p>
            <p className="mb-2">
              Terwijl je op WorkAppic actief bent, worden cookies gebruikt om jou te kunnen onderscheiden van andere
              gebruikers, zodat je niet vaker in hoeft te loggen dan noodzakelijk. We gebruiken cookies ook om jouw
              eerdere gebruik van WorkAppic voort te kunnen zetten en jij verder kunt gaan waar je gebleven was. Cookies
              en het weblog worden tevens gebruikt om inzichtelijk te krijgen hoe jij en andere gebruikers WorkAppic
              gebruiken, maar ook om te meten hoeveel mensen WorkAppic bezoeken en om te bepalen welke onderdelen het
              meest populair zijn, zodat we de dienst zo efficiënt mogelijk kunnen aanbieden. Cookies worden niet
              gebruikt om bepaalde persoonlijke informatie te verzamelen van gebruikers en wij zullen in het algemeen
              geen persoonlijke informatie opslaan in jouw cookies.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">Wat is een cookie?</h2>
            <p className="mb-2">
              Een cookie is een eenvoudig klein bestandje dat met pagina’s van deze website wordt meegestuurd en door
              jouw browser op de harde schijf van jouw computer wordt opgeslagen (of op soortgelijke wijze als gebruik
              gemaakt wordt van een ander mobiel apparaat zoals een tablets of smartphone, bij gebruik maken van de
              webapp). De daarin opgeslagen informatie kan bij een volgend bezoek weer naar onze servers of die van de
              betreffende derde partijen teruggestuurd worden. Cookies kunnen via de browser worden geblokkeerd, indien
              je dit wenst. Let wel dat dit effect kan hebben op het gebruik van WorkAppic en je daardoor mogelijk zult
              worden beperkt en/of vertraagt. Meer informatie over cookies? Zie de uitleg van de Consumentenbond.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">Waar worden jouw gegevens opgeslagen?</h2>
            <p className="mb-2">
              Wij maken gebruik van een aantal programma’s en systemen van externe providers om bepaalde informatie op
              te slaan en bij te houden:
            </p>
            <ul className="list-disc pl-5">
              <li>
                De app-bouwer voor de achterkant van de app, om de gegevens van WorkAppic gebruikers op te slaan in een
                speciaal voor WorkAppic gemaakt dashboard. De gegevens die hier worden verzameld zijn uitsluitend voor
                ons inzichtelijk en worden door de app-bouwer opgeslagen op de server van Firebase van Google. Wij
                hebben met de app-bouwer een verwerkersovereenkomst gesloten.
              </li>
              <li>
                Google Analytics om bij te houden hoe bezoekers WorkAppic gebruiken. Wij hebben een
                verwerkersovereenkomst met Google gesloten om afspraken te maken over de omgang met onze data. Verder
                hebben wij Google niet toegestaan de verkregen Analytics informatie te gebruiken voor andere
                Googlediensten.
              </li>
              <li>
                Mailchimp en Sendgrid, om de nieuwsbrief per e-mail te sturen, indien dit door jou is aangegeven. De
                enige informatie die hier wordt opgeslagen zijn je naam en e-mailadres. Verder hebben wij Mailchimp en
                Sendgrid niet toegestaan de verkregen gegevens te gebruiken voor andere diensten. Je kunt je op elk
                moment afmelden voor de nieuwsbrief.
              </li>
              <li>
                Google Drive, om bij te houden hoe bezoekers WorkAppic gebruiken. Wij hebben Google niet toegestaan de
                op de zakelijke Google Drive opgeslagen informatie te gebruiken voor andere Google-diensten.
              </li>
            </ul>
            <h2 className="text-lg font-bold mb-2 mt-5">Beveiliging</h2>
            <p className="mb-2">
              Wij nemen passende beveiligingsmaatregelen om misbruik van en ongeautoriseerde toegang tot jouw
              persoonsgegevens te beperken. Zo zorgen wij dat alleen de noodzakelijke personen toegang hebben tot jouw
              gegevens, dat de toegang tot de gegevens afgeschermd is en dat onze veiligheidsmaatregelen regelmatig
              gecontroleerd worden.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">Minderjarigen</h2>
            <p className="mb-2">
              Personen die de leeftijd van 16 jaar nog niet hebben bereikt, mogen uitsluitend onder toezicht van hun
              ouders of wettelijk vertegenwoordigers gebruik maken van onze webapplicatie. Doen zij dit zonder
              toestemming, dan is WorkAppic niet verantwoordelijk of aansprakelijk voor het gebruik van de website of
              app. Om deze reden hebben wij dit expliciet toegevoegd in de Algemene Voorwaarden.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">Correspondentie</h2>
            <p className="mb-2">
              Wij bewaren correspondentie, zoals contact via e-mail of telefoon, voor zo lang wij dit nodig achten. Dit
              is inclusief alle ratings en notificaties die wij ontvangen van gebruikers.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">Algemene Voorwaarden</h2>
            <p className="mb-2">
              Wij kunnen ook persoonlijke data verwerken voor de implementatie en handhaving van de Algemene
              Voorwaarden. Bijvoorbeeld om maatregelen te nemen tegen zaken die in strijd zijn met de Algemene
              Voorwaarden.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">Wordt data beschikbaar gesteld aan derden?</h2>
            <p className="mb-2">
              Wij nemen de beveiliging en de methodiek om persoonlijke informatie van gebruikers te verwerken uiterst
              serieus en zullen onder geen enkele omstandigheid de verzamelde informatie verkopen aan derden. De door
              ons verzamelde data zal worden verwerkt door een externe ICT partner en worden opgeslagen (hosting) in
              Firebase van Google. Met deze partijen hebben wij een overeenkomst getroffen, dat zij vertrouwelijk met
              deze informatie om zullen gaan en deze zelf niet zullen gebruiken, noch zullen herleiden naar de personen
              van wie deze afkomstig zijn.
            </p>
            <p className="mb-2">
              De gegevens zullen wel overhandigd worden, indien wij hiertoe wettelijk verplicht worden gesteld of om
              onze Algemene Voorwaarden toe te kunnen passen of andere overeenkomsten om de rechten, het eigendom en de
              veiligheid van de gebruikers van WorkAppic en alle bij WorkAppic betrokken partijen te beschermen. Voor
              het opmaken van anonieme statistieken (jouw gebruik van de webapp zonder dat jouw persoonsgegevens daaraan
              zijn gekoppeld) zal door ons data kunnen worden gebruikt voor doelgroep- en marktanalyse en het
              optimaliseren van WorkAppic. Deze informatie kan mogelijk worden gedeeld met derden om de impact van
              WorkAppic te kunnen meten. Wij hebben tenslotte een missie: zoveel mogelijk mensen helpen aan meer
              werkplezier en zo een blijere samenleving.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">Websites van derden</h2>
            <p className="mb-2">
              Deze privacyverklaring is niet van toepassing op websites van derden, noch op de dienstverlening van
              derden. Wij kunnen niet garanderen dat deze derden op een betrouwbare of veilige manier met jouw
              persoonsgegevens omgaan. Wij raden je aan de privacyverklaring van deze websites te lezen en onderzoek te
              doen naar hoe derden omgaan met jouw persoonsgegevens alvorens van deze websites gebruik te maken.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">Wijzigingen in deze privacy- en cookieverklaring</h2>
            <p className="mb-2">
              Wij behouden ons het recht voor om wijzigingen aan te brengen in deze privacyverklaring. De meest recente
              versie zal beschikbaar zijn op de website www.workappic.com. Het verdient aanbeveling om deze
              privacyverklaring geregeld te raadplegen, zodat je van deze wijzigingen op de hoogte bent.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">Autoriteit Persoonsgegevens</h2>
            <p className="mb-2">
              Natuurlijk helpen wij jou graag verder als je klachten hebt over de verwerking van jouw persoonsgegevens.
              Je kunt ons bereiken via de contactgegevens onderaan deze pagina, of via ons contactformulier op
              www.worklifedifferently.com. Als je ontevreden bent over de manier waarop wij omgaan met je klacht, heb je
              het recht om een klacht in te dienen bij de Autoriteit Persoonsgegevens. Je kunt hiervoor contact opnemen
              met de Autoriteit Persoonsgegevens.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">Inzage en wijzigen van je gegevens</h2>
            <p className="mb-2">
              Voor vragen over ons privacybeleid of vragen over inzage en wijzigingen in (of verwijdering van) jouw
              persoonsgegevens kun je altijd contact met ons opnemen via onderstaande gegevens.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">
              Wat gebeurt er met jouw persoonlijke data nadat je account is verwijderd?
            </h2>
            <p className="mb-2">
              Als jij of wij jouw account verwijderen, zal al jouw persoonlijke data na een maand permanent worden
              verwijderd, tenzij jij ons verzoekt om dit direct te doen. Hiermee geven we je voldoende bedenktijd mocht
              je de webapp toch weer willen gebruiken, zonder dat je opnieuw een account hoeft aan te maken. Je kunt
              hiervoor gebruik maken door een e-mail te sturen naar onderstaande contactgegevens. De verzamelde data met
              betrekking tot jouw gebruikersgedrag wordt nog wel langer bewaard, maar zal worden gepseudonimiseerd,
              waardoor deze niet te herleiden is naar jou.
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">Klachten</h2>
            <p className="mb-2">
              Als je klachten hebt over onwettige of ongewenste content op WorkAppic kun je dit bij ons melden. Wij
              respecteren en beschermen de privacy van iedereen die een klacht indient of wie de klacht betreft.
              Persoonlijke informatie die met de klacht te maken heeft zal vertrouwelijk worden behandeld en alleen
              worden gebruikt om de klacht af te handelen.
            </p>
            <p className="mb-2">
              Bedankt voor het lezen! We hopen dat je deze informatie transparant, eerlijk en redelijk vindt, maar als
              je toch vindt dat we iets stoms of onredelijks met jouw persoonlijke informatie doen, laat dit ons dan
              graag weten via een mailtje aan{' '}
              <a className="text-blue-500 underline pointer" href="mailto:hi@workappic.com">
                hi@workappic.com
              </a>
              .
            </p>
            <h2 className="text-lg font-bold mb-2 mt-5">Contactgegevens</h2>
            <p className="mb-2">
              Mens & Werkplezier (met als onderdeel WorkLifeDifferently en WorkAppic).{' '}
              <a className="text-blue-500 underline pointer" href="mailto:hi@workappic.com">
                hi@workappic.com
              </a>{' '}
              of{' '}
              <a className="text-blue-500 underline pointer" href="mailto:glenn@mensenwerkplezier.nl">
                glenn@mensenwerkplezier.nl
              </a>{' '}
              of{' '}
              <a className="text-blue-500 underline pointer" href="mailto:nienke@mensenwerkplezier.nl">
                nienke@mensenwerkplezier.nl
              </a>{' '}
            </p>
            <p className="mb-2">KVK: 63411814</p>
            <p className="mb-2">Veel plezier!</p>
            <p className="mb-2">Groetjes,</p>
            <p className="mb-2">Glenn & Nienke</p>
          </div>
        </div>
      </div>
    )
  }
}

VoorwaardenPrivacy.propTypes = {}

VoorwaardenPrivacy.defaultProps = {}

export default VoorwaardenPrivacy
