import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

// Import Components
import Navigation from './../Navigation'
import ScrollToTop from './../ScrollToTop'

// Import Modulen
import Werkleven from './../../modulen/Werkleven'

// Import Pages
import Dashboard from './../../pages/Dashboard'
import Actielijst from './../../pages/Actielijst'
import AccountPage from './../../pages/Account'
import TimelinePage from './../../pages/Timeline'
import SignUpPage from './../../pages/SignUp'
import SignInPage from './../../pages/SignIn'
import PasswordForgetPage from './../../pages/PasswordForget'
import VoorwaardenPrivacy from './../../pages/VoorwaardenPrivacy'
import VoorwaardenAlgemeen from './../../pages/VoorwaardenAlgemeen'

import * as ROUTES from '../../css/constants/routes'
import { withAuthentication } from '../Session'

const bugsnagClient = bugsnag('245fee19a47101574bb4d61baf1b1562')
bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin('react')

class App extends Component {
  constructor(props) {
    super(props)

    // The app has a profilePictureURL state property so that we can pass that to the dashboard. Here the user is prompted to upload a profile picture. After uploading we set the URL to the state, which we then pass to the navigation component to display it. This is the only use of this state property, as the navigation component always tries to fetch the user profile picture url (which is based on the UID).
    this.state = {
      profilePictureURL: null,
    }
    this.userUploadsProfilePicture = this.userUploadsProfilePicture.bind(this)
  }

  userUploadsProfilePicture(url) {
    this.setState(
      {
        profilePictureURL: url,
      },
      () => console.log(url),
    )
  }

  render() {
    return (
      <ErrorBoundary>
        <Router>
          <div className="bg-gray-100 min-h-screen">
            {/* Component to set window.scrollTo(0, 0) on every Router change*/}
            <ScrollToTop />
            <Navigation profilePictureURL={this.state.profilePictureURL} />

            {this.props.authUser !== null ? (
              <Switch>
                {/* Router when there is an authorised user (props.authUser from withAuthentication wrapper) */}
                <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
                <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
                <Route
                  path={ROUTES.DASHBOARD}
                  exact
                  render={() => <Dashboard userUploadsProfilePicture={this.userUploadsProfilePicture} />}
                />
                <Route path={ROUTES.TO_DO_LIJST} component={Actielijst} />
                <Route path={ROUTES.TIMELINE} component={TimelinePage} />
                <Route path={ROUTES.ACCOUNT} component={AccountPage} />
                <Route path={ROUTES.MODULEN_WERKLEVEN_SECTIE_ONDERDEEL} component={Werkleven} />
                <Route path={ROUTES.VOORWARDEN_PRIVACY} component={VoorwaardenPrivacy} />
                <Route path={ROUTES.VOORWARDEN_ALGEMEEN} component={VoorwaardenAlgemeen} />
              </Switch>
            ) : (
              <Switch>
                {/* Routes if there is NO authorised user */}
                <Route path={ROUTES.VOORWARDEN_PRIVACY} component={VoorwaardenPrivacy} />
                <Route path={ROUTES.VOORWARDEN_ALGEMEEN} component={VoorwaardenAlgemeen} />
                <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
                <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
                <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
                <Route component={SignInPage} />
              </Switch>
            )}

            {/* Toast container to display notifations to the user (react-toastify library) */}
            <ToastContainer position="bottom-left" autoClose={1500} />
          </div>
        </Router>
      </ErrorBoundary>
    )
  }
}

export default withAuthentication(App)
