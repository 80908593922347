import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { compose } from 'recompose'
import { toast } from 'react-toastify'
import { PasswordForgetLink } from '../PasswordForget'
import { withFirebase } from '../Firebase'
import * as ROUTES from '../../css/constants/routes'
import Modal from 'react-modal'

const SignInPage = () => (
  <div className="container mx-auto px-6 pt-24 flex flex-wrap flex-row justify-center">
    <div className="bg-white w-full md:w-3/5 rounded shadow-lg flex">
      <div className="w-full md:w-1/2 px-8 pt-4 pb-8">
        <h1 className="mt-4 mb-2 font-bold text-gray-800 text-2xl">Inloggen</h1>
        {/* <p className="text-gray-700">
          Login to your WorkLifeEnjoy account.
        </p> */}
        <SignInForm />
        <SignInGoogle />
        <Link
          to={ROUTES.SIGN_UP}
          className="bg-transparent text-sm w-full hover:bg-blue-500 text-blue-700 font-semibold hover:text-white w-full block text-center py-2 px-2 border border-blue-500 cursor-pointer mb-4 hover:border-transparent rounded"
          href="#"
        >
          Nog geen account? Start hier.
        </Link>
        <PasswordForgetLink />
      </div>

      <div className="hidden md:block w-1/2 bg-green-200 rounded-r" id="login-image">
        <div className="bottom-0 z-10 text-white absolute p-6 text-left">
          <p className="font-thin italic mt-2">"Whether you think you can or can't, you're right"</p>
          <div id="quote-author" className="flex mt-1">
            <span id="author-picture" className="block shadow h-8 w-8 mr-3 rounded-full bg-white border border-white" />
            <h3 className="font-bold">Henry Ford</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
}

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/account-exists-with-different-credential'

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`

class SignInFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = (event) => {
    const { email, password } = this.state

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        this.props.history.push(ROUTES.DASHBOARD)
      })
      .catch((error) => {
        this.setState({ error })
        toast(`Error: ${error.message}`)
      })

    event.preventDefault()
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    // const isInvalid = password === '' || email === '';

    return (
      <form className="pt-6" onSubmit={this.onSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            E-mail
          </label>
          <input
            required
            onChange={this.onChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="text"
            autoComplete="off"
            placeholder="Email"
            name="email"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Wachtwoord
          </label>
          <input
            onChange={this.onChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="******************"
            name="password"
          />
        </div>
        {/* {error && <UIAlert message={error.message}/>} */}
        <div className="flex items-center flex-wrap">
          <input
            required
            value="Inloggen"
            type="submit"
            className="bg-transparent text-sm w-full hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-2 border border-blue-500 cursor-pointer hover:border-transparent rounded"
          />
        </div>
      </form>
    )
  }
}

// Sign in with Google Component
class SignInGoogleBase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      firebaseLoginStatus: false,
      firebaseLoginObject: null,
      userDetails: {
        gender: null,
        age: '',
      },
      open: false,
    }
  }

  onSubmit = (event) => {
    event.preventDefault()
    this.props.firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        let res = {}
        if (socialAuthUser.additionalUserInfo.isNewUser) {
          res = {
            firebaseLoginObject: socialAuthUser,
            firebaseLoginStatus: true,
            open: true,
          }
          this.props.history.push(ROUTES.SIGN_IN)
        } else {
          res = {
            firebaseLoginObject: socialAuthUser,
            firebaseLoginStatus: true,
          }
          this.props.history.push(ROUTES.DASHBOARD)
        }
        this.setState(res)
      })
      .catch((error) => {
        // Catch and display errors by setting them in state
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }
        this.setState({ error, firebaseLoginStatus: false })
      })
    // Merge social user with potential existing user (based on email)
    // return this.props.firebase.user(socialAuthUser.user.uid).set(
    //   {
    //     username: socialAuthUser.user.displayName,
    //     email: socialAuthUser.user.email,
    //     roles: {},
    //   },
    //   { merge: true },
    // );

    // .then(() => {
    // Reset state and push to Dashboard
    // this.setState({ error: null });
    // this.props.history.push(ROUTES.DASHBOARD);
    // })
  }

  signIn = (evt) => {
    evt.preventDefault()
    if (this.state.firebaseLoginStatus) {
      const socialAuthUser = this.state.firebaseLoginObject
      this.props.firebase
        .user(socialAuthUser.user.uid)
        .set(
          {
            username: socialAuthUser.user.displayName,
            email: socialAuthUser.user.email,
            age: this.state.userDetails.age,
            gender: this.state.userDetails.gender,
            roles: {},
          },
          { merge: true },
        )
        .then(() => {
          // Reset state and push to Dashboard
          this.setState({ error: null })
          this.props.history.push(ROUTES.DASHBOARD)
        })
    }
  }

  handleAgeChange = (evt) => {
    evt.persist()
    this.setState((state) => {
      const userDetails = state.userDetails
      userDetails.age = evt.target.value
      return { userDetails, ...state }
    })
  }

  handleGenderChange = (val) => {
    this.setState((state) => {
      const userDetails = state.userDetails
      userDetails.gender = val
      return { userDetails, ...state }
    })
  }

  handleCloseModal = () => {
    this.setState({ open: false })
    this.props.firebase.doSignOut()
  }

  render() {
    const { error } = this.state

    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.open}
          // onAfterOpen={afterOpenModal}
          // onRequestClose={closeModal}
          // style={customStyles}
          overlayClassName="modal-overlay"
          className="modal absolute w-full h-full top-0 left-0 flex items-center justify-center"
          contentLabel="Example Modal"
        >
          <div className="w-full max-w-md">
            <form className="bg-white shadow-md rounded px-10 py-12 mb-4" onSubmit={this.signIn}>
              <h3 className="text-lg text-blue-800">Additionele informatie*</h3>
              <hr />
              <div className="my-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="age">
                  Leeftijd
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline z-50"
                  value={this.state.userDetails.age}
                  onChange={this.handleAgeChange}
                  id="age"
                  type="number"
                  placeholder="Leeftijd"
                  required
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="gender">
                  Geschlacht
                </label>
                <label>
                  <input
                    type="radio"
                    name="gender"
                    onChange={() => this.handleGenderChange('male')}
                    value="male"
                    required
                  />{' '}
                  <span className="text-sm text-gray-800 pr-4">Man</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="gender"
                    onChange={() => this.handleGenderChange('female')}
                    value="Female"
                    required
                  />{' '}
                  <span className="text-sm text-gray-800 pr-4">Vrouw</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="gender"
                    onChange={() => this.handleGenderChange('other')}
                    value="Other"
                    required
                  />{' '}
                  <span className="text-sm text-gray-800 pr-4">Overig</span>
                </label>
              </div>
              <div className="flex items-center">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-5"
                  type="submit"
                >
                  Inloggen
                </button>
                <button
                  className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={this.handleCloseModal}
                  type="button"
                >
                  Sluiten
                </button>
              </div>
            </form>
          </div>
        </Modal>
        <form ref="form" onSubmit={this.onSubmit}>
          <div className="google-btn my-2" onClick={() => this.refs.form.dispatchEvent(new Event('submit'))}>
            <div className="google-icon-wrapper">
              <img
                className="google-icon"
                src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
              />
            </div>
            <p className="btn-text">
              <b>Inloggen met Google</b>
            </p>
          </div>
          {/*<button*/}
          {/*  type="submit"*/}
          {/*  className="bg-transparent mt-2 mb-4 w-full hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-2 text-sm border border-blue-500 hover:border-transparent rounded"*/}
          {/*>*/}
          {/*  Inloggen met Google*/}
          {/*</button>*/}
          {error && <p>{error.message}</p>}
        </form>
      </React.Fragment>
    )
  }
}
const SignInForm = compose(withRouter, withFirebase)(SignInFormBase)

const SignInGoogle = compose(withRouter, withFirebase)(SignInGoogleBase)

export default SignInPage

export { SignInForm, SignInGoogle }
