import { toast } from 'react-toastify'

// Handler voor saved State, inclusief exitStatus als de gebruiker de pagina wil verlaten
function savedStateToAPI(exitStatus) {
  this.setState({
    saving: false,
    saved: true,
  })

  if (exitStatus === true) {
    this.resetSavingState(true)
  } else {
    this.resetSavingState()
  }
}

export default savedStateToAPI
