import React, { Component } from 'react'
import PropTypes from 'prop-types'

function ActielijstHoeForm(props) {
  return (
    <>
      <h3 className="font-bold text-gray-700 mb-1 mt-4">Hoe ga ik dat doen?</h3>
      <input
        required
        name="hoeActie"
        className="border-b w-full px-2 py-1"
        placeholder="typ hier je antwoord"
        type="text"
        autoComplete="off"
        value={props.item.hoeForm.hoeActie}
        onChange={props.handleHoeOfTwijfelChange}
        data-index={props.index}
        data-formname="hoeForm"
      />
      <h3 className="font-bold text-gray-700 mb-1 mt-4">Wat heb ik hiervoor nodig?</h3>
      <input
        required
        placeholder="typ hier je antwoord"
        name="hoeNodig"
        className="border-b w-full px-2 py-1"
        type="text"
        autoComplete="off"
        value={props.item.hoeForm.hoeNodig}
        onChange={props.handleHoeOfTwijfelChange}
        data-index={props.index}
        data-formname="hoeForm"
      />
      <h3 className="font-bold text-gray-700 mb-1 mt-4">Heb ik nog iets van anderen nodig?</h3>
      <input
        required
        placeholder="typ hier je antwoord"
        name="hoeAndere"
        className="border-b w-full px-2 py-1"
        type="text"
        autoComplete="off"
        value={props.item.hoeForm.hoeAndere}
        onChange={props.handleHoeOfTwijfelChange}
        data-index={props.index}
        data-formname="hoeForm"
      />
      {/* onSubmit={(e) => saveToDatabase(e, true)} */}
      <button
        type="submit"
        className="mt-4 inline-block cursor-pointer bg-wa-button-green hover:bg-green-800 text-white rounded-full px-5 py-1 font-bold mb-4"
        onClick={(e) => props.submitHoeForm(e)}
      >
        {props.item.hoeForm.savedState ? 'Wijzigen' : 'Toevoegen'}
      </button>
    </>
  )
}

export default ActielijstHoeForm
