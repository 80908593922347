import React from 'react'
import { Link } from 'react-router-dom'

import Title from './title'
import Footer from './footer'

const LikeNoLike = (props) => {
  return (
    <div className="flex w-full flex-grow flex-col pl-6 pr-6">
      <Link to={props.goTo}>
        <Title color={`${props.like ? 'wa-bg-green' : 'wa-bg-bordeaux'}`} title={props.title} amount={props.amount} />
      </Link>
      <div className="w-full">{props.children}</div>
      <Footer
        showAll={props.showAll}
        add={props.add}
        goTo={props.goTo}
        displayAll={props.displayAll}
        showAllBtnVisible={props.amount > 5}
      />
    </div>
  )
}

export default LikeNoLike
