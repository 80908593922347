import React from 'react'

const ButtonSmallGreen = (props) => {
  return (
    <button
      value={props.value}
      name={props.name}
      onClick={props.onClick}
      className={
        'w-26 text-xs md:w-56 h-10 px-8 md:px-5 md:py-1 mt-2 md:mt-0 md:text-base cursor-pointer bg-wa-button-green text-white rounded-full'
      }
    >
      {props.title}
    </button>
  )
}

export default ButtonSmallGreen
