import React, { useEffect, useState } from 'react'
import Modal from '../../components/Template/Modal'

export const InfoUitlegModule = (props) => {
  const [toggleOpenState, setToggleOpenState] = useState(false)

  const close = () => {
    if (props.readInfo) {
      props.readInfo(false)
    }

    setToggleOpenState(false)
  }

  useEffect(() => {
    setToggleOpenState(props.firstVisit)
  }, [props.firstVisit])

  return (
    <React.Fragment>
      <div
        onClick={() => {
          setToggleOpenState(true)
        }}
        className="w-20 mt-1 mb-4 md:w-24 text-xs md:text-base ml-5 wa-bg-bordeaux text-white cursor-pointer text-white rounded-full overflow-hidden border-white border-2 z-50	"
      >
        <div className="flex justify-center align-middle">
          <h3 className="text-1xl text-white font-hairline py-1 md:py-3 rounded">Uitleg</h3>
        </div>
      </div>
      {toggleOpenState ? <Modal onClose={close}>{props.children}</Modal> : null}
    </React.Fragment>
  )
}

export default InfoUitlegModule
