import React from 'react'
import { Link } from 'react-router-dom'
import TooltipPopUp from 'react-tooltip-lite'
import { toast } from 'react-toastify'

import RadioButton from './../../../../components/RadioButton'
import Slider from './../../../../components/Slider'
import Editor from './../../../../components/Editor'
import LoaderCijferHuidigWerkleven from './../../../../components/Loaders/LoaderCijferHuidigWerkleven.js'
import Lightbulb from './../../../../components/Lightbulb'
import TitleQuestionair from './../../../../components/Atoms/Title-Questionair'

import differenceInWeeks from 'date-fns/difference_in_weeks'
import differenceInMonths from 'date-fns/difference_in_months'
import differenceInYears from 'date-fns/difference_in_years'
import format from 'date-fns/format'
import nlLocale from 'date-fns/locale/nl'

import { BarChart, X } from 'react-feather'
import { AreaChart, Area, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import ButtonSmallGreen from '../../../../components/Atoms/Button-Small-Green'
import SmileySelection from '../../../../components/Template/Smiley-Selection'

import smiley1 from '../../../../assets/images/icons/smiley-1.png'
import smiley2 from '../../../../assets/images/icons/smiley-2.png'
import smiley3 from '../../../../assets/images/icons/smiley-3.png'
import smiley4 from '../../../../assets/images/icons/smiley-4.png'
import smiley5 from '../../../../assets/images/icons/smiley-5.png'

import Modal from '../../../../components/Template/Modal'

const SMILEYS = [smiley1, smiley2, smiley3, smiley4, smiley5]

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    return (
      <div className="px-4 py-2 text-3xl text-gray-700 font-bold rounded shadow bg-white">
        <p>
          {/* ${payload[0].dataKey} */}
          {`${payload[0].value} - `}
          <span role="img" aria-label="emoji" className="no-underline text-3xl">
            <img src={SMILEYS[parseInt(payload[0].payload.smiley)]} height={24} width={24} />
          </span>
        </p>
      </div>
    )
  }

  return null
}

class WerklevenCijferChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeFilter: 'alles',
    }
    this.switchFilter = this.switchFilter.bind(this)
  }

  switchFilter(filterName) {
    this.setState({
      activeFilter: filterName,
    })
  }

  render() {
    let data = this.props.arrayItems.map((object) => ({ ...object }))

    function weekFilter(data) {
      return data.filter((item) => {
        const diff = differenceInWeeks(new Date(), item.datum.toDate())
        if (diff <= 1) {
          return item
        }
      })
    }

    function maandFilter(data) {
      return data.filter((item) => {
        const diff = differenceInMonths(new Date(), item.datum.toDate())
        if (diff <= 1) {
          return item
        }
      })
    }

    function jaarFilter(data) {
      return data.filter((item) => {
        const diff = differenceInYears(new Date(), item.datum.toDate())
        if (diff <= 1) {
          return item
        }
      })
    }

    if (this.state.activeFilter === 'week') {
      data = weekFilter(data)
    } else if (this.state.activeFilter === 'maand') {
      data = maandFilter(data)
    } else if (this.state.activeFilter === 'jaar') {
      data = jaarFilter(data)
    }

    data.forEach((item) => {
      if (typeof item.datum.toDate === 'function') {
        item.datum = format(item.datum.toDate(), 'DD/MM/YYYY', {
          locale: nlLocale,
        })
      } else {
        item.datum = format(item.datum, 'DD/MM/YYYY', {
          locale: nlLocale,
        })
      }
      item.cijfer = parseInt(item.cijfer)
    })

    return (
      <React.Fragment>
        <div className="py-4 md:absolute sm:relative xs:relative top-0 right-0 flex justify-center md:justify-end">
          <span
            onClick={() => this.switchFilter('week')}
            className={`${
              this.state.activeFilter === 'week'
                ? 'text-white border-green-600 bg-green-600'
                : 'text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-white border-green-600'
            } font-semibold py-2 text-center border-2 rounded px-2 md:px-4 text-xs md:text-sm cursor-pointer mr-2 md:mr-4`}
          >
            Week
          </span>
          <span
            onClick={() => this.switchFilter('maand')}
            className={`${
              this.state.activeFilter === 'maand'
                ? 'text-white border-green-600 bg-green-600'
                : 'text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-white border-green-600'
            } font-semibold py-2 text-center border-2 rounded px-2 md:px-4 text-xs md:text-sm cursor-pointer mr-2 md:mr-4`}
          >
            Maand
          </span>
          <span
            onClick={() => this.switchFilter('jaar')}
            className={`${
              this.state.activeFilter === 'jaar'
                ? 'text-white border-green-600 bg-green-600'
                : 'text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-white border-green-600'
            } font-semibold py-2 text-center border-2 rounded px-2 md:px-4 text-xs md:text-sm cursor-pointer mr-2 md:mr-4`}
          >
            Jaar
          </span>
          <span
            onClick={() => this.switchFilter('alles')}
            className={`${
              this.state.activeFilter === 'alles'
                ? 'text-white border-green-600 bg-green-600'
                : 'text-green-600 hover:border-green-600 hover:bg-green-600 hover:text-white border-green-600'
            } font-semibold py-2 text-center border-2 rounded px-2 md:px-4 text-xs md:text-sm cursor-pointer mr-2 md:mr-4`}
          >
            Alles
          </span>
        </div>
        <ResponsiveContainer width="100%" height="85%">
          <AreaChart data={data}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#4FD1C5" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#4FD1C5" stopOpacity={0} />
              </linearGradient>
            </defs>
            <YAxis type="number" domain={[0, 'dataMax + 1']} interval={0} ticks={[0, 2, 4, 6, 8, 10]} />
            <XAxis dataKey="datum" interval={0} angle={-45} textAnchor="end" height={75} padding={{ right: 10 }} />
            <Area
              isAnimationActive={false}
              dot={{ stroke: '#34898a', strokeWidth: 10 }}
              type="monotone"
              dataKey="cijfer"
              stroke="#34898a"
              strokeWidth={4}
              fillOpacity={1}
              fill="url(#colorUv)"
            />
            <Tooltip content={<CustomTooltip />} />
          </AreaChart>
        </ResponsiveContainer>
      </React.Fragment>
    )
  }
}

class NieuwCijferItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cijfer: 0,
      datum: new Date(),
      externOfIntern: '',
      gevoel: '',
      hogerLager: '',
      smiley: '',
    }
    this.setEditorState = this.setEditorState.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSliderChange = this.handleSliderChange.bind(this)
    this.saveCurrentState = this.saveCurrentState.bind(this)
  }

  //   Methods voor het aanmaken van een nieuw huidig werkleven cijfer
  handleSliderChange = (value, key) => {
    this.setState(
      {
        [key]: value,
      },
      () => {
        console.log(this.state)
      },
    )
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  setEditorState(stateValue, fieldName, itemIndex) {
    // Algemene State Handler voor de Editor
    if (itemIndex !== undefined) {
      let arrayItems = this.state.arrayItems
      arrayItems[itemIndex][fieldName] = stateValue
      this.setState({
        arrayItems: arrayItems,
      })
    } else {
      this.setState({
        [fieldName]: stateValue,
      })
    }
  }

  saveCurrentState() {
    var docref2 = this.props.firebase.db
      .collection('users')
      .doc(this.props.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('2')
      .collection('cijfers')
      .doc('cijfers')

    docref2.get().then((doc) => {
      if (doc.exists) {
        docref2
          .set({
            ...doc.data(),
            HWCijfer: this.state.cijfer,
            HWGevoel: this.state.gevoel,
            HWSmiley: this.state.smiley,
            HWToelichting: this.state.externOfIntern,
          })
          .then(function () {
            console.log('Updated cijfer!')
          })
          .catch(function (error) {
            console.error('Error writing document: ', error)
          })
      }
    })

    var docRef = this.props.firebase.db
      .collection('users')
      .doc(this.props.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('2')
      .collection('cijfers')
      .doc('cijfers')

    docRef
      .update({
        huidigCijfers: this.props.firebase.fieldValue.arrayUnion(this.state),
      })
      .then(function () {
        console.log('Document successfully written!')
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
  }

  render() {
    return (
      <div className="md:max-height-modal overflow-y-scroll">
        <h2 className="font-bold text-xl wa-green-color mb-4">
          Nieuw cijfer -{' '}
          {format(new Date(), 'DD MMMM YYYY', {
            locale: nlLocale,
          })}
        </h2>
        <Slider
          onChange={this.handleSliderChange}
          keyName={'cijfer'}
          huidigOfGewenst={'huidig'}
          sliderValue={this.state.cijfer}
        />
        <SmileySelection
          handleChange={this.handleChange}
          title="Hoe voel ik mij bij dat cijfer?"
          smileyType="smiley"
          smiley={this.state.smiley}
        />
        <div className="mt-5">
          <h3 className="font-bold wa-green-color mb-2">
            Is het cijfer hoger, lager of gelijk dan de vorige keer? En wat is de reden hiervan?
          </h3>
          <Editor
            setEditorState={this.setEditorState}
            fieldName="hogerLager"
            initialEditorState={this.state.hogerLager}
          />
        </div>
        <div className="mt-5">
          <h3 className="font-bold wa-green-color mb-2">
            Komt dit door mijzelf, door een gebeurtenis of door een ander?
            <TooltipPopUp
              content={
                <div className="max-w-sm">
                  <p>Door mijzelf: heb je zelf iets wel/niet gedaan?</p>
                  <p>Door een gebeurtenis: is er iets leuks of minder leuks voorgevallen? </p>
                  <p>Door een ander: heeft een ander iets wel/niet gedaan? </p>
                </div>
              }
              className="inline-block z-20"
            >
              <Lightbulb />
            </TooltipPopUp>
          </h3>
          <Editor
            setEditorState={this.setEditorState}
            fieldName="externOfIntern"
            initialEditorState={this.state.externOfIntern}
          />
        </div>
        <div className="mt-5">
          <h3 className="font-bold wa-green-color mb-2">
            Wil ik iets toevoegen aan:
            <TooltipPopUp
              content={
                <div className="max-w-sm">
                  <p>Heb je een minder leuk punt of leuk punt toe te voegen aan jouw huidige werkleven?</p>
                  <p>
                    Wil je een punt wat je niet wilt of wel wilt aan jouw lijstjes van gewenste werkleven toevoegen?
                  </p>
                  <p>Of wil je een actie toevoegen aan jouw actielijst?</p>
                </div>
              }
              className="inline-block z-20"
            >
              <Lightbulb />
            </TooltipPopUp>
          </h3>
          <div className="flex mt-6">
            <div
              className="text-xs shadow flex justify-center items-center bg-wa-button-light-green rounded-full cursor-pointer px-3 py-2 font-bold text-center mb-4 w-full"
              onClick={() => {
                this.props.togglePopUp()
                toast('Opgeslagen! 👍')
                this.saveCurrentState()
                this.props.history.push(`/mijn-werkleven/3/1/`)
                window.scrollTo(0, 0)
              }}
            >
              Huidig Werkleven
            </div>
            <div
              className="text-xs shadow flex justify-center items-center bg-wa-button-light-green rounded-full cursor-pointer px-3 py-2 font-bold text-center mb-4 w-full ml-3"
              onClick={() => {
                this.props.togglePopUp()
                toast('Opgeslagen! 👍')
                this.saveCurrentState()
                this.props.history.push(`/mijn-werkleven/4/1/`)
                window.scrollTo(0, 0)
              }}
            >
              Gewenst Werkleven
            </div>
            <div
              className="text-xs shadow flex justify-center items-center bg-wa-button-light-green rounded-full cursor-pointer px-3 py-2 font-bold text-center mb-4 w-full ml-3"
              onClick={() => {
                this.props.togglePopUp()
                toast('Opgeslagen! 👍')
                this.saveCurrentState()
                this.props.history.push(`/to-do-lijst`)
                window.scrollTo(0, 0)
              }}
            >
              Actielijst
            </div>
          </div>
        </div>
        <button
          className="py-2 w-full text-center px-4 inline-block mt-4 bg-wa-button-green hover:bg-green-800 font-bold text-white md:text-lg rounded-full shadow-md mr-4"
          onClick={() => {
            this.saveCurrentState()
            this.props.voegCijferToeAanStateNaOpslaan(this.state)
            this.props.togglePopUp()
          }}
        >
          Opslaan
        </button>
      </div>
    )
  }
}

class CijferItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popUpOpen: false,
    }
    this.togglePopUp = this.togglePopUp.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    this.props.handleChange(e, this.props.index, 'huidig')
  }

  togglePopUp() {
    this.setState({
      popUpOpen: !this.state.popUpOpen,
    })
  }

  render() {
    const item = this.props.item

    let formattedDatum = item.datum

    if (typeof formattedDatum.toDate === 'function') {
      formattedDatum = format(formattedDatum.toDate(), 'DD MMMM YYYY', {
        locale: nlLocale,
      })
    } else {
      formattedDatum = format(formattedDatum, 'DD MMMM YYYY', {
        locale: nlLocale,
      })
    }

    let cijferClassNames = ''
    if (parseInt(item.cijfer) >= 6) {
      cijferClassNames = 'px-4 py-1 bg-green-200 text-green-700 rounded-full'
    }
    if (parseInt(item.cijfer) < 6 && parseInt(item.cijfer) > 4) {
      cijferClassNames = 'px-4 py-1 bg-orange-200 text-orange-700 rounded-full'
    }
    if (parseInt(item.cijfer) <= 4) {
      cijferClassNames = 'px-4 py-1 bg-red-200 text-red-700 rounded-full'
    }

    return (
      <React.Fragment>
        <div
          className="bg-white text-gray-700 border-b border-gray-200 cursor-pointer px-6 py-2 text-center mt-1 flex justify-between items-center hover:underline"
          onClick={this.togglePopUp}
        >
          <span className="italic">{formattedDatum}</span>
          {/* <span className="px-4 py-1 bg-green-200 text-green-700 rounded-full"> */}
          <span className={cijferClassNames}>{item.cijfer}</span>
          <span role="img" aria-label="happy emoji" className="no-underline" className="text-3xl">
            <img src={SMILEYS[parseInt(item.smiley)]} height={24} width={24} />
          </span>
        </div>

        {this.state.popUpOpen ? (
          <div>
            <div className="w-screen h-screen z-40 left-0 top-0 bg-black opacity-25 fixed" />
            <div className="flex z-50 justify-center items-center w-screen h-screen top-0 left-0 fixed">
              <div className="max-w-xl bg-white rounded shadow-lg p-8 relative huidig-werkleven-popup overflow-y-auto">
                <X className="absolute right-0 top-0 mr-5 mt-5 cursor-pointer" onClick={this.togglePopUp} />
                <h2 className="font-bold text-xl text-gray-700 mb-4">Cijfer - {formattedDatum}</h2>
                <Slider
                  onChange={this.props.handleSliderChange}
                  itemIndex={this.props.index}
                  keyName={'cijfer'}
                  huidigOfGewenst={'huidig'}
                  sliderValue={item.cijfer}
                />
                <SmileySelection
                  handleChange={this.handleChange}
                  title="Hoe voel ik mij bij dat cijfer?"
                  smileyType="smiley"
                  smiley={item.smiley}
                />
                <div className="mt-5">
                  <h3 className="font-bold text-gray-700 mb-2">
                    Is het cijfer hoger, lager of gelijk dan de vorige keer? En wat is de reden hiervan?
                  </h3>
                  <Editor
                    index={this.props.index}
                    setEditorState={this.props.setEditorState}
                    fieldName="hogerLager"
                    initialEditorState={item.hogerLager}
                  />
                </div>
                <div className="mt-5">
                  <h3 className="font-bold text-gray-700 mb-2">
                    Komt dit door mijzelf, door een gebeurtenis of door een ander?
                    <TooltipPopUp
                      content={
                        <div className="max-w-sm">
                          <p>Door mijzelf: heb je zelf iets wel/niet gedaan?</p>
                          <p>Door een gebeurtenis: is er iets leuks of minder leuks voorgevallen? </p>
                          <p>Door een ander: heeft een ander iets wel/niet gedaan? </p>
                        </div>
                      }
                      className="inline-block z-20"
                    >
                      <Lightbulb />
                    </TooltipPopUp>
                  </h3>
                  <Editor
                    index={this.props.index}
                    setEditorState={this.props.setEditorState}
                    fieldName="externOfIntern"
                    initialEditorState={item.externOfIntern}
                  />
                </div>
                <div className="mt-5">
                  <h3 className="font-bold text-gray-700 mb-2">
                    Wil ik iets toevoegen aan:
                    <TooltipPopUp
                      content={
                        <div className="max-w-sm">
                          <p>Heb je een minder leuk punt of leuk punt over jouw huidige werkleven?</p>
                          <p>Heb je een nieuwe eis of een wens voor jouw gewenste werkleven?</p>
                          <p>Heb je een gedachte of een actie waar je iets mee wilt doen in jouw actielijst?</p>
                        </div>
                      }
                      className="inline-block z-20"
                    >
                      <Lightbulb />
                    </TooltipPopUp>
                  </h3>
                  <div className="flex">
                    <div
                      className="bg-white shadow flex justify-center items-center text-green-500 rounded cursor-pointer px-3 py-2 font-bold text-center mb-4 w-full"
                      onClick={() => {
                        this.togglePopUp()
                        this.props.saveCurrentState()
                        this.props.history.push(`/mijn-werkleven/3/1/`)
                        window.scrollTo(0, 0)
                      }}
                    >
                      Huidig Werkleven
                    </div>
                    <div
                      className="bg-white shadow flex justify-center items-center text-green-500 rounded cursor-pointer px-3 py-2 font-bold text-center mb-4 w-full ml-3"
                      onClick={() => {
                        this.togglePopUp()
                        this.props.saveCurrentState()
                        this.props.history.push(`/mijn-werkleven/4/1/`)
                        window.scrollTo(0, 0)
                      }}
                    >
                      Gewenst Werkleven
                    </div>
                    <div
                      className="bg-white shadow flex justify-center items-center text-green-500 rounded cursor-pointer px-3 py-2 font-bold text-center mb-4 w-full ml-3"
                      onClick={() => {
                        this.togglePopUp()
                        this.props.saveCurrentState()
                        this.props.history.push(`/to-do-lijst`)
                        window.scrollTo(0, 0)
                      }}
                    >
                      Actielijst
                    </div>
                  </div>
                </div>
                <div className="h-1 block w-full mb-4 mt-4 border-b-2 border-gray-300" />
                <button
                  className="py-2 w-full text-center px-4 inline-block mt-4 bg-green-600 hover:bg-green-800 font-bold text-white md:text-lg rounded-lg shadow-md mr-4"
                  onClick={() => {
                    this.props.saveCurrentState(this.props.isLast)
                    this.togglePopUp()
                  }}
                >
                  Opslaan
                </button>
                <button
                  className="py-2 w-full text-center px-4 inline-block mt-4 text-red-600 hover:bg-red-600 hover:text-white md:text-lg rounded-lg font-bold shadow-md mr-4 border"
                  onClick={() => {
                    if (window.confirm('Wil je dit cijfer echt verwijderen?')) {
                      this.props.removeCijferHuidigWerkleven(this.props.index)
                      this.togglePopUp()
                    }
                  }}
                >
                  Verwijderen
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    )
  }
}

class NieuwGewenstPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cijfer: this.props.laatsteItem.cijfer || '0',
      toelichting: this.props.laatsteItem.toelichting || '',
      smiley: this.props.laatsteItem.smiley || '',
      gevoel: this.props.laatsteItem.gevoel || '',
      datum: new Date(),
    }
    this.saveCurrentState = this.saveCurrentState.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSliderChange = this.handleSliderChange.bind(this)
    this.setEditorState = this.setEditorState.bind(this)
  }

  // Save de huidige state naar de Database
  saveCurrentState() {
    let firebase = this.props.firebase

    if (!this.props.authUser) {
      return
    }

    if (this.state.gewenstCijfers && this.state.huidigCijfers) {
      const gwLife = this.state.gewenstCijfers[this.state.gewenstCijfers.length - 1]
      const hwLife = this.state.huidigCijfers[this.state.huidigCijfers.length - 1]
      console.log(gwLife, hwLife)
      firebase.db
        .collection('users')
        .doc(this.state.authUser)
        .collection('modulen')
        .doc('mijnWerkleven')
        .collection('subModulen')
        .doc('2')
        .set({
          GWCijfer: gwLife.cijfer,
          GWGevoel: gwLife.gevoel,
          GWSmiley: gwLife.GWSmiley,
          GWToelichting: gwLife.externOfIntern,
          HWCijfer: hwLife.cijfer,
          HWGevoel: hwLife.gevoel,
          HWSmiley: hwLife.GWSmiley,
          HWToelichting: hwLife.externOfIntern,
        })
    } else {
      var docref2 = this.props.firebase.db
        .collection('users')
        .doc(this.props.authUser)
        .collection('modulen')
        .doc('mijnWerkleven')
        .collection('subModulen')
        .doc('2')

      docref2.get().then((doc) => {
        if (doc.exists) {
          docref2
            .set({
              ...doc.data(),
              GWCijfer: this.state.cijfer,
              GWGevoel: this.state.gevoel,
              GWSmiley: this.state.smiley,
              GWToelichting: this.state.toelichting,
            })
            .then(function () {
              console.log('Updated cijfer!')
            })
            .catch(function (error) {
              console.error('Error writing document: ', error)
            })
        }
      })
    }

    var docRefCijfers = firebase.db
      .collection('users')
      .doc(this.props.authUser)
      .collection('modulen')
      .doc('mijnWerkleven')
      .collection('subModulen')
      .doc('2')
      .collection('cijfers')
      .doc('cijfers')

    const that = this

    docRefCijfers
      .update({
        gewenstCijfers: firebase.fieldValue.arrayUnion(this.state),
      })
      .then(function () {
        that.props.updateStateNaOpslaanNieuwGWCijfer(that.state)
        that.setState(
          {
            cijfer: '0',
            toelichting: '',
            smiley: '',
            gevoel: '',
            datum: new Date(),
          },
          () => {
            toast('Opgeslagen! 👍')
          },
        )
        that.props.togglePopup()
      })
      .catch(function (error) {
        console.error('Error writing document: ', error)
      })
  }

  handleChange = (event) => {
    console.log(event.target.value, event.target.name)
    if (event.target.name === 'GWSmiley') {
      this.setState({
        gwSmiley: event.target.value,
      })
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      })
    }
  }

  handleSliderChange = (value, key) => {
    this.setState(
      {
        [key]: value,
      },
      () => {
        console.log(this.state)
      },
    )
  }

  setEditorState(stateValue, fieldName, itemIndex) {
    // Algemene State Handler voor de Editor
    this.setState({
      [fieldName]: stateValue,
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="w-screen h-screen z-40 left-0 top-0 bg-black opacity-25 fixed" />
        <div className="flex z-50 justify-center items-center w-screen h-screen top-0 left-0 fixed">
          <div className="max-w-xl bg-white rounded shadow-lg p-8 relative huidig-werkleven-popup overflow-y-auto">
            <X
              className="absolute right-0 top-0 mr-5 mt-5 cursor-pointer"
              onClick={() => {
                console.log('CLICKED')
                this.props.togglePopup()
              }}
            />
            <TitleQuestionair>B. Gewenst werkleven</TitleQuestionair>

            <div className="h-1 block w-full mb-6 mt-4 border-b-2 border-gray-300" />

            <h3 className="font-bold text-black mb-2">Welke cijfer geef je jouw huidige werkleven?</h3>
            <Slider sliderValue={this.state.cijfer} onChange={this.handleSliderChange} keyName="cijfer" />

            <div className="mt-5">
              <h3 className="font-bold text-black mb-2">Waarom geef je dat cijfer?</h3>
              <Editor
                setEditorState={this.setEditorState}
                fieldName="toelichting"
                initialEditorState={this.state.toelichting}
              />
            </div>
            <SmileySelection
              handleChange={this.handleChange}
              title="Hoe voel je je bij dat cijfer? Kies een smiley."
              smileyType="smiley"
              smiley={this.state.smiley}
            />
            <div className="mt-8">
              <h3 className="font-bold text-gray-700 mb-2">Hoe is dat dan aan mij te zien of te merken?</h3>
              <Editor setEditorState={this.setEditorState} fieldName="gevoel" initialEditorState={this.state.gevoel} />
            </div>
            <button
              type="submit"
              onClick={() => {
                this.saveCurrentState()
                // this.props.togglePopup();
              }}
              className="py-2 px-5 mt-6 text-center bg-wa-button-light-green hover:bg-green-800 font-bold text-white md:text-lg rounded-full cursor-pointer shadow-md"
            >
              Cijfer aanpassen
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

class MijnWerkleven2Pagina3 extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      cijferPopupOpen: false,
      nieuwHWCijferPopUp: false,
      nieuwGWCijferPopUp: false,
    }

    this.togglePopup = this.togglePopup.bind(this)
    this.toggleNieuwHWCijferPopUp = this.toggleNieuwHWCijferPopUp.bind(this)
  }

  togglePopup() {
    this.setState({
      nieuwGWCijferPopUp: !this.state.nieuwGWCijferPopUp,
    })
  }

  toggleNieuwHWCijferPopUp() {
    this.setState({
      nieuwHWCijferPopUp: !this.state.nieuwHWCijferPopUp,
    })
  }

  render() {
    let reversedHuidigCijfersArrayWithIndex = this.props.huidigCijfers.slice(0)

    reversedHuidigCijfersArrayWithIndex.forEach((item, index) => {
      item.index = index
    })

    const laatsteGewensteCijfer = this.props.gewenstCijfers[this.props.gewenstCijfers.length - 1]

    console.log('laatsteGewensteCijfer', this.props.gewenstCijfers, this.props.huidigCijfers)

    return (
      <React.Fragment>
        <div className="block md:flex">
          <div className="w-full md:w-3/4 bg-white shadow-md rounded px-6 py-4">
            <h2 className="font-bold wa-green-color text-2xl">Huidig Werkleven</h2>
            <p className="mt-2">In de tabel en grafiek hieronder zie je hoe je jouw huidige werkleven beoordeelt.</p>
            <p className="">
              Voeg wanneer jij wilt een nieuw cijfer toe. Dit kan per dag, week, maand of wanneer er iets verandert in
              jouw werkleven.
            </p>
            <p className="">Ook kan je jouw cijfer voor gewenst werkleven aanpassen.</p>

            <div
              className="py-2 px-5 mt-6 text-center bg-wa-button-green hover:bg-green-800 font-bold md:text-lg rounded-full cursor-pointer shadow-md mb-6 block"
              onClick={this.toggleNieuwHWCijferPopUp}
            >
              + Cijfer toevoegen
            </div>
            {this.state.nieuwHWCijferPopUp ? (
              <Modal onClose={this.toggleNieuwHWCijferPopUp}>
                <NieuwCijferItem
                  history={this.props.history}
                  firebase={this.props.firebase}
                  authUser={this.props.authUser}
                  togglePopUp={this.toggleNieuwHWCijferPopUp}
                  voegCijferToeAanStateNaOpslaan={this.props.voegCijferToeAanStateNaOpslaan}
                />
              </Modal>
            ) : null}

            <div className="h-64 overflow-y-auto">
              {this.props.initialAPIDataReceived ? (
                reversedHuidigCijfersArrayWithIndex.reverse().map((item, index) => {
                  console.log(reversedHuidigCijfersArrayWithIndex, index)
                  return (
                    <CijferItem
                      item={item}
                      key={index}
                      index={item.index}
                      setEditorState={this.props.setEditorState}
                      handleChange={this.props.handleChange}
                      handleSliderChange={this.props.handleSliderChange}
                      saveCurrentState={this.props.saveCurrentState}
                      removeCijferHuidigWerkleven={this.props.removeCijferHuidigWerkleven}
                      history={this.props.history}
                      isLast={index === 0}
                    />
                  )
                })
              ) : (
                <LoaderCijferHuidigWerkleven />
              )}

              {/* <CijferItem toggleOpen={this.togglePopup} /> */}
            </div>
          </div>

          <div className="w-full md:w-1/4 bg-white shadow-md rounded px-6 py-4 mt-5 md:mt-0 ml-0 md:ml-5 text-center flex flex-col justify-between py-10">
            <p className="font-bold text-center wa-green-color text-2xl">Gewenst werkleven</p>
            <p className="font-bold text-6xl wa-green-color">
              {laatsteGewensteCijfer ? laatsteGewensteCijfer.cijfer : null}
            </p>
            <div className="flex justify-center font-bold text-6xl wa-green-color">
              <span role="img" aria-label="crying emoji" className="no-underline">
                <img src={SMILEYS[parseInt(laatsteGewensteCijfer?.smiley)]} height={56} width={56} />
              </span>
            </div>
            <button
              className="py-2 px-5 mt-6 text-center bg-wa-button-green hover:bg-green-800 font-bold md:text-lg rounded-full cursor-pointer shadow-md"
              onClick={this.togglePopup}
            >
              Cijfer aanpassen
            </button>
          </div>
        </div>

        {this.state.nieuwGWCijferPopUp ? (
          <NieuwGewenstPopup
            laatsteItem={laatsteGewensteCijfer}
            authUser={this.props.authUser}
            firebase={this.props.firebase}
            togglePopup={this.togglePopup}
            updateStateNaOpslaanNieuwGWCijfer={this.props.updateStateNaOpslaanNieuwGWCijfer}
            huidigCijfers={this.props.huidigCijfers}
          />
        ) : null}

        <div className="flex">
          <div className="w-full relative bg-white shadow-md rounded px-6 py-4 mt-5">
            <h2 className="font-bold mb-5 wa-green-color text-2xl mb-4">
              <BarChart className="inline-block mr-2 relative text-gray-500 icon-position" />
              Huidig Werkleven
            </h2>
            {this.props.huidigCijfers.length > 0 ? (
              <WerklevenCijferChart arrayItems={this.props.huidigCijfers} />
            ) : null}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default MijnWerkleven2Pagina3
