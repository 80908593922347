import React, { useState, useMemo, useEffect, createRef } from 'react'
import { CheckCircle, Circle, Link, X } from 'react-feather'
import Bin from '../../../components/Atoms/Bin'
import Modal from '../../../components/Template/Modal'
import ActielijstItemPopup from '../../../components/ActielijstItemPopup'
import ToDoLijstTwijfel from '../../../components/ToDoLijstTwijfel/ToDoLijstTwijfel'
import { withAuthentication } from '../../../components/Session'
import isBefore from 'date-fns/is_before'
import subDays from 'date-fns/sub_days'
import format from 'date-fns/format'
import nlLocale from 'date-fns/locale/nl'
import DeadLinePicker from '../../../components/Organisms/DeadLine-Picker'
import { toast } from 'react-toastify'
import { addAction, createActionObj, updateAction } from '../../../functions/actionsHelper'
import { getAllGoals, updateCurrentGoal } from '../../../functions/goalsHelper'
import DatePicker from 'react-date-picker'

const ActionListItem = (props) => {
  let currentInput = createRef()
  const [modalType, setModalType] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [title, setTitle] = useState('')
  const [addedToGoal, setAddedToGoal] = useState(false)
  const [toggleDateSelect, setToggleDateSelect] = useState(false)
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    if (props.title) {
      setTitle(props.title)
    }
  }, [props.title])

  const completedAction = (e) => {
    e.preventDefault()
    let actions = props.allActions || []
    const index = props.allActions.findIndex((a) => a.aUUID === props.item.aUUID)
    actions[index] = { ...props.item, voltooid: !props.item.voltooid }
    console.log('props.item', props.item)
    let action = { ...props.item, voltooid: !props.item.voltooid }

    // the action belongs to a goal
    if ((props.item.labels && props.item.labels.length > 0) || props.item.UUID) {
      getAllGoals(props.firebase, props.authUser).then((g) => {
        let findGoal = []
        if (props.item?.labels) {
          findGoal = g.find((g) => g.UUID === props.item?.labels[0]?.UUID)
        } else {
          findGoal = g.find((g) => g.UUID === props.item.UUID)
        }
        updateAction(props.firebase, props.authUser, action, findGoal).then((r) => {
          props.setReloading(true)
        })
      })
    } else {
      try {
        props.firebase.db
          .collection('users')
          .doc(props.authUser.uid)
          .collection('actielijst')
          .doc('acties')
          .set({ acties: [...actions] })
          .then((r) => {
            toast('Actie verplaatst naar  voltooid! 👍')
            console.log('VOLTOIID')
            props.setReloading(true)
          })
      } catch (e) {
        console.error('something horrible happened', e.message)
      }
    }
  }

  const onDelete = (e, title) => {
    e.preventDefault()
    const confirmDelete = window.confirm(`Wil je '${title}' verwijderen?`)

    if (confirmDelete) {
      const newActions = props.allActions?.some((a, i) => a.aUUID === props.item.aUUID)
      const isGoalAction = props.allGoals?.some((goal, i) => goal.actions?.filter((g) => g.aUUID === props.item.aUUID))

      if (isGoalAction && !newActions) {
        // search in goal and remove
        getAllGoals(props.firebase, props.authUser).then((g) => {
          const findGoal = g.find((g) => g.actions?.find((gA) => gA.aUUID === props.item.aUUID))
          const goalActions = findGoal.actions?.filter((a, i) => a.aUUID !== props.item.aUUID)

          updateCurrentGoal(
            props.firebase,
            props.authUser,
            findGoal,
            findGoal.labelNaam,
            findGoal.deadline,
            goalActions,
            findGoal.state,
            findGoal.notes,
          ).then(() => {
            props.setReloading(true)
          })
        })
      } else {
        const actions = props.allActions?.filter((action) => action.aUUID !== props.item.aUUID)

        try {
          props.firebase.db
            .collection('users')
            .doc(props.authUser.uid)
            .collection('actielijst')
            .doc('acties')
            .set({ acties: [...actions] })
            .then(() => {
              props.setReloading(true)
            })
        } catch (e) {
          console.error('something horrible happened', e.message)
        }
      }
    }
  }

  const onTitleEdit = (e) => {
    if (e.key === 'Enter') {
      let actions = props.allActions || []
      if (!props.item.UUID) {
        const index = props.allActions.findIndex((a) => a.aUUID === props.item.aUUID)
        actions[index] = { ...props.item, actieNaam: e.target.value }

        try {
          props.firebase.db
            .collection('users')
            .doc(props.authUser.uid)
            .collection('actielijst')
            .doc('acties')
            .set({ acties: [...actions] })
            .then(() => {
              setIsEdit(false)
              props.setReloading((prev) => !prev)
            })
        } catch (e) {
          console.error('something horrible happened', e.message)
        }
      } else {
        const updatedAction = { ...props.item, actieNaam: e.target.value }

        getAllGoals(props.firebase, props.authUser).then((g) => {
          const findGoal = g.find((g) => g.UUID === props.item.UUID)
          updateAction(props.firebase, props.authUser, updatedAction, findGoal).then(() => {
            setIsEdit(false)
            props.setReloading(true)
            toast('Actie titel is gewijzigd! 👍')
          })
        })
      }
    }
  }

  const handleCalendarClose = () => {
    let actions = props.allActions || []
    if (!props.item.UUID) {
      const index = props.allActions.findIndex((a) => a.aUUID === props.item.aUUID)
      let newDate = ''

      if (date) {
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const year = date.getFullYear()
        newDate = year + '-' + month + '-' + day
        actions[index] = { ...props.item, deadline: newDate }
      }

      try {
        props.firebase.db
          .collection('users')
          .doc(props.authUser.uid)
          .collection('actielijst')
          .doc('acties')
          .update({ acties: [...actions] })
          .then((r) => {
            toast('Actie is verplaatst naar Acties met datum')

            setToggleDateSelect(false)
            setIsEdit(false)
            props.setReloading(true)
          })
      } catch (e) {
        console.error('something horrible happened', e.message)
      }
    } else {
      if (date) {
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const year = date.getFullYear()
        const newDate = year + '-' + month + '-' + day

        const updatedAction = { ...props.item, deadline: newDate }
        getAllGoals(props.firebase, props.authUser).then((g) => {
          const findGoal = g.find((g) => g.UUID === props.item.UUID)
          updateAction(props.firebase, props.authUser, updatedAction, findGoal).then(() => {
            setToggleDateSelect(false)
            setIsEdit(false)
            props.setReloading(true)
            toast('Actie datum is gewijzigd! 👍')
          })
        })
      }
    }
  }

  useEffect(() => {
    const leftOvers = props.allActions?.filter((a) => !a.labels)

    try {
      props.firebase.db
        .collection('users')
        .doc(props.authUser.uid)
        .collection('actielijst')
        .doc('acties')
        .set({ acties: leftOvers })
    } catch (e) {
      console.error('something horrible happened', e.message)
    }
  }, [addedToGoal])

  useEffect(() => {
    if (isEdit) {
      currentInput.focus()
    }
  }, [isEdit])

  const modalContent = useMemo(() => {
    switch (modalType) {
      case 'actionList':
        return (
          <ActielijstItemPopup
            item={props.item}
            index={props.index}
            closePopup={props.setReloading}
            allGoals={props.allGoals}
            allActions={props.allActions}
            setReloading={props.setReloading}
            addedToGoal={setAddedToGoal}
            setModalType={setModalType}
          />
        )
      case 'bears':
        return (
          <ToDoLijstTwijfel
            closePopup={() => {
              setModalType(null)
            }}
          />
        )
    }
  }, [modalType])

  return (
    <>
      {modalType && (
        <Modal
          onClose={() => {
            props.setReloading(true)
            setModalType(null)
          }}
        >
          {modalContent}
        </Modal>
      )}
      <li
        key={props.index + '_key_action'}
        className={`flex flex-row pl-4 md:flex-row wa-list-container-bg my-2 text-black text-lg border-b md:px-4 py-3 relative md:items-center rounded-full justify-between ${
          props.completed && 'line-through'
        } `}
      >
        <div className="flex flex-row">
          <div
            className="grid place-items-center"
            onClick={(e) => {
              completedAction(e)
            }}
          >
            {props.completed ? (
              <CheckCircle className="relative wa-green-color select-none cursor-pointer" />
            ) : (
              <span className={'cursor-pointer actie-item-onvoltooid '}>
                <Circle
                  size={window.innerWidth >= 500 ? 30 : 15}
                  style={{
                    borderRadius: '50%',
                  }}
                  className="relative wa-green-color select-none cursor-pointer actie-icon-onvoltooid"
                />
                <CheckCircle
                  size={window.innerWidth >= 500 ? 30 : 15}
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '50%',
                  }}
                  className="relative wa-green-color select-none cursor-pointer actie-icon-voltooid"
                />
              </span>
            )}
          </div>
          {!isEdit ? (
            <span
              onClick={(e) => {
                setIsEdit(true)
              }}
              className="ml-1 text-sm md:text-base md:ml-3"
            >
              {title}
            </span>
          ) : (
            <div className="flex relative items-center min-w-min">
              <input
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value)
                }}
                className="flex justify-end py-2 px-5 md:py-3 md:px-6 w-full wa-text-gray border-b h-14 "
                onKeyDown={(e) => {
                  onTitleEdit(e)
                }}
                ref={(input) => {
                  currentInput = input
                }}
              />
              <X
                onClick={(e) => {
                  setIsEdit(false)
                }}
                className="absolute right-0 mr-2 cursor-pointer"
              />
            </div>
          )}
        </div>
        <div className="flex flex-row">
          {props.deadline && !toggleDateSelect && (
            <span
              className={
                isBefore(props.deadline, subDays(new Date(), 1)) && props.voltooid !== true
                  ? 'absolute px-1 right-0 mr-32 py-1 cursor-pointer text-red-700 md:px-4 bg-wa-bordeaux text-xs md:text-sm text-red-700 rounded-full'
                  : 'absolute px-1 right-0 mr-32 py-1 cursor-pointer text-gray-700 md:px-4 bg-wa-green text-xs md:text-sm text-green-800 rounded-full'
              }
              onClick={() => {
                setToggleDateSelect(true)
              }}
            >
              {props.deadline
                ? format(props.deadline, 'D MMM. YYYY', {
                    locale: nlLocale,
                  })
                : null}
            </span>
          )}
          {(!props.deadline || toggleDateSelect) && (
            <DatePicker
              selected={date}
              onChange={(date) => setDate(date)}
              onCalendarClose={handleCalendarClose}
              name={'deadline'}
              value={props.deadline ? new Date(props.deadline) : ''}
              format={'dd-MM-y'}
              locale={'nl-NL'}
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
              required
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleCalendarClose()
                }
              }}
            />
          )}
          <div
            className="cursor-pointer teddy-bear ml-2 mr-2 block w-8 h-8"
            onClick={(e) => {
              setModalType('bears')
            }}
          />
          <span
            className="mr-2 mt-1"
            onClick={(e) => {
              setModalType('actionList')
            }}
          >
            <Link className="wa-connect cursor-pointer inline-block relative -mt-1 hover:text-blue-700" size={20} />
          </span>
          <span className="mr-2 mt-0.5">
            <Bin
              onClickHandle={(e) => {
                onDelete(e, title)
              }}
            />
          </span>
        </div>
      </li>
    </>
  )
}

export default withAuthentication(ActionListItem)
